import React, { memo, useCallback, useMemo } from 'react'
import {
  filter,
  fromPairs,
  groupBy,
  map,
  mapObjIndexed,
  pipe,
  sort,
  sum,
  toPairs,
  uniqBy,
  unnest,
  values,
} from 'ramda'
import styled from '@emotion/styled/macro'
import { DesktopFiltersHeader } from './DesktopFiltersHeader'
import { useSelector } from 'react-redux'
import { useAction } from '../hooks/useAction'
import { setFilterMovies, setFilterMoviesItem } from '../actions/filter'
import { useInterimValue } from '../hooks/useInterimValue'
import Ripple from './Ripple'
import { PRIMARY_TEXT_COLOR, THEME_COLOR } from '../constants/colors'
import { DesktopFiltersButtons } from './DesktopFiltersButtons'
import normalizeTitle from '../utils/normalizeTitle'
import { CHROME_ANDROID_NO_HIGHLIGHT, SECONDARY_FONT } from '../constants/CSS'

const StyledCinemaFilter = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const StyledCinemaFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 80px;
  overflow-y: scroll;
`

const StyledFilterItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background: ${({ isSelected }) => (isSelected ? THEME_COLOR : 'transparent')};
  border: 2px solid
    ${({ isSelected }) => (isSelected ? THEME_COLOR : PRIMARY_TEXT_COLOR)};
  padding: 16px 26px;
  border-radius: 100px;
  margin: 0 25px 15px;
  cursor: pointer;

  ${CHROME_ANDROID_NO_HIGHLIGHT}
`

const StyledFilterItemText = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 900;
  color: ${({ isSelected }) => (isSelected ? 'white' : PRIMARY_TEXT_COLOR)};
  user-select: none;
  text-align: center;
`

const StyledAlphabetCharacter = styled.div`
  width: 50px;
  text-align: center;
  font-family: ${SECONDARY_FONT};
  font-size: 32px;
  font-weight: 900;
  color: ${THEME_COLOR};
  user-select: none;
  padding: 15px 0 10px;
  margin-bottom: 15px;
  position: relative;

  svg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const FilterMovieItem = memo(({ alphabetChar, canonicalTitle, title }) => {
  const [isSelected, setSelected] = useInterimValue({
    value: useSelector(state => state.filter.movies[canonicalTitle]),
    onChange: useAction(setFilterMoviesItem),
  })

  const onClick = useCallback(
    () => {
      setSelected(!isSelected, canonicalTitle)
    },
    [isSelected, setSelected],
  )

  return (
    <>
      {alphabetChar && (
        <StyledAlphabetCharacter>
          {alphabetChar}
          <svg width="50" height="5" viewBox="0 0 50 5">
            <rect y="1" width="50" height="3" rx="1.5" fill={THEME_COLOR} />
          </svg>
        </StyledAlphabetCharacter>
      )}
      <StyledFilterItem onClick={onClick} isSelected={isSelected}>
        {/*<Ripple />*/}
        <StyledFilterItemText isSelected={isSelected}>
          {title}
        </StyledFilterItemText>
      </StyledFilterItem>
    </>
  )
})

const normTitle = t =>
  t
    .replace('„', '')
    .replace('#', '')
    .toLocaleUpperCase()
const stringComparatorProp1 = ([_, a], [__, b]) =>
  normTitle(a).localeCompare(normTitle(b))

const useFilterableMovies = () => {
  const moviesData = useSelector(state => state.data.movies)
  const movieLinks = useSelector(state => state.data.movieLinks)
  const movieCsfdData = useSelector(state => state.data.movieCsfdData)

  return useMemo(
    () => {
      const movieTitledLinks = pipe(
        mapObjIndexed((links, canonicalTitle) => {
          const csfdTitle =
            movieCsfdData[canonicalTitle] &&
            normalizeTitle(movieCsfdData[canonicalTitle].title)

          if (csfdTitle) {
            return normalizeTitle(csfdTitle)
          }

          const title =
            links.length > 0 &&
            moviesData[links[0]] &&
            moviesData[links[0]].title

          if (title) {
            return title
          }

          return canonicalTitle
        }),
        toPairs,
        sort(stringComparatorProp1),
        groupBy(([_, title]) => normTitle(title).substr(0, 1)),
        map(sort(stringComparatorProp1)),
        values,
        unnest,
      )(movieLinks)

      const titleCharMap = pipe(
        map(([canonicalTitle, title]) => {
          return [canonicalTitle, normTitle(title).substr(0, 1)]
        }),
        uniqBy(([_, character]) => character),
        fromPairs,
      )(movieTitledLinks)

      return { movieTitledLinks, titleCharMap }
    },
    [moviesData, movieLinks, movieCsfdData],
  )
}

const useFiltersCount = () => {
  const movies = useSelector(state => state.filter.movies)

  return useMemo(
    () => {
      return sum(map(() => 1, filter(Boolean, values(movies))))
    },
    [movies],
  )
}

export const DesktopFiltersMovies = memo(({ closeFilter }) => {
  const setMovies = useAction(setFilterMovies)
  const filtersCount = useFiltersCount()
  const { movieTitledLinks, titleCharMap } = useFilterableMovies()

  const clearFilters = useCallback(
    () => {
      setMovies({})
    },
    [setMovies],
  )
  return (
    <StyledCinemaFilter>
      <DesktopFiltersHeader
        closeFilter={closeFilter}
        title="Vyber film"
        filtersCount={filtersCount}
      />
      <StyledCinemaFiltersContainer>
        {movieTitledLinks.map(([canonicalTitle, title]) => {
          const alphabetChar = titleCharMap[canonicalTitle]

          return (
            <FilterMovieItem
              key={canonicalTitle}
              alphabetChar={alphabetChar}
              canonicalTitle={canonicalTitle}
              title={title}
            />
          )
        })}
      </StyledCinemaFiltersContainer>
      <DesktopFiltersButtons
        disabled={!filtersCount}
        clearFilters={clearFilters}
        closeFilter={closeFilter}
      />
    </StyledCinemaFilter>
  )
})
