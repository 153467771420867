import React, { memo } from 'react'
import styled from '@emotion/styled/macro'
import { CloseCross } from './CloseCross'
import { THEME_COLOR } from '../constants/colors'
import Ripple from './Ripple'

const StyledClearButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  //position: absolute;
  //bottom: 0;
  //left: 0;
  //right: 0;
  padding: 20px 20px;
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.07);
`

const StyledClearButtonContainerBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: white;
  opacity: 0.9;
`

const StyledButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 900;
  user-select: none;
  position: relative;
  width: 45%;
  height: 45px;
  overflow: hidden;
`

const StyledClearButton = styled(StyledButton)`
  color: ${({ disabled }) => (disabled ? '#C9C9C9' : '#C43944')};
  border: 1px solid ${({ disabled }) => (disabled ? '#C9C9C9' : '#C43944')};
`

const StyledCloseButton = styled(StyledButton)`
  background: #39c695;
  color: white;
`

export const DesktopFiltersButtons = memo(
  ({ clearFilters, closeFilter, disabled }) => {
    return (
      <StyledClearButtonContainer>
        {/*<StyledClearButtonContainerBackground />*/}
        <StyledClearButton onClick={clearFilters} disabled={disabled}>
          Vymazat filtry
          <Ripple />
        </StyledClearButton>
        <StyledCloseButton onClick={closeFilter}>
          Potvrdit
          <Ripple />
        </StyledCloseButton>
      </StyledClearButtonContainer>
    )
  },
)
