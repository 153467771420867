const currentDayMs = new Date().getTime()

const getDatesDayDifference = date1 => {
  const ONE_DAY = 1000 * 60 * 60 * 24
  const date1_ms = date1.getTime()
  const difference_ms = date1_ms - currentDayMs

  if (difference_ms <= 0) {
    return 0
  }

  return Math.ceil(difference_ms / ONE_DAY)
}

export default getDatesDayDifference
