import React from 'react'
import { pure } from 'recompose'
import { THEME_COLOR } from '../constants/colors'

const Camera = pure(() => (
  <svg width="17.726" height="15.403" viewBox="0 0 17.726 15.403">
    <path
      fill={THEME_COLOR}
      d="M113.1-17.081a.723.723,0,0,0-1-.255l-2.429,1.406-.617.362V-12.2l.575.341c.021,0,.021.021.042.021l2.386,1.385a.656.656,0,0,0,.4.127.718.718,0,0,0,.724-.724v-2.855l.022-2.812A.56.56,0,0,0,113.1-17.081Z"
      transform="translate(-95.48 25.028)"
    />
    <path
      fill={THEME_COLOR}
      d="M93.624-21.123a3.428,3.428,0,0,0,3.431-3.43,3.428,3.428,0,0,0-3.431-3.43,3.428,3.428,0,0,0-3.43,3.43A3.415,3.415,0,0,0,93.624-21.123Zm0-4.836a1.4,1.4,0,0,1,1.406,1.406,1.4,1.4,0,0,1-1.406,1.406,1.418,1.418,0,0,1-1.406-1.406A1.378,1.378,0,0,1,93.624-25.959Z"
      transform="translate(-90.194 27.983)"
    />
    <path
      fill={THEME_COLOR}
      d="M102.616-18.267a4.084,4.084,0,0,1-1.832.447,3.984,3.984,0,0,1-2.216-.66H96.139a3.984,3.984,0,0,1-2.216.66,3.823,3.823,0,0,1-1.832-.447,1.665,1.665,0,0,0-.831,1.427v5.2a1.732,1.732,0,0,0,1.726,1.726h8.756a1.732,1.732,0,0,0,1.726-1.726v-5.2A1.734,1.734,0,0,0,102.616-18.267Z"
      transform="translate(-90.493 25.319)"
    />
    <path
      fill={THEME_COLOR}
      d="M103.157-21.123a3.428,3.428,0,0,0,3.43-3.43,3.428,3.428,0,0,0-3.43-3.43,3.427,3.427,0,0,0-3.43,3.43A3.427,3.427,0,0,0,103.157-21.123Zm0-4.836a1.4,1.4,0,0,1,1.406,1.406,1.4,1.4,0,0,1-1.406,1.406,1.419,1.419,0,0,1-1.406-1.406A1.391,1.391,0,0,1,103.157-25.959Z"
      transform="translate(-92.866 27.983)"
    />
  </svg>
))

export default Camera
