import React from 'react'
import { pure } from 'recompose'
import {
  AERO,
  BIOOKO,
  SVETOZOR,
  LUCERNA,
  DLABACOV,
  EVALD,
  ATLAS,
  PILOTU,
  MAT,
  KINO35,
  RADOTIN,
  MODRANSKY_BIOGRAF,
  EDISON,
  KINOLOD,
  KARLIN,
  PONREPO,
  PRITOMNOST,
  MOJEKINOLIVE,
  STRAHOV,
  LEDARNY,
} from '../constants/cinemas'

const AeroLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={width}
    height={height}
  >
    <g className={AERO}>
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M311.5,386.6h-3.7c-1.9,0-3.2,1.3-3.2,3.2v10.8c0,1.9,1.3,3.2,3.2,3.2h3.7c1.9,0,3.2-1.3,3.2-3.2v-10.8
    C314.6,387.8,313.4,386.6,311.5,386.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M199.1,386.6h-3.7c-1.9,0-3.2,1.3-3.2,3.2v10.8c0,1.9,1.3,3.2,3.2,3.2h3.7c1.9,0,3.2-1.3,3.2-3.2v-10.8
    C202.3,387.8,201,386.6,199.1,386.6z"
      />
      <polygon
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        points="225.3,389.5 222.4,398.1 228.5,398.1 225.5,389.5 	"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M283.2,386.9h-6.1v6.7h6.1c1.2,0,1.8-0.9,1.8-2.1V389C285.1,387.7,284.4,386.9,283.2,386.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M363.8,251.5c-10.9,35.8-0.2,72.6,10.3,72.6c10.9,0,16.9-22.1,19.4-43.9C378,276.6,364.4,264.5,363.8,251.5z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M250,0C111.9,0,0,111.9,0,250c0,138.1,111.9,250,250,250c138.1,0,250-111.9,250-250C500,111.9,388.1,0,250,0z M263.3,116
    c1-4.5,10.4-6.2,34.5-2.1c3.1,0.7,6.9,1.7,11.4,2.4l31.1-23.8c4.1-1,5.5,1.4,4.8,4.1l-18.6,23.8c7.9,2.1,16.2,4.5,22.4,6.9l6.9-9.3
    c4.5-1,5.2,1,4.8,4.1l-0.3,11c-0.7,2.8-21.1,1.7-39.7-0.3l-3.5,22.8c-0.3,3.1-2.4,4.8-6.2,2.4l-7.6-27.3l-8.3-1.4
    C270.9,125,262.6,120.5,263.3,116z M127.8,408.4l-7.3-11.1l-2.9,3.3v7.8h-6.1v-26.5h6.1v10.8h0.3l9.2-10.8h6.7l-9.4,10.8l10.4,15.7
    H127.8z M146.2,408.4h-6.1v-26.5h6.1V408.4z M177.4,408.4h-5.8l-10.4-16.1H161v16.1h-5.8v-26.5h5.8l10.4,16.1h0.2v-16.1h5.8V408.4z
    M208.4,399.9c0,5.7-3,8.8-11.1,8.8c-8.1,0-11.1-3.1-11.1-8.8v-9.5c0-5.7,3-8.8,11.1-8.8c8.1,0,11.1,3.1,11.1,8.8V399.9z
    M232,408.4l-1.8-5.3h-9.5l-1.8,5.3h-6l9.9-26.5h5.8l9.9,26.5H232z M263.6,408.4h-19.3v-26.5H263v5h-12.7v5.7h10.6v5h-10.6v5.7
    h13.2V408.4z M285.2,408.4l-5.5-9.7h-2.6v9.7H271v-26.5h12.8c4.7,0,7.4,2.5,7.4,7.6v1.5c0,4.1-1.7,6.6-4.9,7.4l5.7,10H285.2z
    M320.7,399.9c0,5.7-3,8.8-11.1,8.8s-11.1-3.1-11.1-8.8v-9.5c0-5.7,3-8.8,11.1-8.8s11.1,3.1,11.1,8.8V399.9z M334.7,408.4h-6.4V402
    h6.4V408.4z M364.1,390.9H358v-1.2c0-1.9-1-3.2-3.4-3.2h-2.9c-1.9,0-3.2,1.3-3.2,3.2v10.8c0,1.9,1.3,3.2,3.2,3.2h2.9
    c2.4,0,3.4-1.3,3.4-3.2v-1.2h6.1v0.5c0,5.7-2.5,8.8-10.6,8.8s-11.1-3.1-11.1-8.8v-9.5c0-5.7,3-8.8,11.1-8.8s10.6,3.1,10.6,8.8
    V390.9z M390,408.4h-19.6v-5l12.1-16.2v-0.2h-12.1v-5h19.2v5l-12,16.2v0.2H390V408.4z M410.8,280.8c-7.2,37.2-30.1,55.8-43.7,55.8
    c-7.4,0-20.7-3.4-26.7-22.8c-5.9,5.7-12.6,9.7-20.3,9.7c-13.5,0-23.8-19.7-16.2-66.3c-4.5,0.3-8.7,0.2-12.6-0.2
    c-7.4,54-39.3,78.2-56.7,78.2c-14.7,0-26.8-8.7-34.3-24.3c-6.3,3.2-12.3,4.9-17.5,4.9c-7.7,0-17.2-3.5-24.3-19.3
    c-14.7,27.4-35.2,41.2-55.5,41c-28.6-0.3-45.6-33.5-45.6-74.5c0-97.3,63.2-163.6,109.1-163.6c47.7,0,32.1,68.8,12.9,80.5
    c1.4,37.1-2.8,67.7-10.6,91.8c3.5,20,9.1,32.4,19,32.4c2.8,0,5.8-0.8,8.8-2.2c-2.8-8.9-4.3-19.4-4.3-31.3
    c0-53.2,20.4-83.2,41.8-83.2c13.8,0,20.7,7.9,20.7,26.2c0,36.2-16.7,65.7-35.6,83.5c3.7,16.4,11.3,25.5,21.8,25.5
    c15.3,0,31.3-36.9,33.9-71c-7.1-4.7-11.2-12.6-11.2-24.9c0-11,10.3-28,27.3-20c1.4,11.2,1.9,21.7,1.6,31.3
    c12.1-0.3,25-2.8,30.1-6.1c4.1,0.3,7.9,1.4,5.9,9c-12.4,43.8-9.3,67.6-1,67.6c3.6,0,7.2-1.7,10.6-4.2c-0.6-4.1-0.9-8.6-0.9-13.8
    c0-49.3,24.2-68.6,35.3-66.5c4.6-4.4,9.9-6,13.4-6c15.2,0,26.9,11.7,26.9,38.3c0,3.9-0.2,7.6-0.5,11.3c42.1-7.2,59.1-97-40.6-125.5
    c-1.4-6.2,2.8-7.9,9.3-5.9C487.3,168.8,473.2,272.5,410.8,280.8z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M164.3,177.7c-1.3-1.9-1.4-4.1-0.8-5.4c14.8-35.2,11.4-58.3-2.8-58.3c-33.1,0-73.9,58.7-73.9,148.1
    c0,32.4,9.7,60.4,27.3,60.4c12.1,0,28.4-15.8,36-61c-1.2-10.2-1.8-22.3-1.8-36.6C148.4,202.3,155,184.7,164.3,177.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M394.6,265.1c0.1-2.6,0.2-5.2,0.2-7.6c0-24.2-11.7-31.1-13.8-24.8C376.9,245.6,383.4,259.1,394.6,265.1z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M237.4,216.1c0-10-2.4-13.1-5.2-13.1c-5.5,0-15.5,19.7-15.5,66.6c0,4.8,0.2,9.3,0.5,13.5C228.6,267,237.4,243,237.4,216.1z"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M295.1,129.5l8.3,1.4l7.6,27.3c3.8,2.4,5.9,0.7,6.2-2.4l3.5-22.8c18.6,2.1,39,3.1,39.7,0.3l0.3-11
    c0.3-3.1-0.3-5.2-4.8-4.1l-6.9,9.3c-6.2-2.4-14.5-4.8-22.4-6.9l18.6-23.8c0.7-2.8-0.7-5.2-4.8-4.1l-31.1,23.8
    c-4.5-0.7-8.3-1.7-11.4-2.4c-24.2-4.1-33.5-2.4-34.5,2.1C262.6,120.5,270.9,125,295.1,129.5z"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M372,142.2c99.7,28.5,82.7,118.3,40.6,125.5c0.3-3.6,0.5-7.4,0.5-11.3c0-26.6-11.7-38.3-26.9-38.3
    c-3.5,0-8.8,1.5-13.4,6c-11.1-2.1-35.3,17.2-35.3,66.5c0,5.1,0.3,9.7,0.9,13.8c-3.4,2.4-7,4.2-10.6,4.2c-8.3,0-11.4-23.8,1-67.6
    c2.1-7.6-1.7-8.6-5.9-9c-5.2,3.4-18,5.8-30.1,6.1c0.2-9.7-0.3-20.1-1.6-31.3c-16.9-7.9-27.3,9-27.3,20c0,12.3,4.1,20.2,11.2,24.9
    c-2.6,34.1-18.7,71-33.9,71c-10.6,0-18.1-9.1-21.8-25.5c18.9-17.8,35.6-47.3,35.6-83.5c0-18.3-6.9-26.2-20.7-26.2
    c-21.4,0-41.8,30-41.8,83.2c0,11.9,1.5,22.4,4.3,31.3c-3,1.4-6,2.2-8.8,2.2c-9.9,0-15.6-12.4-19-32.4c7.8-24.1,12-54.7,10.6-91.8
    c19.1-11.7,34.8-80.5-12.9-80.5c-45.9,0-109.1,66.3-109.1,163.6c0,41.1,16.9,74.2,45.6,74.5c20.3,0.2,40.8-13.6,55.5-41
    c7.1,15.8,16.6,19.3,24.3,19.3c5.2,0,11.2-1.7,17.5-4.9c7.4,15.6,19.6,24.3,34.3,24.3c17.4,0,49.4-24.2,56.7-78.2
    c3.9,0.4,8.1,0.5,12.6,0.2c-7.6,46.6,2.8,66.3,16.2,66.3c7.6,0,14.4-4,20.3-9.7c5.9,19.3,19.3,22.8,26.7,22.8
    c13.5,0,36.4-18.5,43.7-55.8c62.3-8.3,76.5-112-29.5-144.4C374.8,134.3,370.6,136,372,142.2z M114.2,322.4
    c-17.6,0-27.3-28-27.3-60.4c0-89.4,40.7-148.1,73.9-148.1c14.2,0,17.6,23.1,2.8,58.3c-0.7,1.3-0.6,3.5,0.8,5.4
    c-9.3,7-15.9,24.6-15.9,47c0,14.3,0.7,26.4,1.8,36.6C142.6,306.7,126.4,322.4,114.2,322.4z M232.2,203c2.8,0,5.2,3.1,5.2,13.1
    c0,26.8-8.8,50.9-20.2,67c-0.4-4.2-0.5-8.7-0.5-13.5C216.7,222.7,226.7,203,232.2,203z M381,232.7c2.1-6.2,13.8,0.7,13.8,24.8
    c0,2.4-0.1,4.9-0.2,7.6C383.4,259.1,376.9,245.6,381,232.7z M374.1,324.1c-10.5,0-21.3-36.8-10.3-72.6c0.6,13,14.2,25.1,29.7,28.7
    C391,302.1,385,324.1,374.1,324.1z"
      />
      <polygon
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        points="171.6,398 171.4,398 161,381.9 155.2,381.9 155.2,408.4 161,408.4 161,392.3 161.2,392.3
    171.6,408.4 177.4,408.4 177.4,381.9 171.6,381.9"
      />
      <rect
        x="140.1"
        y="381.9"
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        width="6.1"
        height="26.5"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M353.5,381.6c-8.1,0-11.1,3.1-11.1,8.8v9.5c0,5.7,3,8.8,11.1,8.8s10.6-3.1,10.6-8.8v-0.5H358v1.2
    c0,1.9-1,3.2-3.4,3.2h-2.9c-1.9,0-3.2-1.3-3.2-3.2v-10.8c0-1.9,1.3-3.2,3.2-3.2h2.9c2.4,0,3.4,1.3,3.4,3.2v1.2h6.1v-0.5
    C364.1,384.7,361.6,381.6,353.5,381.6z"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M197.3,381.6c-8.1,0-11.1,3.1-11.1,8.8v9.5c0,5.7,3,8.8,11.1,8.8c8.1,0,11.1-3.1,11.1-8.8v-9.5
    C208.4,384.7,205.4,381.6,197.3,381.6z M202.3,400.5c0,1.9-1.3,3.2-3.2,3.2h-3.7c-1.9,0-3.2-1.3-3.2-3.2v-10.8
    c0-1.9,1.3-3.2,3.2-3.2h3.7c1.9,0,3.2,1.3,3.2,3.2V400.5z"
      />
      <polygon
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        points="133.8,381.9 127.1,381.9 117.9,392.7 117.6,392.7 117.6,381.9 111.5,381.9 111.5,408.4
    117.6,408.4 117.6,400.5 120.5,397.3 127.8,408.4 134.8,408.4 124.4,392.7 	"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M291.1,391v-1.5c0-5.1-2.8-7.6-7.4-7.6H271v26.5h6.1v-9.7h2.6l5.5,9.7h6.7l-5.7-10
    C289.4,397.6,291.1,395.1,291.1,391z M285.1,391.6c0,1.2-0.7,2.1-1.8,2.1h-6.1v-6.7h6.1c1.2,0,1.8,0.8,1.8,2V391.6z"
      />
      <rect
        x="328.4"
        y="402"
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        width="6.4"
        height="6.3"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M309.6,381.6c-8.1,0-11.1,3.1-11.1,8.8v9.5c0,5.7,3,8.8,11.1,8.8s11.1-3.1,11.1-8.8v-9.5
    C320.7,384.7,317.7,381.6,309.6,381.6z M314.6,400.5c0,1.9-1.3,3.2-3.2,3.2h-3.7c-1.9,0-3.2-1.3-3.2-3.2v-10.8
    c0-1.9,1.3-3.2,3.2-3.2h3.7c1.9,0,3.2,1.3,3.2,3.2V400.5z"
      />
      <polygon
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        points="377.6,403.1 389.6,386.9 389.6,381.9 370.5,381.9 370.5,386.9 382.5,386.9 382.5,387.2 370.5,403.4 370.5,408.4 390,408.4 390,403.4 377.6,403.4"
      />
      <polygon
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        points="250.3,397.6 260.9,397.6 260.9,392.6 250.3,392.6 250.3,386.9 263,386.9 263,381.9 244.3,381.9 244.3,408.4 263.6,408.4 263.6,403.4 250.3,403.4"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M222.8,381.9l-9.9,26.5h6l1.8-5.3h9.5l1.8,5.3h6.5l-9.9-26.5H222.8z M222.4,398.1l3-8.6h0.2l3,8.6H222.4z"
      />
    </g>
  </svg>
)

const BioOkoLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={width}
    height={height}
  >
    <image className="all" width="100%" height="100%" />
    <g className={BIOOKO}>
      <rect
        x="33.8"
        y="193.2"
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        width="432.5"
        height="113.6"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M224.4,250c0-14.9,11.5-27,25.7-27c14.2,0,25.7,12.2,25.7,27c0,14.9-11.5,27-25.7,27C235.9,277,224.4,264.9,224.4,250
     M192,250c0,31.1,26.4,56.8,58.1,56.8s58.1-25.7,58.1-56.8s-26.4-56.8-58.1-56.8S192,218.9,192,250 M250.1,193.2
    c75,0,154.7,23.6,216.2,56.8c-61.5,33.1-141.2,56.8-216.2,56.8S95.3,283.1,33.8,250C95.3,216.9,175.1,193.2,250.1,193.2 M250.1,0
    L33.8,125v250l216.2,125l216.2-125V125L250.1,0z"
      />
    </g>
  </svg>
)

const SvetozorLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={width}
    height={height}
  >
    <image className="all" width="100%" height="100%" />
    <g className={SVETOZOR}>
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        fillRule="evenodd"
        d="M0,0h500v500H0V0z"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M88.8,211h28.5v34.5l32.3-34.5h36L146,253.5l41.8,59.8H153l-26.9-38.4l-8.8,9.5v28.9H88.8V211z"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M209.8,200.3c8.3,0,14.5,6.1,14.5,14.8c0,8.3-6.1,14.5-14.5,14.5c-8.6,0-14.8-6.1-14.8-14.5
    C195.1,206.5,201.2,200.3,209.8,200.3z M196.5,236.6h26.3v76.8h-26.3V236.6z"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M240.4,236.6h26.3v8.5c5.3-6.1,13-9.9,22.2-9.9c17.4,0,29.7,13,29.7,31.4v46.8h-26.3v-43.7
    c0-7.3-5.3-12.4-12.9-12.4c-7.3,0-12.6,5-12.7,12v44.2h-26.3V236.6z"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M373.7,235.1c24.3,0,41.4,16.5,41.4,39.9c0,23.2-17.1,39.8-41.4,39.8c-24.1,0-41.2-16.5-41.2-39.8
    C332.4,251.6,349.5,235.1,373.7,235.1z M373.7,292.1c8.3,0,14.3-7.2,14.3-17.1c0-10.1-6-17.3-14.3-17.3c-8.3,0-14.2,7.2-14.2,17.3
    C359.5,285,365.3,292.1,373.7,292.1z"
      />
      <polygon
        fillRule="evenodd"
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        points="326.3,147.2 366.3,147.2 338.5,174.9 338.6,215.8 310.7,187.1 269.9,187.1
    298.6,159 298.7,119.3 326.3,147.2"
      />
    </g>
  </svg>
)

const LucernaLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={width}
    height={height}
  >
    <image className="all" width="100%" height="100%" />
    <g className={LUCERNA}>
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M175.4,332.6c0-36.9,0-73.7,0-110.6c0-4.8,0-4.8-4.8-4.8c-2.7,0-5.4,0-8,0c-1.8,0-2.8-0.6-1.5-2.5
		c4.4-6.2,8.8-12.4,13.2-18.6c1.1-1.5,2.6-1.6,4.3-1.6c7.2,0,14.4,0,21.5,0c40,0,80,0,119.9-0.1c3.3,0,5.3,1,7.1,3.7
		c3.6,5.4,7.6,10.6,11.4,16c0.5,0.7,1.6,1.5,1,2.5c-0.6,1-1.7,0.6-2.7,0.6c-2.6,0-5.1,0-7.7,0c-4.3,0-4.4,0.1-4.4,4.3
		c0,19.9,0,39.9,0,59.8c0,53.2-0.1,106.3,0.1,159.5c0,5.1-1.5,9-4.7,12.7c-4.5,5.3-8.9,10.7-13.3,16.1c-1.3,1.7-2.8,2.3-4.9,2.3
		c-34.4,0-68.8,0-103.2,0c-2.4,0-4-0.8-5.5-2.6c-5.1-6.4-10.2-12.7-15.6-18.8c-1.9-2.2-2.4-4.5-2.4-7.3
		C175.4,406.4,175.4,369.5,175.4,332.6z M271.9,409.5c0-11.7,0-23.4,0-35.1c0-2.4-0.7-3.5-3.3-3.4c-4.6,0.2-9.2,0.2-13.8,0
		c-2.6-0.1-3.3,1-3.3,3.4c0.1,9.4,0,18.9,0,28.3c0,1-0.1,1.9-0.2,2.9c-0.1,0.6-0.5,0.9-1.1,1c-0.7,0.1-1.3-0.3-1.4-1
		c-0.1-0.9-0.2-1.9-0.2-2.9c0-9.4-0.1-18.9,0-28.3c0-2.5-0.8-3.5-3.3-3.4c-4.6,0.2-9.2,0.2-13.8,0c-2.6-0.1-3.3,1-3.2,3.4
		c0.1,23.2,0.1,46.3,0,69.5c0,2.5,0.8,3.4,3.3,3.4c12.2-0.1,24.4-0.1,36.7,0c2.9,0,3.7-1,3.6-3.7
		C271.8,432.2,271.9,420.9,271.9,409.5z M222.9,409.2C222.9,409.2,222.9,409.2,222.9,409.2c0-11.6-0.1-23.2,0-34.7
		c0-2.7-0.9-3.5-3.5-3.5c-4.7,0.2-9.4,0.2-14.1,0c-2.6-0.1-3.3,1-3.3,3.4c0.1,8.8,0,17.6,0,26.4c0,1.4,0,2.8-0.1,4.2
		c0,0.8-0.3,1.5-1.3,1.5c-1,0-1.2-0.8-1.3-1.6c-0.1-1-0.1-1.9-0.1-2.9c0-9.2-0.1-18.4,0-27.7c0-2.5-0.9-3.4-3.4-3.3
		c-4.1,0.1-8.2,0.2-12.2,0c-2.9-0.1-3.7,1-3.7,3.7c0.1,22.9,0.1,45.9,0,68.8c0,2.7,0.8,3.8,3.6,3.8c11.9-0.1,23.8-0.1,35.7,0
		c2.8,0,3.7-0.9,3.6-3.7C222.8,432.2,222.9,420.7,222.9,409.2z M277.7,408.9c0,11.6,0.1,23.2,0,34.7c0,2.8,0.9,3.8,3.7,3.7
		c11.9-0.1,23.8-0.1,35.7,0c2.2,0,3.1-0.7,3.1-3c0-23.5,0-47,0-70.4c0-2.1-0.8-2.9-2.8-2.8c-4.3,0.1-8.6,0.1-12.9,0
		c-2.7-0.1-3.6,0.9-3.5,3.5c0.1,9.2,0,18.4,0,27.7c0,1,0,1.9-0.1,2.9c-0.1,0.8-0.5,1.5-1.4,1.5c-0.9,0-1.3-0.7-1.3-1.5
		c-0.1-1.5-0.3-3-0.3-4.5c0-8.8-0.1-17.6,0-26.4c0-2.3-0.8-3.3-3.2-3.2c-4.3,0.1-8.6,0.2-12.9,0c-3.1-0.2-4.1,1-4,4.1
		C277.8,386.4,277.7,397.6,277.7,408.9z M271.9,249.1c0-9.5,0-19.1,0-28.6c0-2.2-0.6-3.3-3.1-3.3c-12.5,0.1-25.1,0.1-37.6,0
		c-2.1,0-2.9,0.8-2.9,2.9c0,19.3,0,38.6,0,57.9c0,2.1,0.9,2.8,2.9,2.8c4.6-0.1,9.2-0.2,13.8,0c2.8,0.1,3.7-0.9,3.7-3.7
		c-0.1-9.4-0.1-18.9,0-28.3c0-1.1-0.3-2.7,1.3-2.8c1.7-0.1,1.4,1.6,1.5,2.7c0.1,0.9,0,1.7,0,2.6c0,8.6,0.1,17.1-0.1,25.7
		c-0.1,3,1,3.9,3.9,3.8c4.3-0.2,8.6-0.2,12.9,0c2.8,0.1,3.7-0.9,3.7-3.7C271.8,267.8,271.9,258.4,271.9,249.1z M222.9,249
		c0-9.5-0.1-19.1,0-28.6c0-2.4-0.8-3.2-3.2-3.2c-12.2,0.1-24.4,0.1-36.6,0c-2.2,0-3.1,0.8-3.1,3c0,19.2,0,38.4,0,57.5
		c0,2.2,0.8,3.1,3,3c4.3-0.1,8.6-0.1,12.9,0c2.5,0.1,3.4-0.9,3.3-3.4c-0.1-8.4,0-16.7,0-25.1c0-1.6,0-3.2,0.1-4.8
		c0-0.8,0.3-1.5,1.3-1.5c1,0,1.2,0.8,1.3,1.5c0.1,1,0.1,1.9,0.1,2.9c0,9,0.1,18,0,27c0,2.5,0.8,3.4,3.3,3.4c4.7-0.1,9.4-0.2,14.1,0
		c2.6,0.1,3.6-0.8,3.5-3.5C222.8,267.8,222.9,258.4,222.9,249z M277.7,248.7c0,0-0.1,0-0.1,0c0,9.6,0,19.3,0,28.9
		c0,2.2,0.7,3.2,2.9,3.1c4.8-0.1,9.6-0.1,14.5,0c2.3,0.1,2.9-0.8,2.8-3c-0.1-9.3-0.1-18.6,0-27.9c0-1.4-0.7-3.9,1.6-3.8
		c2.2,0,1.5,2.5,1.5,3.9c0.1,9.2,0.1,18.4,0,27.6c0,2.3,0.7,3.3,3.1,3.3c4.3-0.1,8.6-0.1,12.9,0c2.3,0.1,3.3-0.7,3.3-3.1
		c-0.1-19.1-0.1-38.1,0-57.2c0-2.3-0.8-3.3-3.2-3.3c-11.8-0.1-23.6-0.2-35.3-0.5c-3.2-0.1-4,1.1-4,4.1
		C277.8,230.1,277.7,239.4,277.7,248.7z M222.9,296.6C222.9,296.6,222.9,296.6,222.9,296.6c0-3.6-0.1-7.3,0-10.9
		c0-1.9-0.7-2.7-2.6-2.7c-5.2,0.1-10.5,0.1-15.7,0c-1.7,0-2.6,0.7-2.5,2.5c0,7.3,0,14.6,0,21.8c0,1.7,0.8,2.6,2.5,2.6
		c5.2,0.1,10.5,0.1,15.7,0.2c2.2,0.1,2.8-1,2.7-3C222.8,303.7,222.9,300.1,222.9,296.6z M222.9,325.6c0-3.4-0.1-6.8,0-10.3
		c0.1-2-0.7-2.9-2.8-2.9c-5.1,0.1-10.3,0.1-15.4,0c-2.1,0-2.8,0.9-2.8,2.9c0.1,7,0.1,13.9,0,20.9c0,2.2,0.7,3.1,3,3.1
		c5-0.1,10.1-0.1,15.1,0c2.3,0,3-1,2.9-3.1C222.8,332.7,222.9,329.2,222.9,325.6z M222.9,355.1c0-3.4-0.1-6.9,0-10.3
		c0-2-0.8-2.9-2.8-2.9c-5,0-10.1-0.1-15.1-0.1c-2.2,0-3.1,0.9-3,3.1c0.1,6.7,0.1,13.5,0,20.2c0,2.3,0.7,3.3,3.1,3.3
		c4.9-0.1,9.8-0.1,14.8,0c2.2,0,3.1-0.8,3.1-3C222.8,362,222.9,358.5,222.9,355.1z M248.7,296.4c0-3.5-0.1-7.1,0-10.6
		c0.1-2.1-0.8-2.8-2.9-2.8c-4.9,0.1-9.9,0.1-14.8,0c-1.9,0-2.7,0.6-2.7,2.6c0.1,7.2,0,14.3,0,21.5c0,1.9,0.9,2.7,2.7,2.7
		c4.9,0.1,9.8,0.2,14.8,0.3c2.1,0.1,2.9-0.8,2.9-2.8C248.6,303.7,248.7,300.1,248.7,296.4z M251.6,296.6c0,3.6,0,7.3,0,10.9
		c0,1.5,0.3,2.4,2.1,2.4c5.1-0.1,10.3-0.1,15.4-0.1c2.1,0,2.8-1,2.8-3c0-7.1,0-14.1,0-21.2c0-1.9-0.7-2.7-2.7-2.7
		c-5,0.1-10.1,0.1-15.1,0c-2,0-2.7,0.8-2.6,2.7C251.6,289.4,251.6,293,251.6,296.6z M271.9,326.1c0-3.6,0-7.3,0-10.9
		c0-1.9-0.7-2.7-2.7-2.7c-5,0.1-10.1,0-15.1,0c-1.7,0-2.6,0.6-2.6,2.4c0,7.3,0,14.6,0,21.8c0,2,1,2.6,2.8,2.6c4.9,0,9.9-0.1,14.8,0
		c2.1,0,2.8-1,2.8-2.9C271.8,333,271.9,329.5,271.9,326.1z M228.4,325.7c0,3.5,0,7.1,0,10.6c0,1.9,0.6,3,2.7,3c5-0.1,10.1,0,15.1,0
		c1.7,0,2.5-0.7,2.5-2.5c0-7.3,0-14.6,0-21.8c0-1.8-0.8-2.5-2.5-2.5c-5,0-10.1,0.1-15.1,0c-2.2,0-2.7,1.1-2.7,3
		C228.4,318.9,228.4,322.3,228.4,325.7z M251.6,355.1c0,3.5,0,7.1,0,10.6c0,1.5,0.3,3,2.1,2.9c5.3-0.4,10.7,0.4,16-0.2
		c1.6-0.2,2.2-1.1,2.2-2.6c0-7.2,0-14.3,0-21.5c0-1.7-0.6-2.5-2.4-2.4c-4.9,0.1-9.8,0.1-14.8,0.1c-2.4,0-3.2,1-3.1,3.3
		C251.6,348.5,251.6,351.8,251.6,355.1z M248.7,355.5c0-3.4-0.1-6.9,0-10.3c0.1-2.3-0.7-3.3-3.1-3.3c-4.7,0.1-9.4,0.1-14.1,0
		c-2.1,0-3.1,0.7-3.1,3c0.1,6.8,0.1,13.7,0,20.5c0,2,0.7,3,2.8,2.9c4.9,0,9.8,0,14.8,0c2.1,0,2.8-1,2.8-3
		C248.6,362.1,248.7,358.8,248.7,355.5z M277.7,296.6C277.7,296.6,277.7,296.6,277.7,296.6c-0.1,3.6,0,7.3-0.1,10.9
		c-0.1,1.9,0.5,2.4,2.4,2.4c5.2-0.1,10.5,0,15.7,0.1c1.5,0,2.2-0.4,2.2-1.9c-0.1-7.5-0.1-15-0.1-22.5c0-1.7-0.8-2.5-2.5-2.5
		c-4.9,0-9.8,0.1-14.8,0c-2.2,0-2.8,1.1-2.7,3C277.8,289.5,277.7,293.1,277.7,296.6z M297.8,325.6
		C297.8,325.6,297.8,325.6,297.8,325.6c0-3.4-0.1-6.9,0.1-10.3c0.1-2.2-0.7-2.8-2.7-2.8c-4.7,0.1-9.4,0.1-14.1,0
		c-2.3-0.1-3.3,0.8-3.3,3.2c0.1,6.9,0.1,13.7,0,20.6c0,2.1,0.7,3.2,3,3.1c4.7-0.1,9.4-0.1,14.1,0c2.3,0,3-1.1,2.9-3.2
		C297.7,332.6,297.8,329.1,297.8,325.6z M277.7,355.4c0,0-0.1,0-0.1,0c0,3.5,0.1,7.1-0.1,10.6c-0.1,1.9,0.6,2.5,2.3,2.5
		c5.3,0,10.7-0.1,16-0.2c1.7,0,2-1,2-2.6c-0.1-6.8-0.1-13.7,0-20.5c0-2.4-0.8-3.3-3.1-3.3c-4.8,0-9.6,0.1-14.4,0
		c-2-0.1-2.7,0.7-2.6,2.6C277.8,348.1,277.7,351.7,277.7,355.4z M199.2,296.6c0-3.6,0-7.3,0-10.9c0-1.9-0.8-2.7-2.7-2.7
		c-4.5,0.1-9,0.1-13.5,0c-2.2-0.1-3.1,0.8-3,3c0.1,6.9,0.1,13.7,0,20.6c0,2.3,0.8,3.3,3.2,3.3c4.4,0,8.8,0.1,13.2,0.3
		c2.1,0.1,2.9-0.9,2.8-2.9C199.2,303.7,199.2,300.1,199.2,296.6z M320.1,296.5c0-3.6,0-7.3,0-10.9c0-1.7-0.6-2.6-2.4-2.6
		c-4.7,0-9.4,0-14.1,0c-1.7,0-2.6,0.6-2.6,2.4c0,7.4,0,14.8,0,22.1c0,1.8,1,2.5,2.7,2.5c4.6-0.1,9.2-0.2,13.8-0.2
		c1.9,0,2.7-0.9,2.7-2.7C320.1,303.6,320.1,300,320.1,296.5z M320.1,326c0-3.4-0.1-6.8,0-10.3c0.1-2.2-0.6-3.4-3.1-3.3
		c-4.4,0.1-8.8,0.1-13.1,0c-2.2,0-3.1,0.9-3,3c0.1,6.9,0.1,13.9,0,20.8c0,1.9,0.6,3,2.8,3c4.6-0.1,9.2,0,13.8,0
		c1.9,0,2.7-0.8,2.7-2.7C320.1,333.1,320.1,329.5,320.1,326z M199.2,325.9c0-3.5-0.1-7.1,0-10.6c0-2-0.7-2.9-2.8-2.9
		c-4.5,0.1-9,0.1-13.5,0c-2,0-3,0.7-2.9,2.8c0.1,7.1,0,14.1,0,21.2c0,2,0.8,2.9,2.8,2.9c4.5-0.1,9-0.1,13.5,0c2.3,0,3-1,2.9-3.1
		C199.1,332.8,199.2,329.4,199.2,325.9z M180,355.2c0,3.3,0.1,6.6,0,9.9c-0.1,2.7,1.3,3.6,3.7,3.6c4.3-0.1,8.6-0.2,12.8-0.3
		c1.9,0,2.7-0.8,2.7-2.7c0-7,0-13.9,0-20.9c0-2.1-0.9-2.9-2.9-2.9c-4.4,0-8.8,0.1-13.2,0c-2.4,0-3.2,1-3.2,3.3
		C180.1,348.5,180,351.8,180,355.2z M300.9,354.5c0,3.6,0,7.3,0,10.9c0,2,0.8,3,2.8,3c4.5,0,9,0,13.5,0c2,0,2.9-0.8,2.9-2.9
		c0-7.1-0.1-14.1,0-21.2c0-2.1-0.9-2.5-2.7-2.4c-4.7,0.1-9.4,0-14.1-0.1c-2-0.1-2.4,0.9-2.4,2.5C301,347.7,300.9,351.1,300.9,354.5z
		"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M250.4,185c-24.4,0-48.9,0-73.3,0c-5.5,0-5.7-0.2-2.9-4.8c3.3-5.4,6.8-10.6,10.2-16c0.9-1.5,2.1-1.9,3.8-1.9
		c4.3,0,8.6,0,12.9,0c36.2,0,72.5,0,108.7-0.1c3.4,0,5.5,0.9,7.2,4c2.8,5.1,6.1,9.8,9.1,14.8c0.6,1,2.2,1.9,1.4,3.3
		c-0.8,1.2-2.4,0.6-3.6,0.6c-7.5,0-15,0-22.5,0C284.3,185,267.4,185,250.4,185z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M249.8,152.9c-19.9,0-39.9,0-59.8,0c-0.7,0-1.5,0-2.2-0.1c-1.8-0.1-2-1-1-2.3c4.7-6.4,9.4-12.7,14.2-19.1
		c0.9-1.2,2.2-1.2,3.5-1.2c30.5,0,61.1,0,91.6,0c1.8,0,3,0.7,4.1,2.1c4.2,5.8,8.5,11.6,12.7,17.4c0.5,0.7,1.6,1.5,1,2.5
		c-0.7,1.2-2.1,0.8-3.2,0.8c-19.6,0-39.2,0-58.8,0C251,152.8,250.4,152.8,249.8,152.9C249.8,152.8,249.8,152.9,249.8,152.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M250.1,120.7c-15.3,0-30.6,0-45.9,0c-1.2,0-2.9,0.5-3.5-0.8c-0.6-1.2,0.8-2.1,1.4-3.1c3.4-5.5,6.9-10.8,10.2-16.3
		c1.1-1.8,2.4-2.5,4.4-2.5c22.4,0.1,44.8,0,67.2,0c1.9,0,3.2,0.6,4.2,2.3c3.2,5.4,6.7,10.7,9.9,16.1c0.7,1.1,2.4,2.2,1.4,3.6
		c-0.8,1.2-2.6,0.6-4,0.6C280.3,120.7,265.2,120.6,250.1,120.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M249.1,28.1c18.9,0,37.7,0,56.6,0c1.4,0,3.4-0.6,4,1.2c0.7,2.2,0.9,4.8-0.2,6.9c-0.7,1.4-2.5,0.9-3.9,0.9
		c-18.3,0-36.7,0-55,0c-19.2,0-38.4,0-57.6,0c-1.4,0-3.4,0.6-3.9-1.2c-0.6-2.2-0.9-4.8,0.1-6.9c0.6-1.3,2.5-0.8,3.9-0.8
		C211.8,28.1,230.5,28.1,249.1,28.1z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M326.8,388.9c3.3,3.3,6.7,6.5,9.9,9.9c4.3,4.5,8.4,9.1,12.6,13.7c0.4,0.5,0.9,1,1.2,1.5c0.9,1.3,2.1,2.9,0.5,4.3
		c-1.8,1.6-3.2,0-4.3-1.2c-6.3-7.3-11.7-15.2-17.4-23c-1.1-1.5-2.2-3.1-3.2-4.6C326.3,389.2,326.5,389,326.8,388.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M173.4,388.8c-0.5,3-2.4,5-4,7.2c-4.8,6.5-9.6,12.9-14.4,19.3c-0.7,0.9-1.5,1.8-2.4,2.6c-1,0.8-2.1,1.2-3.2,0.2
		c-1-0.8-0.7-1.9-0.2-3c0.7-1.6,1.9-2.8,3.1-4.1c6.6-7.1,13.3-14.2,20-21.3C172.5,389.4,173,389.1,173.4,388.8z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M159.4,360.2c-3.3,1.6-6.6,3.3-10,4.9c-7.8,3.6-15.4,7.4-23.4,10.5c-1.3,0.5-2.9,1.2-3.6-0.6c-0.8-1.8,0.7-2.8,2.1-3.4
		c11.2-4.3,22.4-8.4,33.8-12.2c0.3-0.1,0.6,0.1,0.9,0.1C159.2,359.7,159.3,359.9,159.4,360.2z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M341.3,359.1c4.7,1.6,9.4,3.2,14.1,4.9c5.9,2.2,11.8,4.4,17.8,6.6c0.9,0.3,1.8,0.7,2.7,1.1c1.2,0.7,2.7,1.5,2.1,3.2
		c-0.7,1.8-2.3,1.1-3.6,0.6c-7.9-3.1-15.5-6.8-23.2-10.4c-3.5-1.6-6.8-3.4-10.3-5C341,359.8,341.1,359.4,341.3,359.1z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M281.2,72.6c-3.1-2.7-3.6-5.1-1.7-7.3c1.5-1.7,4.1-1.8,5.7-0.1c2.2,2.1,1.7,4.4-1.5,7.3c3.1,2.9,3.6,5.2,1.6,7.3
		c-1.6,1.7-4.5,1.6-6-0.1C277.6,77.5,278.2,75.1,281.2,72.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M283.8,55c3.2,3.1,3.7,5.4,1.4,7.5c-1.6,1.5-4.2,1.4-5.7-0.3c-1.9-2.2-1.4-4.4,2-7.1c-1.6-1.1-3.1-2.3-3.1-4.4
		c0-1.1,0.4-2.1,1.2-2.9c1.6-1.7,4.4-1.6,6,0.3C287.4,50,286.8,52.3,283.8,55z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M346.8,309.4c0.5-1.3,1.7-1.4,2.6-1.7c9.9-3.2,19.8-6.5,29.7-9.6c1.8-0.6,4.5-1.9,5.2,0.8c0.7,2.4-2.3,2.7-4,3.1
		c-10.8,2.6-21.7,5.1-32.6,7.6C347.5,309.6,347.1,309.4,346.8,309.4z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M218.8,55c0.8,0.6,1.5,1,2.1,1.7c1.5,1.8,1.5,3.8,0,5.5c-1.4,1.7-3.3,2-5.1,0.6c-2-1.5-2.6-3.7-0.9-5.6
		c1.4-1.6,2-2.3,0.1-4.1c-1.7-1.7-1.3-4,0.7-5.6c1.9-1.5,4-1.1,5.4,0.7c1.6,1.9,1.4,4-0.5,5.7C220.1,54.3,219.5,54.6,218.8,55z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M154,309.8c-12.4-2.9-24-5.6-35.5-8.4c-1.3-0.3-3-0.9-2.7-2.6c0.3-1.9,2.2-1.6,3.4-1.2c11.3,3.4,22.5,6.8,33.6,10.9
		C153.1,308.6,153.2,308.9,154,309.8z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M219.1,89.5c0.6,0.6,1.4,1,1.9,1.7c1.4,1.9,1.4,3.9-0.3,5.5c-1.6,1.5-3.5,1.6-5.2,0.2c-1.8-1.5-2.2-3.6-0.7-5.4
		c1.3-1.5,1.3-2.2,0-3.7c-1.5-1.9-1.1-4.1,0.9-5.7c1.8-1.4,3.7-1.1,5.2,0.5c1.7,1.8,1.5,3.9-0.2,5.8
		C220.3,88.7,219.7,89.1,219.1,89.5z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M346.4,336.8c9.1,1.4,17.5,2.7,25.9,4c4,0.6,8,1.3,12,2c1.1,0.2,2.6,0.6,2.4,2.1c-0.3,1.6-1.9,1.7-3.1,1.4
		c-12.2-2.5-24.3-5-36.3-8.4C347.2,337.9,347.1,337.6,346.4,336.8z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M153.2,337.8c-3.9,1-7.8,2-11.7,2.9c-7.4,1.7-14.8,3.4-22.2,5c-1.1,0.3-2.3,0.4-3.5,0.5c-1.1,0.1-2.4,0-2.6-1.3
		c-0.2-1.3,1-1.9,2-2.1c3.7-0.7,7.3-1.4,11-2c8.5-1.4,17.1-2.7,25.7-4c0.4-0.1,0.8,0,1.2,0.1C153.2,337.2,153.2,337.5,153.2,337.8z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M167.1,376.8c-0.1,1-1,1.3-1.6,1.8c-9.1,6.5-18.2,12.8-27.7,18.7c-0.7,0.4-1.5,0.9-2.3,1.2c-0.9,0.3-1.7,0.2-2.2-0.7
		c-0.4-0.8,0-1.5,0.5-2c0.4-0.4,0.8-0.7,1.2-1c10.1-5.9,20.2-11.7,30.3-17.5C165.9,376.8,166.4,376.2,167.1,376.8z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M333,376.1c4.9,2.8,9.8,5.6,14.7,8.4c5.4,3.1,10.7,6.2,16.1,9.3c0.4,0.2,0.8,0.4,1.1,0.7c1,0.9,2.8,1.6,1.9,3.2
		c-1,1.7-2.6,0.4-3.6-0.2c-6.5-4.1-12.9-8.3-19.4-12.6c-3.1-2.1-6.2-4.2-9.3-6.3C333.7,378.1,332.9,377.7,333,376.1z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M163.3,282c-2.9-1.5-5.9-3-8.8-4.5c-7.7-4-15.3-8.1-23-12.1c-0.2-0.1-0.4-0.2-0.6-0.3c-1.2-0.9-3.5-1.5-2.4-3.4
		c1.1-1.9,2.9-0.5,4.2,0.3c9.6,5.8,19.1,11.7,28.7,17.6c0.8,0.5,1.5,1.1,2.3,1.7C163.6,281.5,163.5,281.7,163.3,282z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M173.2,267.3c-3.8-2.3-6.7-5.4-9.8-8.1c-6.4-5.4-12.7-10.9-19-16.3c-0.3-0.3-0.6-0.6-0.9-0.9c-0.8-0.9-1.3-1.9-0.2-2.8
		c1-0.9,1.8-0.2,2.5,0.5c4.6,4.3,9.2,8.7,13.7,13.1c4.4,4.2,8.8,8.5,13.1,12.8C172.9,265.9,173.4,266.2,173.2,267.3z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M337.5,280.6c5.1-3.2,10.3-6.4,15.4-9.6c5.2-3.2,10.4-6.3,15.7-9.4c1-0.6,2.3-1.3,3.1,0c0.8,1.3-0.4,2.2-1.4,2.8
		c-10.7,5.7-21.5,11.4-32.2,17.1C337.9,281.2,337.7,280.9,337.5,280.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M326.9,267.4c6.7-6.9,12.7-13.1,18.8-19.2c2.9-2.9,5.9-5.6,8.9-8.3c0.7-0.6,1.6-1.4,2.5-0.5c1.1,1,0.2,2-0.6,2.7
		c-9.2,8.1-18.4,16.2-27.7,24.3C328.4,266.7,328,266.8,326.9,267.4z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M278.4,41.7c0.2-2.2,1.4-3.8,3.9-4c2.2-0.2,4.4,1.9,4.3,4.2c-0.2,2.5-1.7,3.9-4.1,4.1C280.4,46.2,278.5,44.2,278.4,41.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M254.3,42.2c-0.2,2.2-1.7,3.6-4,3.7c-2.4,0.1-4-1.4-4.1-3.9c-0.1-2.6,1.4-4,3.8-4.3C252.1,37.4,254.2,39.6,254.3,42.2z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M221.9,67.9c0,2.6-1.8,4.4-4.1,4.4c-2.3,0-4-1.9-3.9-4.2c0.1-2.5,1.5-4.1,4-4.1C220.5,64.1,221.8,65.8,221.9,67.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M254.1,68.1c0,2.3-1.8,4.1-4,4.2c-2.2,0-4.1-1.8-4-4.1c0.1-2.5,1.5-4,4-4.2C252.2,63.9,254.1,65.9,254.1,68.1z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M249.9,63.5c-2.5-0.4-3.8-1.9-3.7-4.4c0.1-2.6,1.7-3.8,4.2-3.9c2.3,0,4.2,2.2,3.9,4.5C253.9,62.3,252.3,63.5,249.9,63.5z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M286.5,93.6c-0.2,2.5-1.5,4-4.1,4.1c-2.4,0-4.2-1.7-4-4c0.2-2.4,1.5-4,4.1-4.1C285.1,89.6,286.3,91.2,286.5,93.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M221.9,42.1c-0.1,2.2-2.1,4.1-4.2,3.9c-2.5-0.3-3.8-1.9-3.7-4.4c0.1-2.5,1.8-3.6,4.1-3.7C220.3,37.8,222,39.8,221.9,42.1z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M249.9,80.9c-2.5-0.3-3.9-1.7-3.8-4.2c0.1-2.6,1.7-3.9,4.2-3.8c2.5,0.1,4,1.4,3.9,4.1C254,79.5,252.5,80.8,249.9,80.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M250.4,89.3c-2.5-0.2-4.1-1.3-4.2-3.9c-0.1-2.6,1.4-4,3.8-4.1c2.4-0.1,4.1,1.1,4.2,3.8C254.2,87.6,252.7,88.9,250.4,89.3z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M282.3,89.3c-2.2-0.1-4.1-2.1-3.8-4.3c0.3-2.4,1.7-3.6,4.1-3.7c2.3,0,4.2,1.9,4.1,4.1C286.5,87.5,284.5,89.3,282.3,89.3z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M221.9,76.6c-0.1,2.7-1.9,4.3-4.2,4.2c-2.2-0.1-4-2.1-3.8-4.3c0.2-2.3,1.5-3.7,4-3.7C220.5,73,221.8,74.5,221.9,76.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M250.2,97.7c-2.5-0.2-4-1.5-4-4c0-2.5,1.5-4,4-4c2.5,0,4,1.4,4,3.9C254.1,96.1,252.7,97.5,250.2,97.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M254.2,50.7c-0.3,2.5-1.6,3.9-4.1,3.9c-2.5,0-4-1.5-3.9-4c0.1-2.5,1.5-4,4.1-3.9C252.7,46.6,254,48.2,254.2,50.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M356.5,322.8c-2.7,0.1-5.3,0.3-8,0.4c-0.8,0-1.9,0.1-2-1.1c0-1,1-0.9,1.7-1c5.5-0.6,11.1-0.4,16.7-0.5
		c0.7,0,1.7,0.1,1.6,1.1c0,0.9-1,1-1.7,1.1C362.1,322.8,359.3,322.8,356.5,322.8C356.5,322.8,356.5,322.8,356.5,322.8z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M343.8,394.3c-0.4,1.3-1.2,1-1.8,0.5c-4.8-3.6-9.5-7.2-14.3-10.9c-0.5-0.4-1.1-1-0.4-1.6c0.4-0.4,1-0.1,1.4,0.2
		c4.7,3.5,9.4,7.1,14.1,10.6C343.3,393.5,343.5,394,343.8,394.3z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M163.9,368.3c-0.3,0.9-1,1.2-1.7,1.6c-4.2,2.5-8.5,5-12.8,7.4c-0.8,0.4-1.8,1.3-2.5,0.1c-0.5-1,0.6-1.5,1.3-1.9
		c4.5-2.5,9-5,13.5-7.5c0.6-0.3,1.1-0.7,1.8-0.4C163.7,367.7,163.8,368.1,163.9,368.3z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M143.9,322.7c-2.9,0-5.8,0-8.7,0c-0.7,0-1.7-0.1-1.6-1.1c0-0.8,0.8-0.9,1.5-0.9c5.7,0.1,11.3-0.3,17,0.4
		c0.6,0.1,1.5,0.1,1.6,0.8c0.1,1.1-1,1-1.7,1c-2.7,0-5.3,0-8,0C143.9,322.8,143.9,322.7,143.9,322.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M353.3,377c0.1,1-0.6,1.1-1.3,0.9c-5.4-2.4-10.3-5.6-15.3-8.8c-0.3-0.2-0.5-0.8-0.4-1.1c0.1-0.5,0.7-0.6,1-0.4
		c5.3,2.8,10.5,5.7,15.6,9C353.1,376.6,353.2,376.8,353.3,377z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M173.2,382.6c-0.1,0.9-0.8,1.3-1.3,1.8c-4.3,3.3-8.6,6.7-12.9,10c-0.6,0.5-1.4,1.4-2.2,0.6c-0.8-0.9,0.3-1.6,0.9-2
		c4.6-3.5,9.2-7,13.8-10.4C172,382,172.7,381.5,173.2,382.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M234.7,416c0-4.9,0.1-9.9,0-14.8c0-2.4,0.8-3.5,3.4-3.4c8.3,0.1,7-0.5,7.2,7c0,1.5-0.8,3.7,1,4.4c2.4,0.9,5.1,0.7,7.6,0
		c0.8-0.2,0.9-1.2,0.9-1.9c0-0.7,0-1.5,0-2.2c0-7.2,1.9-8.6,9-7.1c1.5,0.3,1.8,1.3,1.8,2.6c0,10.5,0,21,0,31.5c0,2-0.9,2.9-2.9,2.9
		c-8.2,0-16.5-0.1-24.7,0c-2.4,0-3.2-1.2-3.1-3.4C234.7,426.2,234.7,421.1,234.7,416z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M216.1,416.2c0,5-0.1,10.1,0,15.1c0,2.5-0.8,3.5-3.4,3.4c-8-0.1-16.1-0.1-24.1,0c-2.4,0-3.2-0.9-3.2-3.3
		c0.1-10.2,0.1-20.3,0-30.5c0-2.3,0.8-3.3,3.2-3.2c8.4,0.1,7.2-0.7,7.4,7.4c0,1.4-0.7,3.4,1.2,4c2.4,0.7,5.1,0.7,7.6-0.1
		c0.6-0.2,0.7-1,0.7-1.7c0-1-0.1-1.9,0-2.9c0.2-2.1-1.1-5,0.7-6.1c2.3-1.5,5.4-0.6,8.2-0.4c1.8,0.1,1.7,1.6,1.7,2.9
		C216.1,406,216.1,411.1,216.1,416.2z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M314.6,416.5c0,4.9-0.1,9.9,0,14.8c0.1,2.4-0.7,3.5-3.3,3.5c-8-0.1-16.1-0.1-24.1,0c-2.1,0-3.1-0.8-3.1-3
		c0-10.3,0-20.6,0-30.9c0-2.1,0.8-3.1,3-3.1c8.7,0.1,7.3-0.6,7.5,7c0,1.5-0.8,3.7,1,4.4c2.4,0.9,5.1,0.7,7.6,0
		c0.7-0.2,0.8-1,0.8-1.6c0-1.1,0.1-2.1,0.1-3.2c0-6.2,3-8.3,9-6.3c1.7,0.6,1.4,1.9,1.5,3.1c0,0.1,0,0.2,0,0.3
		C314.6,406.4,314.6,411.4,314.6,416.5z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M250.4,224.9c4,0,7.9,0.1,11.9,0c2.3,0,3.3,1,3.2,3.3c-0.1,7.7-0.1,15.4,0,23.1c0,2.3-1,3.2-3.3,3.2
		c-7.6,0.3-7.6,0.3-7.6-7.3c0-4.3-4.5-6.6-8.4-4.5c-1,0.6-1.1,1.5-1.1,2.5c0,2.3,0,4.5,0,6.8c0,1.6-0.7,2.4-2.3,2.4
		c-0.1,0-0.2,0-0.3,0c-9,0.1-7.8,0.4-7.8-7c0-6.2,0.1-12.4,0-18.6c-0.1-2.9,0.9-4.1,3.9-4C242.5,225,246.4,224.9,250.4,224.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M201.1,224.9c4,0,7.9,0,11.9,0c2.1,0,3.2,0.6,3.2,2.9c-0.1,7.9-0.1,15.9,0,23.8c0,2-0.8,2.9-2.9,2.9
		c-9.4,0-7.5,0.5-7.8-7.4c0-1.5,0.8-3.7-1.1-4.3c-2.4-0.8-5.1-0.7-7.6,0c-0.7,0.2-0.7,1-0.7,1.7c0,1.1,0,2.1,0,3.2
		c0,6.9-1.4,8-8.4,6.7c-1.4-0.3-2.1-0.9-2.1-2.3c0-8.3,0-16.5,0-24.8c0-2.1,1.3-2.5,3-2.5C192.7,224.9,196.9,224.9,201.1,224.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M314.6,240c0,3.9-0.1,7.7,0,11.6c0,2-0.8,2.8-2.8,3c-7.6,0.5-7.6,0.5-7.7-7.2c0-1.5,0.9-3.7-0.8-4.4
		c-2.3-1-5.1-0.7-7.6-0.1c-1.1,0.3-1,1.5-1,2.5c0,2.2,0,4.5,0,6.7c0,1.6-0.7,2.4-2.3,2.4c-0.2,0-0.4,0-0.6,0c-7.5,0.5-7.5,0.5-7.5-7
		c0-6.2,0.1-12.4,0-18.6c-0.1-2.8,0.8-4.1,3.8-4c7.7,0.1,15.4,0.1,23.1,0c2.6,0,3.7,0.9,3.6,3.6C314.5,232.3,314.6,236.2,314.6,240z
		"
      />
    </g>
  </svg>
)

const DlabacovLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={width}
    height={height}
  >
    <image className="all" width="100%" height="100%" />
    <g className={DLABACOV} transform="scale(1.1 1.1) translate(-20 -20)">
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M158.8,37.7c0-4,4.9-6,7.6-3.1C167,35.2,330.6,175,412.2,243.8c9.2,7.7,8.3,11.1-0.1,18
		c-82.1,68.2-164,136.7-246,205.2c-2.9,2.4-7.3,0.4-7.3-3.4l0-124.6c0-0.3,0-5.9,0-5.9s26.6,0,27.2,0c22.4-0.6,44.9,1.7,67.2-3.3
		c45.9-10.3,70.1-43.8,65.2-91.2c-4-38.6-37-66.6-80.3-67.6c-18.7-0.5-37.4,0-56.1-0.2c-0.5,0-23.1,0-23.1,0s0-1.8,0-3.2
		C158.8,135.2,158.8,37.7,158.8,37.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M203.2,250.3c0-7.7,0-15.4,0-23c0-28.1,0-28.1,27.7-26.8c30.1,1.4,48.5,20.3,48.8,50.3
		c0.3,31.1-16.4,49.3-47.1,51.2c-29.5,1.9-29.5,1.9-29.5-26.7C203.2,266.9,203.2,258.6,203.2,250.3z"
      />
    </g>
  </svg>
)

const EvaldLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={width}
    height={height}
  >
    <image className="all" width="100%" height="100%" />
    <g className={EVALD}>
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M475.5,140.4c-1.1-9.3-7.8-15-16.4-17.3c-12.3-3.3-37-9.8-55.4-10.5c-16.7-0.6-45.4,1.7-52.3,2.4
		C248.8,125.5,155,158.7,73.3,222.8C54,238,35.2,255.2,27.3,279.5c-3.6,11.1-3.2,15.1-2,19c7.3,23,28.6,25.9,49.2,23.4
		c24.4-3,48.5-8.3,72.7-12.8c6.5-1.2,12.9-3.2,19.3-4.8c0.3,0.7,0.6,1.4,0.9,2.1c-2.1,1.8-4.3,3.5-6.3,5.4
		c-14.7,13.9-30.5,26.9-43.6,42.2c-13,15.1-5.2,32.1,14.5,33.3c11.7,0.7,23.9-0.3,35.4-2.6c34.1-6.9,79.1-25.2,97.5-34
		c64.4-30.9,109.7-58.8,127.9-75.8c4.6-4.3,8.5-9.6,11.6-15c4.1-7.3,2-12.3-6-14.6c-6.6-1.9-13.6-3.2-20.5-3.4
		c-3.9-0.2-8.9,1.1-11.6,3.6c-37.2,34.7-79.6,60.7-127.3,78.2c-13.1,4.8-26.3,9-39.6,13.1c-2.1,0.6-4.8-0.6-7.3-1
		c0.8-2.5,1-5.7,2.7-7.4c4.6-4.8,9.3-9.8,14.8-13.4c30.8-19.5,61.8-38.7,92.9-57.8c9.6-5.9,19.8-10.9,29.4-16.7
		c1.8-1.1,3.9-4.7,3.4-6.1c-1.1-2.6-3.7-5.2-6.3-6.2c-2.4-1-5.8-0.3-8.5,0.3c-39.4,10-78.6,20.3-118,30.1
		c-23.1,5.7-46.4,10.6-69.6,15.5c-7.2,1.5-14-0.2-18.3-7c-4.5-7.1-0.4-13.3,4-18.1c7.8-8.5,15.8-17.1,25.1-24
		c54.6-40.5,115.5-66.5,182.8-75.6c21.3-2.9,43.3-2,65-1.3c3.8,0.1,9.2,1.2,11.9,4.7c0.8,1,1,2.2,0.7,3.4
		c-0.4,1.6-21.3,10-32.4,15.1c3.3,7.9,9.6,9.5,16.4,9.5c27.4,0.1,52.7-7.1,76-21.4C470.6,155.2,476.5,149.3,475.5,140.4z"
      />
    </g>
  </svg>
)

const AtlasLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={width}
    height={height}
  >
    <image className="all" width="100%" height="100%" />
    <g className={ATLAS}>
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M404.3,298.1c-10,3.6-20.3,6.9-34,11.8c1.8-9.7,2.3-14.6,3.7-19.2c7.5-25.5,15.1-50.9,23-76.3c6.2-20,14.5-26,35.9-26.3
		c11.5-0.2,23,0,38.1,0c-12.2-14.1-43.5-46-43.5-46s-58.7-0.8-86.4,0.1c-12.1,0.4-18.7-0.9-34.8,13.5c-47.7,42.7-96.1,85-139,132.3
		c-36.9,40.7-27.8,31.8-84.4,34.4c-6.7,0.3-27.4-0.5-27.4-0.5s-0.8,13.9-26.7,34c28.9,0,57.2-0.5,85.5,0.2
		c21.7,0.5,39.6-5.8,54.4-22.3c6.5-7.3,15.3-17.1,23.6-17.6c33.1-2,66.4-0.9,101.5-0.9c-2.1,8.3-3.4,13.1-4.5,18
		c-3.8,15.7,1.8,24.1,18,25.2c6.6,0.4,13.7-0.5,20-2.5c24.2-7.7,48.3-15.9,72.3-24.2c13.8-4.8,27.5-10,43.2-15.6l9-36.4
		C438.4,285.3,430.6,288.7,404.3,298.1z M332.7,200c-8.6,26.9-18.1,53.5-27.7,80c-0.9,2.3-5,4.9-7.7,5c-25.2,0.4-81.4,0.2-81.4,0.2
		s63.5-60.8,93-88.8c3.2-3,7.2-5.8,11.4-7.1C329.3,186.6,336,189.6,332.7,200z"
      />
    </g>
  </svg>
)

const MatLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={width}
    height={height}
  >
    <image className="all" width="100%" height="100%" />
    <g className={MAT}>
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M169.1,439.8H51.3c-3.4,0-6.3-2.8-6.3-6.3v-367c0-3.4,2.8-6.3,6.3-6.3h108.9c1.7,0,3.9,1.1,5,2.4l82,106.9
	c2.8,3.6,7.3,3.6,10-0.1L336,62.8c1-1.4,3.3-2.5,5-2.5h107.7c3.4,0,6.3,2.8,6.3,6.3v367c0,3.4-2.8,6.3-6.3,6.3H331.3
	c-3.4,0-6.3-2.8-6.3-6.3V279.1c0-9.7-5-11.5-11.1-3.9l-59.1,72.6c-2.7,3.3-7.1,3.3-9.7,0l-58.6-72.5c-6.1-7.6-11.1-5.8-11.1,3.9
	v154.3C175.4,436.9,172.6,439.8,169.1,439.8z"
      />
    </g>
  </svg>
)

const PilotuLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={width}
    height={height}
  >
    <image className="all" width="100%" height="100%" />
    <g className={PILOTU} transform="scale(4 4) translate(-112 5)">
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        id="logo__propeller"
        d="M 205.65 4.12 L 204.79 0.61 L 123.69 20.3 L 124.54 23.81 L 205.65 4.12 Z M 205.65 4.12"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M 185.52 93.17 C 159.32 99.53 130.1 76.4 124.51 71.7 C 127.31 64.96 142.58 31.03 168.79 24.66 C 194.99 18.3 224.21 41.44 229.8 46.14 C 227 52.87 211.73 86.81 185.52 93.17 L 185.52 93.17 Z M 233.18 44.27 C 232.83 43.96 224.55 36.54 212.38 29.92 C 196.73 21.41 181.92 18.29 169.39 20.84 L 164.79 1.99 L 161.26 2.85 L 165.87 21.72 C 153.8 25.33 142.3 34.82 132.5 49.31 C 124.75 60.77 120.81 71.15 120.65 71.59 L 120.22 72.74 L 121.13 73.56 C 121.48 73.88 129.76 81.3 141.93 87.92 C 150.45 92.55 158.71 95.55 166.5 96.95 L 159.63 115.35 L 163.02 116.61 L 170.17 97.48 C 172.18 97.72 174.17 97.87 176.1 97.87 C 179.67 97.87 183.11 97.48 186.38 96.68 C 191.13 95.53 195.81 93.46 200.33 90.54 L 215.07 103.85 L 217.5 101.18 L 203.38 88.42 C 209.94 83.56 216.16 76.88 221.81 68.52 C 229.56 57.07 233.5 46.68 233.66 46.24 L 234.09 45.09 L 233.18 44.27 Z M 233.18 44.27"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M 187.4 42.13 C 191.9 44.87 195.07 49.17 196.31 54.27 C 197.56 59.36 196.73 64.64 193.99 69.13 C 191.25 73.62 186.93 76.78 181.82 78.01 C 176.71 79.26 171.42 78.43 166.91 75.7 C 162.41 72.97 159.24 68.66 158 63.57 C 156.76 58.48 157.58 53.2 160.32 48.71 C 163.06 44.22 167.38 41.06 172.49 39.82 C 174.04 39.44 175.62 39.26 177.18 39.26 C 180.75 39.26 184.26 40.23 187.4 42.13 M 171.63 36.31 C 165.58 37.78 160.47 41.51 157.22 46.83 C 153.98 52.15 153.01 58.39 154.48 64.42 C 155.95 70.45 159.7 75.55 165.03 78.79 C 168.74 81.04 172.9 82.19 177.13 82.19 C 178.98 82.19 180.84 81.97 182.68 81.53 C 188.73 80.06 193.84 76.32 197.09 71 C 200.33 65.69 201.31 59.44 199.84 53.41 C 198.36 47.38 194.61 42.28 189.28 39.05 C 183.95 35.81 177.68 34.84 171.63 36.31"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M 168.61 61.83 C 168.56 61.7 168.52 61.56 168.48 61.39 L 164.96 62.26 C 165.04 62.58 165.13 62.87 165.19 63.02 C 166.92 68.5 172 71.88 177.95 71.88 C 179.12 71.88 180.34 71.74 181.56 71.47 C 182.06 71.36 182.51 71.15 182.94 70.83 L 180.77 67.94 C 176.17 68.98 170.41 67.54 168.61 61.83"
      />
    </g>
  </svg>
)

const Kino35Logo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={width}
    height={height}
  >
    <image className="all" width="100%" height="100%" />
    <g className={KINO35}>
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M413.6,51.3c-21.9,5-42.8,9.7-64.1,14.6c4.5,8.2,8.6,15.6,12.3,22.4c7.8-2,14.9-5.2,22.1-5.4c9.6-0.2,15.1-4.1,18.8-12.6
		C405.4,63.9,409.5,58.2,413.6,51.3z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M137.9,120.9c18-1.1,35.8-2.1,53.9-3.2c0.2-2.3,0.1-4.4,0.6-6.4c1.5-6.1,4.9-10.9,11-13.3c10.4-4.2,20.8-4.1,30.6,1.4
		c6.7,3.8,10.7,9.7,10.3,17.6c-0.4,7.2-5.9,10.9-11.8,13.1c-6.9,2.6-14.1,4.4-21.2,6.4c-1.6,0.5-3.3,0.9-5.6,1.4
		c5.4,11.4,10.6,22.3,15.9,33.6c7.1-1.8,13.9-3.6,20.8-5.2c9.3-2.1,18.5-3.4,27.7,0.6c9.9,4.4,17,18.4,14.5,28.8
		c-1.3,5.4-4.8,9.2-9.6,11.5c-17.8,8.5-34.2,8-47.8-7.9c-0.7-0.8-2.5-1.5-3.2-1.1c-14,7.5-27.9,15.2-42.5,23.2
		c3,3.5,5.6,6.8,8.5,9.8c13.5,14,30,21.8,49.5,23.5c27.6,2.4,52.7-4.2,74.7-21c17.6-13.4,23-33.9,14.8-54.4
		c-8.7-21.7-24.3-35.3-47.6-39.3c-3.8-0.7-7.9-0.1-13.1-0.1c1.7-1.3,2.3-1.8,3.1-2.2c14-9,18.5-22.5,11.7-37.8
		c-2.2-4.9-2.2-9-0.4-13.8c4.5-12.4,8.6-25,12.9-37.6c0.2-0.7,0.6-1.4,1.1-2.5c15.6,29.4,30.9,58.4,46.5,87.8c2.2-1.2,4-2.2,5.8-3.3
		c13.8-8.8,28.8-13.8,45.1-15.1c20-1.6,38.3,11.2,42.3,29.8c1.8,8.2-1.4,15-8.9,18.9c-11.6,6.1-25.7,3.6-36.3-6.3
		c-5.2-4.9-5.2-4.9-11.5-1.3c-8.4,4.8-16.8,9.7-25.1,14.5c-1,0.6-2,1.2-3,1.9c22.2,28.7,63.4,43,104.2,19.2
		c-1.2,1.4-1.7,2.1-2.3,2.7c-27.7,26.1-55.3,52.3-83,78.4c-41.4,39-82.8,78.1-124.1,117.1c-19.3,18.2-38.5,36.5-57.9,54.6
		c-13.2,12.3-31.7,11.5-43.3-1.4c-4.6-5.1-7-11.2-7.4-18c-0.2-4-0.3-8-0.3-11.9c0-96.3,0-192.6,0-288.9
		C137.7,123.6,137.8,122.4,137.9,120.9z M167.6,438.8c8.4-0.1,15-6.6,15-14.8c0-8.1-7-15-15.1-14.9c-8.2,0.1-14.8,7-14.7,15.2
		C152.9,432.3,159.6,438.8,167.6,438.8z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M45.7,382.9c2.6,2.4,35.1,33.6,35.1,33.6s38.7-34.9,40.2-36.2c0,7.6,0.1,14.5-0.1,21.5c0,0.9,0,0.9-2,2.7
		c-9.7,8.4-19.5,16.8-29.2,25.1c-1.2,1-2.3,2.1-4.3,4h35.4v16.2H46V434h30.9c-1.2-1.5-1.7-2.3-2.3-2.9c-8.7-8.2-17.3-16.4-26.1-24.5
		c-3-2.9-2.8-2.4-2.8-6.6C45.7,394.7,45.7,389.3,45.7,382.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M113.8,205.5c-11.6-10.7-30.2-9.2-39.8,3.3c-9.8,12.8-6.7,34.3,6.2,42.5c9.2,5.8,18.8,6.3,28.3,1.2
		c10-5.4,13.6-14.6,13.9-24.2C122.5,218.6,119.2,210.5,113.8,205.5z M95.5,240.3c-8.2,0-13.5-5.2-13.5-13.3
		c0.1-7.8,5.5-13.1,13.5-13c8,0.1,13.3,5.4,13.3,13.2C108.7,235.1,103.5,240.2,95.5,240.3z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M69.8,325.8c0-4.9,0-9.4,0-14.3c1.9-0.2,3.7-0.3,5.8-0.5c-0.3-0.6-0.3-1.2-0.6-1.5c-6.6-5.4-7.6-12.4-6-20.2
		c1.5-7.1,6.4-11.4,13.2-11.9c12.8-1,25.6-1,38.7-1.5c0,5.7,0,10.5,0,15.9c-2.8,0-5.4-0.1-8,0c-7.9,0.2-15.9,0.2-23.8,0.7
		c-4.4,0.3-6.6,3-7,6.8c-0.4,4.7,1.3,8.3,5.2,9.7c2.4,0.8,5.2,1.2,7.8,1.2c8.2,0.2,16.4,0.1,24.7,0.1c0.2,0,0.5,0.2,1.1,0.3
		c0,4.8,0,9.8,0,15C103.9,325.8,87.1,325.8,69.8,325.8z"
      />
      <polygon
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        points="69.6,364.1 69.5,348.8 120.8,348.8 120.8,364.1 	"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M52.7,347.7c5.2,0,9.2,3.8,9.3,8.7c0.1,5.1-4.2,9.4-9.3,9.3c-4.8-0.1-8.7-4-8.8-8.9C43.9,351.6,47.6,347.7,52.7,347.7z"
      />
    </g>
  </svg>
)

const RadotinLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={width + 5}
    height={height + 5}
  >
    <image className="all" width="100%" height="100%" />
    <g className={RADOTIN}>
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M423.7,270.1c1-9.6-3.5-16-12.5-19.7c8.4-3.3,13-9.2,12.6-18.1c-0.3-9-5.8-14.3-14.4-17c8.5-2.5,13.4-8,13.5-16.7
		c0.1-7.3-3.4-12.9-10.2-15.5c-9.2-3.8-16.5-0.6-21.6,7c-3-3.4-6-6.8-9-10.2c6.2-4.5,9.5-11,8.1-19.2c-1.4-8.2-6.8-13.2-15-15.5
		c9.1-3.9,13.1-10,12-18.1c-1-6.8-6.5-12.5-13.5-13.5c-6-0.9-14.1,1.3-18,11.6c-2.4-8.2-7.6-13.2-16.1-15
		c9.3-3.7,13.5-9.9,12.3-17.8c-1-7.1-6.8-12.4-14.1-13c-7.2-0.6-14,4.1-16,11.1c-1.3,4.2-0.7,8.3,1.6,12.2c2.3,3.8,5.8,6,10,7.2
		c-2.4,1.1-5,2-7.1,3.3c-2.2,1.4-4,3.4-5.7,4.9c-3-2.8-6.1-5.5-9.2-8.3c7.3-6.2,9.8-12.2,7.6-19.6c-2-6.9-6.7-11.4-13.7-12.5
		c-9.4-1.4-15.9,2.9-19.8,11.4c-6.1-16.3-27.7-17.5-35.1-1.8c-3.7-7.3-9.6-11.4-17.8-11.2c-8.3,0.2-13.9,4.9-17.3,12.5
		c-3.4-7.5-9.5-11.8-16.5-11.6c-7.3,0.1-13.1,3.3-16.1,10c-4.2,9.3-1.3,17.1,6.2,22.8c-3,2.7-5.9,5.3-8.3,7.5
		c-3.9-2.7-7.8-5.3-11.3-7.8c7.5-3.2,11.4-9.6,10.2-17.5c-1.1-7-7-12.5-14.1-13.1c-7.3-0.6-14.2,3.8-16.4,10.5
		c-1.3,4.2-1.2,8.4,0.8,12.4c1.9,3.9,5.2,6.4,9.6,8.2c-7.3,2.1-11.9,6.5-14.4,13.3c-4.2-7.9-10.4-11.5-17.7-10.6
		c-6.9,0.9-12.4,5.9-14.1,12.7c-0.9,4-0.4,7.9,1.6,11.5c1.9,3.5,4.9,6.1,9,7.9c-7.2,2.4-11.4,7.2-13.5,14.1
		c-5.1-9.1-11.5-12.5-19.5-10.5c-6.8,1.8-11.5,7.9-11.8,15.1c-0.1,7.1,4.7,13.5,11.5,15.5c8,2.3,14.2-1,19.3-9.6
		c2.5,3.5,5.2,7.3,7.8,11c-2.3,2.5-5,5.4-7.6,8.4c-4.7-6.5-11.1-9.4-19.1-7.6c-7.2,1.6-12,6.3-13.3,13.6
		c-1.8,9.5,2.5,16.1,11.1,20.4c-7.8,3.4-12.2,9-12.4,17.3c-0.2,8.2,3.9,14,11.2,17.7c-15.9,8.2-14.3,29.2,1.7,35.3
		c-8.2,3.7-12.5,9.9-11.4,19c0.9,7.1,5.1,12.1,12,14.2c8.5,2.5,15.4-0.6,20.2-7.4c2.9,3.2,5.8,6.3,8.6,9.5
		c-4.3,2.9-7.1,7.2-8.2,12.7c-4.5-10-10.6-13.7-18.8-12c-7.1,1.6-12.1,8-12.1,15.3c0,6.9,5.1,13.3,12,14.9c4.3,1,8.2,0.2,12-2.1
		c3.7-2.3,5.7-5.8,7-10.2c1.8,8.5,6.7,13.7,14.6,16.1c-9.4,5.7-12.7,11.2-11,19c1.3,6.4,6.8,11.8,13.2,12.6
		c4.1,0.6,7.9-0.1,11.4-2.4c3.4-2.2,5.5-5.4,6.9-9.5c4.8,17.5,25.8,20.2,35.1,6.5c1.6,1.7,2.9,3.5,4.7,5.1c1.7,1.6,3.7,2.7,5.7,4
		c-7.1,4.4-10.5,10.8-8.5,19.1c1.7,6.8,6.2,11.3,13.1,12.5c10.3,1.8,16.9-3.2,20.3-13.1c2.4,8.6,8.1,13.6,16.2,14.5
		c8,0.9,14.2-3.2,18.8-12.6c3.1,7.8,8.5,12.5,17.1,12.5c9.9-0.1,15.6-5.8,18.1-15.4c2.2,8.8,8.1,14,15.7,14.3
		c7.4,0.3,14.2-4.2,16.6-11.2c2.7-7.8-0.1-14.6-8.5-20.8c4.8-1.9,8.4-5.1,11-9.6c8.9,12.3,22.1,10.5,28.7,4.5
		c4-3.5,6.2-8.1,6.3-13.4c0.2-6.8-2.8-12.1-8.1-16.1c1.7-1.3,3.3-2.4,4.8-3.9c1.4-1.4,2.5-3,3.8-4.5c8.2,10.4,21,9.8,28,3.2
		c3.9-3.4,6-7.8,6.2-13c0.3-7.4-3-13.1-8.4-16.7c3.3-3.8,6.4-7.3,9.5-10.9c5.1,7.4,11.8,10.3,19.3,8.2c6.7-1.9,11.8-8.2,12.1-15
		c0.2-4.3-1-8.3-3.7-11.6c-2.7-3.3-6.2-5.3-10.8-6.2C418,282.3,422.9,277.4,423.7,270.1z M110.3,208.3c1.7,2,3.7,4.4,5.7,7
		c-1.9,2.1-3.9,4.3-5.7,6.3c-2.9-2.1-5.9-4.3-8.4-6.3C104.4,213.1,107.3,210.7,110.3,208.3z M115.4,250.3c-1.8,2-3.5,4.1-5.2,6.1
		c-2.5-2.1-5.1-4.1-7.5-6.1c2.3-1.9,5-3.9,7.5-6C111.9,246.2,113.6,248.2,115.4,250.3z M110.2,292.7c-2.9-2.4-6-5-8.8-7.3
		c2.7-2,5.8-4.2,8.9-6.5c1.8,2,3.8,4.2,5.8,6.4C114.1,287.9,112,290.5,110.2,292.7z M144.8,135.5c2.2,3.1,4.4,6.3,6.3,9.1
		c-2,1.9-4.2,4.1-6.5,6.2c-2.3-1.6-5.5-3.9-8.9-6.2C139.9,143,142.9,139.7,144.8,135.5z M145.6,218c-1.1-1-2.1-1.8-3.1-2.5
		c1-0.9,1.9-1.8,2.9-2.8c0.8,0.9,1.7,1.9,2.5,2.9C147.1,216.4,146.4,217.1,145.6,218z M147.7,250.3c-0.7,0.8-1.3,1.4-2.2,2.4
		c-1-0.9-1.7-1.6-2.4-2.2c0.8-0.9,1.6-1.6,2.4-2.3C146.1,248.8,146.8,249.5,147.7,250.3z M145.5,282.4c0.9,1,1.7,1.8,2.5,2.7
		c-0.9,1-1.8,2-2.7,3.1c-1-1-2-1.8-3.1-2.9C143.2,284.4,144.2,283.5,145.5,282.4z M145.2,184.3c-1.6-1.3-3.1-2.7-4.4-3.8
		c1.4-1.6,2.8-3.1,4.2-4.7c1.3,1.6,2.5,3,3.9,4.5C147.7,181.8,146.4,183,145.2,184.3z M144.9,316.3c1.4,1.3,2.8,2.7,4.2,4.1
		c-1.2,1.4-2.5,3.1-4,4.7c-1.8-1.6-3.3-3.1-5.1-4.8C141.8,319,143.5,317.6,144.9,316.3z M148,360.9c-1.3,1.9-2.1,4.1-3.1,6.3
		c-1.8-5.7-5.4-9.5-10.5-11.2c3.5-2.3,7.2-4.8,10.2-6.8c2.2,2.2,4.7,4.7,7.1,7.1l0.9-0.9C151,357.3,149.3,358.9,148,360.9z
		 M180.7,141.1c0.8,1,2.1,2.5,3.4,4.1c-1.2,1.2-2.4,2.3-3.8,3.7c-1.4-1.2-3-2.4-4.7-3.8C177.5,143.6,179,142.3,180.7,141.1z
		 M182.5,320c-0.6,0.8-1.1,1.4-2,2.4l-2.1-2.1c0.7-0.7,1.3-1.3,2.1-2C181.1,318.9,181.8,319.3,182.5,320z M180.3,360.9
		c-1.7-1.8-3.3-3.5-5-5.3c1.8-1.4,3.4-2.8,5.2-4.3c1.3,1.3,2.9,2.7,4.3,4.1C183.3,357.3,181.8,359,180.3,360.9z M285.5,101.4
		c2.2,2.8,4.8,5.9,7.3,8.9c-2.2,1.8-4.8,3.9-7.3,5.9c-2.2-2-4.4-4-6.5-5.9C281.1,107.3,283.5,104.1,285.5,101.4z M288.2,145.3
		c-1,0.9-2,1.8-3,2.8c-0.9-0.9-1.7-1.7-2.7-2.5c0.9-1.1,1.8-2.1,2.8-3.3C286.4,143.2,287.2,144.1,288.2,145.3z M250.4,102.6
		c1.9,2.3,4,4.9,6.1,7.5c-2,1.8-4.1,3.5-6.1,5.2c-2-1.7-4-3.5-6-5.2C246.4,107.6,248.5,105.1,250.4,102.6z M252.6,145.5
		c-0.7,0.6-1.3,1.2-2.2,2c-0.8-0.7-1.6-1.2-2.4-2c0.8-1,1.6-1.8,2.3-2.7C251.2,143.9,251.8,144.6,252.6,145.5z M215.3,101.7
		c1.9,2.5,4.1,5.4,6.4,8.4c-1.9,1.7-4.1,3.7-6.3,5.7c-2.3-1.8-4.8-3.8-7.1-5.7C210.6,107.4,213.1,104.4,215.3,101.7z M215.4,352.4
		c1.1,1,2.1,2,3.1,2.9c-1.1,1.3-2.1,2.4-3.1,3.7c-1.1-1.1-2.2-2.3-3.3-3.5C213.2,354.4,214.2,353.4,215.4,352.4z M212.5,145.3
		c1-1.1,1.8-1.9,2.7-2.9c0.9,1.1,1.8,2,2.9,3.2c-0.9,0.7-1.7,1.4-2.7,2.2C214.5,147.1,213.6,146.2,212.5,145.3z M215.3,402.3
		c-1.4-5-4.3-9-8.8-11.3c3-2.4,6-4.7,8.8-6.9c2.5,2.1,5.1,4.4,7.9,6.8C219.3,393.6,216.4,397.3,215.3,402.3z M250.3,399.6
		c-2.1-2.8-4.5-6-7-9.2c2.1-1.8,4.5-3.9,7-5.9c2.3,2,4.9,4,7.1,5.9C255,393.6,252.4,396.9,250.3,399.6z M247.4,355.3
		c0.9-0.9,1.8-1.7,2.8-2.5c1,0.9,1.9,1.7,3,2.7c-1,1.1-1.9,2-2.9,3C249.3,357.4,248.4,356.4,247.4,355.3z M253.6,260.6l-23.7,0.2
		v39.9h-11.2V188.8c16.9,0,23.5-1.4,40.8-0.2c11.2,0.8,23.6,11.9,26,27.2c1.7,11.2,0.8,21.9-2.2,27.5c-5.7,10.8-8.6,12.9-18.1,17
		c9.2,13.8,26.9,40.4,26.9,40.4h-13.8L253.6,260.6z M288.8,355.5c-1.1,1.2-2.2,2.4-3.4,3.7c-1.1-1.3-2.1-2.5-3.3-3.9
		c1.1-1,2.1-2,3.2-3C286.5,353.4,287.6,354.4,288.8,355.5z M285.7,403.3c-1-2.5-1.8-5-3.1-7.1c-1.3-2.1-3.3-3.8-5-5.5
		c2.5-2.2,5.2-4.5,7.8-6.9c2.9,2.2,5.9,4.7,9.1,7.1C289.9,393.5,286.6,397.4,285.7,403.3z M320.3,140.1c1.6,1.7,3.1,3.2,4.8,5
		c-1.4,1.3-3.1,2.7-5,4.3c-1.3-1.6-2.4-2.9-3.9-4.4C317.4,143.5,318.8,141.9,320.3,140.1z M322.7,320.3c-0.8,0.8-1.6,1.4-2.4,2.1
		c-0.7-0.8-1.4-1.6-2.3-2.5c0.7-0.7,1.3-1.2,2.2-2C321,318.8,321.7,319.4,322.7,320.3z M320.5,361.5c-1.7-2-3.2-4-4.9-6
		c1.4-1.4,3-2.9,4.7-4.4c1.9,1.6,3.8,3.1,5.7,4.7C324,357.7,322.3,359.6,320.5,361.5z M355.6,325.8c-1.4-1.7-3.1-3.7-4.7-5.5
		c1.4-1.6,3-3.2,4.4-4.7c2.1,1.8,4.2,3.3,6.1,4.8C359.6,322.1,357.6,323.9,355.6,325.8z M355.3,218.6c-1-1.1-1.9-2.1-2.9-3.1
		c1-1.1,2-2.2,3-3.4c1.2,1.1,2.3,2.1,3.5,3.2C357.7,216.4,356.5,217.5,355.3,218.6z M358.4,250.4c-1,0.9-1.9,1.8-2.9,2.7
		c-0.8-0.9-1.7-1.9-2.5-3c0.6-0.7,1.4-1.6,2.3-2.5C356.5,248.6,357.4,249.4,358.4,250.4z M355.4,282.1c1.2,1.1,2.4,2.1,3.8,3.3
		c-1.2,1.1-2.4,2.2-3.7,3.4c-1-1.2-2.1-2.3-3.2-3.5C353.4,284.1,354.3,283.1,355.4,282.1z M355.4,184.8c-1.3-1.4-2.7-2.9-4.1-4.3
		c1.4-1.8,2.9-3.4,4.3-5.2c1.8,1.7,3.5,3.3,5.4,5C359.2,181.8,357.3,183.2,355.4,184.8z M356.1,151.5c-2.2-2.2-4.7-4.5-7-6.7
		c2-3,4.4-6.8,6.9-10.3c1.8,4.9,5.5,8.6,10.6,10.2C363,147.1,359.2,149.5,356.1,151.5z M391,206.6c2.4,4.1,6.1,7.2,12,8.5
		c-5.5,1.4-9.4,4-12.1,8c-2.3-2.8-4.5-5.3-6.8-7.9C386.2,212.5,388.6,209.6,391,206.6z M399.5,250.3c-2.7,2.1-6,4.5-9.2,7
		c-1.8-2.1-3.8-4.5-5.9-7c2-2.3,4.1-4.8,6-7C393.5,245.7,396.8,248.2,399.5,250.3z M391,294.5c-2.4-3.1-4.9-6.2-7.1-9.2
		c2.2-2.4,4.5-5.2,7-8c2.9,4.2,7,7,12.3,8.1C397.5,286.6,393.4,289.8,391,294.5z M275.4,235.4c1.7-9.3,1.4-23.1-6.2-32
		c-4.1-4.8-12.9-5.5-14.9-5.5c-10-0.4-24.3,0.2-24.3,0.2v52.7c10,0,19.2,0.4,28.1-0.4C267.7,250,273.6,244.7,275.4,235.4z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M371.4,386.6c-8.5,0-15.4-6.8-15.3-15.3c0-8.6,6.8-15.3,15.3-15.3c8.5,0,15.4,6.8,15.4,15.3
		C386.8,379.9,379.9,386.6,371.4,386.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M405.9,179c-8.4-0.1-15-6.8-15-15.3c0.1-8.2,6.9-14.9,15.2-14.7c8.4,0.1,15,6.9,15,15.3C420.9,172.5,414.1,179.1,405.9,179
		z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M164,421c-8.3,0-15.1-6.8-15.1-15c0-8.4,6.9-15.2,15.3-15.1c8.3,0.1,15,6.8,14.9,15.2C179.1,414.3,172.3,421,164,421z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M322.3,405.7c0.1-8.3,6.8-14.6,15.1-14.4c8,0.2,14.3,6.8,14.2,14.9c-0.1,8.2-6.7,14.5-14.9,14.4
		C328.5,420.6,322.1,413.9,322.3,405.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M406,322.1c8.2,0,14.7,6.5,14.6,14.6c0,8.2-6.4,14.6-14.6,14.6c-8.2,0-14.7-6.5-14.7-14.6
		C391.3,328.7,397.9,322.1,406,322.1z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M60,246.6c-7.5-0.2-13.5-6.4-13.3-13.7c0.2-7.6,6.5-13.4,14.2-13.2c7.3,0.2,13.3,6.7,13,13.9C73.6,241,67.4,246.8,60,246.6
		z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M246.7,60.3c0,7.4-6.1,13.5-13.5,13.5s-13.5-6.2-13.5-13.6c0.1-7.4,6-13.4,13.3-13.4C240.7,46.7,246.7,52.6,246.7,60.3z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M254.3,60.3c0-7.5,6-13.4,13.5-13.4c7.3,0,13.3,6,13.3,13.4c0,7.2-6.1,13.4-13.3,13.5C260.4,73.9,254.3,67.8,254.3,60.3z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M46.9,267.6c0-7.3,6.1-13.4,13.4-13.3c7.5,0.1,13.4,6.1,13.4,13.6c-0.1,7.3-6.1,13.3-13.4,13.3
		C52.8,281.1,46.9,275.1,46.9,267.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M142.1,94.7c0,7.2-5.5,12.9-12.7,12.9c-7,0-12.6-5.7-12.6-12.6c-0.1-7.2,5.5-12.7,12.7-12.7
		C136.5,82.1,142.1,87.8,142.1,94.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M95,142.2c-7.1,0.1-12.7-5.4-12.9-12.5c-0.1-7.1,5.5-13,12.6-13.1c6.9-0.1,12.7,5.7,13,12.5
		C107.9,136.2,102.1,142.1,95,142.2z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M453.1,233.2c0,6.9-5.8,12.6-12.6,12.5c-6.9,0-12.5-5.7-12.6-12.5c0-7,5.8-12.7,12.7-12.6
		C447.5,220.6,453.2,226.3,453.1,233.2z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M60.5,186c6.9,0.1,12.5,5.8,12.4,12.6c-0.1,7-5.8,12.5-12.6,12.5c-7,0-12.6-5.7-12.6-12.5C47.7,191.6,53.4,186,60.5,186z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M198.6,47.7c6.9-0.1,12.5,5.4,12.6,12.4c0.1,6.9-5.5,12.6-12.4,12.6c-7.1,0.1-12.7-5.4-12.7-12.5
		C185.9,53.5,191.5,47.9,198.6,47.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M440.6,280.2c-7,0-12.5-5.4-12.6-12.4c-0.1-7,5.7-12.6,12.7-12.6c6.9,0,12.5,5.7,12.5,12.5
		C453.1,274.7,447.5,280.2,440.6,280.2z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M267.6,428c7.2,0.1,12.7,5.7,12.6,12.7c-0.1,7-5.9,12.6-12.9,12.4c-6.8-0.2-12.4-5.9-12.3-12.6
		C255.2,433.5,260.9,427.9,267.6,428z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M245.7,440.8c-0.1,6.9-5.9,12.4-12.9,12.2c-7-0.1-12.4-6-12.3-13.1c0.2-6.8,5.9-12.2,12.6-12.1
		C240.5,428.1,245.8,433.7,245.7,440.8z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M72.5,302.2c0,6.9-5.4,12.4-12.3,12.3c-6.8,0-12.2-5.4-12.2-12.2c0-6.9,5.4-12.4,12.3-12.3C67.2,290,72.5,295.4,72.5,302.2
		z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M290,59.9c0.1-6.8,5.7-12.1,12.4-12c6.9,0.1,12.2,5.8,12.1,12.6c-0.1,6.7-5.9,12.2-12.4,12C295.1,72.4,289.9,66.9,290,59.9
		z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M371.5,82.7c6.8,0.1,12.2,5.5,12.1,12.3c0,6.7-5.7,12.2-12.3,12.1c-6.8-0.1-12.1-5.5-12.1-12.3
		C359.3,88,364.7,82.6,371.5,82.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M95,383.5c-6.8,0-12.3-5.3-12.3-12.1c0-6.9,5.5-12.3,12.3-12.3c6.5,0,12.1,5.8,12.1,12.4C107,378.1,101.6,383.5,95,383.5z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M417.9,129.7c-0.1,6.5-5.5,11.8-12.1,11.8c-6.7,0-12-5.5-11.9-12.2c0.1-6.9,5.4-12,12.4-11.8
		C413,117.6,418.1,123,417.9,129.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M129.3,417.9c-6.5,0-12-5.5-11.9-12c0.1-6.7,5.5-12.1,12.1-12c6.7,0.1,12,5.5,12,12.2S136,417.9,129.3,417.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M210.2,440.5c0,6.5-5.1,11.6-11.6,11.8c-6.4,0-11.8-5.2-11.8-11.6c0-6.4,5.3-11.8,11.8-11.8
		C205.1,428.8,210.2,434,210.2,440.5z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M452.2,198.6c0,6.4-5.4,11.9-11.8,11.8s-11.6-5.4-11.6-11.9s5.4-11.8,11.8-11.8C447.1,186.9,452.2,192.1,452.2,198.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M394.6,371.1c0.1-6.4,5.3-11.3,11.9-11.1c6.2,0.1,11.2,5.4,11,11.8c-0.2,6.2-5.5,11.2-11.8,11
		C399.3,382.6,394.4,377.4,394.6,371.1z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M440.6,290.9c6.4,0,11.5,5.2,11.4,11.6c-0.1,6.1-5.2,11.2-11.4,11.2c-6.3,0.1-11.6-5.3-11.5-11.5
		C429.3,295.7,434.2,290.9,440.6,290.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M313.6,440.5c0,6.3-5.1,11.4-11.3,11.4c-6.2,0-11.4-5.1-11.5-11.3c-0.1-6.4,5.1-11.5,11.5-11.5
		C308.8,429.2,313.6,434.1,313.6,440.5z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M371.4,417.3c-6.3,0-11.4-5.1-11.4-11.3c0-6.2,5.2-11.4,11.4-11.4c6.4,0,11.4,5.1,11.4,11.5
		C382.8,412.5,377.8,417.3,371.4,417.3z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M60.4,174.7c-6,0-10.8-4.5-10.9-10.5c-0.1-6.1,4.7-11,10.8-11c6,0,10.8,4.8,10.8,10.6C71.1,169.9,66.4,174.7,60.4,174.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M163.9,71c-6-0.1-10.8-5-10.6-11.1c0.1-5.7,5-10.4,10.8-10.4c6,0,10.9,5,10.8,11C174.6,66.4,169.7,71.1,163.9,71z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M326.5,59.9c0.1-5.5,4.8-10.1,10.4-10.1c5.8,0,10.4,4.7,10.4,10.4c0,5.7-4.8,10.4-10.4,10.3
		C330.9,70.6,326.4,65.9,326.5,59.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M60.3,347.2c-5.8,0-10.3-4.5-10.3-10.3c0-5.8,4.7-10.4,10.3-10.4c5.7,0,10.4,4.8,10.4,10.4
		C70.6,342.6,66.1,347.2,60.3,347.2z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M440.7,153.9c5.3,0,10,4.7,9.9,10c0,5.4-4.5,10.1-10.1,10.1c-5.4,0-10.2-4.8-10.1-10.1
		C430.6,158.4,435.1,153.9,440.7,153.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M154.1,440.3c0.1-5.5,4.5-9.8,10.1-9.8c5.7,0.1,10,4.5,9.9,10.2c-0.1,5.5-4.7,9.9-10.3,9.8
		C158.2,450.4,154,445.8,154.1,440.3z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M336.8,450c-5.3,0-9.5-4.2-9.5-9.5s4.4-9.6,9.8-9.6c5.2,0.1,9.4,4.3,9.3,9.8C346.3,445.9,342.1,450,336.8,450z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M440.7,327.2c5.4,0.1,9.6,4.5,9.5,9.9c-0.1,5.2-4.7,9.4-9.9,9.3c-5.2-0.1-9.5-4.7-9.3-9.9
		C431,331.3,435.5,327.1,440.7,327.2z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M94.8,104.2c-5.2,0-9.3-4.3-9.3-9.5c0.1-5.1,4.2-9.1,9.4-9.1c5.2,0,9.4,4.3,9.2,9.5C104.1,100.2,99.9,104.2,94.8,104.2z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M406,103.5c-4.9,0-8.8-4-8.8-8.8c0-4.7,4-8.4,8.8-8.4c4.8,0,8.6,3.8,8.6,8.4C414.7,99.5,410.7,103.5,406,103.5z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M94.8,397.4c4.8,0,8.6,3.8,8.8,8.4c0.1,4.9-3.8,8.8-8.5,8.9c-4.7,0-8.6-3.8-8.8-8.4C86.2,401.3,89.9,397.4,94.8,397.4z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M17.4,233.1c0-4.7,3.8-8.3,8.4-8.3c4.7,0.1,8.3,3.9,8.2,8.5c-0.1,4.5-3.8,8.1-8.3,8.2C21.1,241.5,17.4,237.8,17.4,233.1z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M241.5,25.6c0,4.8-3.5,8.4-8.3,8.4c-4.7,0-8.4-3.7-8.4-8.3c0-4.5,3.9-8.4,8.4-8.3C237.8,17.4,241.5,21.1,241.5,25.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M25.8,259.4c4.4,0,8.2,3.9,8.2,8.3c0,4.5-3.8,8.2-8.2,8.2c-4.7,0-8.3-3.8-8.3-8.4C17.5,263.1,21.4,259.4,25.8,259.4z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M267.6,34c-4.5,0-8.2-3.8-8.1-8.3c0.1-4.7,3.9-8.4,8.4-8.2c4.4,0.1,8.2,4.1,8.1,8.4C275.8,30.4,272.2,34,267.6,34z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M137.6,60c0,4.7-3.4,8.3-8.1,8.4c-4.3,0.1-8.1-3.7-8.2-8c-0.1-4.7,3.5-8.3,8.1-8.4C133.8,52.1,137.6,55.7,137.6,60z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M68.4,129.7c-0.1,4.4-3.8,8-8.2,7.9c-4.7-0.1-8.1-3.7-8-8.4c0.1-4.4,3.9-8.1,8.2-8C65,121.4,68.5,125,68.4,129.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M414.1,406c0,4.4-3.7,8.1-8.1,8.1c-4.2,0-8-3.7-8.1-7.9c-0.1-4.5,3.5-8.3,8.2-8.2C410.5,397.9,414.1,401.5,414.1,406z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M371.5,52.6c4.3,0,7.6,3.5,7.6,7.8s-3.5,7.6-7.9,7.5c-4.2-0.1-7.5-3.4-7.5-7.6C363.7,56.1,367.2,52.6,371.5,52.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M68,371.3c0,4.3-3.2,7.6-7.6,7.6c-4.2,0-7.5-3.2-7.6-7.4c-0.1-4.3,3.2-7.8,7.5-7.8C64.5,363.7,67.9,366.9,68,371.3z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M33.4,198.5c0,4.1-3.4,7.5-7.5,7.6c-3.9,0.1-7.6-3.5-7.8-7.5c-0.1-4,3.4-7.6,7.4-7.8C29.7,190.9,33.4,194.3,33.4,198.5z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M206.1,25.6c0,4.3-3.2,7.6-7.4,7.6s-7.6-3.3-7.8-7.4c-0.1-4.2,3.3-7.5,7.5-7.6C202.8,18.1,206.1,21.4,206.1,25.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M233.3,467.6c4,0,7.4,3.4,7.4,7.4c0,4.1-3.4,7.6-7.6,7.5c-4.3-0.1-7.4-3.4-7.3-7.6C225.7,470.8,229.2,467.6,233.3,467.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M136.9,440.4c0,4.2-3.3,7.5-7.5,7.5c-4.1,0-7.4-3.4-7.4-7.4c0-4.1,3.4-7.6,7.5-7.6C133.5,433,136.8,436.4,136.9,440.4z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M275.2,475.2c0,4.1-3.3,7.4-7.4,7.4c-4.2,0-7.6-3.4-7.5-7.5c0.1-4.1,3.5-7.4,7.8-7.3C272.1,467.8,275.3,471.1,275.2,475.2z
		"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M475.3,240.6c-4.2,0-7.5-3.2-7.5-7.5c0-4.1,3.2-7.4,7.3-7.4c4.2,0,7.5,3.3,7.5,7.5C482.6,237.2,479.4,240.5,475.3,240.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M448.1,129.4c0,4.1-3.3,7.4-7.4,7.4c-4.2,0-7.6-3.3-7.5-7.5c0-4.1,3.5-7.5,7.6-7.5C444.8,122,448.1,125.5,448.1,129.4z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M475,260.3c4.2,0,7.4,3.1,7.5,7.2c0,4.3-3.1,7.5-7.4,7.5c-4.1,0-7.4-3.1-7.5-7.3C467.6,263.6,470.8,260.3,475,260.3z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M18.4,302.3c0-4.1,3.2-7.4,7.3-7.5c4.1,0,7.4,3.2,7.4,7.3c0,4.1-3.2,7.4-7.3,7.5C21.7,309.7,18.4,306.4,18.4,302.3z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M302.3,18.4c4.1,0,7.4,3.4,7.4,7.4c0,4-3.3,7.3-7.4,7.3c-4,0-7.4-3.3-7.4-7.4C294.9,21.7,298.2,18.3,302.3,18.4z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M447.5,371.4c0,3.8-3.1,6.9-6.9,7c-4,0-7.1-3-7.1-7c0-3.9,3.2-7.1,7.2-7C444.4,364.5,447.5,367.6,447.5,371.4z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M371.4,447.5c-4,0-7-3.2-6.9-7.2c0.1-3.8,3.4-7,7.1-6.9c3.9,0.1,7.1,3.4,7,7.3C378.4,444.5,375.2,447.5,371.4,447.5z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M481.9,198.4c0,3.9-2.9,6.9-6.8,6.9c-3.8,0-6.8-2.9-6.8-6.7c-0.1-3.8,2.9-6.9,6.5-6.9C478.8,191.6,481.8,194.5,481.9,198.4
		z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M198.6,481.9c-3.7,0-6.8-3-6.9-6.7c-0.1-3.8,3-7,6.9-7c3.7,0,6.9,3.2,6.9,7C205.4,478.8,202.2,481.9,198.6,481.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M475,308.8c-3.7,0-6.7-2.9-6.7-6.5c0-3.7,3-6.7,6.5-6.7c3.8,0,6.8,2.9,6.8,6.7C481.7,305.9,478.8,308.8,475,308.8z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M302.2,481.6c-3.7,0-6.7-3-6.5-6.7c0.1-3.7,3-6.7,6.7-6.7c3.8,0,6.8,3.1,6.7,6.9S305.9,481.6,302.2,481.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M170.2,25.7c0,3.5-2.7,6.2-6.1,6.3c-3.4,0.1-6.3-2.7-6.3-6.1s2.8-6.3,6.2-6.3C167.4,19.5,170.2,22.1,170.2,25.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M25.7,170.1c-3.4,0-6.2-2.8-6.2-6.2c0-3.3,2.8-6.2,6-6.3c3.5-0.1,6.4,2.8,6.4,6.3C31.9,167.5,29.3,170.1,25.7,170.1z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M342.8,25.7c0,3.2-2.7,6-5.9,6c-3.3,0-6.2-2.9-6.1-6.1c0.1-3.3,2.8-5.9,6-5.8C340.2,19.8,342.8,22.4,342.8,25.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M19.9,337c0-3.3,2.4-6,5.8-6.1c3.4-0.1,6.1,2.5,6.1,6c0,3.1-2.7,5.9-5.9,6C22.7,343,19.9,340.1,19.9,337z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M475.2,169.6c-3,0-5.5-2.5-5.7-5.5c0-3,2.5-5.5,5.5-5.5s5.7,2.7,5.7,5.5C480.8,167,478.3,169.5,475.2,169.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M94.9,54.7c3,0,5.4,2.4,5.5,5.3c0.1,3.1-2.3,5.7-5.4,5.8c-3,0.1-5.7-2.4-5.8-5.5C89.1,57.4,91.8,54.7,94.9,54.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M164,469.6c3.2,0,5.7,2.3,5.7,5.5c0,3.1-2.4,5.5-5.7,5.5c-3,0-5.5-2.4-5.5-5.4C158.3,472.1,160.9,469.6,164,469.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M54.8,95c0-3.2,2.3-5.5,5.5-5.5s5.5,2.3,5.7,5.5c0,3.1-2.5,5.5-5.7,5.5C57.1,100.3,54.8,98,54.8,95z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M336.9,469.8c2.9,0,5.2,2.3,5.2,5.3c0,2.8-2.5,5.3-5.3,5.3c-2.9,0-5.2-2.4-5.2-5.3C331.6,472.1,333.9,469.8,336.9,469.8z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M480.4,336.9c0,3-2.2,5.2-5.3,5.2c-3,0-5.2-2.3-5.2-5.3c0-2.9,2.4-5.3,5.3-5.2C478,331.5,480.4,334,480.4,336.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M406,55.2c2.8,0,5.1,2.3,5.1,5.1s-2.4,5.2-5.1,5.1c-2.8,0-5.1-2.4-5.1-5.1C400.9,57.5,403.2,55.2,406,55.2z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M60.3,400.9c2.9,0,5.1,2.2,5.1,5c0,2.9-2.2,5.1-5.1,5.1s-5-2.1-5.1-5C55.2,403,57.3,400.9,60.3,400.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M440.5,99.8c-2.7,0-4.9-2.3-4.9-5c0-2.8,2.1-4.9,4.9-5c2.9,0,5.1,2.2,5.1,5.1C445.4,97.7,443.2,99.8,440.5,99.8z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M94.8,445.5c-2.8,0-5-2.3-4.9-5c0.1-2.7,2.3-5,5-5c2.7,0,5,2.3,5,5C99.9,443.3,97.7,445.5,94.8,445.5z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M410.6,440.3c0.1,2.4-2,4.7-4.4,4.9c-2.4,0.1-4.7-2-4.8-4.4c-0.1-2.5,1.9-4.7,4.4-4.8C408.3,435.9,410.5,437.8,410.6,440.3
		z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M440.5,410.5c-2.5,0-4.5-2.1-4.5-4.7c0-2.5,2.2-4.5,4.7-4.5c2.5,0.1,4.5,2.2,4.5,4.7C445.1,408.6,443,410.6,440.5,410.5z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M30.4,129.4c0,2.3-2.2,4.4-4.5,4.4c-2.4,0-4.7-2.1-4.7-4.7c0-2.3,2.1-4.4,4.5-4.4C28.1,124.9,30.4,127.1,30.4,129.4z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M133.9,25.7c0,2.4-1.9,4.4-4.3,4.5c-2.5,0.1-4.7-1.9-4.7-4.4s2-4.5,4.5-4.5C131.9,21.2,133.9,23.2,133.9,25.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M29.9,371.2c0.1,2.3-1.8,4.3-4.1,4.4c-2.1,0-4.1-1.9-4.3-4c-0.1-2.3,1.7-4.4,4-4.4C27.8,367,29.8,368.9,29.9,371.2z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M371.4,29.9c-2.3,0-4.3-2-4.2-4.3c0-2.2,2.2-4.2,4.4-4.1c2.2,0.1,4,2,4,4.2C375.6,28.1,373.8,29.9,371.4,29.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M475,133.3c-2.1,0-4-2-3.9-4s2.1-4,4.1-3.9c2.1,0.1,3.9,2,3.9,4.1C479,131.7,477.1,133.4,475,133.3z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M133.4,475c0,2.2-1.7,4-3.8,4.1c-2.1,0.1-4-1.8-4-3.8c0-2.2,1.6-3.9,3.8-4C131.6,471.1,133.3,472.8,133.4,475z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M478.8,371.3c0,1.9-1.7,3.7-3.5,3.7c-2,0-3.8-1.8-3.7-3.9c0.1-2,1.7-3.5,3.7-3.4C477.1,367.8,478.8,369.3,478.8,371.3z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M375,475.2c0,2-1.7,3.5-3.7,3.4c-2,0-3.7-1.7-3.7-3.5c0-2,1.9-3.8,3.9-3.7C373.5,471.5,375.2,473.2,375,475.2z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M63.3,59.7c0.1,2.5-1.2,3.5-3,3.7c-1.7,0-3-1-3-2.9s0.9-3.1,2.9-3.2C62.1,57.2,63.2,58.3,63.3,59.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M25.2,91.9c2.7,0.4,3.9,1.7,3.3,3.3c-0.3,1.1-1.9,2.3-3,2.4c-1.8,0.2-3-1.3-2.5-3.1C23.3,93.4,24.6,92.5,25.2,91.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M95.9,22.9c0.4,0.7,1.7,1.7,1.9,2.8c0.2,1.7-1.1,2.9-2.9,2.9c-1.8,0-2.8-1.2-2.8-2.8C92,24,93,22.9,95.9,22.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M59.5,443.6c-0.7-1-1.8-2-1.9-3.1c-0.2-1.7,1.2-2.9,2.8-2.5c1,0.2,2.2,1.8,2.3,2.8c0.1,0.9-1.3,2-2.1,2.9
		C60.3,443.6,60,443.6,59.5,443.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M441.1,57.3c0.8,1,2,1.9,2.1,3c0.2,1.7-1.1,3-2.7,2.7c-1-0.2-2.4-1.6-2.5-2.5c-0.1-0.9,1.2-2,2-3
		C440.3,57.3,440.7,57.3,441.1,57.3z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M28.7,406.7c-1,0.6-2.1,1.8-3.1,1.7c-0.9-0.1-2.4-1.7-2.2-2.3c0.2-1,1.6-2.3,2.4-2.4c0.9-0.1,2,1.2,3,2
		C28.7,405.9,28.7,406.3,28.7,406.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M405.4,28.7c-0.7-1-1.9-2.1-1.8-3c0.1-0.9,1.6-2.5,2.2-2.4c1,0.2,2.4,1.4,2.5,2.4c0.1,0.9-1.1,2-1.8,3
		C406.2,28.7,405.8,28.7,405.4,28.7z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M94.7,478.5c-1.1-1.7-2.3-2.7-2.2-3.5c0.1-0.9,1.4-2.1,2.3-2.3c1.6-0.3,2.7,1,2.4,2.4C97.1,476.2,96,477,94.7,478.5z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M478.7,94.9c-1.7,1.1-2.5,2.2-3.5,2.3c-1.7,0.2-2.8-1-2.4-2.4c0.2-0.9,1.6-2.1,2.4-2.2C476,92.5,477,93.7,478.7,94.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M440.6,443.8c-1.1-1.4-2.3-2.4-2.2-3.2c0.2-1,1.4-1.8,2.2-2.5c0.8,0.9,2,1.7,2.2,2.5C442.9,441.4,441.7,442.4,440.6,443.8z
		"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M406,478.2c-1-1.6-2-2.4-2-3.2c0-0.8,1.2-1.4,2-2.1c0.8,0.8,1.9,1.4,2.1,2.3C408.1,475.9,407,476.8,406,478.2z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M478.3,405.8c-1.3,1.1-2.3,2.2-2.9,2.1c-0.9-0.1-1.6-1.3-2.3-2.1c0.7-0.7,1.3-1.9,2.1-2
		C475.9,403.8,476.8,404.8,478.3,405.8z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M27.8,60c-0.9,0.8-1.4,1.4-2,1.6c-0.4,0.1-1.4-0.8-1.4-1c0.1-0.7,0.7-1.4,1.1-1.6C26.1,58.8,26.8,59.5,27.8,60z"
      />
    </g>
  </svg>
)

const ModranskyBiografLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={width}
    height={height}
  >
    <image className="all" width="100%" height="100%" />
    <g className={MODRANSKY_BIOGRAF}>
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M467.2,421c-144.9,0-289.3,0-434.2,0c0-1.9,0-3.6,0-5.3c0-35.8,0.2-71.6-0.2-107.4c-0.1-5.7,2.4-8.4,6.6-11.2
	c9.8-6.4,19.4-13.2,29.2-19.7c8.4-5.7,16.8-11.3,25.2-17c7.6-5.2,15.2-10.4,23.1-15.8c-4.5-3.1-8.5-6-12.6-8.8
	C95.5,229.9,86.8,224,78,218c-9.2-6.3-18.4-12.5-27.6-18.8c-5.2-3.6-10.4-7.2-15.5-10.9c-0.9-0.7-1.8-2-1.8-3.1
	c-0.1-12.6-0.1-25.1-0.1-38.2c22.8,0,45.4,0,68.2,0c0-22.9,0-45.3,0-68.1c122,0,243.7,0,365.7,0c0,98.8,0,197.7,0,296.8
	c-16.3-3.7-30.8-10.5-41.3-24.2c-5.5-7.2-11.4-14-17.1-20.9c-5.4-6.6-10.6-13.5-16.3-19.8c-7.2-8-16.8-9.7-26.4-6.2
	c-5.4,2-10.2,6.5-14.3,10.8c-9.4,9.9-14,22.6-19.5,34.7c-2,4.3-3,9-4.5,13.5c-0.4,0-0.8,0-1.3,0c-1.3-4.5-2.5-9.1-3.8-13.6
	c-3.5-12.1-6.9-24.2-10.6-36.2c-2.6-8.4-4.8-16.9-8.4-24.8c-7.6-16.8-20.4-27.1-39.1-29.8c-18.2-2.6-33.5,3.9-47.1,15.1
	c-8.1,6.6-16.1,13.5-23,21.4c-9.7,11-18.3,23.1-27.3,34.7c-0.2,0.3-0.4,0.7-0.6,1.1c-0.4-0.1-0.8-0.2-1.2-0.3c0-1.5,0-3,0-4.5
	c0-25.6,0-51.2,0-76.9c0-10.2-4.7-14.7-15.4-14.7c-9.4,0-14.3,5-14.3,14.6c0,41.1,0,82.1,0,123.2c0,7.4,3.2,13,9.3,17
	c4.6,3,9.9,2.8,14.1-0.5c9.8-7.7,16-18.1,22.8-28.2c3.4-5.1,7.1-10.1,10.6-15.1c5.6-8,11.1-16.2,16.8-24.1
	c8.2-11.5,16.8-22.6,29.2-29.9c7.1-4.2,14.8-7.6,23.4-4.7c6.3,2.1,10.5,6.9,13.6,12.2c7.5,12.6,10.2,27.1,14.5,40.8
	c4.5,14.5,6.9,29.7,14.7,43c7.3,12.5,16,19.4,31.6,15c4.4-1.2,8.5-5.9,11.1-10.1c4.4-7,7.6-14.8,11.3-22.3
	c5-10.4,9.9-20.9,15.3-32.2c3.3,3.6,5.9,6.4,8.3,9.2c10,11.5,20,23,30,34.5c12.5,14.2,28.8,21.5,46.7,25.7c8.2,1.9,8.2,1.9,8.2,10.6
	C467.2,416,467.2,418.3,467.2,421z M146.9,147.1h-45.7v46.5h45.7V147.1z"
      />
    </g>
  </svg>
)

const PritomnostLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={width}
    height={height}
  >
    <image className="all" width="100%" height="100%" />
    <g className={PRITOMNOST}>
      <path
        d="M500 0H0V500H500V0Z"
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
      />
      <path
        d="M348.281 186.42C372.181 186.42 384.911 196.35 384.911 214.42C384.911 232.25 372.181 242.421 348.281 242.421H336.281V270.421H350.101C391.666 270.421 417.646 250.811 417.646 214.39C417.646 177.46 391.646 158.355 350.101 158.355H336.296V186.355L348.281 186.42Z"
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
      />
      <path
        d="M261.281 186.42C285.181 186.42 297.911 196.35 297.911 214.42C297.911 232.25 285.181 242.421 261.281 242.421H249.281V270.421H263.101C304.666 270.421 330.646 250.811 330.646 214.39C330.646 177.46 304.646 158.355 263.101 158.355H249.296V186.355L261.281 186.42Z"
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
      />
      <path
        d="M243.644 214.435C243.644 177.505 217.644 158.4 176.099 158.4H101.539V341.27H134.794V270.465H176.099C217.664 270.465 243.644 250.855 243.644 214.435ZM174.279 242.435H134.779V186.42H174.279C198.179 186.42 210.909 196.35 210.909 214.42C210.909 232.265 198.179 242.45 174.279 242.45V242.435Z"
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
      />
    </g>
  </svg>
)

const EdisonLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={width + 10}
    height={height + 10}
  >
    <image className="all" width="100%" height="100%" />
    <g className={EDISON}>
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M258.3,47.6c7.8,1.4,15.8,2.2,23.5,4.3c50.8,14.3,85.6,45.2,99.7,97.1c6.7,24.5,3.2,48.9-3.7,72.9
		c-9.3,32.2-24,62.1-41.1,90.7c-27.8,46.4-56.7,92.1-85.2,138.1c-0.3,0.6-0.8,1-1.4,1.9c-0.5-0.5-1.1-0.9-1.4-1.3
		c-31-50.7-62.6-101-92.9-152.1c-16.2-27.4-28.8-56.5-36.5-87.7C98,126,159.1,63.7,222.7,50.7c6.2-1.3,12.4-2.1,18.6-3.2
		C247,47.6,252.7,47.6,258.3,47.6z M326.5,116c-21-25.5-47.9-38.7-81-37.3c-29.6,1.2-53.7,14.3-73.5,38c2.8-0.5,4.2-0.7,5.6-1
		c28.6-5.7,56.6,11.2,64,39.4c2,7.6,2.1,15.8,2.4,23.7c0.4,8.6,0.1,17.2,0.1,25.5c9.5,2.3,10.1,1.8,10.5-5.9c0.5-11,1.1-22,1.7-32.9
		c0.9-15.7,7.7-28.5,19.7-38.4C290.8,115,307.8,111.9,326.5,116z M274.4,204.9c14.2,0,27.7,0.8,41.1-0.2c18-1.3,31.2-16.7,31.3-34.7
		c0.1-18.2-13.3-33.3-31.4-35.5c-17.3-2.1-34.2,9.6-38.1,26.8c-1.1,5-1.2,10.2-1.5,15.3C275.1,186,274.8,195.3,274.4,204.9z
		 M224.4,204.5c0-5.9,0.2-12-0.1-18.1c-0.4-8.6-0.2-17.5-2.1-25.8c-3.9-16.8-20.8-27.9-37.6-26c-16.6,1.9-30.4,15.9-31.4,31.8
		c-1.2,18.6,9.9,35.4,27.6,37.7C194.9,206,209.5,204.5,224.4,204.5z M271,274.8c25.8-1.7,65.6-34.8,69.6-57.8
		c-21.1,13.5-44.6,6.4-67.2,8.9C272.6,242.2,271.8,258.2,271,274.8z M158.7,216.6c7.3,25.6,47.1,58.5,68.6,57.3
		c-0.6-16-1.2-32.1-1.7-48.1C203,223.5,180,230.2,158.7,216.6z M247.2,225.7c0,16.6,0,32.7,0.1,48.8c0,1,1.1,2,1.8,3
		c0.7-1.1,1.9-2.2,2-3.3c0.5-7.1,0.9-14.3,1.1-21.4c0.2-8.9,0-17.9,0-27.1C250.7,225.7,248.6,225.7,247.2,225.7z"
      />
    </g>
  </svg>
)

const KinolodLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={width + 10}
    height={height + 10}
  >
    <image className="all" width="100%" height="100%" />
    <g className={KINOLOD}>
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M439.1,412.6H59.4V263.7c35.2-11.6,64.2-1.2,88.3,27.7c21.6,26,40.9,29.4,71.9,14.3c36.9-18.1,74.4-34.4,114.7-43.7
		c33.1-7.6,60,0.5,81.2,27.5c6,7.6,13.2,14.2,19.5,21.6c2,2.3,3.9,5.6,4,8.5C439.3,350,439.1,380.4,439.1,412.6z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M474.6,115.4c-1.7,11.9-3.4,23.8-5.2,36.2c-4.1,0-7.7,0-12.2,0c0-21.2,0-42,0-64.2c6,0,11.7,0,17.5,0
		C474.6,96.7,474.6,106.1,474.6,115.4z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M302.9,248.9c0.9-28.8,9-53.4,32.8-69.8c25.9-17.8,51.7-14.1,76.9,5.7c0-28.8,0-56,0-84.3c8.7,0,16.8,0,25.7,0
		c0,61.1,0,122.1,0,185.4c0.7-0.4-24.7-29.2-24.7-29.2s0-27,0-29.5c0-7.6,0-20.3,0-20.3s-9.7-8.6-17.2-11.5
		c-16-6.2-33.8-8.2-47.1,4.1c-7.4,6.8-11.9,13.2-14.9,22.1c-2.5,7.6-4.2,20-4.2,20L302.9,248.9z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M156.8,271.4c-6.9-5.6-21.1-17.2-28.7-24.3l0,0c10.3-56.8,33-79.3,78.1-79c44.4,0.3,69.3,48.1,67,91.8l0,0
		c0,0-20.9,8.8-27.2,11.4c-0.9-14.6-0.3-29.5-3.1-43.6c-4.7-23.6-21.3-37.3-41.6-37.4c-20.5,0-36.7,13.3-41.5,37.3
		C156.8,242.2,157.6,257.5,156.8,271.4z"
      />
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M59.4,242.7c0-47.8,0-94.4,0-142.2c8.4,0,16.5,0,25.5,0c0,45.8,0,91.6,0,138.4C76.7,240.1,68.5,241.4,59.4,242.7z"
      />
    </g>
  </svg>
)

const KasarnaKarlinLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={width}
    height={height}
  >
    <image className="all" width="100%" height="100%" />
    <g className={KARLIN}>
      <g transform="translate(-8.34e-7 -924)">
        <g transform="matrix(1.15 0 0 -1.23 10.4 924)">
          <path
            fill={SECONDARY_COLOR}
            className="SECONDARY_COLOR"
            d="M110.7-33.1l-12.9,0H81.2v-341.6h65v110.7l3.7,4.1l0.1-0.1l24,27l101.8-141.6h78.8L216.4-182.8L347.4-31.7h-80.2
			L146.4-173.3v141.6l-35.5,0v0L110.7-33.1z M181.4-183.9L303.9-41h25.9L205.9-183.9l132.7-184.3h-25.6L181-183.9H181.4z M277.6-41
			h15.7l-6.7-7.8C286.6-48.8,277.6-41,277.6-41z M116-41h22.1v-155.4l12.7-10.9L116-247.7L116-41L116-41z M89.1-41H108v-10.6
			l-18.9,0V-41L89.1-41z M261.9-51.6l6.7,7.8l12.9-11.1l-6.7-7.8L261.9-51.6z M250-65.4l6.7,7.8l12.9-11l-6.7-7.8L250-65.4L250-65.4
			z M89.1-59.7l18.9,0v-10.6l-18.9,0V-59.7L89.1-59.7z M237.9-79.3l6.7,7.8l12.9-11l-6.7-7.8C250.7-90.3,237.9-79.3,237.9-79.3z
			 M89.1-78.3l18.9,0v-10.6H89.1V-78.3L89.1-78.3z M226-93.2l6.7,7.8l12.9-11l-6.7-7.8C238.9-104.2,226-93.2,226-93.2z M89.1-96.9
			l18.9,0v-10.6H89.1V-96.9L89.1-96.9z M214.2-107.1l6.7,7.8l12.9-11l-6.7-7.8C227.1-118,214.2-107.1,214.2-107.1z M202.4-121
			l6.7,7.8l12.8-11l-6.7-7.8C215.2-131.9,202.4-121,202.4-121z M89-115.5h18.9v-10.6H89V-115.5z M190.5-134.8l6.7,7.8l12.8-10.9
			l-6.7-7.8L190.5-134.8L190.5-134.8z M89-134.1l18.9,0v-10.6l-18.9,0V-134.1z M178.7-148.7l6.7,7.8l12.8-10.9l-6.7-7.8L178.7-148.7
			L178.7-148.7z M89-152.7h18.9v-10.6H89V-152.7z M166.9-162.6l6.7,7.8l12.8-10.9l-6.7-7.8L166.9-162.6L166.9-162.6z M155-176.4
			l6.7,7.8l12.7-10.9l-6.7-7.8C167.8-187.3,155-176.4,155-176.4z M89-171.3l18.9,0V-182H89V-171.3L89-171.3z M143.2-190.2l6.5,7.7
			l12.7-10.9l-6.6-7.7L143.2-190.2L143.2-190.2z M89-190h18.9v-10.6H89V-190z M115.9-259.9l59.8,69.6l11.9-16.6L138-262.8v-105.8
			h-22.1v108.4V-259.9z M89-208.7h18.9v-10.6H89V-208.7z M179.3-228.4l13.3,14.9l6.7-9.4l-13.2-14.9
			C186.1-237.8,179.3-228.4,179.3-228.4z M89-227.3h18.9v-10.6H89V-227.3z M190.5-244.4l13.2,14.9l6.7-9.4l-13.2-14.9L190.5-244.4
			L190.5-244.4z M202-260.4l13.2,14.9l6.7-9.4l-13.2-14.9L202-260.4z M89-245.9h18.9v-10.6H89V-245.9z M213.5-276.4l13.2,14.9
			l6.7-9.3l-13.2-14.9L213.5-276.4L213.5-276.4z M89-264.6l18.9,0v-10.6l-18.9,0V-264.6L89-264.6z M225-292.4l13.2,14.9l6.7-9.4
			l-13.2-14.8L225-292.4L225-292.4z M89-283.2l18.9,0v-10.6l-18.9,0V-283.2L89-283.2z M236.5-308.4l13.2,14.8l6.7-9.4l-13.2-14.8
			L236.5-308.4L236.5-308.4z M89-301.8l18.9,0v-10.6l-18.9,0V-301.8z M247.9-324.4l13.1,14.8l6.7-9.4l-13.1-14.8L247.9-324.4
			L247.9-324.4z M89-320.4l18.9,0v-10.6H89V-320.4L89-320.4z M259.4-340.4l13.2,14.8l6.7-9.4l-13.1-14.8L259.4-340.4L259.4-340.4z
			 M89-339h18.9v-10.6l-18.9,0V-339L89-339z M270.9-356.4l13.1,14.8l6.7-9.4l-13.1-14.8L270.9-356.4L270.9-356.4z M295.5-357.6
			l7.7-10.7H286C286-368.3,295.5-357.6,295.5-357.6z M88.9-357.7h18.9v-10.6H88.9V-357.7z"
          />
        </g>
      </g>
    </g>
  </svg>
)

const PonrepoLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 500 500"
    width={width}
    height={height}
  >
    <image className="all" width="100%" height="100%" />
    <g className={PONREPO}>
      <path
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
        d="M193.5,292.3c0,53.7,0,105.3,0,157.7c-22.2,0-43.5,0-65.6,0c0-131.2,0-261.8,0-393.3c4.3-0.3,8-0.7,11.7-0.7
	c39.8,0,79.7,0,119.5,0c24.9,0,49,4.3,71.2,15.9c37.9,19.7,57.4,51.7,59.4,93.9c0.7,13.8-0.6,28.1-3.8,41.5
	c-11,45.1-41.8,70.4-85.9,80.1c-15.2,3.3-31,4.1-46.6,4.7C234.1,292.9,214.7,292.3,193.5,292.3z M194.4,238.3
	c27.8-0.4,54.9,2.4,81.7-2.4c23.8-4.3,40-18.1,44.7-42.6c1.8-9.4,2-19.4,1.1-29c-2.8-28.2-19.4-46.8-47.2-52.3
	c-26.3-5.2-52.9-1.2-80.2-2.4C194.4,152.7,194.4,194.4,194.4,238.3z"
      />
    </g>
  </svg>
)

const MojeKinoLiveLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg viewBox="0 0 500 500" width={width} height={height}>
    <image className="all" width="100%" height="100%" />
    <g className={MOJEKINOLIVE}>
      <path
        d="M0,0h500v500H0V0z"
        fill={SECONDARY_COLOR}
        className="SECONDARY_COLOR"
      />
      <polygon
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        points="63.8,248.7 82.4,248.7 82.4,292.2 100,272.2 121.8,272.2 101,294.4 124.2,331.5 102.4,331.5
		88.1,307.3 82.4,313.3 82.4,331.5 63.8,331.5 	"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M128.6,248.7h18.9V264h-18.9V248.7z M128.6,272.2h18.9v59.3h-18.9V272.2z"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M156.5,272.2h18.2v8.1h0.3c4.4-6.4,10.1-9.8,18.3-9.8c13,0,20.6,9.3,20.6,22.5v38.6h-18.9v-34.7
			c0-6.3-3.1-10.5-9.3-10.5c-6.3,0-10.4,5.2-10.4,12.5v32.8h-18.9V272.2z"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M219.9,301.9c0-17.8,12.7-31.4,31.7-31.4c19.1,0,31.6,13.5,31.6,31.4c0,17.8-12.5,31.4-31.6,31.4
			C232.6,333.3,219.9,319.8,219.9,301.9 M264.1,301.9c0-10.8-4.4-18.1-12.6-18.1c-8.2,0-12.5,7.3-12.5,18.1
			c0,10.8,4.3,17.9,12.5,17.9C259.7,319.9,264.1,312.7,264.1,301.9"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M183.8,182.2h18.1v8.5h0.3c3.7-6.1,9.4-10.2,17.9-10.2c7.8,0,13.7,4.1,16.7,10.4h0.2
			c4.6-7.2,11-10.4,18.6-10.4c13.4,0,20.1,9.1,20.1,22.5v38.5h-18.9v-35.2c0-6.4-2.7-10.1-8.3-10.1c-6,0-9.4,5-9.4,12v33.2h-18.9
			v-35.2c0-6.4-2.7-10.1-8.3-10.1c-5.9,0-9.4,5-9.4,12v33.2h-18.9V182.2z"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M281.7,211.9c0-17.8,12.7-31.4,31.7-31.4c19.1,0,31.6,13.5,31.6,31.4c0,17.8-12.5,31.4-31.6,31.4
			C294.5,243.3,281.7,229.8,281.7,211.9 M325.9,211.9c0-10.8-4.4-18.1-12.6-18.1c-8.2,0-12.5,7.3-12.5,18.1
			c0,10.8,4.3,17.9,12.5,17.9C321.5,229.9,325.9,222.7,325.9,211.9"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M341.5,260.4v-13.1c6.6,0,10,0,10-6.4v-58.8h18.9v59.7c0,7.9-1.7,11.9-4.5,14.8c-3.4,3.4-8,4.6-13.7,4.6
			C348.9,261.4,344.4,261,341.5,260.4 M351.4,158.7h18.9V174h-18.9V158.7z"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M376.7,211.7c0-17.7,12.4-31.3,30.4-31.3c8.5,0,15.2,2.8,20.3,7.4c7.1,6.5,10.5,16.8,10.4,28.8h-42.6
			c1.2,8,5.6,13,13.3,13c5,0,8.2-2.2,9.8-5.9h18.4c-1.3,5.3-4.7,10.4-10,14c-5,3.5-11,5.4-18.5,5.4
			C388.7,243.2,376.7,229.6,376.7,211.7 M418.7,205.5c-0.7-7.1-5.1-11.6-11.2-11.6c-7.2,0-10.9,4.5-12.2,11.6H418.7z"
      />
      <path
        fill={PRIMARY_COLOR}
        className="PRIMARY_COLOR"
        d="M172.1,202.1c0-10.6-8.6-19.3-19.3-19.3H81.5c-10.6,0-19.3,8.6-19.3,19.3v20c0,10.6,8.6,19.3,19.3,19.3h71.3
			c10.6,0,19.3-8.6,19.3-19.3V202.1z M93.7,231.9H78.8c-0.9,0-1.7-0.7-1.7-1.8v-34.4c0-1.1,0.9-1.7,1.8-1.7c0.9,0,1.8,0.6,1.8,1.7
			v32.5h13c1.1,0,1.7,1,1.7,1.8C95.4,231,94.8,231.9,93.7,231.9 M104.8,230.1c0,1.2-1,1.7-1.8,1.7c-0.9,0-1.8-0.5-1.8-1.7v-34.4
			c0-1.1,0.9-1.7,1.8-1.7c0.9,0,1.8,0.6,1.8,1.7V230.1z M134.2,196.2l-9.8,34.1c-0.3,1-0.9,1.5-1.7,1.5c-0.8,0-1.5-0.5-1.7-1.5
			l-9.8-34.1c-0.1-0.2-0.1-0.4-0.1-0.5c0-1,0.9-1.7,1.8-1.7c0.7,0,1.4,0.4,1.6,1.2l8.3,28.6l8.2-28.6c0.3-0.9,0.9-1.2,1.6-1.2
			c0.9,0,1.8,0.6,1.8,1.7C134.3,195.8,134.2,196,134.2,196.2 M153.5,209.8c1.1,0,1.7,1,1.7,1.8c0,0.9-0.6,1.8-1.7,1.8h-9.6v14.9h13
			c1.1,0,1.7,1,1.7,1.8c0,0.9-0.6,1.8-1.7,1.8h-14.7c-0.9,0-1.7-0.7-1.7-1.8v-34.4c0-1,0.6-1.7,1.7-1.7h14.7c1.1,0,1.7,0.8,1.7,1.7
			c0,0.9-0.6,1.7-1.7,1.7h-13v12.4H153.5z"
      />
    </g>
  </svg>
)

const StrahovLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg viewBox="0 0 500 500" width={width} height={height}>
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M179.9,261.7c0-23.4,0-46.7,0-70.1c0-2.4,0-4.9-0.2-7.3c-1.2-13.8-6.4-20.5-18.2-23.2c-2.6-0.6-5.3-1-8-1.2
		c-18.2-1.3-36.4-0.4-54.6-0.6c-6.7-0.1-13.3,0.3-19.9,1.5c-11.7,2.2-17.8,8.8-19.1,20.6c-0.3,3.1-0.6,6.2-0.6,9.3
		c0,48.9,0,97.8-0.1,146.8c0,2.8,0.7,3.7,3.6,3.6c15.1-0.1,30.2-0.1,45.3-0.1c6.7,0,6.7,0,6.7-6.8c0-16,0.2-32.1-0.1-48.1
		c-0.1-4.1,1.2-5.3,5-4.7c0.1,0,0.3,0,0.4,0c3.4-0.6,4.6,0.5,4.5,4.2c-0.2,17,0,34-0.2,51c0,3.3,0.5,4.6,4.3,4.5
		c15.8-0.3,31.5-0.2,47.3,0c3.1,0,4.1-0.6,4-3.9C179.8,311.9,179.9,286.8,179.9,261.7z M121.4,247.2c-6.6-0.1-6.6,0.1-6.6-6.6
		c0-13.6,0-27.1,0-40.7c0-2.4-0.1-5.1,2.6-6.4c3.7-1.8,7,0.4,7.1,4.8c0.1,7.7,0,15.4,0,23.2c0,0,0,0,0,0c0,7.4-0.1,14.9,0,22.4
		C124.5,246.3,124,247.2,121.4,247.2z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M190.9,250c0-28.8,0.1-57.6-0.1-86.4c0-3.3,0.7-4.4,4.2-4.3c15.9,0.2,31.8,0.2,47.7,0c3.1,0,3.9,0.9,3.8,3.9
		c-0.1,20.5-0.1,41,0,61.6c0,1.6-1.4,4.1,1.8,4.5c2.9,0.3,5.4,0,6.7-3.2c0.9-2.2,0.8-4.5,0.8-6.8c0-18.6,0.1-37.2-0.1-55.9
		c0-3.2,0.8-4.1,4.1-4.1c15.9,0.2,31.8,0.1,47.7,0c2.8,0,4,0.4,4,3.7c-0.2,17.8-0.1,35.6-0.1,53.4c0,2.8-0.1,5.7-0.5,8.5
		c-1.6,10.5-7.9,16.6-18.1,19c-5.9,1.4-11.8,1.8-17.8,1.9c-1.1,0-2.9-0.7-3,1.1c-0.2,2.3,1.9,1.4,3,1.4c6.1,0.3,12.2,0.5,18.2,2
		c11.4,2.9,17.6,9.9,17.9,21.7c0.5,14.9,0.3,29.9,0.3,44.8c0,7.2,0,14.4,0,21.6c0,1.9-0.4,2.6-2.5,2.6c-16.9-0.1-33.7-0.1-50.6,0
		c-3.2,0-2.6-1.9-2.6-3.7c0-20.5,0-41,0-61.6c0-2,0-4.1-0.3-6.1c-0.3-2.4-1.6-4.3-4.1-5c-3.6-1-5,0-5,3.8c0,22.4,0,44.9,0,67.3
		c0,5.3,0,5.3-5.5,5.3c-15.5,0-31-0.1-46.5,0.1c-3.1,0-3.8-0.8-3.8-3.8C191,308.2,190.9,279.1,190.9,250z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M379.1,341c-9.2,0-18.5,0-27.7,0c-3.4,0-6.8-0.4-10.1-1.1c-11.6-2.4-17.4-9.1-18.6-21.5c-1.2-11.9-0.6-23.9-0.8-35.8
		c0-2.1,0.6-2.9,2.8-2.9c16.3,0.1,32.6,0.1,48.9,0c2.4,0,2.9,1,2.9,3.1c-0.1,7.1,0,14.1,0,21.2c0,1.9,0.1,3.8,0.9,5.6
		c0.9,2.1,2.5,2.7,4.6,2.7c1.9,0,3-1.1,3.5-2.9c0.2-0.8,0.6-1.6,0.6-2.4c0-7.6,0.5-15.2-0.3-22.8c-0.5-4.7-3.1-7.6-7.1-9.6
		c-10.1-4.9-20.8-8.6-31.2-12.9c-8.1-3.4-15.9-7.1-20.4-15.4c-3.5-6.5-4.9-13.5-5.1-20.7c-0.3-12.5-0.2-25-0.1-37.5
		c0-4.1,0.8-8.1,2.1-12c3.9-11.8,13.5-15.3,24.4-16.3c20.9-1.8,41.8-0.6,62.7-0.7c3.2,0,6.5,0.6,9.7,1.2c15,3.2,16.9,11,18.4,21.1
		c1.8,12.4,0.6,25,0.9,37.4c0.1,2.2-0.8,2.8-2.9,2.8c-16-0.1-32.1-0.1-48.1,0c-2.4,0-3-0.9-3-3.1c0.1-7.1,0.1-14.1,0-21.2
		c0-2-0.4-4-0.8-6c-0.4-1.9-1.6-3.1-3.7-3.2c-2.1-0.1-3.7,0.6-4.5,2.7c-0.2,0.6-0.6,1.3-0.6,1.9c-0.2,7.6-0.5,15.2,0.1,22.8
		c0.5,6.4,5.3,9.2,10.4,11.4c7.1,3,14.4,5.8,21.5,8.7c6.6,2.7,13.5,4.9,19.3,9.4c8.4,6.6,11.2,15.7,12,25.7c1.1,13.8,1,27.7,0,41.5
		c-0.7,9.5-2.4,18.9-11.8,24.1c-5.5,3.1-11.8,4.2-18,4.3C399.8,341.1,389.4,340.9,379.1,341C379.1,340.9,379.1,341,379.1,341z"
    />
  </svg>
)

const LedarnyLogo = ({
  width,
  height,
  PRIMARY_COLOR = 'white',
  SECONDARY_COLOR = 'black',
}) => (
  <svg viewBox="0 0 500 500" width={width} height={height}>
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M341.3,325.2c4.3,2.3,7.2,5.2,8.7,9.5c0.6,1.8,0.7,3.6,0.7,5.5c0,19.2,0,38.5,0,57.7c0,2.9-0.1,5.8,0.9,8.6
		c0.4,1.2-0.2,1.4-1.2,1.4c-7.7,0-15.3,0-23,0c-1.1,0-1.8-0.6-2.1-1.6c-0.4-1.5-0.8-3-0.8-4.6c0-19.2,0-38.5,0-57.7
		c0-3-0.8-3.7-3.4-3.7c-1.3,0-1.5,0.5-1.5,1.6c0.1,1.6,0,3.3,0,4.9c0,19.5,0,38.9,0.1,58.4c0,2.1-0.5,2.8-2.7,2.8
		c-7.2-0.1-14.4-0.1-21.7,0c-1.5,0-2.1-0.3-2.1-2c0-49.9,0-99.9,0-149.8c0-1.7,0.6-2.1,2.2-2.1c13,0.1,25.9-0.1,38.9,0.1
		c9.7,0.1,15.8,6.6,16,17.1c0.2,13,0.2,25.9,0,38.9C350.2,316.6,347.8,322,341.3,325.2z M319.6,296c0,6.7,0,13.4,0,20.1
		c0,0.8-0.6,2.2,0.9,2.4c1.3,0.2,2.7,0,3.5-1.4c0.5-0.8,0.5-1.7,0.5-2.6c0-12.4,0-24.7,0-37.1c0-0.7,0-1.4-0.1-2
		c-0.5-1.8-1.9-1.7-3.4-1.7c-1.7,0-1.4,1.2-1.4,2.2C319.6,282.6,319.6,289.3,319.6,296z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M174.3,330.8c0-24.7,0-49.5-0.1-74.2c0-2,0.6-2.5,2.5-2.5c11.8,0.1,23.7-0.1,35.5,0.1c6.5,0.1,12.4,1.9,16.1,7.9
		c1.9,3.1,3.1,6.5,3.2,10.1c0.2,5.8,0.1,11.6,0.1,17.4c0,31.7,0,63.4,0,95c0,5.2-0.7,10.1-3.4,14.6c-3.6,6.1-9.3,8.6-16.1,8.7
		c-12.1,0.1-24.1,0-36.2,0.1c-1.8,0-1.8-0.9-1.8-2.3C174.3,380.8,174.3,355.8,174.3,330.8z M199.9,330.8c0,17.6,0,35.2,0,52.8
		c0,0.9-0.7,2.2,0.9,2.5c2.1,0.4,3.7-0.3,4.2-2c0.2-0.8,0.3-1.8,0.3-2.6c0-34,0-68,0-102c0-0.8,0-1.6-0.3-2.4c-0.8-2-2.5-2-4.1-1.8
		c-1.6,0.2-1,1.6-1,2.5C199.9,295.5,199.9,313.2,199.9,330.8z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M390.7,334.4c0-25.7,0-51.4,0-77.1c0-3.7-0.1-3.1,3.1-3.2c5.5,0,11,0,16.5-0.1c1.7,0,2.2,0.5,2.2,2.3
		c-0.2,6.3-0.1,12.7-0.1,19c0,42.6,0,85.1,0,127.7c0,4.9,0,4.9-4.9,4.9c-5.7,0-11.3,0-17,0c-1.7,0-2.4-0.3-2.6-2.3
		c-1.6-12.9-3.5-25.8-5.2-38.7c-1.9-14.2-3.6-28.3-5.5-42.5c-0.2-1.8-0.3-3.6-1.1-5.3c0,12.4-0.1,24.8,0,37.2
		c0.2,16.2,0.1,32.3,0.2,48.5c0,3.1,0,3-3.1,3.1c-5.5,0-11,0-16.5,0.1c-1.7,0-2.3-0.5-2.3-2.3c0.1-48.6,0-97.2,0.1-145.8
		c0-5.8,0-5.8,5.8-5.8c6.2,0,12.4,0,18.5,0c1.3,0,2,0.3,2.1,1.8c0.9,8.1,1.9,16.3,2.9,24.4c1.8,14.3,3.6,28.6,5.3,43
		C389.8,327,390.2,330.7,390.7,334.4C390.7,334.5,390.7,334.5,390.7,334.4z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M143.1,159.2c0,20.6,0.1,41.3,0,61.9c-0.1,8.4-3.7,15.2-10.9,19.8c-2.5,1.6-5.2,2.7-8.1,3.1c-8,1.2-16,1.6-23.8-1.1
		c-8.5-2.9-13.9-10.3-14.4-19.3c-0.6-9.8-0.2-19.5-0.2-29.3c-0.1-32.9,0-65.7-0.1-98.6c0-1.8,0.5-2.3,2.3-2.3
		c7.3,0.1,14.6,0.1,21.9,0c1.9,0,2.2,0.7,2.1,2.3c0,39.9,0,79.7,0,119.6c0,1.2-0.1,2.4,0.2,3.6c0.4,1.2,1.2,2,2.4,1.9
		c1.2,0,2.1-0.6,2.5-1.9c0.3-0.9,0.2-1.8,0.2-2.7c0-39.8,0-79.6,0-119.4c0-3.5,0-3.5,3.4-3.5c6.8,0,13.6,0,20.3,0
		c1.7,0,2.3,0.4,2.3,2.2C143.1,116.9,143.1,138.1,143.1,159.2z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M414.9,168.5c0-16.6-0.1-33.2,0.1-49.9c0.1-7,1.9-13.7,6.8-19.1c3.2-3.6,7.3-5.7,11.9-6.5c8.3-1.4,16.6-1.8,24.7,1.3
		c7.5,2.8,11.6,8.7,13.6,16.2c1.1,4.2,1.4,8.5,1.4,12.8c0,30.7,0,61.4,0,92.1c0,4.8-0.6,9.5-2.3,14c-3.6,9.1-10.6,14.1-20.1,15
		c-6.2,0.6-12.5,0.9-18.6-1c-9.2-2.8-14.3-9.2-16.4-18.2c-0.8-3.3-1-6.8-1-10.2C414.9,199.5,414.9,184,414.9,168.5
		C414.9,168.5,414.9,168.5,414.9,168.5z M447,168.1c0-16.2,0-32.3,0-48.5c0-0.9,0.1-1.8-0.1-2.7c-0.4-1.4-1.3-2.3-2.9-2.3
		c-1.5,0-2.2,1-2.4,2.4c-0.1,0.8-0.2,1.6-0.2,2.4c0,32.5,0,65,0,97.5c0,3.3,0.9,4.8,2.7,4.9c2,0.1,3-1.5,3-4.8
		C447,200.7,447,184.4,447,168.1z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M196.3,168.5c0-16.3-0.1-32.6,0-49c0-5.2,1-10.2,3.4-14.9c3.4-6.6,8.9-10.5,16.1-11.7c6.5-1.1,13.2-1.3,19.7,0.2
		c10.5,2.5,16.8,9.6,18.3,20.2c0.5,3.3,0.7,6.7,0.7,10c0,30.9,0,61.8,0,92.8c0,5.9-1,11.6-4,16.7c-3.5,6-8.7,9.6-15.5,11
		c-6.1,1.3-12.3,1.3-18.4,0.2c-12-2.1-18.3-10.3-20-21.8c-0.5-3-0.4-6.1-0.4-9.1C196.3,198.3,196.3,183.4,196.3,168.5
		C196.3,168.5,196.3,168.5,196.3,168.5z M222.7,168.2c0,15.9,0,31.9,0,47.8c0,1.1,0,2.2,0.1,3.3c0.2,1.5,1.2,2.4,2.7,2.5
		c1.4,0,2.4-0.8,2.7-2.2c0.2-0.7,0.2-1.5,0.2-2.2c0-32.9,0-65.7,0-98.6c0-0.8,0-1.6-0.3-2.4c-0.5-1.3-1.4-1.9-2.7-1.9
		c-1.4,0-2.2,0.7-2.5,2c-0.3,1-0.2,2.1-0.2,3.1C222.7,135.9,222.7,152.1,222.7,168.2z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M374.1,159.6c0,5.1,0,10.1,0,15.1c0,17,0.1,34,0.1,51c0,5.2,0.1,10.4,0.2,15.6c0,1.7-0.6,2.2-2.2,2.2
		c-5.9-0.1-11.8-0.1-17.6,0c-1.8,0-1.8-0.9-1.8-2.2c0-16.2,0-32.4,0-48.5c0-32.3,0-64.6,0-96.8c0-1.9,0.5-2.4,2.4-2.4
		c7.1,0.1,14.3,0.1,21.4,0c1.8,0,2.3,0.7,2.5,2.3c2.1,17.7,4.2,35.5,6.4,53.2c0.8,6.7,1.3,13.5,2.7,20.1c0.1,0.4,0.1,0.9,0.2,1.3
		c0-2.8,0-5.6,0-8.4c-0.1-22-0.1-44-0.3-66c0-2,0.5-2.6,2.4-2.5c5.5,0.1,11,0.1,16.5,0c2-0.1,2.5,0.6,2.5,2.5
		c-0.1,37.9,0,75.9,0,113.8c0,10.4-0.1,20.9,0,31.3c0,1.9-0.5,2.3-2.4,2.3c-6.4-0.1-12.8-0.1-19.2,0c-1.7,0-2.3-0.5-2.5-2.2
		c-2.3-17.9-4.7-35.9-7.1-53.8c-1.2-8.9-2.4-17.8-3.6-26.8C374.7,160.3,374.7,159.9,374.1,159.6z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M260.3,232.1c0-0.9-0.1-1.8-0.1-2.7c0-44.4,0-88.8,0-133.3c0-2.1,0.5-2.8,2.7-2.7c6.5,0.2,13.1,0.1,19.7,0.1
		c3.2,0,3.2,0,3.2,3.3c-0.1,15.3-0.1,30.7-0.2,46c0,0.4,0,0.9,0,1c0.1,0.3,0.1,0.3,0.1,0.3c0-0.1-0.1-0.1-0.1-0.2
		c1.7-7.3,2.5-14.8,3.7-22.2c1.4-8.8,2.8-17.6,4.1-26.5c0.2-1.4,0.8-1.8,2.2-1.8c7.1,0.1,14.3,0.1,21.4,0c1.9,0,2.2,0.6,1.9,2.4
		c-3.5,19.2-6.7,38.4-10.6,57.5c-1.4,7.1-0.5,13.7,0.6,20.5c2.6,16.4,5.2,32.8,7.7,49.2c0.9,6,1.8,11.9,2.8,17.9
		c0.3,1.9-0.2,2.5-2.1,2.5c-7.2-0.1-14.4-0.1-21.7,0c-1.8,0-2.6-0.4-2.9-2.4c-1.5-12.4-3.1-24.8-4.9-37.2
		c-0.8-5.4-1.2-10.9-2.2-15.2c0,16.6,0,34.2,0,51.8c0,2.9,0,2.9-2.9,2.9c-2.5,0-4.9,0-7.4,0c1.5-3.8,3-7.7,4.6-11.7
		c-6.4,0-12.5,0-18.7,0C260.9,231.7,260.6,232,260.3,232.1z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M261.8,254.1c4.8,0,9.7,0,14.5,0c1.4,0,1.9,0.4,2,1.9c0.9,10.5,1.9,21.1,2.9,31.6c1.6,17.6,3.2,35.1,4.8,52.7
		c1.4,15.9,2.8,31.8,4.2,47.6c0.5,6,1.1,12,1.7,18c0.2,1.6-0.2,2.1-1.9,2.1c-7.1-0.1-14.2-0.1-21.2,0c-1.7,0-2.3-0.5-2.4-2.3
		c-0.3-7.7-0.8-15.5-1.1-23.2c-0.1-1.8-0.7-2.6-2.6-2.3c-2.1,0.3-4.6-0.9-6.1,0.4c-1.4,1.3-0.6,4-0.7,6c-0.4,6.2-0.8,12.3-1,18.5
		c-0.1,2.2-0.8,2.9-3,2.8c-5.8-0.2-11.6-0.1-17.4,0c-1.9,0-2.3-0.6-2.1-2.3c0.8-9.4,1.6-18.8,2.4-28.3c1-12.1,2-24.2,3-36.3
		c1.1-13,2.2-26,3.3-39c1-12,2-24.1,2.9-36.1c0.3-3.5,0.6-7,0.9-10.5c0.1-1.7,1.3-1.4,2.2-1.4C252.1,254.1,256.9,254.1,261.8,254.1z
		 M261.1,301.6c-0.1,1.1-0.3,2.1-0.4,3.2c-0.9,17-1.9,34-2.8,51c-0.1,1.5,0.3,2.1,1.8,2c0.6-0.1,1.2-0.1,1.8,0
		c1.9,0.3,2.5-0.4,2.4-2.4c-0.8-12.8-1.4-25.7-2-38.5C261.6,311.7,261.4,306.7,261.1,301.6z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M72.2,243.4c-3.4,0-6.9-0.1-10.3,0c-1.4,0-1.9-0.3-2-1.8c-0.3-7.9-0.9-15.8-1.2-23.6c-0.1-1.4-0.6-1.8-1.9-1.7
		c-1.1,0.1-2.2,0-3.3,0c-3.6,0-3.5,0-3.7,3.7c-0.3,7.1-0.7,14.1-1,21.2c-0.1,1.5-0.6,2.3-2.2,2.3c-6-0.1-11.9,0-17.9,0
		c-1.7,0-1.9-0.8-1.8-2.2c0.8-9.9,1.6-19.9,2.4-29.8c1-11.8,2-23.6,3-35.4c1.1-13.2,2.2-26.4,3.3-39.6c1-12.1,2-24.2,3-36.3
		c0.1-1.3,0.4-2.7,0.3-4c-0.2-2.3,0.9-2.6,2.9-2.6c9.1,0.1,18.2,0.1,27.2,0c1.8,0,2.4,0.5,2.6,2.3c1.6,18.8,3.2,37.7,4.9,56.5
		c1.6,17.5,3.2,35,4.8,52.5c1.1,11.9,2.1,23.7,3.2,35.6c0.3,3,0.2,3-2.7,3C78.6,243.4,75.4,243.4,72.2,243.4z M54.6,140.4
		c-0.7,4.4-0.7,9-1,13.6c-0.7,12.7-1.3,25.4-2.1,38.1c-0.1,1.8,0.4,2.5,2.3,2.4c3.6-0.2,3.6-0.1,3.5-3.8c0-0.9-0.1-1.8-0.2-2.7
		C56.3,172.1,55.4,156.1,54.6,140.4z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M58.4,408c-1.9,0-3.7-0.1-5.6,0c-1.6,0.1-2-0.6-1.9-2.1c0.8-16.1,1.5-32.1,2.3-48.2c0.1-1.2,0.5-2.6-1.6-2.6
		c-1.8,0-2.4,0.4-2.5,2.3c-0.7,15.9-1.5,31.8-2.2,47.8c-0.1,2.2-0.8,2.9-3,2.8c-3.4-0.2-6.8-0.1-10.3,0c-1.6,0-2.2-0.3-2.2-2.1
		c0.6-11.8,1.1-23.5,1.6-35.3c0.2-4.5,0.3-8.9,0.6-13.4c0.1-1.5-0.4-2.1-2-2.1c-1.6,0-3.8,0.8-4.7-0.4c-0.7-1-0.1-3,0-4.5
		c0.1-3,0.3-5.9,0.4-8.9c0-1.3,0.5-1.8,1.8-1.6c0.1,0,0.1,0,0.2,0c5.1-0.2,5.2-0.2,5.4-5.3c0.2-5.1,0.4-10.3,0.7-15.4
		c0.1-1.8-0.4-2.5-2.3-2.5c-4.5,0-4.5,0-4.4-4.6c0.1-3.1,0.3-6.2,0.4-9.4c0-1.1,0.5-1.6,1.6-1.6c0.1,0,0.1,0,0.2,0
		c5.1-0.3,5.1-0.3,5.3-5.4c0.6-13.3,1.2-26.6,1.7-40c0.1-1.2,0.5-1.5,1.6-1.5c4.1,0,8.2,0.1,12.3,0c1.5,0,1.9,0.6,1.8,2
		c-0.6,11.3-1.1,22.6-1.6,33.9c-0.1,3.1-0.3,6.3-0.4,9.4c0,1,0.2,1.7,1.3,1.5c1-0.2,2.6,0.8,2.7-1.3c0.2-4.3,0.5-8.6,0.6-12.9
		c0.5-10.3,0.9-20.5,1.3-30.8c0.1-1.3,0.4-1.9,1.8-1.8c4,0.1,8,0.1,12.1,0c1.5,0,1.9,0.6,1.8,2c-0.4,8.4-0.8,16.8-1.2,25.2
		c-0.3,6.1-0.6,12.2-0.9,18.3c-0.1,0.9,0.3,1.4,1.3,1.4c0.1,0,0.3,0,0.4,0c5,0.3,5,0.3,4.8,5.3c-0.1,2.8-0.3,5.6-0.4,8.5
		c0,1.3-0.5,1.8-1.9,1.9c-4.9,0.2-4.9,0.2-5.1,5.2c-0.3,5.3-0.4,10.6-0.7,15.9c-0.1,1.5,0.3,2.1,1.9,2.1c4.5,0,4.6,0,4.4,4.6
		c-0.1,3-0.4,5.9-0.5,8.9c0,1.4-0.6,1.9-2,2c-4.9,0.2-4.8,0.2-5.1,5.3c-0.7,15.1-1.4,30.2-2.1,45.3c-0.1,1.8-0.7,2.3-2.4,2.2
		C62.2,407.9,60.3,408,58.4,408z M50.5,328.1c-0.1,0-0.2,0-0.3,0c0,3.3,0.1,6.5,0,9.8c0,1.3,0.3,1.8,1.7,1.8
		c1.9,0.1,2.2-0.9,2.2-2.4c0.2-6.4,0.5-12.8,0.8-19.1c0.1-1.5-0.6-1.6-1.7-1.6c-1,0-2.1-0.4-2.2,1.3
		C50.9,321.3,50.7,324.7,50.5,328.1z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M444.3,305c0.9-10.8,1.8-21.6,2.7-32.5c0.5-5.5,0.9-11,1.4-16.5c0.1-1.3,0.6-1.9,2.1-1.9c6.6,0.1,13.1,0,19.7,0
		c1.3,0,1.8,0.2,1.6,1.8c-2.3,13.2-4.4,26.4-6.6,39.6c-2.8,17.2-5.7,34.3-8.5,51.5c-0.1,0.9-0.2,1.8-0.2,2.7c0,18.6,0,37.3,0,55.9
		c0,1.8-0.4,2.5-2.3,2.4c-7.1-0.1-14.3-0.1-21.4,0c-1.8,0-2.3-0.5-2.2-2.3c0.1-18.5,0.1-37,0-55.4c0-6.2-1.8-12.2-2.8-18.3
		c-1.8-10.6-3.8-21.1-5.7-31.6c-2.3-12.6-4.6-25.2-6.9-37.7c-0.4-2.3-0.8-4.5-1.2-6.8c-0.3-1.2,0-1.8,1.4-1.8c7.6,0,15.2,0,22.8,0
		c1.4,0,1.7,0.7,1.8,1.9c1.2,15.1,2.5,30.1,3.7,45.2c0.1,1.2,0.3,2.5,0.5,3.7C444.2,305,444.2,305,444.3,305z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M126.1,331.1c0-24.7,0-49.3,0-74c0-2.9,0-2.9,2.9-2.9c12.9,0,25.8,0,38.6,0c2,0,2.8,0.5,2.7,2.6c-0.1,6.3-0.1,12.7,0,19
		c0,1.9-0.7,2.2-2.3,2.2c-4.8-0.1-9.7,0-14.5-0.1c-1.4,0-1.8,0.4-1.8,1.8c0,11.3,0,22.7,0,34c0,1.3,0.4,1.7,1.7,1.7
		c3.9-0.1,7.9,0.1,11.8,0c1.6,0,2.3,0.4,2.3,2.1c-0.1,6.8-0.1,13.6,0,20.3c0,1.6-0.6,2-2.1,2c-3.9-0.1-7.9,0-11.8-0.1
		c-1.5,0-1.9,0.5-1.9,1.9c0,13.5,0.1,27,0,40.5c0,1.6,0.6,2,2.1,2c5.1-0.1,10.1,0,15.2,0c1.6,0,2,0.5,2,2c-0.1,6.6-0.1,13.3,0,19.9
		c0,1.6-0.4,2.2-2.1,2.2c-13.5-0.1-27-0.1-40.4,0c-2.3,0-2.3-1.1-2.3-2.7C126.2,380.6,126.2,355.8,126.1,331.1
		C126.2,331.1,126.2,331.1,126.1,331.1z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M80.6,330.8c0-24.7,0-49.5,0-74.2c0-2,0.5-2.6,2.5-2.5c7,0.1,14,0.1,21,0c1.8,0,2.5,0.3,2.5,2.4c0,41.3,0,82.6,0.1,123.9
		c0,3.1,0,3.1,3.2,3.1c3.8,0,7.6,0,11.4,0c1.4,0,2.1,0.3,2.1,1.8c-0.1,7.1-0.1,14.2-0.2,21.2c0,1.2-0.6,1.5-1.7,1.5
		c-13.1,0-26.2,0-39.3,0c-1.6,0-1.5-0.9-1.5-2c0-8.6,0-17.3,0-25.9C80.6,363.6,80.6,347.2,80.6,330.8z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M157.8,179.6c0-20.1,0-40.2,0-60.4c0-2.1-0.5-2.9-2.7-2.8c-2.3,0.2-4.6,0-6.9,0.1c-1.4,0.1-2-0.4-2-1.9
		c0.1-6.6,0.1-13.1,0-19.7c0-1.2,0.4-1.5,1.6-1.5c15,0,30.1,0,45.1,0c1.2,0,1.5,0.5,1.5,1.6c0,6.4-0.1,12.8,0,19.2
		c0,1.8-0.7,2.3-2.4,2.2c-2.4-0.1-4.8,0-7.1,0c-1.4,0-2,0.4-2,1.9c0.1,2.2,0,4.5,0,6.7c0,38.3,0,76.6,0,114.9c0,3.9,0.2,3.3-3.4,3.3
		c-6.3,0-12.7-0.1-19,0.1c-2.2,0.1-2.7-0.6-2.7-2.8C157.9,220.3,157.8,200,157.8,179.6z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M348.4,168.3c0,24.1,0,48.2,0,72.2c0,2.9,0,2.9-2.9,2.9c-6.7,0-13.4-0.1-20.1,0c-2.1,0-2.6-0.7-2.6-2.7
		c0.1-47.6,0-95.3,0-142.9c0-4.3,0-4.3,4.4-4.3c6.2,0,12.4,0.1,18.5-0.1c2.2-0.1,2.7,0.6,2.7,2.7
		C348.3,120.3,348.4,144.3,348.4,168.3C348.4,168.3,348.4,168.3,348.4,168.3z"
    />
    <path
      fill={SECONDARY_COLOR}
      className="SECONDARY_COLOR"
      d="M260.3,232.1c0.3-0.1,0.7-0.4,1-0.4c6.2,0,12.3,0,18.7,0c-1.6,4-3.1,7.9-4.6,11.7c-1.3,1.8-1.8,4-2.7,6.1
		c-0.5,1.2-1.2,1.8-2.7,1.8c-5.4-0.1-10.7,0-16.1,0c-2.4,0-2.6-0.3-1.5-2.6C255.2,243.3,257.1,237.4,260.3,232.1z"
    />
  </svg>
)

const cinemaLogos = {
  [AERO]: AeroLogo,
  [BIOOKO]: BioOkoLogo,
  [SVETOZOR]: SvetozorLogo,
  [LUCERNA]: LucernaLogo,
  [DLABACOV]: DlabacovLogo,
  [EVALD]: EvaldLogo,
  [ATLAS]: AtlasLogo,
  [MAT]: MatLogo,
  [PILOTU]: PilotuLogo,
  [KINO35]: Kino35Logo,
  [RADOTIN]: RadotinLogo,
  [MODRANSKY_BIOGRAF]: ModranskyBiografLogo,
  [EDISON]: EdisonLogo,
  [KINOLOD]: KinolodLogo,
  [KARLIN]: KasarnaKarlinLogo,
  [PONREPO]: PonrepoLogo,
  [PRITOMNOST]: PritomnostLogo,
  [MOJEKINOLIVE]: MojeKinoLiveLogo,
  [STRAHOV]: StrahovLogo,
  [LEDARNY]: LedarnyLogo,
}

const DefaultLogo = () => null

const CinemaLogo = ({
  cinema,
  width,
  height,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
}) => {
  const Logo = cinemaLogos[cinema] || DefaultLogo
  return <Logo {...{ width, height, PRIMARY_COLOR, SECONDARY_COLOR }} />
}

export default pure(CinemaLogo)
