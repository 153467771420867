import React, { memo } from 'react'

export const MuteIcon = memo(() => (
  <svg width={25.519} height={22.223} viewBox="0 0 25.519 22.223">
    <path
      fill="rgba(255,255,255,.85)"
      d="M.748 5.78A.736.736 0 000 6.523v8.971a.74.74 0 00.748.748h5.333V5.78H.748zM16.159.093a.77.77 0 00-.763.015l-7.82 5.039v11.738l7.805 5.218a.781.781 0 00.778.03.761.761 0 00.389-.658V.741a.72.72 0 00-.39-.647zM22.842 11.009l2.462-2.462a.748.748 0 00-1.057-1.057l-2.462 2.462-2.462-2.462a.748.748 0 00-1.057 1.057l2.462 2.462-2.462 2.462a.748.748 0 101.057 1.057l2.462-2.462 2.457 2.464a.748.748 0 101.057-1.057z"
    />
  </svg>
))
