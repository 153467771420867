const getDayDifferenceText = n => {
  switch (n) {
    case 0:
      return 'dnes'
    case 1:
      return 'zítra'
    case 2:
      return 'pozítří'
    case 3:
      return `za ${n} dny`
    case 4:
      return `za ${n} dny`
    case 7:
      return `za týden`
    case 14:
      return `za 2 týdny`
    case 21:
      return `za 3 týdny`
    case 28:
      return `za 4 týdny`
    default: {
      if (n < 29) {
        return `za ${n} dní`
      } else if (n < 40) {
        return `za měsíc`
      } else if (n < 50) {
        return `za měsíc a půl`
      } else if (n < 71) {
        return `za 2 měsíce`
      } else if (n < 102) {
        return `za 3 měsíce`
      } else if (n < 132) {
        return `za 4 měsíce`
      } else if (n < 163) {
        return `za 5 měsíců`
      } else if (n < 194) {
        return `za půl roku`
      } else if (n < 230) {
        return `za 7 měsíců`
      } else if (n < 261) {
        return `za 8 měsíců`
      } else if (n < 291) {
        return `za tři čtvrtě roku`
      } else if (n < 322) {
        return `za 10 měsíců`
      } else if (n < 352) {
        return `za 11 měsíců`
      } else {
        const nYears = Math.round(n / 365)

        switch (nYears) {
          case 1:
            return `za rok`
          case 2:
            return `za 2 roky`
          case 3:
            return `za 3 roky`
          case 4:
            return `za 4 roky`
          default:
            return `za ${nYears} let`
        }
      }
    }
  }
}

export default getDayDifferenceText
