import typeToReducer from 'type-to-reducer'
import * as ActionTypes from '../constants/ActionTypes'

const initialState =
  (window.localStorage &&
    JSON.parse(window.localStorage.getItem('favouriteScreenings') || '{}')) ||
  {}

export default typeToReducer(
  {
    [ActionTypes.SET_FAVOURITE_SCREENINGS]: (_, { payload }) => payload,
  },
  initialState,
)
