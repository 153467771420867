import React from 'react'
import { pure } from 'recompose'

const Magnifier = pure(() => (
  <svg width="28.969" height="20.59" viewBox="0 0 28.969 20.59">
    <path
      fill="#484848"
      d="M18.088,16.277h-.954l-.338-.326a7.868,7.868,0,1,0-.845.845l.326.338v.954l5.13,5.13a1.272,1.272,0,1,0,1.8-1.8Zm-7.242,0a5.432,5.432,0,1,1,5.432-5.432A5.424,5.424,0,0,1,10.846,16.277Z"
      transform="translate(0.621 -2.999)"
    />
  </svg>
))

export default Magnifier
