import React from 'react'
import styled from '@emotion/styled/macro'
import { connect } from 'react-redux-hoc'
import { map } from 'ramda'
import {
  withState,
  withHandlers,
  withPropsOnChange,
  lifecycle,
  compose,
} from 'recompose'
import withDerivedProps from '@surglogs/with-derived-props'

import decodeId from '../../utils/decodeId'
import withRenderOnProp from '../../hoc/withRenderOnProp'
import {
  isDataLoaded,
  getScreeningsObj,
  deriveAllScreenings,
  getScreeningMovieById,
} from '../../selectors/data'
import MoreScreeningsHeader from './MoreScreeningsHeader'
import VerticalDivider from '../../components/VerticalDivider'
import LoadingScreeningsIcon from '../../components/LoadingScreeningsIcon'
import triggerOnScroll from '../../utils/triggerOnScroll'
import MoreScreeningsItem from './MoreScreeningsItem'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #e6e6e6;
  min-height: 100vh;
`

const StyledLoader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: #e6e6e6;
  & svg path {
    fill: #292522;
  }
`

const StyledMoreScreenings = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 11px 15px;
  width: 100%;
  max-width: 800px;
`

const MoreScreeningsContent = ({
  movie,
  isLoaded,
  otherScreenings,
  showLoader,
}) => {
  if (!isLoaded || showLoader) {
    return (
      <StyledContainer>
        <MoreScreeningsHeader {...{ title: 'Už se to točí...' }} />
        <StyledLoader>
          <VerticalDivider {...{ space: 200 }} />
          <LoadingScreeningsIcon />
        </StyledLoader>
      </StyledContainer>
    )
  }

  if (!movie) {
    return (
      <StyledContainer>
        <MoreScreeningsHeader {...{ title: 'Film nikde nedávají :(' }} />
      </StyledContainer>
    )
  }

  const { title } = movie

  // console.log({ youtube })

  // youtube = withFallBack(youtube, 'youtube')
  // director = withFallBack(director, 'director')
  // origin = withFallBack(origin, 'origin')
  // originalTitle = withFallBack(originalTitle, 'originalTitle') || ''
  // duration =
  //   duration && duration > 600
  //     ? withFallBackReversed(duration, 'duration')
  //     : withFallBack(duration, 'duration')
  // year =
  //   year && year < 1800
  //     ? withFallBackReversed(year, 'year')
  //     : withFallBack(year, 'year')
  // csfd = withFallBack(csfd, 'csfd')
  // imdb = withFallBack(imdb, 'imdb')
  // description = withFallBack(description || altDescription, 'description')
  // genre = withFallBack(genre, 'genre')
  // score = withFallBack(score, 'score')

  // const numOfOtherScreenings = (otherScreenings || []).length

  return (
    <StyledContainer>
      <MoreScreeningsHeader {...{ title }} />
      <StyledMoreScreenings>
        {map(
          screening => (
            <MoreScreeningsItem key={screening.id} screening={screening} />
          ),
          otherScreenings,
        )}
      </StyledMoreScreenings>
      {/* <StyledWrapper>
        <StyledInfoGroup>
          <StyledFirstGroup>
            {image || youtube ? (
              <ScreeningDetailTrailer {...{ image, youtube }} />
            ) : (
              <VerticalDivider {...{ space: 40 }} />
            )}
            <ScreeningDetailInfo
              {...{
                cinema,
                time,
                date,
                buyLink,
                screeningId: id,
              }}
            />
          </StyledFirstGroup>
          <ScreeningDetailMetadata
            {...{
              title,
              director,
              language,
              origin,
              originalTitle,
              duration,
              year,
              csfd,
              imdb,
              website,
              description,
              genre,
              score,
              csfdId: csfdLink,
              csfdData: csfdMovie,
              tags,
              hall,
            }}
          />
        </StyledInfoGroup>
        {description && (
          <StyledDescriptionContainer>
            <StyledDescription>
              {getFormattedDescription(description)}
            </StyledDescription>
          </StyledDescriptionContainer>
        )}
      </StyledWrapper> */}
    </StyledContainer>
  )
}

export default compose(
  withState('id', 'setId', ({ movieId }) => movieId),
  withHandlers({
    resetId: ({ movieId, setId }) => () => {
      if (!movieId) {
        setId(undefined)
      }
    },
  }),
  withPropsOnChange(['movieId'], ({ setId, movieId, resetId }) => {
    if (movieId) {
      setId(decodeId(movieId))
    } else {
      setTimeout(() => {
        resetId()
      }, 400)
    }
  }),
  withRenderOnProp('id'),
  connect({
    isLoaded: isDataLoaded,
    movie: getScreeningMovieById,
    screeningsObj: getScreeningsObj,
  }),
  withDerivedProps(['movie', 'screeningsObj'], {
    otherScreenings: deriveAllScreenings,
  }),
  withState('showLoader', 'setShowLoader', true),
  lifecycle({
    componentDidMount() {
      const { setShowLoader } = this.props
      triggerOnScroll()
      setTimeout(() => {
        triggerOnScroll()
        setShowLoader(false)
      }, 0)
    },
  }),
)(MoreScreeningsContent)
