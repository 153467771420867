import React, { memo } from 'react'
import styled from '@emotion/styled/macro'
import { CloseCross } from './CloseCross'
import { THEME_COLOR } from '../constants/colors'

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px 10px;
`

const StyledCloseButton = styled.div`
  display: flex;
  padding: 5px;
  cursor: pointer;

  svg path {
    fill: #484848;
  }
`

const StyledTitle = styled.div`
  font-size: 18px;
  font-weight: 900;
  color: #484848;
  user-select: none;
`

const StyledFiltersCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  position: relative;
  width: 30px;
  height: 30px;

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const StyledFiltersCountText = styled.div`
  font-size: 16px;
  font-weight: 900;
  color: #ffffff;
  user-select: none;
  position: relative;
`

const CloseButton = memo(({ onClick }) => {
  return (
    <StyledCloseButton onClick={onClick}>
      <CloseCross />
    </StyledCloseButton>
  )
})

const FiltersCount = memo(({ count }) => {
  return (
    <StyledFiltersCount>
      {!!count && (
        <>
          <svg width="30" height="30" viewBox="0 0 30 30">
            <circle cx={15} cy={15} r={15} fill={THEME_COLOR} />
          </svg>
          <StyledFiltersCountText>{count}</StyledFiltersCountText>
        </>
      )}
    </StyledFiltersCount>
  )
})

export const DesktopFiltersHeader = memo(
  ({ closeFilter, title, filtersCount }) => {
    return (
      <StyledHeader>
        <CloseButton onClick={closeFilter} />
        <StyledTitle>{title}</StyledTitle>
        <FiltersCount count={filtersCount} />
      </StyledHeader>
    )
  },
)
