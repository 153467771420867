export const zindex = {
  // NOT MORE THAN 1000
  returnToTopButton: 80,
  resetButton: 70,
  okButton: 70,
  filterButton: 70,
  desktopFilters: 65,
  filterMenu: 60,
  filterMenuBackground: 50,
  logo: 40,
  header: 40,
  searchBar: 35,
  storiesBar: 33,
  headerShadow: 32,
  screeningHall: 30,
  tag: 30,
  screeningDay: 20,
  playButton: 10,
  price: 10,
  tooltip: 2,
  cinemaLogo: 1,
}
