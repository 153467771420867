import React from 'react'
import styled from '@emotion/styled/macro'
import Link from '../../components/Link'
import { MOUSE_DEVICE, SECONDARY_FONT } from '../../constants/CSS'
import { THEME_COLOR } from '../../constants/colors'
import {
  MEDIA_FULL_DESKTOP,
  MEDIA_EXTENDED_DESKTOP,
} from '../../constants/media'
import { pure } from 'recompose'
import Award from '../../components/Award'
import { map, isEmpty } from 'ramda'

const StyledActorsContainer = styled.div`
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding: 30px 35px;
  background: #f7f7f7;

  ${MEDIA_FULL_DESKTOP} {
    flex-direction: row;
    align-items: flex-start;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  ${MEDIA_EXTENDED_DESKTOP} {
    flex-direction: column;
    border-radius: 3px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  }
`

const StyledActor = styled(Link)`
  text-decoration: none;
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  margin-right: 10px;
  font-weight: 600;
  color: #484848;
  text-decoration: underline;
  display: inline-block;
  margin-bottom: 13px;

  ${MOUSE_DEVICE} {
    &:hover {
      color: ${THEME_COLOR};
      text-decoration: none;
    }
  }
`

const StyledLabelText = styled.span`
  font-family: ${SECONDARY_FONT};
  font-size: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #484848;
  font-weight: 800;
`

const StyledLabel = styled.div`
  display: flex;
  align-items: center;

  & svg {
    margin-right: 15px;
    position: relative;
    bottom: 3px;
  }
`

const StyledActors = styled.span`
  display: flex;
  flex-direction: column;
  margin: 25px 0px 0px 35px;

  ${MEDIA_FULL_DESKTOP} {
    display: block;
    margin: 8px 0px 0px 35px;
  }

  ${MEDIA_EXTENDED_DESKTOP} {
    display: flex;
    margin: 20px 0px 0px 0px;
  }
`

const ScreeningDetailActors = ({ actors }) => {
  return (
    !isEmpty(actors || []) && (
      <StyledActorsContainer>
        <StyledLabel>
          <Award />
          <StyledLabelText>Hrají:</StyledLabelText>
        </StyledLabel>
        <StyledActors>
          {map(({ id, name }) => (
            <StyledActor
              {...{
                key: id,
                target: '_blank',
                href: `https://www.csfd.cz/tvurce/${id}`,
              }}
            >
              {name}
            </StyledActor>
          ))(actors)}
        </StyledActors>
      </StyledActorsContainer>
    )
  )
}

export default pure(ScreeningDetailActors)
