import { F } from 'ramda'
import { T } from 'ramda'
import { __ } from 'ramda'
import { add } from 'ramda'
import { addIndex } from 'ramda'
import { adjust } from 'ramda'
import { all } from 'ramda'
import { allPass } from 'ramda'
import { always } from 'ramda'
import { and } from 'ramda'
import { any } from 'ramda'
import { anyPass } from 'ramda'
import { ap } from 'ramda'
import { aperture } from 'ramda'
import { append } from 'ramda'
import { apply } from 'ramda'
import { applySpec } from 'ramda'
import { applyTo } from 'ramda'
import { ascend } from 'ramda'
import { assoc } from 'ramda'
import { assocPath } from 'ramda'
import { binary } from 'ramda'
import { bind } from 'ramda'
import { both } from 'ramda'
import { call } from 'ramda'
import { chain } from 'ramda'
import { clamp } from 'ramda'
import { clone } from 'ramda'
import { comparator } from 'ramda'
import { complement } from 'ramda'
import { compose } from 'ramda'
import { composeK } from 'ramda'
import { composeP } from 'ramda'
import { composeWith } from 'ramda'
import { concat } from 'ramda'
import { cond } from 'ramda'
import { construct } from 'ramda'
import { constructN } from 'ramda'
import { contains } from 'ramda'
import { converge } from 'ramda'
import { countBy } from 'ramda'
import { curry } from 'ramda'
import { curryN } from 'ramda'
import { dec } from 'ramda'
import { defaultTo } from 'ramda'
import { descend } from 'ramda'
import { difference } from 'ramda'
import { differenceWith } from 'ramda'
import { dissoc } from 'ramda'
import { dissocPath } from 'ramda'
import { divide } from 'ramda'
import { drop } from 'ramda'
import { dropLast } from 'ramda'
import { dropLastWhile } from 'ramda'
import { dropRepeats } from 'ramda'
import { dropRepeatsWith } from 'ramda'
import { dropWhile } from 'ramda'
import { either } from 'ramda'
import { empty } from 'ramda'
import { endsWith } from 'ramda'
import { eqBy } from 'ramda'
import { eqProps } from 'ramda'
import { equals } from 'ramda'
import { evolve } from 'ramda'
import { filter } from 'ramda'
import { find } from 'ramda'
import { findIndex } from 'ramda'
import { findLast } from 'ramda'
import { findLastIndex } from 'ramda'
import { flatten } from 'ramda'
import { flip } from 'ramda'
import { forEach } from 'ramda'
import { forEachObjIndexed } from 'ramda'
import { fromPairs } from 'ramda'
import { groupBy } from 'ramda'
import { groupWith } from 'ramda'
import { gt } from 'ramda'
import { gte } from 'ramda'
import { has } from 'ramda'
import { hasIn } from 'ramda'
import { hasPath } from 'ramda'
import { head } from 'ramda'
import { identical } from 'ramda'
import { identity } from 'ramda'
import { ifElse } from 'ramda'
import { inc } from 'ramda'
import { includes } from 'ramda'
import { indexBy } from 'ramda'
import { indexOf } from 'ramda'
import { init } from 'ramda'
import { innerJoin } from 'ramda'
import { insert } from 'ramda'
import { insertAll } from 'ramda'
import { intersection } from 'ramda'
import { intersperse } from 'ramda'
import { into } from 'ramda'
import { invert } from 'ramda'
import { invertObj } from 'ramda'
import { invoker } from 'ramda'
import { is } from 'ramda'
import { isEmpty } from 'ramda'
import { isNil } from 'ramda'
import { join } from 'ramda'
import { juxt } from 'ramda'
import { keys } from 'ramda'
import { keysIn } from 'ramda'
import { last } from 'ramda'
import { lastIndexOf } from 'ramda'
import { length } from 'ramda'
import { lens } from 'ramda'
import { lensIndex } from 'ramda'
import { lensPath } from 'ramda'
import { lensProp } from 'ramda'
import { lift } from 'ramda'
import { liftN } from 'ramda'
import { lt } from 'ramda'
import { lte } from 'ramda'
import { map } from 'ramda'
import { mapAccum } from 'ramda'
import { mapAccumRight } from 'ramda'
import { mapObjIndexed } from 'ramda'
import { match } from 'ramda'
import { mathMod } from 'ramda'
import { max } from 'ramda'
import { maxBy } from 'ramda'
import { mean } from 'ramda'
import { median } from 'ramda'
import { memoizeWith } from 'ramda'
import { merge } from 'ramda'
import { mergeAll } from 'ramda'
import { mergeDeepLeft } from 'ramda'
import { mergeDeepRight } from 'ramda'
import { mergeDeepWith } from 'ramda'
import { mergeDeepWithKey } from 'ramda'
import { mergeLeft } from 'ramda'
import { mergeRight } from 'ramda'
import { mergeWith } from 'ramda'
import { mergeWithKey } from 'ramda'
import { min } from 'ramda'
import { minBy } from 'ramda'
import { modulo } from 'ramda'
import { multiply } from 'ramda'
import { nAry } from 'ramda'
import { negate } from 'ramda'
import { none } from 'ramda'
import { not } from 'ramda'
import { nth } from 'ramda'
import { nthArg } from 'ramda'
import { o } from 'ramda'
import { objOf } from 'ramda'
import { of } from 'ramda'
import { omit } from 'ramda'
import { once } from 'ramda'
import { or } from 'ramda'
import { otherwise } from 'ramda'
import { over } from 'ramda'
import { pair } from 'ramda'
import { partial } from 'ramda'
import { partialRight } from 'ramda'
import { partition } from 'ramda'
import { path } from 'ramda'
import { pathEq } from 'ramda'
import { pathOr } from 'ramda'
import { pathSatisfies } from 'ramda'
import { pick } from 'ramda'
import { pickAll } from 'ramda'
import { pickBy } from 'ramda'
import { pipe } from 'ramda'
import { pipeK } from 'ramda'
import { pipeP } from 'ramda'
import { pipeWith } from 'ramda'
import { pluck } from 'ramda'
import { prepend } from 'ramda'
import { product } from 'ramda'
import { project } from 'ramda'
import { prop } from 'ramda'
import { propEq } from 'ramda'
import { propIs } from 'ramda'
import { propOr } from 'ramda'
import { propSatisfies } from 'ramda'
import { props } from 'ramda'
import { range } from 'ramda'
import { reduce } from 'ramda'
import { reduceBy } from 'ramda'
import { reduceRight } from 'ramda'
import { reduceWhile } from 'ramda'
import { reduced } from 'ramda'
import { reject } from 'ramda'
import { remove } from 'ramda'
import { repeat } from 'ramda'
import { replace } from 'ramda'
import { reverse } from 'ramda'
import { scan } from 'ramda'
import { sequence } from 'ramda'
import { set } from 'ramda'
import { slice } from 'ramda'
import { sort } from 'ramda'
import { sortBy } from 'ramda'
import { sortWith } from 'ramda'
import { split } from 'ramda'
import { splitAt } from 'ramda'
import { splitEvery } from 'ramda'
import { splitWhen } from 'ramda'
import { startsWith } from 'ramda'
import { subtract } from 'ramda'
import { sum } from 'ramda'
import { symmetricDifference } from 'ramda'
import { symmetricDifferenceWith } from 'ramda'
import { tail } from 'ramda'
import { take } from 'ramda'
import { takeLast } from 'ramda'
import { takeLastWhile } from 'ramda'
import { takeWhile } from 'ramda'
import { tap } from 'ramda'
import { test } from 'ramda'
import { times } from 'ramda'
import { toLower } from 'ramda'
import { toPairs } from 'ramda'
import { toPairsIn } from 'ramda'
import { toString } from 'ramda'
import { toUpper } from 'ramda'
import { transduce } from 'ramda'
import { transpose } from 'ramda'
import { traverse } from 'ramda'
import { trim } from 'ramda'
import { tryCatch } from 'ramda'
import { type } from 'ramda'
import { unapply } from 'ramda'
import { unary } from 'ramda'
import { uncurryN } from 'ramda'
import { unfold } from 'ramda'
import { union } from 'ramda'
import { unionWith } from 'ramda'
import { uniq } from 'ramda'
import { uniqBy } from 'ramda'
import { uniqWith } from 'ramda'
import { unless } from 'ramda'
import { unnest } from 'ramda'
import { until } from 'ramda'
import { update } from 'ramda'
import { useWith } from 'ramda'
import { values } from 'ramda'
import { valuesIn } from 'ramda'
import { view } from 'ramda'
import { when } from 'ramda'
import { where } from 'ramda'
import { whereEq } from 'ramda'
import { without } from 'ramda'
import { xprod } from 'ramda'
import { zip } from 'ramda'
import { zipObj } from 'ramda'
import { zipWith } from 'ramda'
import { thunkify } from 'ramda'

export {
  F,
  T,
  __,
  add,
  addIndex,
  adjust,
  all,
  allPass,
  always,
  and,
  any,
  anyPass,
  ap,
  aperture,
  append,
  apply,
  applySpec,
  applyTo,
  ascend,
  assoc,
  assocPath,
  binary,
  bind,
  both,
  call,
  chain,
  clamp,
  clone,
  comparator,
  complement,
  compose,
  composeK,
  composeP,
  composeWith,
  concat,
  cond,
  construct,
  constructN,
  contains,
  converge,
  countBy,
  curry,
  curryN,
  dec,
  defaultTo,
  descend,
  difference,
  differenceWith,
  dissoc,
  dissocPath,
  divide,
  drop,
  dropLast,
  dropLastWhile,
  dropRepeats,
  dropRepeatsWith,
  dropWhile,
  either,
  empty,
  endsWith,
  eqBy,
  eqProps,
  equals,
  evolve,
  filter,
  find,
  findIndex,
  findLast,
  findLastIndex,
  flatten,
  flip,
  forEach,
  forEachObjIndexed,
  fromPairs,
  groupBy,
  groupWith,
  gt,
  gte,
  has,
  hasIn,
  hasPath,
  head,
  identical,
  identity,
  ifElse,
  inc,
  includes,
  indexBy,
  indexOf,
  init,
  innerJoin,
  insert,
  insertAll,
  intersection,
  intersperse,
  into,
  invert,
  invertObj,
  invoker,
  is,
  isEmpty,
  isNil,
  join,
  juxt,
  keys,
  keysIn,
  last,
  lastIndexOf,
  length,
  lens,
  lensIndex,
  lensPath,
  lensProp,
  lift,
  liftN,
  lt,
  lte,
  map,
  mapAccum,
  mapAccumRight,
  mapObjIndexed,
  match,
  mathMod,
  max,
  maxBy,
  mean,
  median,
  memoizeWith,
  merge,
  mergeAll,
  mergeDeepLeft,
  mergeDeepRight,
  mergeDeepWith,
  mergeDeepWithKey,
  mergeLeft,
  mergeRight,
  mergeWith,
  mergeWithKey,
  min,
  minBy,
  modulo,
  multiply,
  nAry,
  negate,
  none,
  not,
  nth,
  nthArg,
  o,
  objOf,
  of,
  omit,
  once,
  or,
  otherwise,
  over,
  pair,
  partial,
  partialRight,
  partition,
  path,
  pathEq,
  pathOr,
  pathSatisfies,
  pick,
  pickAll,
  pickBy,
  pipe,
  pipeK,
  pipeP,
  pipeWith,
  pluck,
  prepend,
  product,
  project,
  prop,
  propEq,
  propIs,
  propOr,
  propSatisfies,
  props,
  range,
  reduce,
  reduceBy,
  reduceRight,
  reduceWhile,
  reduced,
  reject,
  remove,
  repeat,
  replace,
  reverse,
  scan,
  sequence,
  set,
  slice,
  sort,
  sortBy,
  sortWith,
  split,
  splitAt,
  splitEvery,
  splitWhen,
  startsWith,
  subtract,
  sum,
  symmetricDifference,
  symmetricDifferenceWith,
  tail,
  take,
  takeLast,
  takeLastWhile,
  takeWhile,
  tap,
  test,
  times,
  toLower,
  toPairs,
  toPairsIn,
  toString,
  toUpper,
  transduce,
  transpose,
  traverse,
  trim,
  tryCatch,
  type,
  unapply,
  unary,
  uncurryN,
  unfold,
  union,
  unionWith,
  uniq,
  uniqBy,
  uniqWith,
  unless,
  unnest,
  until,
  update,
  useWith,
  values,
  valuesIn,
  view,
  when,
  where,
  whereEq,
  without,
  xprod,
  zip,
  zipObj,
  zipWith,
  thunkify,
}

export const lookup = flip(prop)
