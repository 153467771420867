import { useMemo } from 'react'
import { getRouterLocation } from '../selectors/router'
import { find, prop } from 'ramda'
import { ROUTE_STACK } from '../constants/routes'
import { getScreenByLocation } from '../components/StackScreen'
import { useSelector } from 'react-redux'

export const useStackRouteMatch = routeKey => {
  const location = useSelector(getRouterLocation)

  return useMemo(
    () => {
      const stack = (() => {
        if (prop('state', location)) {
          return JSON.parse(prop('state', location))
        }

        const screen = getScreenByLocation(location)
        const params = screen.path.match((location || {}).pathname)

        return screen ? [{ routeKey: screen.routeKey, ...params }] : []
      })()

      const isVisible = !!(() => {
        const item = find(item => item.routeKey === routeKey, stack)

        if (!item && routeKey === ROUTE_STACK.MAIN) {
          return { routeKey }
        }

        return item
      })()

      return isVisible
    },
    [routeKey, location],
  )
}
