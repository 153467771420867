import { useEffect, useCallback, useState } from 'react'

export const useInterimValue = ({ value, onChange }) => {
  const [interimValue, setInterimValue] = useState(value)

  const handleChange = useCallback(
    (newValue, ...args) => {
      setInterimValue(newValue)
      setImmediate(() => {
        onChange(newValue, ...args)
      })
    },
    [setInterimValue, onChange],
  )

  useEffect(
    () => {
      setInterimValue(value)
    },
    [value],
  )

  return [interimValue, handleChange]
}
