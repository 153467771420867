import React from 'react'
import styled from '@emotion/styled/macro'
import { pure, compose } from 'recompose'

import { map } from 'ramda'
import withContentRenderDefer from '../../hoc/withContentRenderDefer'
import {
  MEDIA_FULL_DESKTOP,
  MEDIA_EXTENDED_DESKTOP,
} from '../../constants/media'

const StyledImages = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;

  flex-shrink: 0;
  padding: 20px 0px 0px;
  background: #f7f7f7;

  ${MEDIA_FULL_DESKTOP} {
    padding: 20px 45px 0px;
  }

  ${MEDIA_EXTENDED_DESKTOP} {
    padding: 20px 0px 0px 0px;
    background: transparent;
  }
`

const StyledImageContainer = styled.div`
  height: 0px;
  overflow: hidden;
  margin-bottom: 35px;
  background-color: black;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 56%;

  ${MEDIA_FULL_DESKTOP} {
    border-radius: 3px;
    padding: 20px 45px 0px;
    padding-bottom: 56%;
  }

  ${MEDIA_EXTENDED_DESKTOP} {
    padding: 0px 0px 0px;
    margin-bottom: 15px;
    padding-bottom: 56%;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  }
`

const Images = withContentRenderDefer(({ images }) => {
  return map(image => (
    <StyledImageContainer
      key={image}
      style={{ backgroundImage: `url(${image})` }}
    >
      {/* <StyledImage src={image} /> */}
    </StyledImageContainer>
  ))(images)
})

const ScreeningDetailImages = ({ images }) => {
  return (
    <StyledImages>
      <Images {...{ images }} />
    </StyledImages>
  )
}

export default compose(pure)(ScreeningDetailImages)
