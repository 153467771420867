import React from 'react'
import styled from '@emotion/styled/macro'
import { pure, compose, withHandlers } from 'recompose'

import {
  SECONDARY_FONT,
  MOUSE_DEVICE,
  SHOW_HIDE_FAST_DURATION,
  CHROME_ANDROID_NO_HIGHLIGHT,
} from '../../constants/CSS'
import { PRIMARY_BACKGROUND_COLOR, THEME_COLOR } from '../../constants/colors'
import {
  CINEMA_NAMES,
  CINEMA_LOCATIONS,
  CINEMA_MAP_LINKS,
} from '../../constants/cinemas'
import CinemaLogo from '../../components/CinemaLogo'
import { DAYS_OF_WEEK_FULL } from '../../constants/date'
import Ticket from '../../components/Ticket'
import VerticalDivider from '../../components/VerticalDivider'
import Camera from '../../components/Camera'
import Share from '../../components/Share'
import Ripple from '../../components/Ripple'
import ScreeningDetailInfoHeart from './ScreeningDetailInfoHeart'
import { MEDIA_FULL_DESKTOP } from '../../constants/media'
import connect from '../../hoc/connect'
import { goTo } from '../../actions/router'
import { createAllScreeningsRoute, ROUTE_STACK } from '../../constants/routes'
import encodeId from '../../utils/encodeId'
import BigHall from '../../components/BigHall'
import SmallHall from '../../components/SmallHall'
import Tram from '../../components/Tram'
import { deriveIsBigHall } from '../../selectors/data'
import getTimeText from '../../utils/getTimeText'

const StyledInfoContainer = styled.div`
  position: relative;
  background: ${THEME_COLOR};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 60px 0px 40px;

  ${MEDIA_FULL_DESKTOP} {
    margin-top: 20px;
    padding: 35px 100px 35px 50px;
    border-radius: 5px;
    flex-direction: row;
  }
`

const StyledCinemaName = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 18px;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
  color: ${PRIMARY_BACKGROUND_COLOR};
  font-weight: 900;
  margin-bottom: 14px;

  display: flex;
  align-items: center;
`

const StyledDateContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 15px;
`

const StyledDot = styled.div`
  height: 3px;
  width: 3px;
  margin: 0px 7px;
  border-radius: 50%;
  background: ${PRIMARY_BACKGROUND_COLOR};
`

const StyledDate = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${PRIMARY_BACKGROUND_COLOR};
  font-weight: 500;
`

const StyledHour = styled(StyledDate)`
  font-weight: 700;
`

const StyledCinemaLogoContainer = styled.div`
  position: absolute;
  top: -40px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;

  & svg {
    position: relative;
  }

  ${MEDIA_FULL_DESKTOP} {
    position: relative;
    top: 0px;
    margin-bottom: 20px;
  }
`

const StyledCinemaLogoContainerBackground = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-radius: 50%;
  background: ${PRIMARY_BACKGROUND_COLOR};
  opacity: 0.8;

  ${MEDIA_FULL_DESKTOP} {
    opacity: 1;
  }
`

const StyledBuyButton = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 18px;
  border-radius: 50px;
  background: ${PRIMARY_BACKGROUND_COLOR};
  text-transform: uppercase;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  font-family: ${SECONDARY_FONT};
  color: ${THEME_COLOR};
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  font-size: 13px;
  font-weight: 900;
  margin-top: 16px;

  & svg {
    margin-left: 8px;
  }

  transition: background ${SHOW_HIDE_FAST_DURATION} ease-out;

  ${MOUSE_DEVICE} {
    &:hover {
      /* opacity: 0.9; */
      background: #b1c8e1;
    }
  }

  ${CHROME_ANDROID_NO_HIGHLIGHT}
`

const StyledShareButton = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  cursor: pointer;
  user-select: none;
  position: absolute;
  right: 20px;
  top: 40px;

  & svg {
    margin-right: 2px;
  }

  transition: background ${SHOW_HIDE_FAST_DURATION} ease-out;

  ${MOUSE_DEVICE} {
    &:hover {
      /* opacity: 0.9; */
      background: #b1c8e1;
    }
  }

  ${CHROME_ANDROID_NO_HIGHLIGHT}

  ${MEDIA_FULL_DESKTOP} {
    flex-shrink: 0;
  }
`

const StyledMoreScreeningsButton = styled(StyledBuyButton)``.withComponent(
  'div',
)

const StyledCinemaDetailButton = styled(StyledBuyButton)``.withComponent('a')

const StyledFirstGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${MEDIA_FULL_DESKTOP} {
    align-items: flex-start;
    margin-left: 0px;
  }
`

const StyledSecondGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${MEDIA_FULL_DESKTOP} {
    flex-shrink: 0;
  }
`

const StyledHall = styled.div`
  display: flex;
  align-items: center;

  & svg {
    margin-left: 15px;
    margin-bottom: 5px;
  }

  & svg path {
    fill: ${PRIMARY_BACKGROUND_COLOR};
    stroke: ${PRIMARY_BACKGROUND_COLOR};
  }
`

// const StyledHall = styled.div`
//   display: flex;
//   align-items: center;
//   font-family: ${SECONDARY_FONT};
//   font-size: 16px;
//   font-weight: 700;
//   padding: 0px 0px 15px;
//   line-height: 1.5;
//   color: ${PRIMARY_BACKGROUND_COLOR};

//   & svg {
//     margin-left: 15px;
//     margin-bottom: 5px;
//   }

//   & svg path {
//     fill: ${PRIMARY_BACKGROUND_COLOR};
//     stroke: ${PRIMARY_BACKGROUND_COLOR};
//   }
// `

const StyledLocation = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${PRIMARY_BACKGROUND_COLOR};
  font-weight: 700;
`

const ScreeningDetailInfo = ({
  cinema,
  time,
  date,
  price,
  buyLink,
  numOfOtherScreenings,
  onShareClick,
  onMoreScreeningsClick,
  screeningId,
  hall,
}) => {
  const dateText = `${
    DAYS_OF_WEEK_FULL[date.getDay()]
  } ${date.getDate()}/${date.getMonth() + 1}`

  return (
    <StyledInfoContainer>
      {navigator.share ? (
        <StyledShareButton
          {...{
            onClick: onShareClick,
          }}
        >
          <Share />
          <Ripple {...{ color: PRIMARY_BACKGROUND_COLOR, opacity: 0.3 }} />
        </StyledShareButton>
      ) : null}
      {/*<ScreeningDetailInfoHeart {...{ screeningId }} />*/}

      <StyledFirstGroup>
        <StyledCinemaLogoContainer>
          <StyledCinemaLogoContainerBackground />
          <CinemaLogo
            {...{ cinema, width: 40, height: 40, SECONDARY_COLOR: THEME_COLOR }}
          />
        </StyledCinemaLogoContainer>
        <StyledCinemaName>
          {CINEMA_NAMES[cinema]}
          {hall && (
            <StyledHall>
              {/* {hall} */}
              {deriveIsBigHall({ hall }) ? <BigHall /> : <SmallHall />}
            </StyledHall>
          )}
        </StyledCinemaName>

        <StyledDateContainer>
          <StyledDate>{dateText}</StyledDate>
          <StyledDot />
          <StyledHour>{getTimeText(time)}</StyledHour>
        </StyledDateContainer>
        <StyledLocation>{CINEMA_LOCATIONS[cinema]}</StyledLocation>
      </StyledFirstGroup>

      <VerticalDivider {...{ space: 10 }} />

      <StyledSecondGroup>
        {CINEMA_MAP_LINKS[cinema] && (
          <StyledCinemaDetailButton
            {...{ target: '_blank', href: CINEMA_MAP_LINKS[cinema] }}
          >
            Jak se tam dostanu <Tram />
            <Ripple {...{ color: THEME_COLOR, opacity: 0.3 }} />
          </StyledCinemaDetailButton>
        )}
        {price !== undefined && buyLink && (
          <StyledBuyButton {...{ target: '_blank', href: buyLink }}>
            Koupit lístek za {price}Kč <Ticket />
            <Ripple {...{ color: THEME_COLOR, opacity: 0.3 }} />
          </StyledBuyButton>
        )}
        {numOfOtherScreenings ? (
          <StyledMoreScreeningsButton
            {...{
              onClick: onMoreScreeningsClick,
            }}
          >
            {numOfOtherScreenings}{' '}
            {numOfOtherScreenings < 5 ? 'Další projekce' : 'Dalších projekcí'}
            <Camera />
            <Ripple {...{ color: THEME_COLOR, opacity: 0.3 }} />
          </StyledMoreScreeningsButton>
        ) : null}
      </StyledSecondGroup>
    </StyledInfoContainer>
  )
}

export default compose(
  connect(
    null,
    { goTo },
  ),
  withHandlers({
    onMoreScreeningsClick: ({ goTo, movieId: decodedId }) => () => {
      const movieId = encodeId(decodedId)
      goTo({
        pathname: createAllScreeningsRoute({ movieId }),
        state: JSON.stringify({
          routeKey: ROUTE_STACK.MORE_SCREENINGS,
          movieId,
        }),
      })
    },
    onShareClick: ({ title: movieTitle, cinema, date, time }) => () => {
      const cinemaName = CINEMA_NAMES[cinema]
      const dateText = `${
        DAYS_OF_WEEK_FULL[date.getDay()]
      } ${date.getDate()}/${date.getMonth() + 1}`
      const title = `${movieTitle} - ${cinemaName} - ${dateText} ${getTimeText(
        time,
      )}`
      const url = window.location.href

      navigator.share({
        title,
        url,
      })
    },
  }),
  pure,
)(ScreeningDetailInfo)
