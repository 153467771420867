import React, { memo, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled/macro'
import { prop } from 'ramda'

import { StoriesComponentProgressBar } from './StoriesComponentProgressBar'
import useHandler from '../hooks/useHandler'
import { createMainRoute, ROUTE_STACK } from '../constants/routes'
import { goTo, replace } from '../actions/router'
import { useAction } from '../hooks/useAction'
import goBackOrPush from '../utils/goBackOrPush'
import { useWindowSize } from '../hooks/useWindowSize'
import { useDispatch } from 'react-redux'
import { DEFAULT_STORY_TIME } from '../constants/storyTiming'

const HAS_TOUCH =
  typeof window !== 'undefined'
    ? window &&
      window.matchMedia &&
      window.matchMedia('(pointer: coarse)').matches &&
      ('ontouchstart' in window ||
        (window.DocumentTouch && document instanceof window.DocumentTouch))
    : false

const StyledStoriesComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${prop('width')};
  height: ${prop('height')};
  overflow: hidden;
  perspective: 700px;
  position: relative;
`

const getTransform = ({ offset }) => `translateX(${offset}%)
rotateY(${offset * 0.75}deg)`

const getTransformOrigin = ({ offset }) =>
  `${offset < 0 ? 'right' : 'left'} center`

const getVerticalTransform = ({ offset }) =>
  // `translateY(${0.6 * offset}%) scale(${1 - (0.3 * offset) / 100})`
  `translateY(${0.6 * offset}%)`
const getVerticalOrigin = () => `center center`

const StyledStoryWrapper = styled.div`
  display: flex;
  //position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;

  //transform: translateX(-50%) rotateY(-41deg);
  transform: ${({ offset }) => getTransform({ offset })};
  transform-origin: ${({ offset }) => getTransformOrigin({ offset })};
  backface-visibility: hidden;
  /* perspective: 1800px; */
  /* transform-style: preserve-3d; */
  border-radius: 6px;

  //transition: transform 0.3s ease-out;

  visibility: ${({ offset }) =>
    offset < -100 || offset > 100 ? 'hidden' : 'visible'};

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  will-change: transform;
`

const StyledTouchArea = styled.div`
  display: flex;
  position: absolute;
  z-index: 3;
  top: 70px;
  bottom: 0;
  left: 0;
  right: 0;
`

const useStoryStartHandler = ({
  progressRef,
  goToNextStory,
  storyDurationRef,
}) => {
  const handleStoryStart = useHandler(() => {
    let frameId
    let elapsedTime
    let elapsedTimeBeforePause = 0
    let startTime

    const step = timestamp => {
      if (!startTime) {
        startTime = timestamp
      }
      const elapsed = timestamp - startTime
      elapsedTime = elapsedTimeBeforePause + elapsed
      const newProgress = Math.max(
        0,
        Math.min(100, (elapsedTime * 100) / storyDurationRef.current),
      )

      // console.log({ storyDuration: storyDurationRef.current })

      if (progressRef.current) {
        progressRef.current.style.transform = `translateX(${(newProgress *
          103) /
          100 -
          103}%)`
      }

      if (newProgress < 100) {
        frameId = window.requestAnimationFrame(step)
      } else {
        window.cancelAnimationFrame(frameId)
        goToNextStory()
      }
    }

    frameId = window.requestAnimationFrame(step)

    const pauseProgress = () => {
      elapsedTimeBeforePause = elapsedTime
      startTime = undefined
      window.cancelAnimationFrame(frameId)
    }

    const continueProgress = () => {
      frameId = window.requestAnimationFrame(step)
    }

    const cancelProgress = newStoryDirection => {
      const progress = newStoryDirection === 'next' ? 100 : 0

      if (progressRef.current) {
        progressRef.current.style.transform = `translateX(${(progress * 103) /
          100 -
          103}%)`
      }

      window.cancelAnimationFrame(frameId)
    }

    return {
      pauseProgress,
      continueProgress,
      cancelProgress,
    }
  })

  return handleStoryStart
}

const noop = () => {}

const getDocumentSize = () => ({
  width: window.innerWidth,
  height: window.innerHeight,
})

const detectGesture = (xDiff, yDiff, x, y, preLastX, preLastY) => {
  const { width, height } = getDocumentSize()

  if (Math.abs(xDiff) < 0.05 * width && Math.abs(yDiff) < 0.05 * height) {
    if (Math.abs(xDiff) > 0.01 * width || Math.abs(yDiff) > 0.01 * height) {
      return 'NONE'
    }

    if (y > 0.89 * height) {
      return 'BOTTOM_CLICK'
    }

    if (x > 0.5 * width) {
      return 'RIGHT_HALF_CLICK'
    }

    return 'LEFT_HALF_CLICK'
  }

  if (Math.abs(xDiff) > Math.abs(yDiff)) {
    if (xDiff > 0) {
      return preLastX > x ? 'LEFT_SWIPE' : 'NONE'
    }

    return preLastX < x ? 'RIGHT_SWIPE' : 'NONE'
  } else {
    if (yDiff > 0) {
      return preLastY > y ? 'UP_SWIPE' : 'NONE'
    }

    return preLastY < y ? 'DOWN_SWIPE' : 'NONE'
  }
}

const defaultProgressHandlers = {
  pauseProgress: noop,
  continueProgress: noop,
  cancelProgress: noop,
}

const defaultVideoHandlers = {
  pauseProgress: noop,
  continueProgress: noop,
}

const useSetOffsetTransform = ({
  previousStoryWrapperRef,
  storyWrapperRef,
  nextStoryWrapperRef,
}) => {
  return useHandler(({ offset }) => {
    if (previousStoryWrapperRef.current) {
      const localOffset = -100 + offset
      previousStoryWrapperRef.current.style.transform = getTransform({
        offset: localOffset,
      })
      previousStoryWrapperRef.current.style.transformOrigin = getTransformOrigin(
        {
          offset: -100,
        },
      )
    }
    if (storyWrapperRef.current) {
      storyWrapperRef.current.style.transform = getTransform({ offset })
      storyWrapperRef.current.style.transformOrigin = getTransformOrigin({
        offset,
      })
    }
    if (nextStoryWrapperRef.current) {
      const localOffset = 100 + offset
      nextStoryWrapperRef.current.style.transform = getTransform({
        offset: localOffset,
      })
      nextStoryWrapperRef.current.style.transformOrigin = getTransformOrigin({
        offset: 100,
      })
    }
  })
}

const useResetTransition = ({
  previousStoryWrapperRef,
  storyWrapperRef,
  nextStoryWrapperRef,
}) => {
  return useHandler(() => {
    const TRANSITION = ''
    if (storyWrapperRef.current) {
      storyWrapperRef.current.style.transition = TRANSITION
    }
    if (previousStoryWrapperRef.current) {
      previousStoryWrapperRef.current.style.transition = TRANSITION
    }
    if (nextStoryWrapperRef.current) {
      nextStoryWrapperRef.current.style.transition = TRANSITION
    }
  })
}

const useSetTransition = ({
  previousStoryWrapperRef,
  storyWrapperRef,
  nextStoryWrapperRef,
}) => {
  return useHandler(() => {
    const TRANSITION = 'transform 0.25s ease-out'
    if (storyWrapperRef.current) {
      storyWrapperRef.current.style.transition = TRANSITION
    }
    if (previousStoryWrapperRef.current) {
      previousStoryWrapperRef.current.style.transition = TRANSITION
    }
    if (nextStoryWrapperRef.current) {
      nextStoryWrapperRef.current.style.transition = TRANSITION
    }
  })
}

export const StoriesComponent = memo(
  ({
    previousSection,
    currentSection,
    nextSection,
    width,
    height,
    handleNextSection,
    handlePreviousSection,
    wrapperRef,
  }) => {
    const { stories, startIndex } = currentSection || {}

    const [currentIndex, setCurrentIndex] = useState(startIndex)
    const [paused, setPaused] = useState(false)
    const progressRef = useRef(null)
    const storyWrapperRef = useRef(null)
    const previousStoryWrapperRef = useRef(null)
    const nextStoryWrapperRef = useRef(null)
    const debouncePauseTimeoutId = useRef(null)
    const touchDownCoords = useRef({})
    const previousTouchMoveCoords = useRef({})
    const touchMoveCoords = useRef({})
    const touchDirection = useRef(null)
    const touchAreaRef = useRef(null)
    const offsetRef = useRef(0)
    const transitioning = useRef(false)
    const dispatch = useDispatch()
    const { width: windowWidth, height: windowHeight } = useWindowSize()
    const storyDurationRef = useRef(DEFAULT_STORY_TIME)

    const handleDurationChange = useHandler(
      (videoDuration, { currentIndex: i }) => {
        if (i === currentIndex) {
          storyDurationRef.current = videoDuration
        }
      },
    )

    const setOffsetTransform = useSetOffsetTransform({
      previousStoryWrapperRef,
      storyWrapperRef,
      nextStoryWrapperRef,
    })
    const resetTransition = useResetTransition({
      previousStoryWrapperRef,
      storyWrapperRef,
      nextStoryWrapperRef,
    })
    const setTransition = useSetTransition({
      previousStoryWrapperRef,
      storyWrapperRef,
      nextStoryWrapperRef,
    })

    const progressHandlers = useRef(defaultProgressHandlers)
    const videoHandlers = useRef(defaultVideoHandlers)

    const replaceAction = useAction(replace)

    const goToNextStory = useHandler(() => {
      if (currentIndex !== stories.length - 1) {
        if (progressHandlers.current) {
          progressHandlers.current.cancelProgress('next')
        }
        if (debouncePauseTimeoutId.current) {
          clearTimeout(debouncePauseTimeoutId.current)
          debouncePauseTimeoutId.current = null
        }
        setPaused(false)

        setCurrentIndex(currentIndex + 1)
      } else {
        const onTransitionEnd = e => {
          if (e.propertyName === 'transform') {
            transitioning.current = false
            const hasNextSection = handleNextSection()
            if (!hasNextSection) {
              goBackOrPush(() => {
                replaceAction({
                  pathname: createMainRoute(),
                  state: JSON.stringify({
                    routeKey: ROUTE_STACK.MAIN,
                  }),
                })
              })
            }
          }
        }

        if (storyWrapperRef.current) {
          setTransition()
          setOffsetTransform({ offset: -100 })

          transitioning.current = true
          storyWrapperRef.current.addEventListener(
            'transitionend',
            onTransitionEnd,
          )
        }
      }
    })

    const handleStoryStart = useStoryStartHandler({
      progressRef,
      goToNextStory,
      storyDurationRef,
    })

    const restartStory = useHandler(() => {
      progressHandlers.current.cancelProgress('previous')
      if (debouncePauseTimeoutId.current) {
        clearTimeout(debouncePauseTimeoutId.current)
        debouncePauseTimeoutId.current = null
      }
      setPaused(false)
      progressHandlers.current = handleStoryStart()
    })

    const goToPreviousStory = useHandler(() => {
      if (currentIndex !== 0) {
        progressHandlers.current.cancelProgress('previous')
        if (debouncePauseTimeoutId.current) {
          clearTimeout(debouncePauseTimeoutId.current)
          debouncePauseTimeoutId.current = null
        }
        setPaused(false)

        setCurrentIndex(currentIndex - 1)
      } else {
        if (!previousSection) {
          restartStory()
        } else {
          const onTransitionEnd = e => {
            if (e.propertyName === 'transform') {
              handlePreviousSection()
              transitioning.current = false
            }
          }

          if (storyWrapperRef.current) {
            setTransition()
            setOffsetTransform({ offset: 100 })
            transitioning.current = true

            storyWrapperRef.current.addEventListener(
              'transitionend',
              onTransitionEnd,
            )
          }
        }
      }
    })

    const goToNextSection = useHandler(() => {
      // console.log('goToNextSection')
      const hasNextSection = handleNextSection()
      if (!hasNextSection) {
        goBackOrPush(() => {
          replaceAction({
            pathname: createMainRoute(),
            state: JSON.stringify({
              routeKey: ROUTE_STACK.MAIN,
            }),
          })
        })
      }
    })

    const goToPreviousSection = useHandler(() => {
      const hasPreviousSection = handlePreviousSection()
      if (!hasPreviousSection) {
        goBackOrPush(() => {
          replaceAction({
            pathname: createMainRoute(),
            state: JSON.stringify({
              routeKey: ROUTE_STACK.MAIN,
            }),
          })
        })
      }
    })

    const handleTouchDown = useHandler(e => {
      e.preventDefault()

      if (transitioning.current) {
        return
      }

      touchDownCoords.current = {
        x: HAS_TOUCH ? e.changedTouches[0].clientX : e.clientX,
        y: HAS_TOUCH ? e.changedTouches[0].clientY : e.clientY,
      }
      progressHandlers.current.pauseProgress()
      videoHandlers.current.pauseProgress()

      debouncePauseTimeoutId.current = setTimeout(() => {
        if (!transitioning.current) {
          setPaused(true)
        }
      }, 200)
    })

    const handleTouchUp = useHandler(e => {
      e.preventDefault()

      if (transitioning.current) {
        return
      }

      const endCoords = {
        x: HAS_TOUCH ? e.changedTouches[0].clientX : e.clientX,
        y: HAS_TOUCH ? e.changedTouches[0].clientY : e.clientY,
      }
      const startCoords = touchDownCoords.current

      const xDiff = startCoords.x - endCoords.x
      const yDiff = startCoords.y - endCoords.y

      const showStory = () => {
        if (debouncePauseTimeoutId.current) {
          clearTimeout(debouncePauseTimeoutId.current)
          debouncePauseTimeoutId.current = null
        }
        setPaused(false)
      }

      const gesture = detectGesture(
        xDiff,
        yDiff,
        endCoords.x,
        endCoords.y,
        previousTouchMoveCoords.current.x,
        previousTouchMoveCoords.current.y,
      )

      if (gesture === 'RIGHT_HALF_CLICK' && !paused) {
        goToNextStory()
      } else if (gesture === 'LEFT_HALF_CLICK' && !paused) {
        goToPreviousStory()
      } else if (
        (gesture === 'LEFT_SWIPE' || gesture === 'RIGHT_SWIPE') &&
        !paused
      ) {
        const onTransitionEnd = e => {
          if (e.propertyName === 'transform') {
            transitioning.current = false
            resetTransition()
            showStory()

            if (gesture === 'LEFT_SWIPE') {
              goToNextSection()
            } else {
              goToPreviousSection()
            }
          }
        }

        if (storyWrapperRef.current) {
          setTransition()
          setOffsetTransform({ offset: gesture === 'LEFT_SWIPE' ? -100 : 100 })

          transitioning.current = true
          storyWrapperRef.current.addEventListener(
            'transitionend',
            onTransitionEnd,
          )
        }
      } else if (gesture === 'DOWN_SWIPE') {
        const onTransitionEnd = e => {
          if (e.propertyName === 'transform') {
            transitioning.current = false
            resetTransition()

            goBackOrPush(() => {
              dispatch(
                replace({
                  pathname: createMainRoute(),
                  state: JSON.stringify({
                    routeKey: ROUTE_STACK.MAIN,
                  }),
                }),
              )
            })
          }
        }

        if (storyWrapperRef.current) {
          if (storyWrapperRef.current) {
            storyWrapperRef.current.style.transition =
              'transform 0.25s ease-out'
            storyWrapperRef.current.style.transform = 'translateY(100%)'
            storyWrapperRef.current.style.transformOrigin = getVerticalOrigin()
          }

          if (wrapperRef.current) {
            wrapperRef.current.style.transition = 'opacity 0.25s ease-out 0.15s'
            wrapperRef.current.style.opacity = 0
          }

          transitioning.current = true
          storyWrapperRef.current.addEventListener(
            'transitionend',
            onTransitionEnd,
          )
        }
      } else {
        if (offsetRef.current === 0) {
          showStory()

          progressHandlers.current.continueProgress()
          videoHandlers.current.continueProgress()
        } else {
          const onTransitionEnd = e => {
            if (e.propertyName === 'transform') {
              transitioning.current = false
              resetTransition()
              showStory()

              progressHandlers.current.continueProgress()
              videoHandlers.current.continueProgress()
            }
          }

          if (storyWrapperRef.current) {
            setTransition()
            setOffsetTransform({ offset: 0 })

            transitioning.current = true
            storyWrapperRef.current.addEventListener(
              'transitionend',
              onTransitionEnd,
            )
          }
        }
      }

      touchDownCoords.current = {}
      touchDirection.current = null
    })

    const handleTouchMove = useHandler(e => {
      e.preventDefault()

      if (transitioning.current) {
        return
      }
      // console.log('handleTouchMove', {
      //   e,
      //   changedTouches: e.changedTouches,
      //   touches: e.touches,
      // })

      if (!paused) {
        if (debouncePauseTimeoutId.current) {
          clearTimeout(debouncePauseTimeoutId.current)
          debouncePauseTimeoutId.current = null
        }

        setPaused(false)
      }

      const touchCoords = {
        x: HAS_TOUCH ? e.changedTouches[0].clientX : e.clientX,
        y: HAS_TOUCH ? e.changedTouches[0].clientY : e.clientY,
      }

      previousTouchMoveCoords.current = touchMoveCoords.current
      touchMoveCoords.current = touchCoords

      // const { width, height } = getDocumentSize()

      if (!touchDirection.current && touchDownCoords.current) {
        const horizontalDiff = Math.abs(
          touchCoords.x - touchDownCoords.current.x,
        )
        const verticalDiff = Math.abs(touchCoords.y - touchDownCoords.current.y)

        if (Math.max(horizontalDiff, verticalDiff) / windowWidth > 0.02) {
          if (horizontalDiff > verticalDiff) {
            touchDirection.current = 'HORIZONTAL'
          } else {
            touchDirection.current = 'VERTICAL'
          }
        }
      }

      if (!paused) {
        if (touchDirection.current === 'HORIZONTAL') {
          const touchOffsetX = touchCoords.x - touchDownCoords.current.x

          // setStoryOffset((touchOffsetX * 100) / width)

          const offset = (touchOffsetX * 100) / windowWidth
          offsetRef.current = offset
          setOffsetTransform({ offset })
        }

        if (touchDirection.current === 'VERTICAL') {
          const touchOffsetY = touchCoords.y - touchDownCoords.current.y

          const offset = (touchOffsetY * 100) / windowHeight

          if (touchOffsetY > 0) {
            storyWrapperRef.current.style.transform = getVerticalTransform({
              offset,
            })
            storyWrapperRef.current.style.transformOrigin = getVerticalOrigin()
            // if (wrapperRef) {
            //   wrapperRef.current.style.opacity = 1 - (0.5 * offset) / 100
            // }
          }
        }
      }
    })

    useEffect(
      () => {
        setCurrentIndex(startIndex)
      },
      [stories],
    )

    useEffect(
      () => {
        restartStory()
      },
      [currentIndex],
    )
    const StoryScreen = stories[currentIndex].content
    const PreviousStoryScreen = (
      ((previousSection || {}).stories || [])[
        (previousSection || {}).startIndex
      ] || {}
    ).content
    const NextStoryScreen = (
      ((nextSection || {}).stories || [])[(nextSection || {}).startIndex] || {}
    ).content

    useEffect(() => {
      if (touchAreaRef.current) {
        if (HAS_TOUCH) {
          touchAreaRef.current.addEventListener('touchstart', handleTouchDown, {
            passive: false,
          })
          touchAreaRef.current.addEventListener('touchend', handleTouchUp, {
            passive: false,
          })
          touchAreaRef.current.addEventListener('touchmove', handleTouchMove, {
            passive: false,
          })
        } else {
          touchAreaRef.current.addEventListener('mousedown', handleTouchDown)
          touchAreaRef.current.addEventListener('mouseup', handleTouchUp)
        }
      }

      return () => {
        if (touchAreaRef.current) {
          if (HAS_TOUCH) {
            touchAreaRef.current.removeEventListener(
              'touchstart',
              handleTouchDown,
            )
            touchAreaRef.current.removeEventListener('touchend', handleTouchUp)
            touchAreaRef.current.removeEventListener(
              'touchmove',
              handleTouchMove,
            )
          } else {
            touchAreaRef.current.removeEventListener(
              'mousedown',
              handleTouchDown,
            )
            touchAreaRef.current.removeEventListener('mouseup', handleTouchUp)
          }
        }
      }
    }, [])

    return (
      <StyledStoriesComponentContainer {...{ width, height }}>
        {previousSection && (
          <StyledStoryWrapper
            {...{ offset: -100, ref: previousStoryWrapperRef }}
          >
            <StoriesComponentProgressBar
              {...{
                length: previousSection.stories.length,
                currentIndex: previousSection.startIndex,
                paused: false,
              }}
            />
            {PreviousStoryScreen && <PreviousStoryScreen />}
          </StyledStoryWrapper>
        )}

        <StyledStoryWrapper {...{ offset: 0, ref: storyWrapperRef }}>
          <StoriesComponentProgressBar
            {...{ length: stories.length, currentIndex, progressRef, paused }}
          />
          <StoryScreen
            {...{
              paused,
              handleDurationChange,
              key: currentIndex,
              currentIndex,
              videoHandlers,
            }}
          />
        </StyledStoryWrapper>

        {nextSection && (
          <StyledStoryWrapper {...{ offset: 100, ref: nextStoryWrapperRef }}>
            <StoriesComponentProgressBar
              {...{
                length: nextSection.stories.length,
                currentIndex: nextSection.startIndex,
                paused: false,
              }}
            />
            {NextStoryScreen && <NextStoryScreen />}
          </StyledStoryWrapper>
        )}

        <StyledTouchArea
          {...{
            ref: touchAreaRef,
            // ...(HAS_TOUCH
            //   ? {
            //       onTouchStart: handleTouchDown,
            //       onTouchEnd: handleTouchUp,
            //       onTouchMove: handleTouchMove,
            //     }
            //   : {
            //       onMouseDown: handleTouchDown,
            //       onMouseUp: handleTouchUp,
            //     }),
          }}
        />
      </StyledStoriesComponentContainer>
    )
  },
)
