import React from 'react'
import styled from '@emotion/styled/macro'

import { SHOW_HIDE_FAST_DURATION, MOUSE_DEVICE } from '../constants/CSS'
import { CINEMA_NAMES } from '../constants/cinemas'
import { THEME_COLOR } from '../constants/colors'

const StyledInfoButton = styled.div`
  opacity: 0.99;
  width: 30px;
  height: 30px;
  border: 3px solid black;
  border-radius: 50%;
  margin: 0px 20px 0px 10px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
`

const StyledText = styled.div`
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 1.4;
  max-width: 120px;
  margin-right: 30px;
  flex: 0;
`

export const StyledCinemaName = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  position: absolute;
  left: -160px;
  width: 160px;

  opacity: 0;
  visibility: hidden;

  @media (min-width: 1200px) {
    opacity: ${({ screeningSelected }) => (screeningSelected ? 1 : 0)};
    visibility: ${({ screeningSelected }) =>
      screeningSelected ? 'visible' : 'hidden'};
  }

  ${MOUSE_DEVICE} {
    &:hover {
      /* & ${StyledInfoButton} {
        border-color: #666;
        color: #666;
      } */

      & ${StyledText} {
        color: #666;
        color: ${THEME_COLOR};
      }
    }
  }

  transition: opacity ${SHOW_HIDE_FAST_DURATION},
    visibility 0s ${SHOW_HIDE_FAST_DURATION};
`

const CinemaName = ({ cinema, screeningSelected }) => {
  return (
    <StyledCinemaName {...{ screeningSelected }}>
      {/* <StyledInfoButton>i</StyledInfoButton> */}
      <StyledText>{CINEMA_NAMES[cinema]}</StyledText>
    </StyledCinemaName>
  )
}

export default CinemaName
