import React from 'react'
import styled from '@emotion/styled/macro'
import { compose, pure, withHandlers, withProps } from 'recompose'
import { path } from 'ramda'
import { replace } from 'connected-react-router'

import { setSelectedScreening } from '../actions/selectedScreening'
import {
  CHROME_ANDROID_NO_HIGHLIGHT,
  MIN_600,
  MOUSE_DEVICE,
  SECONDARY_FONT,
  SHOW_HIDE_FAST_DURATION,
} from '../constants/CSS'
import getHallName from '../utils/getHallName'
import getTimeText from '../utils/getTimeText'
import scrollTo from '../utils/scrollTo'
import { setHoveredScreeningElement } from '../actions/hoveredScreeningElement'
import Hall from './Hall'
import Cinema from './Cinema'
import Price from './Price'
import Ripple from './Ripple'
import CinemaName, { StyledCinemaName } from './CinemaName'
import ScreeningHoverDetailRightImage, {
  StyledHoverDetailImage,
} from './ScreeningHoverDetailRightImage'
import { connect } from 'react-redux-hoc'
import { createScreeningDetailRoute, ROUTE_STACK } from '../constants/routes'
import encodeId from '../utils/encodeId'
import { goTo } from '../actions/router'
import dateToTime from '../utils/dateToTime'
import useHandler from '../hooks/useHandler'
import fireEvent from '../utils/fireEvent'
import { CustomEvents } from '../constants/events'
import { ScreeningSecondSection } from './ScreeningSecondSection'

// const SELECTED_BACKGROUND_COLOR = '#f6f6f6'
// const SELECTED_BACKGROUND_COLOR = 'rgba(246, 246, 246, 0.8313725490196079)'
// const SELECTED_BACKGROUND_COLOR = 'rgba(255, 255, 255, 0.79)'
const SELECTED_BACKGROUND_COLOR = 'rgba(249, 249, 249, 0.83)'
// const HOVER_BACKGROUND_COLOR = '#f4f4f4'
// const HOVER_BACKGROUND_COLOR = '#f0eeeb'
const HOVER_BACKGROUND_COLOR = 'rgba(249, 249, 249, 0.83)'
// const HOVER_BACKGROUND_COLOR = 'rgba(255, 255, 255, 0.79)'

const StyledMovieTitle = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
  line-height: 21px;
  //text-decoration: underline;
  text-decoration: none;
  /* color: #3B3631;
  font-weight: 600; */
  color: #292522;
  //font-weight: 500;
  font-weight: 800;

  ${MIN_600} {
    font-size: 15px;
    /* font-size: 16px; */
  }

  ${MOUSE_DEVICE} {
    cursor: pointer;

    &:hover {
      color: black;
      /* text-decoration: underline; */
    }
  }
`

const BORDER_RADIUS = 4

const StyledScreening = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  /* flex-direction: column; */
  /* box-shadow: 0 8px 15px 0 rgba(0,0,0,0.05),0 2px 18px 0 rgba(0,0,0,0.05); */

  ${MIN_600} {
    margin: 0px 5px 5px;
    margin-bottom: 7px;
  }

  box-shadow: ${({ screeningSelected }) =>
    screeningSelected
      ? '0 3px 6px rgba(93, 87, 80, 0.1), 0 3px 6px rgba(76, 72, 66, 0.13)'
      : '0 1px 3px rgba(93, 87, 80, 0.1), 0 1px 2px rgba(76, 72, 66, 0.13)'};
  transition: all 0.05s cubic-bezier(.25,.8,.25,1);

  ${MOUSE_DEVICE} {
    &:hover {
      box-shadow: 0 3px 6px rgba(93, 87, 80, 0.1), 0 3px 6px rgba(76, 72, 66, 0.13);
      background: ${({ screeningSelected }) =>
        screeningSelected ? SELECTED_BACKGROUND_COLOR : HOVER_BACKGROUND_COLOR};
    }
  }

  /* padding: 0 10px; */
  margin: 0px 10px 5px;
  margin-bottom: 7px;
  background: white;
  border: none;
  border-radius: ${BORDER_RADIUS}px;

  ${({ screeningSelected }) =>
    screeningSelected
      ? `

  ${StyledMovieTitle} {
    font-weight: 600;
  }

    `
      : ''}

  background: ${({ screeningSelected }) =>
    screeningSelected ? SELECTED_BACKGROUND_COLOR : 'white'};

  ${MOUSE_DEVICE} {
    @media (min-width: 1200px) {
      &:hover {
        & ${StyledCinemaName} {
          opacity: 1;
          visibility: visible;
          transition: opacity ${SHOW_HIDE_FAST_DURATION};
        }

        & ${StyledHoverDetailImage} {
          opacity: 1;
          visibility: visible;
          transition: opacity ${SHOW_HIDE_FAST_DURATION};
          background-image: var(--image-src);
        }
      }
    }
  }
`

const StyledScreeningContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  border-radius: ${BORDER_RADIUS}px;
  position: relative;

  ${MIN_600} {
    padding: 0 10px;
  }

  background: white;

  ${MOUSE_DEVICE} {
    &:hover {
      background: ${HOVER_BACKGROUND_COLOR};
    }
  }
`

const StyledScreeningContentFirstSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  //padding-left: 10px;
`

const StyledScreeningSections = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  position: relative;
`

const StyledScreeningTime = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 15px;
  font-weight: 500;
  width: 50px;
  flex-shrink: 0;
  user-select: none;
  cursor: pointer;
`

const StyledTimeAndTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 70px;
  justify-content: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${CHROME_ANDROID_NO_HIGHLIGHT}

  ${MOUSE_DEVICE} {
    cursor: pointer;

    &:hover {
      ${StyledMovieTitle} {
        /* text-decoration: underline; */
      }
    }
  }
`

const StyledTitleAndTimeInnerContent = styled.div`
  display: flex;
  align-items: center;
`

const CINEMA_LOGO_SIZE = 35

const Screening = ({
  screening,
  movie = {},
  hall,
  screeningSelected,
  onScreeningClick,
  setContainerHeight,
  setRef,
  scrollToTitle,
  hoveredElement,
  onCinemaClick,
  onHallClick,
  date: dayDate,
  image,
}) => {
  const { id, tags = [], cinema, date, price, buyLink, info } = screening
  const { title } = movie
  const time = dateToTime(date)
  const altDescription = path(['description', 'cs'], info || {})

  const onMouseEnter = useHandler(() => {
    fireEvent(CustomEvents.hoveredScreeningEvent, { detail: { id } })
  })

  const onMouseLeave = useHandler(() => {
    fireEvent(CustomEvents.hoveredScreeningEvent, { detail: { id: undefined } })
  })

  return (
    <StyledScreening
      id={id}
      screeningSelected={screeningSelected}
      ref={setRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <StyledScreeningContent>
        <Cinema
          cinema={cinema}
          containerSize={60}
          logoSize={CINEMA_LOGO_SIZE}
        />
        <StyledScreeningSections>
          <StyledScreeningContentFirstSection>
            <StyledTimeAndTitle onClick={onScreeningClick}>
              <StyledTitleAndTimeInnerContent>
                <StyledScreeningTime>{getTimeText(time)}</StyledScreeningTime>
                <StyledMovieTitle>{title}</StyledMovieTitle>
                <Ripple
                  {...{ color: '#ddd', opacity: 0.35, forScroll: true }}
                />
              </StyledTitleAndTimeInnerContent>
              <ScreeningSecondSection {...{ cinema }} />
            </StyledTimeAndTitle>
            {/*{hall && (*/}
            {/*  <Hall*/}
            {/*    onHallClick={onHallClick}*/}
            {/*    name={hall}*/}
            {/*    show={hoveredElement === `hall_${id}`}*/}
            {/*  />*/}
            {/*)}*/}
            <Price price={price} buyLink={buyLink} />
          </StyledScreeningContentFirstSection>
        </StyledScreeningSections>
      </StyledScreeningContent>
      {/*{image && !screeningSelected && <ScreeningHoverDetailRightImage {...{ image }} />}*/}
    </StyledScreening>
  )
}

export default compose(
  pure,
  connect(
    (
      { data, selectedScreening, hoveredScreeningElement },
      { screening: { id, movie } },
    ) => {
      const movieData = data && data.movies && data.movies[movie]
      // movieData.canonicalTitle
      const csfdMovie =
        data &&
        data.movieCsfdData &&
        data.movieCsfdData[movieData.canonicalTitle]

      const image =
        (movieData && movieData.image) ||
        (csfdMovie && csfdMovie.image) ||
        (movieData && movieData.imageAlt)

      return {
        image,
        movie: movieData,
        screeningSelected: id === selectedScreening,
        hoveredElement:
          hoveredScreeningElement &&
          hoveredScreeningElement.screeningId === id &&
          hoveredScreeningElement.element,
      }
    },
    {
      setSelectedScreening,
      setHoveredScreeningElement,
      goTo,
      replace,
    },
  ),
  withHandlers(() => {
    let ref = undefined
    let screeningJustSelected = false
    return {
      setRef: () => _ref => {
        ref = _ref
      },
      onScreeningClick: ({
        screeningSelected,
        screening: { id },
        setSelectedScreening,
        goTo,
        replace,
      }) => () => {
        const encodedId = encodeId(id)
        goTo({
          pathname: createScreeningDetailRoute({ screeningId: encodedId }),
          state: JSON.stringify({
            routeKey: ROUTE_STACK.SCREENING_DETAIL,
            screeningId: encodedId,
          }),
        })
        // screeningJustSelected = true
        // setSelectedScreening(screeningSelected ? null : id)
        // window.requestAnimationFrame(() => {
        //   setTimeout(() => {
        //     window.requestAnimationFrame(() => {})
        //   }, 180)
        // })
      },
      scrollToTitle: () => () => {
        if (ref && screeningJustSelected) {
          const rect = ref.getBoundingClientRect()
          const topOffset = rect.top - 39 - 15 - 120

          const bodyOffsetTop = window.document.body.getBoundingClientRect().top

          const distance = Math.abs(topOffset - bodyOffsetTop)

          scrollTo(0, distance)

          // window.requestAnimationFrame(() => {
          setTimeout(() => {
            if (ref) {
              const rect = ref.getBoundingClientRect()
              const topOffset = rect.top - 39 - 15 - 120

              const bodyOffsetTop = window.document.body.getBoundingClientRect()
                .top

              const distance = Math.abs(topOffset - bodyOffsetTop)
              scrollTo(0, distance)
            }
          }, 0)
          // })
        }
      },
      onCinemaClick: ({
        hoveredElement,
        setHoveredScreeningElement,
        screening: { id },
      }) => e => {
        if (matchMedia('(pointer: coarse)').matches) {
          if (hoveredElement !== `cinema_${id}`) {
            e.stopPropagation()
            setHoveredScreeningElement({
              screeningId: id,
              element: `cinema_${id}`,
            })
          }
        }
      },
      onHallClick: ({
        hoveredElement,
        setHoveredScreeningElement,
        screening: { id },
      }) => e => {
        if (matchMedia('(pointer: coarse)').matches) {
          if (hoveredElement !== `hall_${id}`) {
            e.stopPropagation()
            setHoveredScreeningElement({
              screeningId: id,
              element: `hall_${id}`,
            })
          }
        }
      },
    }
  }),
  withProps(({ screening: { tags, hall } }) => ({
    hall: hall || getHallName(tags),
  })),
)(Screening)
