import React from 'react'
import { pure } from 'recompose'
import { THEME_COLOR } from '../constants/colors'

const Subtitles = pure(() => (
  <svg width="24.212" height="21.522" viewBox="0 0 24.212 21.522">
    <g transform="translate(0 -25.5)">
      <g transform="translate(0 25.5)">
        <path
          fill="#484848"
          d="M21.522,25.5H2.69A2.7,2.7,0,0,0,0,28.19V44.332a2.7,2.7,0,0,0,2.69,2.69H21.522a2.7,2.7,0,0,0,2.69-2.69V28.19A2.7,2.7,0,0,0,21.522,25.5ZM10.761,34.916H8.743v-.673H6.053v4.035h2.69v-.673h2.018v1.345A1.271,1.271,0,0,1,9.416,40.3H5.381a1.271,1.271,0,0,1-1.345-1.345V33.571a1.271,1.271,0,0,1,1.345-1.345H9.416a1.271,1.271,0,0,1,1.345,1.345Zm9.416,0H18.159v-.673h-2.69v4.035h2.69v-.673h2.018v1.345A1.271,1.271,0,0,1,18.832,40.3H14.8a1.271,1.271,0,0,1-1.345-1.345V33.571A1.271,1.271,0,0,1,14.8,32.226h4.035a1.271,1.271,0,0,1,1.345,1.345Z"
          transform="translate(0 -25.5)"
        />
      </g>
    </g>
  </svg>
))

export default Subtitles
