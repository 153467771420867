import React, { useMemo } from 'react'
import styled from '@emotion/styled/macro'

import { SECONDARY_FONT } from '../constants/CSS'
import { PRIMARY_BACKGROUND_COLOR, THEME_COLOR } from '../constants/colors'
import { useSelector } from 'react-redux'
import {
  filter,
  head,
  isEmpty,
  keys,
  length,
  map,
  path,
  pipe,
  values,
} from 'ramda'
import { lookup } from '../utils'
import { CINEMA_NAMES } from '../constants/cinemas'

const StyledFiltersDesktopFixedContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 10000;
`

const StyledFiltersDesktopContainer = styled.div`
  width: min(1100px, calc(100% - 40px));
  height: 70px;
  background: ${PRIMARY_BACKGROUND_COLOR};
  display: flex;
  justify-content: center;
  padding: 0 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 16%);
`

const StyledFilterItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  overflow: hidden;
`

const StyledFilterItemWhere = styled(StyledFilterItem)`
  flex: 1.2;
  justify-content: flex-end;
  max-width: 340px;
`

const StyledFilterItemWhat = styled(StyledFilterItem)`
  flex: 1.1;
  justify-content: center;
  max-width: 340px;
`

const StyledFilterItemWhen = styled(StyledFilterItem)`
  flex: 0.9;
  justify-content: flex-start;
  max-width: 270px;
`

const StyledFilterName = styled.div`
  color: #3c3c3c;
  font-family: ${SECONDARY_FONT};
  font-weight: 900;
  padding-right: 10px;
  font-size: 16px;
  user-select: none;
  white-space: nowrap;
`

const StyledFilterOption = styled.div`
  font-family: ${SECONDARY_FONT};
  color: ${THEME_COLOR};
  font-weight: 600;
  padding-right: 15px;
  font-size: 16px;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
`

const StyledFilterButton = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  background: ${THEME_COLOR};
  font-family: ${SECONDARY_FONT};
  font-weight: 800;
  font-size: 14px;
  color: ${PRIMARY_BACKGROUND_COLOR};
  user-select: none;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
  }
`

const useFilterOption = cinemas => {
  return useMemo(
    () => {
      const cinemaNames = pipe(
        filter(Boolean),
        keys,
        map(lookup(CINEMA_NAMES)),
        values,
      )(cinemas)

      switch (length(cinemaNames)) {
        case 0:
          return 'Kamkoliv v Praze'
        case 1:
          return head(cinemaNames)
        default:
          return length(cinemaNames) >= 5
            ? `vybraných ${length(cinemaNames)} kin`
            : `vybraná ${length(cinemaNames)} kina`
      }
    },
    [cinemas],
  )
}

const FilterWhere = () => {
  const filterCinemas = useSelector(path(['filter', 'where']))
  const filterOption = useFilterOption(filterCinemas)

  return (
    <StyledFilterItemWhere>
      <StyledFilterName>Kam?</StyledFilterName>
      <StyledFilterOption>{filterOption}</StyledFilterOption>
      <StyledFilterButton>vybrat kino</StyledFilterButton>
    </StyledFilterItemWhere>
  )
}

export const FiltersDesktop = () => {
  return (
    <StyledFiltersDesktopFixedContainer>
      <StyledFiltersDesktopContainer>
        <FilterWhere />
        <StyledFilterItemWhat>
          <StyledFilterName>Na co?</StyledFilterName>
          <StyledFilterOption>Na jakýkoliv film</StyledFilterOption>
          <StyledFilterButton>vybrat film</StyledFilterButton>
        </StyledFilterItemWhat>
        <StyledFilterItemWhen>
          <StyledFilterName>Kdy?</StyledFilterName>
          <StyledFilterOption>Kdykoliv</StyledFilterOption>
          <StyledFilterButton>vybrat den</StyledFilterButton>
        </StyledFilterItemWhen>
      </StyledFiltersDesktopContainer>
    </StyledFiltersDesktopFixedContainer>
  )
}
