import { connect as redux } from 'react-redux'
import when from 'ramda/src/when'
import map from 'ramda/src/map'

const connect = (connector, ...args) => {
  return redux(
    when(Boolean, defs => {
      return (state, props) => {
        return map(selector => selector(state, props), defs)
      }
    })(connector),
    ...args,
  )
}

export default connect
