import createAction from '../utils/createAction'
import * as ActionTypes from '../constants/ActionTypes'

export const setReturnToTopButtonVisibility = visible =>
  createAction(ActionTypes.SET_RETURN_TO_TOP_VISIBILITY, {
    visible,
  })

export const setFilterMenuVisibility = visible =>
  createAction(ActionTypes.SET_FILTER_MENU_VISIBILITY, {
    visible,
  })

export const setScreeningsToShowCount = count =>
  createAction(ActionTypes.SET_SCREENINGS_TO_SHOW_COUNT, {
    count,
  })

export const setTrailerFullscreen = trailerFullscreen =>
  createAction(ActionTypes.SET_TRAILER_FULLSCREEN, {
    trailerFullscreen,
  })
