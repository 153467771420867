import { pipe } from 'ramda'

export const durationToTime = duration => ({
  hour: Math.floor(duration / 60),
  minute: duration % 60,
})

export const getHourText = hour => {
  switch (hour) {
    case 0:
      return 'hodin'
    case 1:
      return 'hodina'
    case 2:
    case 3:
    case 4:
      return 'hodiny'
    default:
      return 'hodin'
  }
}

export const getMinuteText = hour => {
  switch (hour) {
    case 0:
      return 'minut'
    case 1:
      return 'minuta'
    case 2:
    case 3:
    case 4:
      return 'minuty'
    default:
      return 'minut'
  }
}

export const getFormattedMovieTime = ({ hour, minute }) =>
  `${hour === 0 ? '' : `${hour} ${getHourText(hour)}`}${
    hour > 0 && minute > 0 ? ' ' : ''
  }${minute === 0 ? '' : `${minute} ${getMinuteText(minute)}`}`

export const getFormattedMovieDuration = pipe(
  durationToTime,
  getFormattedMovieTime,
)
