import { fromPairs, length, repeat, zip } from 'ramda'

import action from '../images/storiesGenreIcons/action.svg'
import adventurous from '../images/storiesGenreIcons/adventurous.svg'
import animated from '../images/storiesGenreIcons/animated.svg'
import biography from '../images/storiesGenreIcons/biography.svg'
import classics from '../images/storiesGenreIcons/classics.svg'
import comedy from '../images/storiesGenreIcons/comedy.svg'
import documentary from '../images/storiesGenreIcons/documentary.svg'
import drama from '../images/storiesGenreIcons/drama.svg'
import fairytale from '../images/storiesGenreIcons/fairytale.svg'
import family from '../images/storiesGenreIcons/family.svg'
import fantasy from '../images/storiesGenreIcons/fantasy.svg'
import historical from '../images/storiesGenreIcons/historical.svg'
import horror from '../images/storiesGenreIcons/horror.svg'
import music from '../images/storiesGenreIcons/music.svg'
import musical from '../images/storiesGenreIcons/musical.svg'
import romantic from '../images/storiesGenreIcons/romantic.svg'
import scifi from '../images/storiesGenreIcons/scifi.svg'
import thriller from '../images/storiesGenreIcons/thriller.svg'
import toprated from '../images/storiesGenreIcons/toprated.svg'
import western from '../images/storiesGenreIcons/western.svg'

export const GENRES = [
  'Komedie',
  'Akční',
  'Romantický',
  'Drama',
  'Fantasy',
  'Sci-Fi',
  'Dobrodružný',
  'Rodinný',
  'Thriller',
  'Dokumentární',
  'Životopisný',
  'Historický',
  'Horor',
  'Muzikál',
  'Western',
  'Hudební',
  'Pohádka',
  'Animovaný',
]

export const GENRES_OBJ = fromPairs(zip(GENRES, repeat(true, length(GENRES))))

export const TOP_RATED = 'Top rated'
export const CLASSICS = 'Klasiky'

export const storiesGenreOptions = {
  ['Komedie']: {
    icon: comedy,
    top: 0,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#654C8E',
    storyBackgroundColor: '#443261',
  },
  ['Akční']: {
    icon: action,
    top: 0,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#000',
    storyBackgroundColor: '#111',
  },
  ['Romantický']: {
    icon: romantic,
    top: 0,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#B499AB',
    storyBackgroundColor: '#543f4d',
  },
  ['Drama']: {
    icon: drama,
    top: 0,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#5D435C',
    storyBackgroundColor: '#463045',
  },
  ['Fantasy']: {
    icon: fantasy,
    top: 9,
    left: -1,
    scale: 1,
    bubbleBackgroundColor: '#392E58',
    storyBackgroundColor: '#292140',
  },
  ['Sci-Fi']: {
    icon: scifi,
    top: 0,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#154040',
    storyBackgroundColor: '#0e2d2d',
  },
  ['Dobrodružný']: {
    icon: adventurous,
    top: -2,
    left: 1,
    scale: 1,
    bubbleBackgroundColor: '#554227',
    storyBackgroundColor: '#3e2f1b',
  },
  ['Rodinný']: {
    icon: family,
    top: 2,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#95C989',
    storyBackgroundColor: '#374a33',
  },
  ['Thriller']: {
    icon: thriller,
    top: 0,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#343662',
    storyBackgroundColor: '#20213c',
  },
  ['Dokumentární']: {
    icon: documentary,
    top: 0,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#5D4949',
    storyBackgroundColor: '#4a3939',
  },
  ['Životopisný']: {
    icon: biography,
    top: 0,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#45331C',
    storyBackgroundColor: '#332615',
  },
  ['Historický']: {
    icon: historical,
    top: 0,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#333C30',
    storyBackgroundColor: '#293126',
  },
  ['Horor']: {
    icon: horror,
    top: 0,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#894D4D',
    storyBackgroundColor: '#402020',
  },
  ['Muzikál']: {
    icon: musical,
    top: 0,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#3F3371',
    storyBackgroundColor: '#271f48',
  },
  ['Western']: {
    icon: western,
    top: 0,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#4B2D17',
    storyBackgroundColor: '#3a2211',
  },
  ['Hudební']: {
    icon: music,
    top: 0,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#DBDAC6',
    storyBackgroundColor: '#371437',
  },
  ['Pohádka']: {
    icon: fairytale,
    top: -1,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#248DAD',
    storyBackgroundColor: '#114454',
  },
  ['Animovaný']: {
    icon: animated,
    top: 0,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#AD9990',
    storyBackgroundColor: '#42342e',
  },
  ['Top rated']: {
    icon: toprated,
    top: 0,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#000',
    storyBackgroundColor: '#333',
  },
  ['Klasiky']: {
    icon: classics,
    top: 0,
    left: 0,
    scale: 1,
    bubbleBackgroundColor: '#fff',
    storyBackgroundColor: '#333',
  },
}
