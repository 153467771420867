import typeToReducer from 'type-to-reducer'
import * as ActionTypes from '../constants/ActionTypes'

const initialState = null

export default typeToReducer(
  {
    [ActionTypes.SET_HOVERED_SCREENING_ELEMENT]: (
      _,
      { payload: { screeningId, element } },
    ) => ({ screeningId, element }),
    [ActionTypes.UNSET_HOVERED_SCREENING_ELEMENT]: () => null,
  },
  initialState,
)
