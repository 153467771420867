import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled/macro'
import { keyframes } from '@emotion/core'
import { MOUSE_DEVICE, SECONDARY_FONT } from '../../constants/CSS'
import { PRIMARY_BACKGROUND_COLOR } from '../../constants/colors'
import {
  head,
  isEmpty,
  length,
  map,
  pick,
  prop,
  sortBy,
  take,
  values,
} from 'ramda'
import { storiesGenresIcons } from '../../components/storiesGenreIcons'
import { CameraIcon } from '../../components/CameraIcon'
import { CloseCross } from '../../components/CloseCross'
import { MuteIcon } from '../../components/MuteIcon'
import { SwipeUpIcon } from '../../components/SwipeUpIcon'
import { useSelector } from 'react-redux'
import getDatesDayDifference from '../../utils/getDatesDayDifference'
import getDayDifferenceText from '../../utils/getDayDifferenceText'
import dateToTime from '../../utils/dateToTime'
import getTimeText from '../../utils/getTimeText'
import {
  SHORT_CINEMA_NAMES,
  SHORT_CINEMA_NAMES_WHERE,
} from '../../constants/cinemas'
import { StoriesCategoryGenreBubble } from '../../components/StoriesCategoryGenreBubble'
import { storiesGenreOptions } from '../../constants/storiesGenres'
import YouTube from 'react-youtube'
import useHandler from '../../hooks/useHandler'
import {
  AVERAGE_TRAILER_DURATION,
  DEFAULT_STORY_TIME,
  PRE_VIDEO_START_DURATION,
} from '../../constants/storyTiming'

const storyRelativeSize = normalPx =>
  `calc(${normalPx / 400} * min(59vh, 100vw))`

const StyledStory = styled.div`
  width: 100%;
  padding: ${storyRelativeSize(15)} ${storyRelativeSize(12)}
    ${storyRelativeSize(15)};
  display: flex;
  flex-direction: column;
  align-items: center;
  //background: #504444;
  background: #51405d;
  position: relative;
  background: ${prop('storyBackgroundColor')};
`

const StyledStoryTitle = styled.div`
  padding-top: ${storyRelativeSize(55)};
  font-family: ${SECONDARY_FONT};
  font-weight: 900;
  font-size: ${storyRelativeSize(24)};
  text-align: center;
  color: ${PRIMARY_BACKGROUND_COLOR};
`

const StyledStoryDirector = styled.div`
  padding-top: 10px;
  font-family: ${SECONDARY_FONT};
  font-weight: 600;
  font-size: ${storyRelativeSize(16)};
  text-align: center;
  color: #bfbfbf;
`

const StyledStoryCountry = styled.div`
  padding-top: ${storyRelativeSize(15)};
  font-family: ${SECONDARY_FONT};
  font-weight: 900;
  font-size: ${storyRelativeSize(18)};
  text-align: center;
  color: ${PRIMARY_BACKGROUND_COLOR};
`

const StyledGenresContainer = styled.div`
  display: flex;
  position: absolute;
  top: ${storyRelativeSize(6)};
  left: ${storyRelativeSize(6)};
  padding: ${storyRelativeSize(3)} ${storyRelativeSize(8)};
  border-radius: 4px;
  font-family: ${SECONDARY_FONT};
  font-weight: 900;
  font-size: ${storyRelativeSize(15)};
  line-height: ${storyRelativeSize(20)};
  text-align: left;
  // color: ${PRIMARY_BACKGROUND_COLOR};
  color: #444;
  //box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 16%);
  box-shadow: 0px 0px 6px 0px rgb(255 255 255 / 16%);
  //background: rgb(52 52 52 / 0.9);
  background: rgb(255 255 255 / 0.9);
`

const StyledTrailerCountdown = styled.div`
  display: flex;
  position: absolute;
  bottom: ${storyRelativeSize(6)};
  left: ${storyRelativeSize(6)};
  padding: ${storyRelativeSize(2)} ${storyRelativeSize(6)};
  border-radius: 4px;
  font-family: ${SECONDARY_FONT};
  font-weight: 900;
  font-size: ${storyRelativeSize(16)};
  line-height: ${storyRelativeSize(20)};
  text-align: left;
  // color: ${PRIMARY_BACKGROUND_COLOR};
  //box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 16%);
  //background: rgb(52 52 52 / 0.9);

  //color: rgb(237 228 5);
  //box-shadow: 0px 0px 6px 0px rgb(237 228 5 / 50%);
  //background: rgb(0 0 0 / 0.9);

  color: rgb(0 0 0);
  box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 50%);
  background: rgb(237 228 5 / 0.9);


  visibility: hidden;
`

const StyledStoryRating = styled.div`
  font-family: ${SECONDARY_FONT};
  font-weight: 900;
  font-size: ${storyRelativeSize(16)};
  text-align: right;
  color: ${PRIMARY_BACKGROUND_COLOR};
  position: absolute;
  right: ${storyRelativeSize(8)};
  bottom: ${storyRelativeSize(8)};
  text-shadow: 0px 0px 6px rgb(0 0 0);
  box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 16%);
`

const StyledVideoWrapper = styled.div`
  opacity: 0;
`

const StyledVideoContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  background: #111 url(${prop('image')});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
  max-width: 100%;
  //padding-bottom: calc(67% * 1);
  margin-top: ${storyRelativeSize(25)};
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  border-radius: 4px;

  & iframe {
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const StyledGenreContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 10px;
  display: flex;
  align-items: center;
  transition: opacity 200ms ease-in-out;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
`

const StyledGenreCircle = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid #d3d3d3;
  border-radius: 50px;
  background: #484848;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  & svg {
    //position: relative;
    //top: -20px;
    transform: scale(0.5) translate(1px, -2px);
  }
`

const StyledGenreTitle = styled.div`
  font-family: ${SECONDARY_FONT};
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  color: ${PRIMARY_BACKGROUND_COLOR};
  padding-left: 8px;
  padding-top: 3px;
`

const StyledCloseButton = styled.div`
  position: absolute;
  right: 13px;
  top: 20px;
  padding: 10px;
  transition: opacity 200ms ease-in-out;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
`

const pulse = keyframes`
  from, 0%, 30%, 50%, 70%, 100%, to {
    transform: scale(1.0);
  }

  40%, 60% {
    transform: scale(1.2);
  }
`

const StyledUnmuteButton = styled.div`
  position: absolute;
  right: 55px;
  top: 18px;
  padding: 10px;
  padding-right: 5px;
  transition: opacity 200ms ease-in-out;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${pulse} 2s ease infinite;
  visibility: hidden;
`

const StyledPremiere = styled.div`
  font-family: ${SECONDARY_FONT};
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: ${PRIMARY_BACKGROUND_COLOR};
  padding-left: 8px;
  padding-top: 3px;
  color: #bfbebe;
`

const StyledStoryScreenings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${storyRelativeSize(30)};
`

const StyledStoryScreeningsTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${storyRelativeSize(10)};

  svg {
    width: ${storyRelativeSize(22)};
    height: ${storyRelativeSize(19)};
    position: relative;
    bottom: ${storyRelativeSize(3)};
  }
`

const StyledStoryScreeningsTitle = styled.div`
  font-family: ${SECONDARY_FONT};
  font-weight: 900;
  font-size: ${storyRelativeSize(16)};
  text-align: center;
  color: ${PRIMARY_BACKGROUND_COLOR};
  text-transform: uppercase;
  padding-right: ${storyRelativeSize(10)};
`

const StyledSwipeUp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: ${storyRelativeSize(0)};
  left: 0;
  right: 0;
  transition: opacity 200ms ease-in-out;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
`

const BOUNCE_DISTANCE = 15

const bounce = keyframes`
  from, 0%, 25%, 55%, 100%, to {
    transform: translate3d(0,0,0);
  }

  40% {
    transform: translate3d(0, -${BOUNCE_DISTANCE * 0.8}px, 0);
  }
`

const StyledSwipeUpIconContainer = styled.div`
  position: relative;
  animation: ${bounce} 2s ease infinite;

  svg {
    position: absolute;
    width: ${storyRelativeSize(21)};
    height: ${storyRelativeSize(13)};

    &:last-child {
      position: relative;
      bottom: 15px;
    }
  }
`

const StyledSwipeUpText = styled.div`
  font-family: ${SECONDARY_FONT};
  font-weight: 700;
  font-size: ${storyRelativeSize(17)};
  text-align: center;
  color: ${PRIMARY_BACKGROUND_COLOR};
  padding-up: ${storyRelativeSize(10)};
  padding-bottom: ${storyRelativeSize(10)};
`

const StyledStoryScreeningDetail = styled.div`
  padding-top: ${storyRelativeSize(5)};
  font-family: ${SECONDARY_FONT};
  font-weight: 700;
  font-size: ${storyRelativeSize(16)};
  text-align: center;
  color: #ddd;

  &::first-letter {
    text-transform: uppercase;
  }
`

const StyledStoryScreeningDetailOthers = styled(StyledStoryScreeningDetail)`
  padding-top: ${storyRelativeSize(5)};
  &::first-letter {
    text-transform: none;
  }
`

const getCountdownText = ({ msRemaining }) => {
  const count = Math.ceil(msRemaining / 1000)
  return `Trailer za ${count}`
}

const useMovieScreenings = csfdMovie => {
  const movieLinks = useSelector(state => state.data.movieLinks)
  const movies = useSelector(state => state.data.movies)
  const screeningsObj = useSelector(state => state.data.screeningsObj)
  const data = useMemo(
    () => {
      const movie = movies[movieLinks[csfdMovie.canonicalTitle][0]]
      const screenings = pick(movie.screenings, screeningsObj)
      const screeningsCount = length(values(screenings))

      const sortedScreenings = sortBy(prop('date'), values(screenings))
      const selectedScreenings = take(
        screeningsCount <= 3 ? 3 : 2,
        sortedScreenings || [],
      )

      return { selectedScreenings, screeningsCount }
    },
    [movieLinks],
  )

  return data
}

const isIos = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

const MUTE = isIos()

const TRANSITION_DURATION = '200ms'

const useVideoStartHandler = ({
  videoStartedRef,
  videoWrapperRef,
  videoImageContainerRef,
  unmuteButtonRef,
  playerRef,
  trailerCountdownRef,
}) => {
  const handleStoryStart = useHandler(() => {
    let frameId
    let elapsedTime
    let elapsedTimeBeforePause = 0
    let startTime

    if (trailerCountdownRef.current) {
      trailerCountdownRef.current.style.visibility = 'visible'
    }

    const handleVideoStart = () => {
      videoStartedRef.current = true

      if (videoWrapperRef.current) {
        videoWrapperRef.current.style.transition = `opacity ${TRANSITION_DURATION} ease-out`
        videoWrapperRef.current.style.opacity = 1
      }
      if (videoImageContainerRef.current) {
        videoImageContainerRef.current.style.transition = `background 0s linear ${TRANSITION_DURATION}`
        videoImageContainerRef.current.style.background = 'transparent'
      }
      if (trailerCountdownRef.current) {
        trailerCountdownRef.current.style.visibility = 'hidden'
      }
      if (unmuteButtonRef.current && MUTE) {
        unmuteButtonRef.current.style.visibility = 'visible'
      }

      window.requestAnimationFrame(() => {
        try {
          if (playerRef.current) {
            playerRef.current.playVideo()
          }
        } catch (e) {}
      })
    }

    const step = timestamp => {
      if (!startTime) {
        startTime = timestamp
      }
      const elapsed = timestamp - startTime
      elapsedTime = elapsedTimeBeforePause + elapsed

      if (elapsedTime < PRE_VIDEO_START_DURATION) {
        if (trailerCountdownRef.current) {
          trailerCountdownRef.current.innerHTML = getCountdownText({
            msRemaining: PRE_VIDEO_START_DURATION - elapsedTime,
          })
        }

        frameId = window.requestAnimationFrame(step)
      } else {
        window.cancelAnimationFrame(frameId)

        handleVideoStart()
      }
    }

    frameId = window.requestAnimationFrame(step)

    const pauseProgress = () => {
      elapsedTimeBeforePause = elapsedTime
      startTime = undefined
      window.cancelAnimationFrame(frameId)

      if (videoStartedRef.current) {
        try {
          if (playerRef.current) {
            playerRef.current.pauseVideo()
          }

          // if (videoWrapperRef.current) {
          //   videoWrapperRef.current.style.transition = `opacity ${TRANSITION_DURATION} ease-out`
          //   videoWrapperRef.current.style.opacity = 0
          // }
          // if (videoImageContainerRef.current) {
          //   videoImageContainerRef.current.style.transition = `background ${TRANSITION_DURATION} linear`
          //   videoImageContainerRef.current.style.background = ''
          // }
        } catch (e) {}
      } else {
        if (trailerCountdownRef.current) {
          trailerCountdownRef.current.style.visibility = 'hidden'
        }
      }
    }

    const continueProgress = () => {
      if (videoStartedRef.current) {
        try {
          if (playerRef.current) {
            playerRef.current.playVideo()
          }

          // if (videoWrapperRef.current) {
          //   videoWrapperRef.current.style.transition = `opacity ${TRANSITION_DURATION} ease-out`
          //   videoWrapperRef.current.style.opacity = 1
          // }
          // if (videoImageContainerRef.current) {
          //   videoImageContainerRef.current.style.transition = `background 0s linear ${TRANSITION_DURATION}`
          //   videoImageContainerRef.current.style.background = 'transparent'
          // }
        } catch (e) {}
      } else {
        if (trailerCountdownRef.current) {
          trailerCountdownRef.current.style.visibility = 'visible'
        }
        frameId = window.requestAnimationFrame(step)
      }
    }

    return {
      pauseProgress,
      continueProgress,
    }
  })

  return handleStoryStart
}

const noop = () => {}

export const CategoryStory = ({
  movie,
  paused,
  handleClose,
  isActive,
  handleDurationChange,
  currentIndex,
  videoHandlers,
}) => {
  useEffect(() => {
    if (handleDurationChange && isActive) {
      handleDurationChange(
        movie.youtube
          ? PRE_VIDEO_START_DURATION + AVERAGE_TRAILER_DURATION
          : DEFAULT_STORY_TIME,
        { currentIndex },
      )

      videoHandlers.current = {
        pauseProgress: noop,
        continueProgress: noop,
      }
    }
  }, [])

  const { selectedScreenings, screeningsCount } = useMovieScreenings(movie)
  const playerRef = useRef(null)
  const videoWrapperRef = useRef(null)
  const unmuteButtonRef = useRef(null)
  const videoImageContainerRef = useRef(null)
  const videoStartedRef = useRef(false)
  const trailerCountdownRef = useRef(null)

  const handleVideoStart = useVideoStartHandler({
    videoStartedRef,
    videoWrapperRef,
    videoImageContainerRef,
    unmuteButtonRef,
    playerRef,
    trailerCountdownRef,
  })

  // const [countDownTrailer, setCountDownTrailer] = useState(3)
  //
  // const handleCountDownTick = useHandler(() => {
  //   const newCountDownTrailer =
  //   setCountDownTrailer()
  // })

  const onReady = useHandler(e => {
    try {
      if (isActive && movie.youtube) {
        playerRef.current = e && e.target
        if (playerRef.current) {
          const duration = playerRef.current.getDuration()
          if (duration) {
            handleDurationChange(PRE_VIDEO_START_DURATION + duration * 1000, {
              currentIndex,
            })
          }

          videoHandlers.current = handleVideoStart()
        }
      }
    } catch (e) {}
  })

  const onUnmuteClick = useHandler(() => {
    if (playerRef.current) {
      if (unmuteButtonRef.current && MUTE) {
        unmuteButtonRef.current.style.visibility = 'hidden'
      }

      playerRef.current.unMute()
    }
  })

  const { storyBackgroundColor } = storiesGenreOptions[movie.genreSingle] || {}

  return (
    <>
      <StyledStory {...{ storyBackgroundColor }}>
        <StyledGenreContainer {...{ hidden: paused }}>
          <StoriesCategoryGenreBubble
            {...{
              genre: movie.genreSingle,
              noBorder: true,
              scale: 0.5,
              biggerIcon: true,
            }}
          />
          <StyledGenreTitle>{movie.genreSingle}</StyledGenreTitle>
          {movie.premiere && (
            <StyledPremiere>
              premiéra: {movie.premiere.getDate()}.
              {movie.premiere.getMonth() + 1}.{movie.premiere.getFullYear()}
            </StyledPremiere>
          )}
        </StyledGenreContainer>
        <StyledUnmuteButton
          {...{ hidden: paused, onClick: onUnmuteClick, ref: unmuteButtonRef }}
        >
          <MuteIcon />
        </StyledUnmuteButton>
        <StyledCloseButton {...{ hidden: paused, onClick: handleClose }}>
          <CloseCross />
        </StyledCloseButton>
        <StyledStoryTitle>{movie.title}</StyledStoryTitle>
        {!isEmpty(movie.directors) && (
          <StyledStoryDirector>
            {head(movie.directors).name}
          </StyledStoryDirector>
        )}
        {!isEmpty(movie.shotPlaces) && (
          <StyledStoryCountry>{movie.shotPlaces.join(', ')}</StyledStoryCountry>
        )}
        {!isEmpty(movie.images) && (
          <StyledVideoContainer
            {...{ image: movie.images[0], ref: videoImageContainerRef }}
          >
            {movie.youtube && (
              <StyledVideoWrapper {...{ ref: videoWrapperRef }}>
                <YouTube
                  videoId={movie.youtube}
                  opts={{
                    playerVars: {
                      rel: 0,
                      // autoplay: 1,
                      mute: MUTE ? 1 : 0,
                      // mute: 0,
                      frameBorder: 0,
                      controls: 0,
                      // controls: 1,
                      disablekb: 1,
                      modestbranding: 1,
                      allow:
                        'accelerometer; encrypted-media; gyroscope; picture-in-picture',
                      allowFullScreen: 0,
                      playsinline: 1,
                      showinfo: 0,
                      autohide: 1,
                    },
                  }}
                  onReady={onReady}
                />
              </StyledVideoWrapper>
            )}
            {movie.youtube && (
              <StyledTrailerCountdown {...{ ref: trailerCountdownRef }}>
                {getCountdownText({ msRemaining: PRE_VIDEO_START_DURATION })}
              </StyledTrailerCountdown>
            )}
            {!!movie.genre && (
              <StyledGenresContainer>
                {movie.genre.join(', ')}
              </StyledGenresContainer>
            )}
            {!!movie.score && (
              <StyledStoryRating>{movie.score}% na ČSFD</StyledStoryRating>
            )}
          </StyledVideoContainer>
        )}
        {screeningsCount > 0 && (
          <StyledStoryScreenings>
            <StyledStoryScreeningsTitleContainer>
              <StyledStoryScreeningsTitle>Promítání</StyledStoryScreeningsTitle>
              <CameraIcon />
            </StyledStoryScreeningsTitleContainer>
            {selectedScreenings.map((screening, i) => {
              const normalizedDate = new Date(
                screening.date.getFullYear(),
                screening.date.getMonth(),
                screening.date.getDate(),
              )
              const daysDifference = getDatesDayDifference(normalizedDate)
              const time = dateToTime(screening.date)

              return (
                <StyledStoryScreeningDetail {...{ key: i }}>
                  {getDayDifferenceText(daysDifference)} {getTimeText(time)}{' '}
                  {SHORT_CINEMA_NAMES_WHERE[screening.cinema]}
                </StyledStoryScreeningDetail>
              )
            })}
            {(() => {
              const otherScreeningsCount =
                screeningsCount - length(selectedScreenings)

              return otherScreeningsCount <= 0 ? null : (
                <StyledStoryScreeningDetailOthers>
                  a {otherScreeningsCount}{' '}
                  {otherScreeningsCount >= 5
                    ? 'dalších projekcí'
                    : 'další projekce'}
                </StyledStoryScreeningDetailOthers>
              )
            })()}
          </StyledStoryScreenings>
        )}
      </StyledStory>
      <StyledSwipeUp {...{ hidden: paused }}>
        <StyledSwipeUpIconContainer>
          <SwipeUpIcon />
          <SwipeUpIcon />
        </StyledSwipeUpIconContainer>
        <StyledSwipeUpText>zobrazit</StyledSwipeUpText>
      </StyledSwipeUp>
    </>
  )
}
