export const DAYS_OF_WEEK = {
  0: 'Ne',
  1: 'Po',
  2: 'Út',
  3: 'St',
  4: 'Čt',
  5: 'Pá',
  6: 'So',
}

export const MONDAY = 'Pondělí'
export const TUESDAY = 'Úterý'
export const WEDNESDAY = 'Středa'
export const THURSDAY = 'Čtvrtek'
export const FRIDAY = 'Pátek'
export const SATURDAY = 'Sobota'
export const SUNDAY = 'Neděle'

export const DAYS_OF_WEEK_FULL = {
  0: SUNDAY,
  1: MONDAY,
  2: TUESDAY,
  3: WEDNESDAY,
  4: THURSDAY,
  5: FRIDAY,
  6: SATURDAY,
}

export const DAYS_OF_WEEK_ORDERED = [
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
]
