import { lifecycle } from 'recompose'
import map from 'ramda/src/map'

const withLifecycle = spec => {
  const convertedSpec = map(lifecycleFn => {
    return function(...args) {
      lifecycleFn(this.props)(...args)
    }
  })(spec)

  return lifecycle(convertedSpec)
}

export default withLifecycle
