import React from 'react'
import styled from '@emotion/styled/macro'
import { LAYOUT_MAX_WIDTH } from '../constants/CSS'
import {
  compose,
  pure,
  renderNothing,
  withHandlers,
  withProps,
  withState,
} from 'recompose'
import withScreenWidth from '../hoc/withScreenWidth'
import { path, prop } from 'ramda'
import { SCREENINGS_TOP_OFFSET } from '../constants/layout'
import { withPropsOnChange, branch } from 'recompose'
import withRenderOnProp from '../hoc/withRenderOnProp'
import { connect } from 'react-redux-hoc'
import {
  deriveOtherMovies,
  deriveOtherScreenings,
  getMovies,
  getScreening,
  getScreeningCsfdLink,
  getScreeningCsfdMovie,
  getScreeningHall,
  getScreeningImage,
  getScreeningMovie,
  getScreeningMovieId,
  getScreeningsObj,
  isDataLoaded,
} from '../selectors/data'
import withDerivedProps from '@surglogs/with-derived-props'
import ScreeningHoverDetailLeft from './ScreeningHoverDetailLeft'
import withLifecycle from '../hoc/withLifecycle'
import { CustomEvents } from '../constants/events'
import logProps from '../devTools/logProps'
import ScreeningHoverDetailRight from './ScreeningHoverDetailRight'

const StyledLeftHoverDetail = styled.div`
  position: fixed;
  top: ${SCREENINGS_TOP_OFFSET + 60}px;
  bottom: 0px;
  left: 0px;
  right: calc(50vw + ${LAYOUT_MAX_WIDTH / 2}px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledRightHoverDetail = styled(StyledLeftHoverDetail)`
  right: 0px;
  left: calc(50vw + ${LAYOUT_MAX_WIDTH / 2}px);
`

const ScreeningHoverDetail = ({
  screening,
  hall,
  movie,
  otherMovies,
  csfdMovie,
}) => {
  const { cinema } = screening
  return (
    <>
      <StyledLeftHoverDetail>
        <ScreeningHoverDetailLeft {...{ cinema, hall }} />
      </StyledLeftHoverDetail>
      <StyledRightHoverDetail>
        <ScreeningHoverDetailRight {...{ movie, otherMovies, csfdMovie }} />
      </StyledRightHoverDetail>
    </>
  )
}

export default compose(
  withState('id', 'setId', undefined),
  withHandlers(() => {
    let removeIdTimeout = undefined
    return {
      handleHoveredScreening: ({ setId }) => e => {
        const newId = path(['detail', 'id'], e)

        if (newId) {
          clearTimeout(removeIdTimeout)
          setId(newId)
        } else {
          removeIdTimeout = setTimeout(() => {
            setId(undefined)
          }, 300)
        }
      },
    }
  }),
  withLifecycle({
    componentDidMount: ({ handleHoveredScreening }) => () => {
      window.addEventListener(
        CustomEvents.hoveredScreeningEvent,
        handleHoveredScreening,
      )
    },
    componentWillUnmount: ({ handleHoveredScreening }) => () => {
      window.removeEventListener(
        CustomEvents.hoveredScreeningEvent,
        handleHoveredScreening,
      )
    },
  }),
  withScreenWidth,
  withPropsOnChange(['screenWidth'], ({ screenWidth }) => ({
    hideScreeningDetail: screenWidth < 1200,
  })),
  branch(prop('hideScreeningDetail'), renderNothing),
  // logProps('P'),
  // withProps({
  //   id:
  //     'https://goout.net/cs/listky/vzpominky-na-italii/arfh/',
  // }),
  withRenderOnProp('id'),
  connect({
    isLoaded: isDataLoaded,
    screening: getScreening,
    movieId: getScreeningMovieId,
    movie: getScreeningMovie,
    csfdMovie: getScreeningCsfdMovie,
    csfdLink: getScreeningCsfdLink,
    image: getScreeningImage,
    screeningsObj: getScreeningsObj,
    movies: getMovies,
    hall: getScreeningHall,
  }),
  withRenderOnProp('isLoaded'),
  withDerivedProps(['movie', 'screeningsObj', 'id', 'movies'], {
    otherScreenings: deriveOtherScreenings,
    otherMovies: deriveOtherMovies,
  }),
  pure,
)(ScreeningHoverDetail)
