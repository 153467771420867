import React, { useMemo } from 'react'
import styled from '@emotion/styled/macro'
import { SECONDARY_FONT } from '../constants/CSS'
import { compose, pure } from 'recompose'
import { head } from 'ramda'
import { deriveMovieFallbackedData } from '../selectors/data'
import { PRIMARY_TEXT_COLOR } from '../constants/colors'
import ScreeningHoverDetailRightImage from './ScreeningHoverDetailRightImage'
import Genre from './Genre'
import Clock from './Clock'
import { getFormattedMovieDuration } from '../utils/movieDuration'
import Star from './Star'

const StyledRightHoverDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  margin-right: 20px;
`

const StyledMovieTitle = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 22px;
  color: ${PRIMARY_TEXT_COLOR};
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 900;
  margin-bottom: 14px;
`

const StyledMetaItem = styled.div`
  display: flex;
  align-items: center;
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  font-weight: 700;
  padding: 0px 0px 10px;
  line-height: 1.5;
  color: #484848;

  & span:first-letter {
    text-transform: capitalize;
  }

  & svg {
    margin-right: 10px;
    margin-bottom: 3px;

    transform: scale(0.8);
  }
`

const ScreeningHoverDetailRight = ({ movie, otherMovies, csfdMovie }) => {
  const { title, duration, genre, score, images } = useMemo(
    () =>
      deriveMovieFallbackedData({
        movie,
        otherMovies,
        csfdMovie,
      }),
    [movie, otherMovies, csfdMovie],
  )

  const image =
    (movie && movie.image) || head(images || []) || (movie && movie.imageAlt)

  return (
    <StyledRightHoverDetail>
      <StyledMovieTitle>{title}</StyledMovieTitle>
      {genre && (
        <StyledMetaItem>
          <Genre /> {genre}
        </StyledMetaItem>
      )}
      {duration && (
        <StyledMetaItem>
          <Clock />
          {duration} min
          {duration > 60 && ` (${getFormattedMovieDuration(duration)})`}
        </StyledMetaItem>
      )}
      {score && (
        <StyledMetaItem>
          <Star />
          {score}%
        </StyledMetaItem>
      )}
      {!!image && <ScreeningHoverDetailRightImage {...{ image }} />}
    </StyledRightHoverDetail>
  )
}

export default compose(pure)(ScreeningHoverDetailRight)
