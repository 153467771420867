import React from 'react'
import styled from '@emotion/styled/macro'
import { pure, compose, withHandlers, lifecycle } from 'recompose'
import { pipe, map } from 'ramda'
import Screening from './Screening'
import { MIN_600, SECONDARY_FONT } from '../constants/CSS'
import getDatesDayDifference from '../utils/getDatesDayDifference'
import getDayDifferenceText from '../utils/getDayDifferenceText'
import { THEME_COLOR } from '../constants/colors'
import { zindex } from '../constants/zIndexes'
import { SCREENINGS_TOP_OFFSET } from '../constants/layout'
import { MEDIA_DESKTOP } from '../constants/media'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
`

const DAY_MOBILE_PADDING = 6
const DAY_TABLET_PADDING = 10
const DAY_DESKTOP_PADDING = 0

const StyledDayContainer = styled.div`
  width: 100%;
  height: 39px;
  position: sticky;
  top: 67px;
  z-index: ${zindex.screeningDay};

  height: 54px;
  padding: 5px ${DAY_MOBILE_PADDING}px;

  ${MIN_600} {
    padding: 5px ${DAY_DESKTOP_PADDING}px;
  }

  ${MEDIA_DESKTOP} {
    top: ${SCREENINGS_TOP_OFFSET + 67}px;
  }
`

const StyledDay = styled.div`
  width: 100%;
  height: 39px;
  font-family: ${SECONDARY_FONT};
  font-size: 18px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px 8px;
  font-weight: 900;
  position: relative;
  z-index: ${zindex.screeningDay};
  /* transition: opacity 0.15s; */

  /* background-color: rgba(0, 0, 0, 0.8588235294117647); */
  background-color: ${THEME_COLOR};
  /* opacity: 0.9; */
  color: white;
  margin: 0 auto;
    /* color: #f9f7f4; */
  /* will-change: transform, opacity, position; */

  /* max-width: calc(100% - ${DAY_MOBILE_PADDING * 2}px); */

  ${MIN_600} {
    max-width: calc(800px - ${DAY_DESKTOP_PADDING * 2}px);
  }

  border-radius: 5px;
  padding: 8px 15px 8px;
  box-shadow: 0px 0px 6px #0000005C;
`

const StyledScreenings = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
`

const DayScreenings = ({
  date,
  dayScreenings,
  setRef,
  setWrapperRef,
  setContainerHeight,
}) => {
  const firstScreeningDate =
    (dayScreenings || [])[0] && (dayScreenings || [])[0].date
  const normalizedDate = new Date(
    firstScreeningDate.getFullYear(),
    firstScreeningDate.getMonth(),
    firstScreeningDate.getDate(),
  )
  const daysDifference = getDatesDayDifference(normalizedDate)
  return (
    <StyledContainer ref={setWrapperRef}>
      <StyledDayContainer>
        <StyledDay ref={setRef}>
          {date}
          <div>{getDayDifferenceText(daysDifference)}</div>
        </StyledDay>
      </StyledDayContainer>
      <StyledScreenings>
        {pipe(
          map(screening => (
            <Screening
              {...{
                key:
                  screening.buyLink || `${screening.date}${screening.cinema}`,
                screening,
                setContainerHeight,
                date,
              }}
            />
          )),
        )(dayScreenings)}
      </StyledScreenings>
    </StyledContainer>
  )
}

export default compose(
  pure,
  withHandlers(() => {
    let ref = undefined
    let wrapperRef = undefined
    return {
      setRef: () => _ref => {
        ref = _ref
      },
      setWrapperRef: () => _ref => {
        wrapperRef = _ref
      },
      onScroll: () => () => {
        if (wrapperRef && ref) {
          const rect = wrapperRef.getBoundingClientRect()
          const topOffset = rect.top

          const height = Math.abs(rect.top - rect.bottom)

          const topMargin = 5

          if (topOffset < -5 + topMargin) {
            // ref.style.position = 'fixed'
            const bottomOffset = rect.bottom - 39

            const maxBeginningMax = 240
            const maxBeginningMin = 180
            const maxBeginning = Math.min(
              Math.max(window.innerHeight * 0.2, maxBeginningMin),
              maxBeginningMax,
            )
            const beginning = Math.min(maxBeginning, height)
            const changeSpeed = Math.max(1.6 * (beginning / maxBeginning), 1.1)
            // if (bottomOffset < beginning) {
            //   // ref.style.opacity = 0
            //   ref.style.opacity = Math.max(
            //     0,
            //     1 -
            //       (changeSpeed * Math.abs(bottomOffset - beginning)) /
            //         (39 + beginning),
            //   )
            // } else {
            //   ref.style.opacity = 1
            // }
            // ref.style.maxWidth = window.matchMedia('(min-width: 835px)').matches
            //   ? `calc(800px - ${DAY_DESKTOP_PADDING * 2}px)`
            //   : window.matchMedia('(min-width: 600px)').matches
            //   ? `calc(100% - ${DAY_TABLET_PADDING * 2}px)`
            //   : `calc(100% - ${DAY_MOBILE_PADDING * 2}px)`
            // ref.style.top = `${topMargin}px`
          } else if (ref.style.position === 'fixed') {
            ref.style.opacity = 1
            // ref.style.maxWidth = 'initial'
            // ref.style.position = 'relative'
            // ref.style.top = `0px`
          }
        }
      },
    }
  }),
  lifecycle({
    componentDidMount() {
      const { onScroll } = this.props
      window.addEventListener('customScroll', onScroll)
    },
    componentWillUnmount() {
      const { onScroll } = this.props
      window.removeEventListener('customScroll', onScroll)
    },
  }),
)(DayScreenings)
