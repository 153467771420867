import React from 'react'
import ReactMarkdown from 'react-markdown'

export default description =>
  description
    .replace(/<br>/g, '')
    .replace(/&lt;\/p&gt;&lt;p&gt;/g, '\n')
    .replace(/&lt;p&gt;/g, '')
    .replace(/&lt;\/p&gt;/g, '')
    .split('\n')
    .map((s, i) => <ReactMarkdown key={i}>{s}</ReactMarkdown>)
