import React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled/macro'
import { compose, withState, withProps, withHandlers } from 'recompose'
import { map, prop, pipe, propEq, find, filter } from 'ramda'
import { SHOW_HIDE_FAST_DURATION } from '../constants/CSS'
import Where from './Where'
import When from './When'
import MoviesFilter from './MoviesFilter'
import ResetButton from './ResetButton'
import { setFilterMenuVisibility } from '../actions/ui'
import { setSavedFilter } from '../actions/savedFilter'
import { zindex } from '../constants/zIndexes'

const StyledContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: ${zindex.filterMenuBackground};
  will-change: opacity;
  @supports (-webkit-overflow-scrolling: touch) {
    background: rgba(34, 34, 34, 0.9333333333333333);
  }
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  ${({ visible }) =>
    visible
      ? `
    transition: opacity ${SHOW_HIDE_FAST_DURATION};
  `
      : `
    transition: opacity ${SHOW_HIDE_FAST_DURATION},
    visibility 0s ${SHOW_HIDE_FAST_DURATION};
  `}
`

const FilterMenuBackground = ({ visible }) => (
  <StyledContainer visible={visible} />
)

export default compose(
  connect(({ ui: { filterMenuVisible } }) => ({
    visible: filterMenuVisible,
  })),
)(FilterMenuBackground)
