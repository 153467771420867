import React from 'react'
import { pure } from 'recompose'

const BigHall = pure(({ scale = 1 }) => (
  <svg
    width="28.34"
    height="26.372"
    viewBox="0 0 28.34 26.372"
    transform={`scale(${scale})`}
  >
    <g transform="translate(-2.12 -2.749)">
      <path
        fill="#484848"
        stroke="#484848"
        d="M110.415,0H94.648a.927.927,0,0,0-.926.926v9.869a.927.927,0,0,0,.926.926h15.767a.927.927,0,0,0,.926-.926V.926A.927.927,0,0,0,110.415,0Zm.231,10.795a.232.232,0,0,1-.231.231H94.648a.232.232,0,0,1-.231-.231V.926a.231.231,0,0,1,.231-.231h15.767a.231.231,0,0,1,.231.231Zm0,0"
        transform="translate(-86.393 3.249)"
      />
      <g transform="translate(2.371 18.702)">
        <path
          fill="#484848"
          stroke="#484848"
          strokeWidth="0.5px"
          d="M214.617,262.566A2.569,2.569,0,0,0,212.051,260h-2.586a2.569,2.569,0,0,0-2.566,2.566v7.011a.592.592,0,0,0,.592.592h6.534a.592.592,0,0,0,.592-.592Zm-5.153-1.382h2.587a1.384,1.384,0,0,1,1.382,1.382v3.662h-5.35v-3.662a1.384,1.384,0,0,1,1.382-1.382Zm-1.382,7.8v-1.573h5.35v1.573Zm0,0"
          transform="translate(-206.899 -260)"
        />
        <path
          fill="#484848"
          stroke="#484848"
          strokeWidth="0.5px"
          d="M312.91,260a2.569,2.569,0,0,0-2.566,2.566v7.011a.592.592,0,0,0,.592.592h6.534a.592.592,0,0,0,.592-.592v-7.011A2.569,2.569,0,0,0,315.5,260Zm-1.382,8.985v-1.573h5.35v1.573Zm5.35-6.419v3.662h-5.35v-3.662a1.383,1.383,0,0,1,1.382-1.382H315.5A1.383,1.383,0,0,1,316.878,262.566Zm0,0"
          transform="translate(-300.134 -260)"
        />
        <path
          fill="#484848"
          stroke="#484848"
          strokeWidth="0.5px"
          d="M312.91,260a2.569,2.569,0,0,0-2.566,2.566v7.011a.592.592,0,0,0,.592.592h6.534a.592.592,0,0,0,.592-.592v-7.011A2.569,2.569,0,0,0,315.5,260Zm-1.382,8.985v-1.573h5.35v1.573Zm5.35-6.419v3.662h-5.35v-3.662a1.383,1.383,0,0,1,1.382-1.382H315.5A1.383,1.383,0,0,1,316.878,262.566Zm0,0"
          transform="translate(-290.222 -260)"
        />
      </g>
    </g>
  </svg>
))

export default BigHall
