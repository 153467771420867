import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import styled from '@emotion/styled/macro'
import { zindex } from '../constants/zIndexes'
import Ripple from './Ripple'
import { CHROME_ANDROID_NO_HIGHLIGHT } from '../constants/CSS'
import { MEDIA_DESKTOP } from '../constants/media'
import { DesktopFiltersCinema } from './DesktopFiltersCinema'
import { useClickOutside } from '../hooks/useClickOutside'
import { useSelector } from 'react-redux'
import { filter, keys, map, sum, values } from 'ramda'
import { goBack } from 'connected-react-router'
import { CINEMA_NAMES } from '../constants/cinemas'
import { THEME_COLOR } from '../constants/colors'
import { DesktopFiltersMovies } from './DesktopFiltersMovies'
import useHandler from '../hooks/useHandler'
import { useAction } from '../hooks/useAction'
import { goTo, replace } from '../actions/router'
import { createMainRoute, ROUTE_STACK } from '../constants/routes'
import { useStackRouteMatch } from '../hooks/useStackRouteMatch'

const StyledFiltersLayout = styled.div`
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zindex.desktopFilters};
  display: flex;

  ${MEDIA_DESKTOP} {
    padding: 0 20px;
  }
`

const StyledFiltersContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 850px;
  background: white;
  box-shadow: 0 0 10px #00000088;
  transition: 0.2s all ease-out;
  transform: ${({ isFiltersVisible }) =>
    isFiltersVisible ? 'none' : 'translateY(100%)'};
  padding: 0 25px;

  ${MEDIA_DESKTOP} {
    transform: none;
    box-shadow: 0 0 10px #00000036;
    height: 70px;
    border-radius: 10px 10px 0 0;
    padding: 0;
  }
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  ${MEDIA_DESKTOP} {
    width: 100%;
  }
`

const StyledFilter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 20px;
  position: relative;
  z-index: 2;
  cursor: default;

  ${CHROME_ANDROID_NO_HIGHLIGHT}

  ${MEDIA_DESKTOP} {
    padding: 15px 0 25px;
    flex-direction: row;
    padding: 0;
  }
`

const StyledBackContainer = styled.div`
  position: fixed;
  z-index: 1;
`

const StyledMobileLabel = styled.div`
  font-size: 16px;
  font-weight: 900;
  color: #3c3c3c;
  user-select: none;
  position: relative;
  cursor: default;
  flex: 1;

  ${MEDIA_DESKTOP} {
    display: none;
  }
`

const StyledFilterTitle = styled.div`
  font-size: 16px;
  font-weight: 900;
  color: #3c3c3c;
  user-select: none;
  flex-shrink: 0;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  cursor: default;
  display: none;

  ${MEDIA_DESKTOP} {
    display: block;
    margin: 0;
  }
`

const StyledFilterContentText = styled.div`
  display: none;
  font-size: 14px;
  font-weight: ${({ count }) => (count === 0 ? 600 : 700)};
  color: ${({ count }) => (count === 0 ? '#bbb' : THEME_COLOR)};
  padding-right: 20px;
  padding-left: 20px;
  width: 185px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;

  ${MEDIA_DESKTOP} {
    display: block;
  }
`

const StyledFilterButtonTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: white;
  display: flex;
  align-items: center;
`

const StyledFilterButton = styled.div`
  align-items: center;
  position: relative;
  background: #1f5797;
  border-radius: 4px;
  padding: 7px 13px;
  user-select: none;
  cursor: pointer;
  flex-shrink: 0;
  display: none;

  ${MEDIA_DESKTOP} {
    display: flex;
  }
`

const StyledFilterMobileButton = styled(StyledFilterButton)`
  padding: 0 ${({ count }) => (!!count ? 15 : 20)}px;
  display: flex;
  height: 40px;
  margin-left: 15px;

  ${MEDIA_DESKTOP} {
    display: none;
    margin-left: 0;
  }
`

const StyledFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  position: fixed;
  left: 0;
  right: 0;
  height: ${({ filtersHeight }) => filtersHeight - 80}px;
  bottom: 80px;
  padding: 30px 0 0;
  z-index: ${zindex.desktopFilters};
  opacity: 1;
  cursor: default;

  ${MEDIA_DESKTOP} {
    width: 400px;
    height: 600px;
    border-radius: 10px;
    box-shadow: 0 0 10px #00000036;
    opacity: 0.96;
    position: absolute;
    left: -150px;
    bottom: 60px;
    top: unset;
    right: unset;
  }
`

const StyledFilterBack = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
`

const StyledFiltersCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 20px;
  height: 20px;
  margin-left: 10px;

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  ${MEDIA_DESKTOP} {
    display: none;
  }
`

const StyledFiltersCountText = styled.div`
  font-size: 12px;
  font-weight: 900;
  color: ${THEME_COLOR};
  user-select: none;
  position: relative;
`

export const FilterContainer = memo(({ componentRef, children }) => {
  const onClick = useCallback(e => e.stopPropagation(), [])
  const height = window.innerHeight

  return (
    <StyledFilterContainer
      ref={componentRef}
      onClick={onClick}
      filtersHeight={height}
    >
      {children}
    </StyledFilterContainer>
  )
})

const FiltersCount = memo(({ count }) => {
  return (
    <StyledFiltersCount>
      {!!count && (
        <>
          <svg width="20" height="20" viewBox="0 0 20 20">
            <circle cx={10} cy={10} r={10} fill="#fff" />
          </svg>
          <StyledFiltersCountText>{count}</StyledFiltersCountText>
        </>
      )}
    </StyledFiltersCount>
  )
})

const FilterItem = memo(
  ({
    title,
    buttonLabel,
    mobileButtonLabel,
    filter: Filter,
    content,
    count,
    buttonRef,
    secondButtonRef,
    open,
    secondOpen,
    backContainerEl,
    routeKey,
  }) => {
    const filterRef = useRef(null)
    const navigate = useAction(goTo)
    const replaceNavigate = useAction(replace)
    const navigateBack = useAction(goBack)

    const onClick = useHandler(() => {
      if (!open) {
        const action = secondOpen ? replaceNavigate : navigate

        action({
          pathname: createMainRoute(),
          state: JSON.stringify({
            routeKey,
          }),
        })
      } else {
        navigateBack()
      }
    })

    const closeFilter = useHandler(() => {
      if (open) {
        navigateBack()
      }
    })

    const clickOutsideRefs = useMemo(
      () => [filterRef, buttonRef, secondButtonRef],
      [filterRef, buttonRef, secondButtonRef],
    )
    useClickOutside({ refs: clickOutsideRefs, onOutsideClick: closeFilter })

    const filterNode = open ? (
      <FilterContainer
        componentRef={filterRef}
        backContainerEl={backContainerEl}
      >
        <Filter closeFilter={closeFilter} />
      </FilterContainer>
    ) : null

    return (
      <>
        <StyledFilter onClick={onClick} ref={buttonRef}>
          <StyledFilterTitle>{title}</StyledFilterTitle>
          <StyledFilterContentText count={count}>
            {content}
          </StyledFilterContentText>
          <StyledFilterButton>
            <Ripple />
            <StyledFilterButtonTitle>{buttonLabel}</StyledFilterButtonTitle>
            {filterNode}
          </StyledFilterButton>
          <StyledFilterMobileButton count={count}>
            <Ripple />
            <StyledFilterButtonTitle>
              {mobileButtonLabel} {!!count && <FiltersCount count={count} />}
            </StyledFilterButtonTitle>
            {filterNode}
          </StyledFilterMobileButton>
        </StyledFilter>
        {!!backContainerEl &&
          createPortal(<StyledFilterBack />, backContainerEl)}
      </>
    )
  },
)

const useWhereContent = () => {
  const where = useSelector(state => state.filter.where)

  return useMemo(
    () => {
      const names = keys(filter(Boolean, where))
      const count = sum(map(() => 1, filter(Boolean, values(where))))
      const content = (() => {
        if (count === 0) {
          return 'Kamkoliv v Praze'
        }

        if (count === 1) {
          return CINEMA_NAMES[names[0]]
        }

        return `${count} ${count >= 5 ? 'kin' : 'kina'}: ${names
          .map(n => CINEMA_NAMES[n])
          .join(', ')}`
      })()

      return { content, count }
    },
    [where],
  )
}

const useMoviesContent = () => {
  const movies = useSelector(state => state.filter.movies)
  const movieLinks = useSelector(state => state.data.movieLinks)
  const moviesData = useSelector(state => state.data.movies)

  return useMemo(
    () => {
      const getMovieTitleByCanonicalName = canonicalName =>
        moviesData[movieLinks[canonicalName][0]].title

      const titles = map(
        getMovieTitleByCanonicalName,
        keys(filter(Boolean, movies)),
      )
      const count = sum(map(() => 1, filter(Boolean, values(movies))))
      const content = (() => {
        if (count === 0) {
          return 'Na jakýkoliv film'
        }

        if (count === 1) {
          return titles[0]
        }

        return `${count} ${count >= 5 ? 'filmů' : 'filmy'}: ${titles.join(
          ', ',
        )}`
      })()

      return { content, count }
    },
    [movies, movieLinks, moviesData],
  )
}

const useFiltersVisible = () => {
  const [isVisible, setIsVisible] = useState(true)

  let totalScrollUp = 0
  let totalScrollDown = 0

  let lastScrollTop = window.scrollY

  const onScroll = useHandler(() => {
    const scrollY = window.scrollY
    const upscroll = scrollY < lastScrollTop

    if (upscroll) {
      totalScrollDown = 0

      if (!isVisible) {
        totalScrollUp += lastScrollTop - scrollY

        if (totalScrollUp > 70) {
          totalScrollUp = 0
          setIsVisible(true)
        }
      }
    } else {
      totalScrollUp = 0

      if (isVisible) {
        totalScrollDown += scrollY - lastScrollTop

        if (totalScrollDown > 470) {
          totalScrollDown = 0
          setIsVisible(false)
        }
      }
    }

    lastScrollTop = scrollY
  })

  useEffect(() => {
    window.addEventListener('customScroll', onScroll)

    return () => {
      window.removeEventListener('customScroll', onScroll)
    }
  }, [])

  return isVisible
}

export const DesktopFilters = memo(() => {
  const { content: whereContent, count: whereCount } = useWhereContent()
  const { content: moviesContent, count: moviesCount } = useMoviesContent()
  const cinemaButtonRef = useRef(null)
  const moviesButtonRef = useRef(null)
  const backContainerRef = useRef(null)
  const cinemaOpen = useStackRouteMatch(ROUTE_STACK.FILTERS_CINEMA)
  const moviesOpen = useStackRouteMatch(ROUTE_STACK.FILTERS_MOVIE)
  // const isFiltersVisible = useFiltersVisible()

  return (
    <StyledFiltersLayout>
      <StyledFiltersContainer isFiltersVisible={true}>
        {(cinemaOpen || moviesOpen) && (
          <StyledBackContainer ref={backContainerRef} />
        )}
        <StyledMobileLabel>Filtry projekcí</StyledMobileLabel>
        <StyledButtonContainer>
          <FilterItem
            title="Kam?"
            buttonLabel="vybrat kino"
            mobileButtonLabel="kino"
            filter={DesktopFiltersCinema}
            content={whereContent}
            count={whereCount}
            buttonRef={cinemaButtonRef}
            secondButtonRef={moviesButtonRef}
            open={cinemaOpen}
            secondOpen={moviesOpen}
            backContainerEl={backContainerRef.current}
            routeKey={ROUTE_STACK.FILTERS_CINEMA}
          />
          <FilterItem
            title="Na co?"
            buttonLabel="vybrat film"
            mobileButtonLabel="film"
            filter={DesktopFiltersMovies}
            content={moviesContent}
            count={moviesCount}
            buttonRef={moviesButtonRef}
            secondButtonRef={cinemaButtonRef}
            open={moviesOpen}
            secondOpen={cinemaOpen}
            backContainerRef={backContainerRef}
            routeKey={ROUTE_STACK.FILTERS_MOVIE}
          />
        </StyledButtonContainer>
      </StyledFiltersContainer>
    </StyledFiltersLayout>
  )
})
