import typeToReducer from 'type-to-reducer'
import * as ActionTypes from '../constants/ActionTypes'

const initialState = null

export default typeToReducer(
  {
    [ActionTypes.SET_SELECTED_SCREENING]: (state, { payload: { id } }) => id,
    [ActionTypes.SET_FILTER_DAYS_OF_WEEK]: () => null,
    [ActionTypes.SET_FILTER_DATE]: () => null,
    [ActionTypes.SET_FILTER_MOVIES]: () => null,
    [ActionTypes.SET_FILTER_FAVOURITES]: () => null,
    [ActionTypes.SET_FILTER_PRICE]: () => null,
    [ActionTypes.SET_FILTER_WHERE]: () => null,
    [ActionTypes.SET_FILTER_WHERE_ITEM]: () => null,
    [ActionTypes.SET_FILTER_MOVIES_ITEM]: () => null,
  },
  initialState,
)
