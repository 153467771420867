/* eslint-disable immutable/no-mutation */
/* eslint-disable immutable/no-let */
// TODO: remove mutation

const raf =
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.oRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  function(callback) {
    return window.setTimeout(callback, 1000 / 60)
  }

const caf =
  window.cancelAnimationFrame ||
  window.webkitCancelAnimationFrame ||
  window.mozCancelAnimationFrame ||
  window.oCancelAnimationFrame ||
  window.msCancelAnimationFrame ||
  function(id) {
    window.clearTimeout(id)
  }

export const cancelAnimationTimeout = frame => caf(frame.id)

export const requestAnimationTimeout = (callback, delay) => {
  let start
  // wait for end of processing current event handler, because event handler may be long
  Promise.resolve().then(() => {
    start = Date.now()
  })

  const timeout = () => {
    if (Date.now() - start >= delay) {
      callback.call()
    } else {
      frame.id = raf(timeout)
    }
  }

  const frame = {
    id: raf(timeout),
  }

  return frame
}
