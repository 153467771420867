import React, { memo } from 'react'
import styled from '@emotion/styled/macro'

const StyledStoriesComponentsProgressWrapper = styled.div`
  flex: 1;
  height: 2px;
  background: #555;
  display: flex;
  margin: 2px;
  border-radius: 2px;
  overflow: hidden;
`

const StyledStoriesComponentsProgress = styled.div`
  flex: 1;
  background: #fff;
  height: 2px;
  border-radius: 2px;
`

export const StoriesComponentProgress = memo(({ status, progressRef }) => {
  return (
    <StyledStoriesComponentsProgressWrapper>
      <StyledStoriesComponentsProgress
        {...{
          status,
          ref: progressRef,
          style: {
            transform: `translateX(${status === 'PAST' ? 0 : -103}%)`,
          },
        }}
      />
    </StyledStoriesComponentsProgressWrapper>
  )
})
