import React from 'react'
import styled from '@emotion/styled/macro'
import { CHROME_ANDROID_NO_HIGHLIGHT, MOUSE_DEVICE } from '../constants/CSS'

const StyledLogoContainer = styled.div`
  width: ${({ scale }) => scale * 800}px;
  height: ${({ scale }) => scale * 200}px;
  position: relative;

  & path,
  & polygon {
    fill: rgb(47, 46, 43);
  }

  & .wheel {
    fill: #b45283;
    fill: #b45271;
    fill: #2677ba;
    fill: #1f5797;
  }
`

const DobijakuLogo = ({ scale }) => (
  <StyledLogoContainer {...{ scale }}>
    <svg
      id="no-film-roll"
      viewBox="0 0 800 200"
      width={scale * 800}
      height={scale * 200}
      style={{ position: 'absolute', top: 0, left: 0 }}
    >
      <image className="all" width="100%" height="100%" />
      <g>
        <path
          d="M397.5,154.7l7.2-18.3c3.4,1.2,9.5,4.3,12,4.3c5.7,0,12.1-3,12.1-10.4l0.5-64.9h23.9v64.2c0,10.2-2.6,18-7.8,23.8
		c-5.1,5.8-14.2,8.6-23.3,8.6C414.2,162,405.6,158.7,397.5,154.7z"
        />
        <path d="M532.4,28.3l-26.6,18.5l5.5,9.7l30.2-12L532.4,28.3z" />
        <path
          d="M719.8,65.2v56.4c0,8.2-1.6,15.5-4.8,21.9c-3.2,6.5-7.7,11.3-13.5,14.9c-5.8,3.6-13.4,5.3-20.8,5.3c-1.4,0-2.8,0-4.1-0.1
		c-5.7-0.4-12-2.1-16.6-5c-5.8-3.6-10.2-8.5-13.5-14.9c-3.2-6.4-4.8-13.7-4.8-21.9V65.3H668v56.9c0,5.6,1.1,9.8,3.3,12.6
		c1.4,1.8,3.2,3,5.4,3.6c1.2,0.4,2.6,0.5,4.1,0.5c4.2,0,7.3-1.4,9.5-4.3c2.2-2.9,3.3-6.9,3.3-12.6V65.2H719.8z"
        />
        <path
          d="M80.2,66.6v95.1c0,0,78.4,8.2,78.4-47.1C158.6,59.2,80.2,66.6,80.2,66.6z M104.7,134.2V94.4c0,0,29.4-0.9,29.4,19.8
		S104.7,134.2,104.7,134.2z"
        />
        <path
          className="wheel"
          d="M240.9,153.8c0.2-4.6,0,0,0.2-4.6L218,161.7h44.4v-7.9H240.9z"
        />
        <path
          d="M320.7,109c12.5,0,19.4-8.8,19.4-20.3v-0.2c-0.1-20-30.5-26.4-61.3-23.4V162c27.5,1.1,68.2-1.5,68.2-27.9
		C347.1,116.1,331.7,109,320.7,109z M301.6,82.2c0,0,17.9-1.9,17.9,8.9c0,11.5-17.9,9.6-17.9,9.6V82.2z M301.6,141.9v-21.1
		c0,0,23.4-1.2,23.4,10C324.9,145.1,301.6,141.9,301.6,141.9z"
        />
        <g>
          <path
            d="M386.2,65.4h-1.5c-0.3,0-0.6,0.3-0.6,0.6v0.7c0,0.3-0.3,0.6-0.6,0.6h-1.4c-0.3,0-0.6-0.3-0.6-0.6V66
			c0-0.3-0.3-0.6-0.6-0.6h-16.1c-0.3,0-0.6,0.3-0.6,0.6v0.7c0,0.3-0.3,0.6-0.6,0.6h-1.4c-0.3,0-0.6-0.3-0.6-0.6V66
			c0-0.3-0.3-0.6-0.6-0.6h-1.5c-0.3,0-0.6,0.3-0.6,0.6v95.4c0,0.3,0.3,0.6,0.6,0.6h1.5c0.3,0,0.6-0.3,0.6-0.6v-0.5
			c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v0.5c0,0.3,0.3,0.6,0.6,0.6h16c0.3,0,0.6-0.3,0.6-0.6v-0.5
			c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v0.5c0,0.3,0.3,0.6,0.6,0.6h1.5c0.3,0,0.6-0.3,0.6-0.6v-47.3h0.1V66
			C386.8,65.7,386.5,65.4,386.2,65.4z M361.5,70.3c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.4c0,0.3-0.3,0.6-0.6,0.6h-1.4
			c-0.3,0-0.6-0.3-0.6-0.6V70.3z M361.5,75.2c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.1c0,0.3-0.3,0.6-0.6,0.6h-1.4
			c-0.3,0-0.6-0.3-0.6-0.6V75.2z M361.5,89.4c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.1c0,0.5-0.3,0.7-0.6,0.7h-1.4
			c-0.3,0-0.6-0.3-0.6-0.6V89.4z M361.5,94.2c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.1c0,0.3-0.3,0.6-0.6,0.6h-1.4
			c-0.3,0-0.6-0.3-0.6-0.6V94.2z M361.5,99c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.1c0,0.3-0.3,0.6-0.6,0.6h-1.4
			c-0.3,0-0.6-0.3-0.6-0.6V99z M361.5,103.7c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.1c0,0.5-0.3,0.6-0.6,0.6h-1.4
			c-0.3,0-0.6-0.3-0.6-0.6V103.7z M361.5,108.5c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.1c0,0.3-0.3,0.6-0.6,0.6h-1.4
			c-0.3,0-0.6-0.3-0.6-0.6V108.5z M361.5,113.2c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.2c0,0.3-0.3,0.6-0.6,0.6h-1.4
			c-0.3,0-0.6-0.3-0.6-0.6V113.2z M361.5,118.1c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.1c0,0.4-0.3,0.6-0.6,0.6h-1.4
			c-0.3,0-0.6-0.3-0.6-0.6V118.1z M361.5,122.9c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.1c0,0.3-0.3,0.6-0.6,0.6h-1.4
			c-0.3,0-0.6-0.3-0.6-0.6V122.9z M361.5,127.7c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.3c0,0.3-0.3,0.6-0.6,0.6h-1.4
			c-0.3,0-0.6-0.3-0.6-0.6V127.7z M361.5,132.5c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.1c0,0.3-0.3,0.6-0.6,0.6h-1.4
			c-0.3,0-0.6-0.3-0.6-0.6V132.5z M364.1,157.4c0,0.3-0.3,0.6-0.6,0.6h-1.4c-0.3,0-0.6-0.3-0.6-0.6v-1.1c0-0.3,0.3-0.6,0.6-0.6h1.4
			c0.3,0,0.6,0.3,0.6,0.6V157.4z M364.1,152.7c0,0.3-0.3,0.6-0.6,0.6h-1.4c-0.3,0-0.6-0.3-0.6-0.6v-1.1c0-0.3,0.3-0.6,0.6-0.6h1.4
			c0.3,0,0.6,0.3,0.6,0.6V152.7z M364.1,147.9c0,0.3-0.3,0.6-0.6,0.6h-1.4c-0.3,0-0.6-0.3-0.6-0.6v-1.1c0-0.3,0.3-0.6,0.6-0.6h1.4
			c0.3,0,0.6,0.3,0.6,0.6V147.9z M364.1,143.2c0,0.3-0.3,0.6-0.6,0.6h-1.4c-0.3,0-0.6-0.3-0.6-0.6v-1.1c0-0.3,0.3-0.6,0.6-0.6h1.4
			c0.3,0,0.6,0.3,0.6,0.6V143.2z M364.2,138.5c0,0.3-0.3,0.6-0.6,0.6h-1.4c-0.3,0-0.6-0.3-0.6-0.6v-1.1c-0.1-0.5,0.2-0.6,0.6-0.6
			h1.4c0.3,0,0.6,0.3,0.6,0.6V138.5z M364.2,85.9c0,0.3-0.3,0.6-0.6,0.6h-1.4c-0.3,0-0.6-0.3-0.6-0.6v-1.1c-0.1-0.4,0.2-0.7,0.6-0.7
			h1.4c0.3,0,0.6,0.3,0.6,0.6V85.9z M364.2,81.2c0,0.3-0.3,0.6-0.6,0.6h-1.4c-0.3,0-0.6-0.3-0.6-0.6v-1.1c-0.1-0.5,0.2-0.6,0.6-0.6
			h1.4c0.3,0,0.6,0.3,0.6,0.6V81.2z M366.9,68.2c0-0.3,0.3-0.6,0.6-0.6h10.7c0.3,0,0.6,0.3,0.6,0.6v4.5c0,0.3-0.3,0.6-0.6,0.6h-10.7
			c-0.3,0-0.6-0.3-0.6-0.6V68.2z M366.9,118.9c0-0.3,0.3-0.6,0.6-0.6h10.7c0.3,0,0.6,0.3,0.6,0.6v4.3c0,0.3-0.3,0.6-0.6,0.6h-10.7
			c-0.3,0-0.6-0.3-0.6-0.6V118.9z M378.9,159.2c0,0.3-0.3,0.6-0.6,0.6h-10.7c-0.3,0-0.6-0.3-0.6-0.6V155c0-0.3,0.3-0.6,0.6-0.6h10.7
			c0.3,0,0.6,0.3,0.6,0.6V159.2z M378.9,151.8c0,0.3-0.3,0.6-0.6,0.6h-10.7c-0.3,0-0.6-0.3-0.6-0.6v-4.3c0-0.3,0.3-0.6,0.6-0.6h10.7
			c0.3,0,0.6,0.3,0.6,0.6V151.8z M378.9,144.2c0,0.3-0.3,0.6-0.6,0.6h-10.7c-0.3,0-0.6-0.3-0.6-0.6v-3.5c0-0.3,0.3-0.6,0.6-0.6h10.7
			c0.3,0,0.6,0.3,0.6,0.6V144.2z M378.9,137.5c0,0.3-0.3,0.6-0.6,0.6h-10.7c-0.3,0-0.6-0.3-0.6-0.6v-4.3c0-0.3,0.3-0.6,0.6-0.6h10.7
			c0.3,0,0.6,0.3,0.6,0.6V137.5z M378.9,130c0,0.3-0.3,0.6-0.6,0.6h-10.7c-0.3,0-0.6-0.3-0.6-0.6v-3.7c0-0.3,0.3-0.6,0.6-0.6h10.7
			c0.3,0,0.6,0.3,0.6,0.6V130z M378.9,115.6c0,0.3-0.3,0.6-0.6,0.6h-10.7c-0.3,0-0.6-0.3-0.6-0.6v-3.7c0-0.3,0.3-0.6,0.6-0.6h10.7
			c0.3,0,0.6,0.3,0.6,0.6V115.6z M378.9,108.7c0,0.3-0.3,0.6-0.6,0.6h-10.7c-0.3,0-0.6-0.3-0.6-0.6v-4.3c0-0.3,0.3-0.6,0.6-0.6h10.7
			c0.3,0,0.6,0.3,0.6,0.6V108.7z M378.9,101.2c0,0.3-0.3,0.6-0.6,0.6h-10.7c-0.3,0-0.6-0.3-0.6-0.6v-3.5c0-0.3,0.3-0.6,0.6-0.6h10.7
			c0.3,0,0.6,0.3,0.6,0.6V101.2z M378.9,94.4c0,0.3-0.3,0.6-0.6,0.6h-10.7c-0.3,0-0.6-0.3-0.6-0.6v-4.3c0-0.3,0.3-0.6,0.6-0.6h10.7
			c0.3,0,0.6,0.3,0.6,0.6V94.4z M378.9,86.9c0,0.3-0.3,0.6-0.6,0.6h-10.7c-0.3,0-0.6-0.3-0.6-0.6v-3.6c0-0.3,0.3-0.6,0.6-0.6h10.7
			c0.3,0,0.6,0.3,0.6,0.6V86.9z M378.9,80.2c0,0.3-0.3,0.6-0.6,0.6h-10.7c-0.3,0-0.6-0.3-0.6-0.6v-4.3c0-0.3,0.3-0.6,0.6-0.6h10.7
			c0.3,0.1,0.6,0.3,0.6,0.6V80.2z M381.4,79.9c0-0.3,0.3-0.6,0.6-0.6h1.4c0.4,0.2,0.6,0.3,0.6,0.6V81c0,0.3-0.3,0.6-0.6,0.6H382
			c-0.3,0-0.6-0.3-0.6-0.6V79.9z M381.4,84.7c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.1c0,0.3-0.3,0.6-0.6,0.6H382
			c-0.3,0-0.6-0.3-0.6-0.6V84.7z M381.4,89.4c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.2c0,0.3-0.3,0.6-0.6,0.6H382
			c-0.3,0-0.6-0.3-0.6-0.6V89.4z M381.4,94.2c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.1c0,0.3-0.3,0.6-0.6,0.6H382
			c-0.3,0-0.6-0.3-0.6-0.6V94.2z M381.4,99c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.1c0,0.3-0.3,0.6-0.6,0.6H382
			c-0.3,0-0.6-0.3-0.6-0.6V99z M381.4,103.9c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.1c0,0.3-0.3,0.6-0.6,0.6H382
			c-0.3,0-0.6-0.3-0.6-0.6V103.9z M381.4,108.5c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.1c0,0.3-0.3,0.6-0.6,0.6H382
			c-0.3,0-0.6-0.3-0.6-0.6V108.5z M381.4,113.2c0-0.3,0.3-0.6,0.6-0.6h1.4c0.3,0,0.6,0.3,0.6,0.6v1.2c0,0.3-0.3,0.6-0.6,0.6H382
			c-0.3,0-0.6-0.3-0.6-0.6V113.2z M384,157.4c0,0.3-0.3,0.6-0.6,0.6H382c-0.3,0-0.6-0.3-0.6-0.6v-1.1c0-0.3,0.3-0.6,0.6-0.6h1.4
			c0.3,0,0.6,0.3,0.6,0.6V157.4z M384,152.7c0,0.3-0.3,0.6-0.6,0.6H382c-0.3,0-0.6-0.3-0.6-0.6v-1.1c0-0.3,0.3-0.6,0.6-0.6h1.4
			c0.3,0,0.6,0.3,0.6,0.6V152.7z M384,147.9c0,0.3-0.3,0.6-0.6,0.6H382c-0.3,0-0.6-0.3-0.6-0.6v-1.1c0-0.3,0.3-0.6,0.6-0.6h1.4
			c0.3,0,0.6,0.3,0.6,0.6V147.9z M384,143.2c0,0.3-0.3,0.6-0.6,0.6H382c-0.3,0-0.6-0.3-0.6-0.6v-1.1c0-0.3,0.3-0.6,0.6-0.6h1.4
			c0.3,0,0.6,0.3,0.6,0.6V143.2z M384,138.4c0,0.3-0.3,0.6-0.6,0.6H382c-0.3,0-0.6-0.3-0.6-0.6v-1.1c0-0.3,0.3-0.6,0.6-0.6h1.4
			c0.3,0,0.6,0.3,0.6,0.6V138.4z M384,133.6c0,0.3-0.3,0.6-0.6,0.6H382c-0.3,0-0.6-0.3-0.6-0.6v-1.1c0-0.3,0.3-0.6,0.6-0.6h1.4
			c0.3,0,0.6,0.3,0.6,0.6V133.6z M384,129c0,0.3-0.3,0.6-0.6,0.6H382c-0.3,0-0.6-0.3-0.6-0.6v-1.2c0-0.3,0.3-0.6,0.6-0.6h1.4
			c0.3,0,0.6,0.3,0.6,0.6V129z M384,124c0,0.3-0.3,0.6-0.6,0.6H382c-0.3,0-0.6-0.3-0.6-0.6v-1.1c0-0.3,0.3-0.6,0.6-0.6h1.4
			c0.4,0,0.6,0.3,0.6,0.6V124z M384.1,119.1c-0.1,0.4-0.3,0.6-0.6,0.6h-1.4c-0.3,0-0.6-0.3-0.6-0.6v-1.1c0-0.3,0.3-0.6,0.6-0.6h1.4
			c0.3,0,0.6,0.3,0.6,0.6V119.1z M384.1,76.3c-0.1,0.3-0.3,0.6-0.6,0.6h-1.4c-0.3,0-0.6-0.3-0.6-0.6v-1.1c0-0.3,0.3-0.6,0.6-0.6h1.4
			c0.3,0,0.6,0.3,0.6,0.6V76.3z M384.1,71.6c0,0.3-0.3,0.6-0.6,0.6h-1.4c-0.3,0-0.6-0.3-0.6-0.6v-1.4c0-0.3,0.3-0.6,0.6-0.6h1.4
			c0.3,0,0.6,0.3,0.6,0.6V71.6z"
          />
        </g>
        <polygon points="505.8,85.7 505.8,85.7 503.9,92.8 	" />
        <path
          d="M542.2,144.4l-6.2-22.7l-15.5-56.4h-32.9L472,121.7l-6.3,22.7l-4.9,17.6H484l3.7-13.6h32.4l3.7,13.6h23.2L542.2,144.4z
		 M503.9,89.3l0.5,1.7l9.5,34.7h-20L503.9,89.3z"
        />
        <polygon
          points="632.8,69.9 602.6,114.5 632.8,157.6 632.8,162.1 609.7,162.1 583.5,126.3 583.5,162.1 559.5,162.1 559.5,65.3
		583.5,65.3 583.5,104.8 609.4,65.3 632.8,65.3 	"
        />
      </g>
    </svg>
    <svg
      id="film-roll"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 800 200"
      width={scale * 800}
      height={scale * 200}
      style={{ position: 'absolute', top: 0, left: 0 }}
    >
      <image className="all" width="100%" height="100%" />
      <path
        className="wheel"
        d="M256.6,89.9c-0.3-0.4-0.5-0.9-0.8-1.2c-0.3-0.4-0.5-0.8-0.8-1.2c0,0-0.1-0.1-0.1-0.2c-0.5-0.8-1-1.4-1.5-2.2
		c-0.3-0.4-0.6-0.8-0.9-1.1c0,0,0,0,0-0.1c-0.3-0.4-0.6-0.7-0.9-1c-0.3-0.4-0.6-0.8-1-1c-0.3-0.4-0.7-0.7-1-1c-0.3-0.4-0.7-0.7-1-1
		c-0.3-0.4-0.7-0.7-1-1c-0.4-0.3-0.7-0.7-1-1c-0.4-0.3-0.8-0.6-1.1-1c-0.4-0.3-0.8-0.6-1.1-1c-0.4-0.3-0.8-0.6-1.1-0.9
		c-0.6-0.4-1-0.8-1.6-1.1c-0.6-0.4-1.1-0.8-1.6-1c-1.1-0.7-2.2-1.3-3.3-1.9c-1.1-0.6-2.3-1.1-3.5-1.6c-0.5-0.2-1-0.4-1.6-0.7
		c-0.2,0-0.3-0.1-0.4-0.2c-0.5-0.2-1-0.4-1.5-0.5c-0.6-0.2-1.2-0.4-1.8-0.6c-0.6-0.2-1.1-0.3-1.6-0.5c-0.8-0.2-1.4-0.4-2.2-0.5
		c-0.6-0.1-1-0.2-1.6-0.3c-0.4-0.1-0.9-0.1-1.2-0.2h-0.3c-0.2,0-0.4-0.1-0.6-0.1c-0.6-0.1-1-0.1-1.6-0.2c-0.5,0-1-0.1-1.5-0.1h-5
		c-0.5,0-1,0.1-1.4,0.1c-0.5,0-1,0.1-1.4,0.1c-4.8,0.6-9.2,1.8-13.4,3.6c-0.9,0.4-1.7,0.8-2.6,1.2c-0.4,0.2-0.9,0.5-1.2,0.7
		c-0.4,0.2-0.8,0.5-1.1,0.7c-0.3,0.2-0.7,0.4-1,0.6c-0.3,0.2-0.6,0.3-0.9,0.5c-0.1,0-0.2,0.1-0.3,0.2c-0.3,0.2-0.6,0.4-0.9,0.6
		c-0.3,0.2-0.7,0.5-1,0.7c-0.7,0.5-1.2,1-1.8,1.4c-0.6,0.5-1.2,1-1.8,1.4c-0.3,0.3-0.6,0.5-0.9,0.8c-0.5,0.5-1,1-1.5,1.5
		c-1.2,1.2-2.4,2.5-3.5,3.9c-0.2,0.3-0.5,0.6-0.7,0.9c-0.3,0.4-0.6,0.8-1,1.2c-0.4,0.6-0.8,1-1.1,1.6c-0.4,0.6-0.8,1.1-1,1.6
		c-0.7,1-1.3,2.2-1.9,3.3c-0.6,1.1-1.1,2.3-1.5,3.5c-0.2,0.5-0.4,1-0.7,1.6c0,0.1-0.1,0.3-0.2,0.4c-0.2,0.5-0.4,1-0.5,1.5
		c-0.2,0.6-0.4,1.2-0.6,1.8c-0.2,0.6-0.3,1.1-0.5,1.6c-0.2,0.8-0.3,1.4-0.5,2.2c-0.1,0.6-0.2,1-0.3,1.6c-0.1,0.4-0.1,0.9-0.2,1.2
		v0.3c0,0.2,0,0.4-0.1,0.6c-0.1,0.6-0.1,1-0.2,1.6c0,0.5-0.1,1-0.1,1.5v5c0,0.5,0.1,1,0.1,1.4c0,0.5,0.1,1,0.2,1.4
		c0.5,3.8,1.3,7.3,2.6,10.8c0.2,0.5,0.3,0.9,0.5,1.3c0.2,0.5,0.4,1,0.6,1.4c0.2,0.4,0.4,0.9,0.6,1.2c0.2,0.5,0.4,0.9,0.6,1.2
		c0.4,0.9,0.9,1.7,1.3,2.5c0.4,0.7,0.8,1.2,1.1,1.9c0.9,1.3,1.7,2.6,2.7,3.8c1.2,1.5,2.5,3,3.8,4.3c0.3,0.3,0.6,0.6,0.9,0.8
		c0.3,0.3,0.6,0.6,0.9,0.8c0.3,0.3,0.6,0.5,0.9,0.8c0.6,0.5,1.1,1,1.7,1.4c0.6,0.4,1,0.9,1.6,1.2c0,0,0.1,0,0.1,0.1
		c0.4,0.3,0.8,0.5,1.1,0.8c0,0,0.1,0,0.1,0.1c0.2,0.2,0.4,0.3,0.7,0.4c0.3,0.2,0.6,0.4,0.9,0.6c0.3,0.2,0.6,0.4,0.9,0.5
		c0.4,0.3,0.9,0.5,1.2,0.8c0.5,0.3,1,0.5,1.3,0.8c0.2,0.1,0.4,0.2,0.7,0.3c0.5,0.2,1,0.5,1.4,0.7c0.5,0.3,1,0.5,1.5,0.7
		c0.6,0.3,1.1,0.5,1.8,0.7c0.4,0.1,0.8,0.3,1.1,0.4c0.4,0.1,0.8,0.3,1.1,0.4c0.5,0.2,1,0.3,1.4,0.4c0.3,0.1,0.7,0.2,1,0.3
		c0.8,0.2,1.5,0.4,2.3,0.5c0.4,0.1,0.8,0.2,1.1,0.2c0.8,0.2,1.6,0.3,2.4,0.4c0.4,0.1,0.8,0.1,1.2,0.1c0.8,0.1,1.6,0.2,2.4,0.2
		c0.4,0,0.9,0,1.2,0.1h1.9c0.8,0,1.6,0,2.3-0.1h0c8.3-0.5,16.5-3.2,23.5-7.8h-0.1c12.9-8.5,20.7-22.7,21.4-38v-2
		C263.3,105.3,260.9,97,256.6,89.9z M215.4,74.2c2.2,0,4.3,0.6,6,1.6c3.6,2.1,6,6,6,10.4c0,5.8-4.1,10.6-9.4,11.7
		c-0.9,0.2-1.7,0.3-2.6,0.3c-6.6,0-12-5.4-12-12S208.8,74.2,215.4,74.2z M241.5,129.9c4,5.2,2.8,12.8-2.7,16.7
		c-1.8,1.3-3.8,2-5.9,2.2c-4.1,0.5-8.3-1.3-10.9-4.9c-3.4-4.7-3-10.9,0.8-15c0.6-0.7,1.2-1.2,1.9-1.7
		C230.1,123.3,237.6,124.4,241.5,129.9z M185.1,117c-6.2-2.1-9.7-8.8-7.7-15.2c0.7-2.1,1.9-3.9,3.4-5.2c3.1-2.8,7.4-3.9,11.7-2.5
		c5.5,1.8,8.8,7,8.2,12.6c-0.1,0.9-0.3,1.7-0.5,2.6C198.1,115.7,191.4,119.1,185.1,117z M208.7,143.8c-3.9,5.4-11.4,6.5-16.8,2.7
		c-1.8-1.3-3.1-3-3.9-5c-1.7-3.8-1.3-8.3,1.2-11.9c3.4-4.7,9.4-6.1,14.6-3.9c0.8,0.3,1.5,0.8,2.3,1.2
		C211.3,130.9,212.6,138.5,208.7,143.8z M217.8,118c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2,0-0.3,0.1
		c-0.1,0-0.2,0-0.4,0.1h-0.5c-0.3,0-0.6,0-0.9-0.1c-0.1,0-0.2,0-0.3-0.1c-0.4-0.1-0.9-0.3-1.1-0.6c-0.1-0.1-0.2-0.1-0.3-0.2
		c0,0-0.1,0-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.2c-0.3-0.2-0.5-0.5-0.6-0.8c-0.4-0.2-0.4-0.3-0.5-0.4c0-0.1-0.1-0.2-0.1-0.3
		c0-0.1-0.1-0.2-0.1-0.3c0,0,0-0.1,0-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.3-0.1-0.4v-0.4c0-0.7,0.2-1.2,0.5-1.8
		c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.1,0.2-0.2
		c0.3-0.3,0.6-0.6,1-0.8c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.2,0,0.3-0.1
		c0.1,0,0.2,0,0.4-0.1h0.5c0.3,0,0.6,0,0.8,0.1c0.1,0,0.2,0,0.3,0.1c0.5,0.1,0.9,0.3,1.2,0.6c0.1,0.1,0.2,0.1,0.3,0.2
		c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.1,0.3,0.2,0.4
		c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.1,0.4v1c0,0.1,0,0.3-0.1,0.4c0,0.1,0,0.3-0.1,0.4
		c-0.1,0.4-0.3,0.9-0.6,1.2c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.4,0.3
		C218.1,117.8,217.9,117.9,217.8,118z M253.7,108c-0.9,4.1-3.8,7.4-8,8.9c-5.5,1.8-11.2-0.6-14.1-5.4c-0.5-0.8-0.8-1.5-1-2.4
		c-2-6.3,1.5-13.1,7.7-15.1c6.4-2.1,13,1.4,15.1,7.6C254.1,103.9,254.2,106.1,253.7,108z"
      />
    </svg>
  </StyledLogoContainer>
)

const StyledContainer = styled.div`
  display: flex;
  height: 40px;
  font-family: Pacifico;
  font-size: 40px;
  flex: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  /* margin-left: 60px; */
  ${CHROME_ANDROID_NO_HIGHLIGHT}
  transition: 0.15s;
  color: #3b3631;
  color: #221f1a;
  color: #2f2e2b;

  ${MOUSE_DEVICE} {
    &:hover path,
    &:hover polygon {
      fill: #746a60;
      fill: #1f5797;
    }

    & path,
    & polygon {
      transition: 0.15s;
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  & #film-roll {
    transform-origin: ${({ scale }) => scale * 215.75}px
      ${({ scale }) => scale * 114.15}px;
    animation: spin 2s linear infinite;
  }
`

const Logo = ({ scale = 0.3 }) => (
  <StyledContainer {...{ scale }}>
    <DobijakuLogo {...{ scale }} />
    {/* dobijáku.cz */}
  </StyledContainer>
)

export default Logo
