import React from 'react'
import Header from './Header'
import styled from '@emotion/styled/macro'
import { LAYOUT_MAX_WIDTH, MIN_600 } from '../constants/CSS'
import FilterMenuBackground from './FilterMenuBackground'
import { SCREENINGS_TOP_OFFSET } from '../constants/layout'
import SearchBox from './SearchBox'
import { HeaderShadow } from './HeaderShadow'
import { DesktopFilters } from './DesktopFilters'

export const StyledContainer = styled.div`
  width: 100%;
  /* background: #ccc; */
  /* background: #d8cab9;
  background: #d6c5b1;
  min-height: 100vh;
  background: #e2dcd5;

  background: rgb(229, 224, 219); */
  background: #e5e0db;
  background: #e6e6e6;
  padding-top: ${SCREENINGS_TOP_OFFSET}px;
`

const StyledContent = styled.div`
  margin: 0 auto;
  width: 100%;
`

const StyledChildren = styled.div`
  margin: 0 auto;
  ${MIN_600} {
    max-width: ${LAYOUT_MAX_WIDTH}px;
  }
`

const Layout = ({ children, setRef }) => (
  <StyledContainer ref={setRef}>
    <Header />
    <SearchBox />
    {/*<Stories />*/}
    <HeaderShadow />
    <FilterMenuBackground />
    <DesktopFilters />
    <StyledChildren>{children}</StyledChildren>
  </StyledContainer>
)

export default Layout
