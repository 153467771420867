export const HALLS = {
  'Velký sál': 'V',
  'Malý sál': 'M',
  'Třetí sál': '3',
  'Malý sál C': 'C',
  'Letní kino': 'L',
}
export const HALLS_DESCRIPTION = {
  'Velký sál': 'Velký sál',
  'Malý sál': 'Malý sál',
  'Třetí sál': 'Třetí sál',
  'Malý sál C': 'Malý sál C',
  'Letní kino': 'Letní kino',
}
