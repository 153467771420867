import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from '@emotion/styled/macro'
import { useSelector } from 'react-redux'
import Stories from 'react-insta-stories'

import goBackOrPush from '../../utils/goBackOrPush'
import { createMainRoute, ROUTE_STACK } from '../../constants/routes'
import { useAction } from '../../hooks/useAction'
import { goTo, replace } from '../../actions/router'
import { CategoryStory } from './CategoryStory'
import { map } from 'ramda'
import { StoriesComponent } from '../../components/StoriesComponent'
import useHandler from '../../hooks/useHandler'
import { useWindowSize } from '../../hooks/useWindowSize'

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  //justify-content: center;
  //align-items: center;
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;

  will-change: transform, opacity;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
`

const getSectionByIndex = ({ categories, index, handleClose, isActive }) => {
  if (!categories) {
    return null
  }

  if (index < 0 || index >= categories.length) {
    return null
  }

  return {
    stories: map(
      movie => ({
        content: props => (
          <CategoryStory {...{ ...props, movie, isActive, handleClose }} />
        ),
      }),
      ((categories || [])[index] || {}).movies || [],
    ),
    startIndex: 0,
  }
}

const useBackGestureDisabler = () => {
  const ref = useRef(null)

  const handleTouchStart = useHandler(e => {
    e.preventDefault()
  })

  // useEffect(
  //   () => {
  //     if (ref.current) {
  //       ref.current.addEventListener('touchstart', handleTouchStart, {
  //         passive: false,
  //       })
  //     }
  //
  //     return () => {
  //       if (ref.current) {
  //         ref.current.removeEventListener('touchstart', handleTouchStart)
  //       }
  //     }
  //   },
  //   [ref.current, handleTouchStart],
  // )

  return ref
}

export const CategoryStoriesContent = ({ genre }) => {
  const categories = useSelector(state => state.data.categories)

  const replaceAction = useAction(replace)

  const wrapperRef = useBackGestureDisabler()
  const { width, height } = useWindowSize()

  useEffect(
    () => {
      if (
        categories &&
        genre &&
        !(categories || []).find(cat => cat.genre === genre)
      ) {
        // TODO: fix by implementing replace action
        replaceAction({
          pathname: createMainRoute(),
          state: JSON.stringify({
            routeKey: ROUTE_STACK.MAIN,
          }),
        })
        // goBackOrPush(() =>
        // )
      }
    },
    [categories],
  )

  const genreIndex = (categories || []).findIndex(cat => cat.genre === genre)

  const handleNextSection = useHandler(() => {
    if (genreIndex === categories.length - 1) {
      return false
    }

    replaceAction({
      pathname: createMainRoute(),
      state: JSON.stringify({
        routeKey: ROUTE_STACK.STORIES,
        genre: categories[genreIndex + 1].genre,
      }),
    })

    return true
  })

  const handlePreviousSection = useHandler(() => {
    if (genreIndex === 0) {
      return false
    }

    replaceAction({
      pathname: createMainRoute(),
      state: JSON.stringify({
        routeKey: ROUTE_STACK.STORIES,
        genre: categories[genreIndex - 1].genre,
      }),
    })

    return true
  })

  const handleClose = useHandler(() => {
    goBackOrPush(() =>
      replaceAction({
        pathname: createMainRoute(),
        state: JSON.stringify({
          routeKey: ROUTE_STACK.MAIN,
        }),
      }),
    )
  })

  const previousSection = useMemo(
    () => getSectionByIndex({ categories, handleClose, index: genreIndex - 1 }),
    [categories, genreIndex],
  )
  const currentSection = useMemo(
    () =>
      getSectionByIndex({
        categories,
        handleClose,
        index: genreIndex,
        isActive: true,
      }),
    [categories, genreIndex],
  )
  const nextSection = useMemo(
    () => getSectionByIndex({ categories, handleClose, index: genreIndex + 1 }),
    [categories, genreIndex],
  )

  if (!categories || !genre) {
    return <StyledContainer />
  }

  // console.log({ width, height })

  return (
    <StyledWrapper
      {...{
        ref: wrapperRef,
      }}
    >
      <StyledContainer {...{ style: { height } }}>
        {/*<Stories*/}
        {/*  stories={stories}*/}
        {/*  defaultInterval={5000}*/}
        {/*  width="min(59vh, 100vw)"*/}
        {/*  height="min(170vw, 100vh)"*/}
        {/*/>*/}
        <StoriesComponent
          {...{
            previousSection,
            currentSection,
            nextSection,
            key: genre,
            // width: 'min(59vh, 100vw)',
            // height: 'min(170vw, 100vh)',
            width: `min(calc(${height}px * 0.65), 100%)`,
            height: `min(calc(${width}px * 1.7), 100%)`,
            handleNextSection,
            handlePreviousSection,
            wrapperRef,
          }}
        />
      </StyledContainer>
    </StyledWrapper>
  )
}
