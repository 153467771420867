import React from 'react'
import styled from '@emotion/styled/macro'
import { connect } from 'react-redux-hoc'
import { compose, path, head, prop, take } from 'ramda'
import { withState, withHandlers, withPropsOnChange, pure } from 'recompose'
import withDerivedProps from '@surglogs/with-derived-props'

import decodeId from '../../utils/decodeId'
import withRenderOnProp from '../../hoc/withRenderOnProp'
import {
  getScreening,
  getScreeningMovieId,
  getScreeningMovie,
  getScreeningCsfdMovie,
  getScreeningImage,
  isDataLoaded,
  getScreeningsObj,
  deriveOtherScreenings,
  getMovies,
  deriveOtherMovies,
  getScreeningCsfdLink,
  getScreeningHall,
  deriveMovieFallbackedData,
} from '../../selectors/data'
import { SECONDARY_FONT } from '../../constants/CSS'
import ScreeningDetailHeader from './ScreeningDetailHeader'
import { PRIMARY_BACKGROUND_COLOR } from '../../constants/colors'
import ScreeningDetailTrailer from './ScreeningDetailTrailer'
import ScreeningDetailInfo from './ScreeningDetailInfo'
import VerticalDivider from '../../components/VerticalDivider'
import LoadingScreeningsIcon from '../../components/LoadingScreeningsIcon'
import ScreeningDetailMetadata from './ScreeningDetailMetadata'
import {
  MEDIA_FULL_DESKTOP,
  MEDIA_EXTENDED_DESKTOP,
} from '../../constants/media'
import getFormattedDescription from '../../utils/getFormattedDescription'
import withScreenWidth from '../../hoc/withScreenWidth'
import ScreeningDetailRightPanel from './ScreeningDetailRightPanel'
import ScreeningDetailActors from './ScreeningDetailActors'
import ScreeningDetailImages from './ScreeningDetailImages'
import ScreeningDetailLeftPanel from './ScreeningDetailLeftPanel'
import dateToTime from '../../utils/dateToTime'
import { Helmet } from 'react-helmet'
import { CINEMA_LOCATIONS, CINEMA_NAMES } from '../../constants/cinemas'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #e6e6e6;
  min-height: 100vh;
`

const StyledContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 700px;

  ${MEDIA_FULL_DESKTOP} {
    margin: 30px 0px;
    max-width: 1040px;
    align-items: flex-start;
  }

  ${MEDIA_EXTENDED_DESKTOP} {
    max-width: 1300px;
  }
`

const StyledMiddlePanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${PRIMARY_BACKGROUND_COLOR};
  width: 100%;
  max-width: 600px;

  ${MEDIA_FULL_DESKTOP} {
    align-items: stretch;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    border-radius: 3px;
    overflow: hidden;
  }
`

const StyledInfoGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${MEDIA_FULL_DESKTOP} {
    align-items: stretch;
    justify-content: space-between;
  }
`

const StyledFirstGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${MEDIA_FULL_DESKTOP} {
    order: 2;
  }
`

const StyledLoader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: #e6e6e6;
  & svg path {
    fill: #292522;
  }
`

const StyledDescriptionContainer = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 30px 35px;
  line-height: 1.5;
`

const StyledDescription = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #484848;
`

const StyledScreeningNotFound = styled.div`
  margin: 40vh;
  font-family: ${SECONDARY_FONT};
  font-size: 24px;
  font-weight: 900;
  line-height: 1.5;
  color: #484848;
`

const lowercaseFirstLetter = s => s.charAt(0).toLowerCase() + s.slice(1)

const ScreeningDetailContent = ({
  id,
  screening,
  movieId,
  movie,
  csfdMovie = {},
  image,
  isLoaded,
  otherScreenings,
  otherMovies,
  csfdLink,
  hall,
  isDesktop,
  isExtendedDesktop,
}) => {
  // console.log({
  //   screening,
  //   movieId,
  //   movie,
  //   csfdMovie,
  //   image,
  //   otherScreenings,
  //   otherMovies,
  // })
  if (!isLoaded) {
    return (
      <StyledContainer>
        <ScreeningDetailHeader {...{ title: 'Už se to točí...' }} />
        <StyledLoader>
          <VerticalDivider {...{ space: 200 }} />
          <LoadingScreeningsIcon />
        </StyledLoader>
      </StyledContainer>
    )
  }

  if (!screening) {
    return (
      <StyledContainer>
        <ScreeningDetailHeader {...{ title: 'Projekce nenalezena :(' }} />
        {isDesktop && (
          <StyledScreeningNotFound>
            Projekce nenalezena :(
          </StyledScreeningNotFound>
        )}
      </StyledContainer>
    )
  }

  const { tags = [], cinema, date, price, buyLink } = screening
  const time = dateToTime(date)

  const {
    title,
    language,
    website,
    youtube,
    director,
    origin,
    originalTitle,
    duration,
    year,
    csfd,
    imdb,
    description,
    genre,
    score,
    images,
  } = deriveMovieFallbackedData({ movie, otherMovies, csfdMovie })

  const actors = take(10, prop('actors', csfdMovie) || [])

  const numOfOtherScreenings = (otherScreenings || []).length

  const metaTags = {
    title: `${title} - Dobijáku`,
    description: `${CINEMA_NAMES[cinema]} (${lowercaseFirstLetter(
      CINEMA_LOCATIONS[cinema],
    )}) - lístek za ${price} Kč.`,
  }

  return (
    <StyledContainer>
      <Helmet>
        <title>{metaTags.title}</title>
        <meta name="Description" content={description} />
        <meta property="og:title" content={metaTags.title} />
        <meta property="og:image" content={(images || [])[0]} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description} />
        <meta property="og:locale" content="cs_CZ" />
      </Helmet>
      <ScreeningDetailHeader {...{ title }} />
      <StyledContent>
        {isExtendedDesktop && actors && images && (
          <ScreeningDetailLeftPanel {...{ images, actors }} />
        )}
        <StyledMiddlePanel>
          <StyledInfoGroup>
            <StyledFirstGroup>
              {image || youtube ? (
                <ScreeningDetailTrailer {...{ image, youtube }} />
              ) : (
                <VerticalDivider {...{ space: isDesktop ? 0 : 40 }} />
              )}
              {!isDesktop && (
                <ScreeningDetailInfo
                  {...{
                    cinema,
                    time,
                    date,
                    price,
                    buyLink,
                    numOfOtherScreenings,
                    title,
                    screeningId: id,
                    movieId,
                    hall,
                  }}
                />
              )}
            </StyledFirstGroup>
            <ScreeningDetailMetadata
              {...{
                title,
                director,
                language,
                origin,
                originalTitle,
                duration,
                year,
                csfd,
                imdb,
                website,
                description,
                genre,
                score,
                csfdId: csfdLink,
                csfdData: csfdMovie,
                tags,
                hall,
              }}
            />
          </StyledInfoGroup>
          {description && (
            <StyledDescriptionContainer>
              <StyledDescription>
                {getFormattedDescription(description)}
              </StyledDescription>
            </StyledDescriptionContainer>
          )}
          {!isExtendedDesktop && (
            <>
              {actors && <ScreeningDetailActors {...{ actors }} />}
              {images && <ScreeningDetailImages {...{ images }} />}
            </>
          )}
        </StyledMiddlePanel>

        {isDesktop && (
          <ScreeningDetailRightPanel
            {...{
              cinema,
              time,
              date,
              price,
              buyLink,
              numOfOtherScreenings,
              otherScreenings,
              screeningId: id,
              hall,
            }}
          />
        )}
      </StyledContent>
    </StyledContainer>
  )
}

export default compose(
  withDerivedProps(['screeningId'], {
    id: ({ screeningId }) =>
      screeningId ? decodeId(screeningId) : screeningId,
  }),
  withRenderOnProp('id'),
  connect({
    isLoaded: isDataLoaded,
    screening: getScreening,
    movieId: getScreeningMovieId,
    movie: getScreeningMovie,
    csfdMovie: getScreeningCsfdMovie,
    csfdLink: getScreeningCsfdLink,
    image: getScreeningImage,
    screeningsObj: getScreeningsObj,
    movies: getMovies,
    hall: getScreeningHall,
  }),
  withDerivedProps(['movie', 'screeningsObj', 'id', 'movies'], {
    otherScreenings: deriveOtherScreenings,
    otherMovies: deriveOtherMovies,
  }),
  withScreenWidth,
  pure,
)(ScreeningDetailContent)
