import React from 'react'
import { pure } from 'recompose'

const PlayArrow2 = pure(() => (
  <svg width="10.907" height="12.195" viewBox="0 0 10.907 12.195">
    <path
      fill="#fff"
      d="M-545.395,2202.676h-.286a.786.786,0,0,1-.618-.591,1.764,1.764,0,0,1-.045-.412q0-5.1,0-10.2a1.966,1.966,0,0,1,.023-.308.765.765,0,0,1,.93-.662,1.781,1.781,0,0,1,.495.19c3,1.655,5.948,3.4,8.906,5.128a1.583,1.583,0,0,1,.4.345.631.631,0,0,1,.039.766,1.283,1.283,0,0,1-.494.436l-8.521,4.916A3.212,3.212,0,0,1-545.395,2202.676Z"
      transform="translate(546.346 -2190.481)"
    />
  </svg>
))

export default PlayArrow2
