import {
  descend,
  filter,
  groupBy,
  head,
  includes,
  indexBy,
  indexOf,
  isEmpty,
  length,
  map,
  pick,
  pipe,
  prop,
  sortWith,
  toPairs,
  uniq,
  unnest,
  values,
} from 'ramda'
import daysAfterNow from '../utils/daysAfterNow'
import {
  CLASSICS,
  GENRES,
  GENRES_OBJ,
  TOP_RATED,
} from '../constants/storiesGenres'
import canonizeTitle from '../canonizeTitle'
import { lookup } from '../utils'

const daysBeforeNow = numOfDays => daysAfterNow(-numOfDays)

const getCategorySortIndex = ({ genre, movies }) => {
  const MOVIES_COUNT_COEFFICIENT = 2

  const categoryScore =
    length(GENRES) -
    (includes(genre, GENRES) ? indexOf(genre, GENRES) : length(GENRES)) +
    length(movies) * MOVIES_COUNT_COEFFICIENT

  return categoryScore
}

export const deriveStoriesCategories = ({
  screeningsObj,
  movies,
  movieCsfdData,
  movieLinks,
}) => {
  const latestScreeningDate = daysAfterNow(7)
  const earliestPremiereDate = daysBeforeNow(90)
  const latestClassicsDate = daysBeforeNow(650)

  const filteredMovies = (() => {
    const filteredScreenings = filter(
      ({ date }) => latestScreeningDate > date,
      screeningsObj || {},
    )
    const movieIds = uniq(
      map(screening => screening.movie, values(filteredScreenings)),
    )
    const filteredMoviesObj = pick(movieIds, movies || {})
    const movieTitles = uniq(
      map(movie => movie.canonicalTitle, values(filteredMoviesObj)),
    )

    const deriveYoutube = canonicalTitle => {
      return pipe(
        prop(canonicalTitle),
        map(
          pipe(
            lookup(movies || {}),
            prop('youtube'),
          ),
        ),
        filter(Boolean),
        head,
      )(movieLinks || {})
    }

    const filteredCsfdMovies = pipe(
      filter(movie => !!movie && !!movie.premiere),
      filter(movie => !isEmpty(movie.images)),
      map(movie => ({
        ...movie,
        premiere: new Date(movie.premiere),
        youtube: deriveYoutube(movie.canonicalTitle),
      })),
      values,
      indexBy(movie => movie.id),
      values,
      sortWith([descend(movie => movie.score)]),
    )(pick(movieTitles, movieCsfdData || {}))

    return filteredCsfdMovies
  })()

  const newMovies = (() => {
    return filter(
      movie => movie.premiere > earliestPremiereDate,
      filteredMovies,
    )
  })()

  const classicMovies = (() => {
    return map(
      movie => ({ ...movie, genreSingle: CLASSICS }),
      filter(
        movie =>
          movie.premiere < latestClassicsDate &&
          movie.genre &&
          !movie.genre.includes('Animovaný'),
        filteredMovies,
      ),
    )
  })()

  const bestPicksMovies = (() => {
    return map(
      movie => ({ ...movie, genreSingle: TOP_RATED }),
      filter(
        movie =>
          movie.score &&
          movie.score > 80 &&
          movie.genre &&
          !movie.genre.includes('Animovaný'),
        newMovies,
      ),
    )
  })()

  const newMoviesByGenres = (() => {
    return pipe(
      filter(movie => movie.genre),
      map(movie =>
        map(genre => ({ ...movie, genreSingle: genre }), movie.genre),
      ),
      unnest,
      groupBy(movie => movie.genreSingle),
      toPairs,
      map(([genre, movies]) => ({ genre, movies })),
      filter(({ genre }) => GENRES_OBJ[genre]),
      sortWith([descend(getCategorySortIndex)]),
    )(newMovies)
  })()

  const categories = [
    ...(isEmpty(bestPicksMovies)
      ? []
      : [{ genre: TOP_RATED, movies: bestPicksMovies }]),
    ...newMoviesByGenres,
    ...(isEmpty(classicMovies)
      ? []
      : [{ genre: CLASSICS, movies: classicMovies }]),
  ]

  return categories
}
