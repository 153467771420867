import React from 'react'
import { Provider } from 'react-redux'
import { Switch } from 'react-router'
import { Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import ReactGA from 'react-ga'

// import About from './screens/About'
import Screenings from './screens/Screenings/index'
import initializeStore from './store/initializeStore'
import { history } from './store/middleware'
import Seo from './components/Seo'
import Wrapper from './components/Wrapper'
import triggerOnScroll from './utils/triggerOnScroll'
import ScreeningDetail from './screens/ScreeningDetail/ScreeningDetail'
import MoreScreenings from './screens/MoreScreenings/MoreScreenings'
import CategoryStories from './screens/CategoryStories/CategoryStories'

const store = initializeStore()

ReactGA.initialize('UA-158111191-1')
ReactGA.pageview(window.location.pathname + window.location.search)

window.addEventListener('resize', triggerOnScroll)

window.addEventListener('scroll', triggerOnScroll)

const App = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Wrapper>
        {/*<Seo />*/}
        <Screenings />
        <ScreeningDetail />
        <CategoryStories />
        <MoreScreenings />
        {/* <Switch>
          <Route path="/" component={Screenings} />
          <Route exact path="/o-projektu" component={About} />
        </Switch> */}
      </Wrapper>
    </ConnectedRouter>
  </Provider>
)

export default App
