import React from 'react'
import styled from '@emotion/styled/macro'
import Link from '../../components/Link'
import { MOUSE_DEVICE, SECONDARY_FONT } from '../../constants/CSS'
import { getFormattedMovieDuration } from '../../utils/movieDuration'
import { NETWORK_TYPES } from '../../constants/networkTypes'
import { map, join, prop } from 'ramda'
import Globe from '../../components/Globe'
import { THEME_COLOR } from '../../constants/colors'
import Clapboard from '../../components/Clapboard'
import Carpet from '../../components/Carpet'
import Genre from '../../components/Genre'
import Subtitles from '../../components/Subtitles'
import Clock from '../../components/Clock'
import Star from '../../components/Star'
import BigHall from '../../components/BigHall'
import SmallHall from '../../components/SmallHall'
import { MEDIA_FULL_DESKTOP } from '../../constants/media'
import { deriveIsBigHall } from '../../selectors/data'

const StyledMetadata = styled.div`
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;

  ${MEDIA_FULL_DESKTOP} {
    background: #f7f7f7;
    background: #f7f7f7 0% 0% no-repeat padding-box;
  }
`

const StyledFirstContainer = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 14px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 30px 35px;
  padding-bottom: 20px;
  line-height: 1.5;

  ${MEDIA_FULL_DESKTOP} {
    padding-top: 0px;
    flex-shrink: 0;
    flex: 1;
  }
`

const StyledSecondContainer = styled(StyledFirstContainer)`
  background: #f7f7f7;
  padding-bottom: 35px;
  flex: 1;

  ${MEDIA_FULL_DESKTOP} {
    padding-top: 0px;
    flex-shrink: 0;
    flex: 1;
  }
`

const StyledNetworkLinks = styled.div`
  display: flex;
  align-items: stretch;
  margin-top: 10px;

  ${MEDIA_FULL_DESKTOP} {
    justify-content: flex-end;
  }
`

const StyledNetworkLink = styled(Link)`
  text-decoration: none;
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  margin-right: 10px;
  font-weight: 900;
  color: #484848;
  text-decoration: underline;

  ${MOUSE_DEVICE} {
    &:hover {
      color: ${THEME_COLOR};
      text-decoration: none;
    }
  }
`

const StyledMetaItem = styled.div`
  display: flex;
  align-items: center;
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  font-weight: 700;
  padding: 0px 0px 15px;
  line-height: 1.5;
  color: #484848;

  & span:first-letter {
    text-transform: capitalize;
  }

  & svg {
    margin-right: 15px;
    margin-bottom: 5px;
  }
`

const StyledTitles = styled.div`
  display: flex;
  flex-direction: column;

  padding: 30px 35px 0px;

  ${MEDIA_FULL_DESKTOP} {
    padding-bottom: 30px;
  }
`

const NETWORK_LINKS = {
  csfd: id => `https://www.csfd.cz/film/${id}`,
  imdb: id => `https://www.imdb.com/title/${id}`,
}

const Network = ({ type, id }) => {
  const text = NETWORK_TYPES[type]
  const link = NETWORK_LINKS[type](id)

  return (
    <StyledNetworkLink href={link} target="_blank">
      {text}
    </StyledNetworkLink>
  )
}

const StyledTranslatedTitle = styled.div`
  font-weight: 900;
  color: #484848;
  font-size: 22px;
  font-family: ${SECONDARY_FONT};
  padding-bottom: 3px;
`

const StyledOriginalTitle = styled.div`
  font-weight: 500;
  color: #484848;
  font-size: 20px;
  font-family: ${SECONDARY_FONT};
  padding-bottom: 10px;
`

const StyledDot = styled.span`
  display: inline-block;
  height: 3px;
  width: 3px;
  margin: 4px 7px;
  border-radius: 50%;
  background: #484848;
`

const StyledSpace = styled.div`
  ${MEDIA_FULL_DESKTOP} {
    flex: 1;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${MEDIA_FULL_DESKTOP} {
    flex-direction: row;
  }
`

const ScreeningDetailMetadata = ({
  title,
  director: _director,
  language,
  origin,
  originalTitle,
  duration: _duration,
  year: _year,
  description: _description,
  genre: _genre,
  csfdId,
  csfdData = {},
  hall,
  csfd: altCsfd,
  imdb: altImdb,
}) => {
  csfdId = csfdId || (altCsfd && altCsfd.link)
  const director =
    _director ||
    (csfdData.directors && join(', ', map(prop('name'), csfdData.directors)))
  const duration = _duration || csfdData.length
  const year = _year || csfdData.year
  const genre = _genre || (csfdData.genre && join(', ', csfdData.genre))
  const imdbId = csfdData.imdbId || (altImdb && altImdb.link)
  const score = csfdData.score
  const premiere = csfdData.premiere ? new Date(csfdData.premiere) : undefined
  // const hall = pipe(
  //   filter(t => t.kind === 'theater'),
  //   map(t => t.name),
  //   head,
  // )(tags)
  return (
    <StyledMetadata>
      <StyledTitles>
        <StyledTranslatedTitle>{title}</StyledTranslatedTitle>
        {title !== originalTitle && originalTitle && (
          <StyledOriginalTitle>{originalTitle}</StyledOriginalTitle>
        )}
      </StyledTitles>
      <StyledWrapper>
        <StyledFirstContainer>
          {hall && (
            <StyledMetaItem>
              {deriveIsBigHall({ hall }) ? <BigHall /> : <SmallHall />}
              {hall}
            </StyledMetaItem>
          )}
          {(origin || year) && (
            <StyledMetaItem>
              <Globe />
              <span>
                {origin}
                {origin && year && <StyledDot />}
                {year}
              </span>
            </StyledMetaItem>
          )}
          {director && (
            <StyledMetaItem>
              <Clapboard />
              Režie: {director}
            </StyledMetaItem>
          )}
          {premiere && (
            <StyledMetaItem>
              <Carpet />
              Premiéra: {premiere.getDate()}.{premiere.getMonth() + 1}.
              {premiere.getFullYear()}
            </StyledMetaItem>
          )}
        </StyledFirstContainer>
        <StyledSecondContainer>
          {genre && (
            <StyledMetaItem>
              <Genre /> {genre}
            </StyledMetaItem>
          )}
          {language && (
            <StyledMetaItem>
              <Subtitles /> <span>{language.toLowerCase()}</span>
            </StyledMetaItem>
          )}
          {duration && (
            <StyledMetaItem>
              <Clock />
              {duration} min
              {duration > 60 && ` (${getFormattedMovieDuration(duration)})`}
            </StyledMetaItem>
          )}
          {score && (
            <StyledMetaItem>
              <Star />
              {score}%
            </StyledMetaItem>
          )}
          <StyledSpace />
          {(csfdId || imdbId) && (
            <StyledNetworkLinks>
              {csfdId && (
                <Network
                  type="csfd"
                  {...{
                    id: csfdId,
                    score,
                  }}
                />
              )}
              {imdbId && (
                <Network
                  type="imdb"
                  {...{
                    id: imdbId,
                  }}
                />
              )}
            </StyledNetworkLinks>
          )}
        </StyledSecondContainer>
      </StyledWrapper>
    </StyledMetadata>
  )
}

export default ScreeningDetailMetadata
