import typeToReducer from 'type-to-reducer'
import * as ActionTypes from '../constants/ActionTypes'

const initialState = {
  movies: {},
  screenings: [],
  screeningsByDate: [],
  movieLinks: {},
  movieCsfdLinks: {},
  movieCsfdData: {},
}

export default typeToReducer(
  {
    [ActionTypes.DATA_SUCCESS]: (state, { payload: { data } }) => ({
      ...state,
      ...data,
    }),
  },
  initialState,
)
