import React from 'react'
import { pure } from 'recompose'
import { THEME_COLOR } from '../constants/colors'

const Globe = pure(() => (
  <svg width="24.339" height="24.339" viewBox="0 0 24.339 24.339">
    <path
      fill="#484848"
      d="M12.17,0a12.17,12.17,0,1,0,12.17,12.17A12.205,12.205,0,0,0,12.17,0Zm8.4,7.3H17.037a19.234,19.234,0,0,0-1.7-4.381A9.8,9.8,0,0,1,20.567,7.3Zm-8.4-4.868A16.341,16.341,0,0,1,14.482,7.3H9.857A17.893,17.893,0,0,1,12.17,2.434ZM2.8,14.6a7.847,7.847,0,0,1-.365-2.434A7.847,7.847,0,0,1,2.8,9.736H6.937a16.31,16.31,0,0,0-.122,2.434c0,.852.122,1.582.122,2.434Zm.974,2.434H7.3a19.234,19.234,0,0,0,1.7,4.381A9.8,9.8,0,0,1,3.773,17.037ZM7.3,7.3H3.773A10.257,10.257,0,0,1,9.005,2.921,19.234,19.234,0,0,0,7.3,7.3Zm4.868,14.6a16.341,16.341,0,0,1-2.312-4.868h4.624A17.893,17.893,0,0,1,12.17,21.905Zm2.8-7.3h-5.6a16.492,16.492,0,0,1-.243-2.434,16.492,16.492,0,0,1,.243-2.434h5.72a16.493,16.493,0,0,1,.243,2.434A16.8,16.8,0,0,1,14.969,14.6Zm.365,6.815a17.266,17.266,0,0,0,1.7-4.381h3.529A9.8,9.8,0,0,1,15.334,21.418ZM17.524,14.6a16.31,16.31,0,0,0,.122-2.434c0-.852-.122-1.582-.122-2.434h4.138a7.847,7.847,0,0,1,.365,2.434,7.848,7.848,0,0,1-.365,2.434Z"
    />
  </svg>
))

export default Globe
