import React from 'react'
import { pure } from 'recompose'
import { THEME_COLOR } from '../constants/colors'

const Carpet = pure(() => (
  <svg width="24.71" height="18.825" viewBox="0 0 24.71 18.825">
    <g transform="translate(495.824 -158.51)">
      <path
        fill="#484848"
        d="M-471.114,176.754a.954.954,0,0,1-1.024.581c-1.4-.017-2.8,0-4.2-.007-.565,0-.926-.361-.852-.862a1.261,1.261,0,0,1,.17-.42c.663-1.167,1.333-2.33,2-3.5a.674.674,0,0,0,.1-.313q.009-3.476.005-6.952c0-.039-.006-.079-.01-.128a16.389,16.389,0,0,1-17.094,0v.262c0,2.245,0,4.49.007,6.735a.963.963,0,0,0,.123.45c.657,1.161,1.324,2.317,1.991,3.473a.886.886,0,0,1,.138.689.759.759,0,0,1-.777.565c-1.489.005-2.977.008-4.466,0a.762.762,0,0,1-.672-1.218c.671-1.181,1.353-2.356,2.023-3.538a.866.866,0,0,0,.11-.406q.009-3.729,0-7.459c0-.078-.007-.156-.012-.258-.266,0-.513,0-.761,0a.775.775,0,0,1-.862-.606c-.077-.352.112-.67.55-.89a2.6,2.6,0,0,1-.649-2.539,2.567,2.567,0,0,1,1.071-1.47,2.576,2.576,0,0,1,3.255.329,2.616,2.616,0,0,1,.082,3.624,3.663,3.663,0,0,1,.456.588.65.65,0,0,1-.133.7,14.5,14.5,0,0,0,14.085.063,3.759,3.759,0,0,1-.064-.758c.031-.264.247-.42.523-.528a2.611,2.611,0,0,1-.65-2.548,2.587,2.587,0,0,1,1.055-1.454,2.6,2.6,0,0,1,3.224.267,2.663,2.663,0,0,1,.077,3.736.825.825,0,0,1,.52.548.735.735,0,0,1-.694.939c-.3.011-.592,0-.911,0-.006.108-.014.194-.014.279q0,3.717,0,7.435a.915.915,0,0,0,.119.428c.645,1.14,1.3,2.275,1.952,3.411l.212.369Z"
        transform="translate(0)"
      />
      <path
        fill="#484848"
        d="M-382.209,337.812q-2.52,0-5.039,0a.727.727,0,0,1-.78-.921,1.073,1.073,0,0,1,.2-.367c.811-1.052,1.63-2.1,2.441-3.15a.883.883,0,0,1,.752-.367q2.428.007,4.856,0a.9.9,0,0,1,.769.381q1.2,1.562,2.414,3.111a.807.807,0,0,1,.163.891.757.757,0,0,1-.778.424Q-379.712,337.809-382.209,337.812Z"
        transform="translate(-101.264 -163.966)"
      />
    </g>
  </svg>
))

export default Carpet
