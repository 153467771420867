import React from 'react'
import { pure } from 'recompose'
import { THEME_COLOR } from '../constants/colors'

const Star = pure(() => (
  <svg width="23.078" height="22.012" viewBox="0 0 23.078 22.012">
    <path
      fill="#484848"
      d="M108.361,72.33l5.2-5.071a.648.648,0,0,0-.358-1.105l-7.189-1.044L102.8,58.6a.647.647,0,0,0-1.161,0L98.425,65.11l-7.19,1.044a.648.648,0,0,0-.359,1.105l5.2,5.071-1.227,7.16a.647.647,0,0,0,.939.682l6.43-3.38,6.43,3.38a.647.647,0,0,0,.939-.682Z"
      transform="translate(-90.68 -58.235)"
    />
  </svg>
))

export default Star
