import { history } from '../store/middleware'

const goBackOrPush = fallback => {
  if (history.length > 2) {
    return history.goBack()
  } else {
    return fallback()
  }
}

export default goBackOrPush
