import React from 'react'
import { pure, compose } from 'recompose'
import { ROUTE_STACK } from '../../constants/routes'
import ScreeningDetailContent from './ScreeningDetailContent'
import StackScreen from '../../components/StackScreen'

const ScreeningDetail = () => {
  return (
    <StackScreen {...{ routeKey: ROUTE_STACK.SCREENING_DETAIL }}>
      {({ item }) => {
        const { screeningId } = item
        return <ScreeningDetailContent {...{ screeningId }} />
      }}
    </StackScreen>
  )
}

export default compose(pure)(ScreeningDetail)
