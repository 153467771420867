import { compose, withStateHandlers } from 'recompose'

import withLifecycle from './withLifecycle'
import omitProps from './omitProps'
import { FULL_DESKTOP_WIDTH, EXTENDED_DESKTOP_WIDTH } from '../constants/media'

const HANDLE_SCREEN_RESIZE = '$WITH_SCREEN_WIDTH_HANDLE_SCREEN_RESIZE'

const withScreenWidth = compose(
  withStateHandlers(
    () => ({
      screenWidth: window.innerWidth,
      isDesktop: window.innerWidth >= FULL_DESKTOP_WIDTH,
      isExtendedDesktop: window.innerWidth >= EXTENDED_DESKTOP_WIDTH,
    }),
    {
      [HANDLE_SCREEN_RESIZE]: () => () => ({
        screenWidth: window.innerWidth,
        isDesktop: window.innerWidth >= FULL_DESKTOP_WIDTH,
        isExtendedDesktop: window.innerWidth >= EXTENDED_DESKTOP_WIDTH,
      }),
    },
  ),
  withLifecycle({
    componentDidMount: ({
      [HANDLE_SCREEN_RESIZE]: handleScreenResize,
    }) => () => {
      window.addEventListener('resize', handleScreenResize)
    },
    componentWillUnmount: ({
      [HANDLE_SCREEN_RESIZE]: handleScreenResize,
    }) => () => {
      window.removeEventListener('resize', handleScreenResize)
    },
  }),
  omitProps([HANDLE_SCREEN_RESIZE]),
)

export default withScreenWidth
