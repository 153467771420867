import React from 'react'
import { pure, compose } from 'recompose'
import { ROUTE_STACK } from '../../constants/routes'
import StackScreen from '../../components/StackScreen'
import { CategoryStoriesContent } from './CategoryStoriesContent'

const CategoryStories = () => {
  return (
    <StackScreen {...{ routeKey: ROUTE_STACK.STORIES, noAnimation: true }}>
      {({ item }) => {
        const { genre } = item
        // console.log({ genre })
        return <CategoryStoriesContent {...{ genre }} />
      }}
    </StackScreen>
  )
}

export default compose(pure)(CategoryStories)
