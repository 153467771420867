import React from 'react'
import { connect } from 'react-redux'
import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  withPropsOnChange,
} from 'recompose'
import { sum, pipe, map, reduce } from 'ramda'
import styled from '@emotion/styled/macro'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
`

const DayScreeningsVirtualList = ({
  renderItem,
  shouldRender,
  height,
  list,
  topOffsetsList,
  setContainerHeight,
  setRef,
  getItemKey,
}) => {
  return (
    <StyledContainer ref={setRef} style={{ height }}>
      {list.map((item, i) => {
        if (shouldRender(i, item)) {
          return (
            <div
              key={getItemKey(item)}
              style={{
                position: 'absolute',
                top: topOffsetsList[i],
                width: '100%',
              }}
            >
              {renderItem(item, setContainerHeight)}
            </div>
          )
        } else {
          return null
        }
      })}
    </StyledContainer>
  )
}

export default compose(
  connect(({ selectedScreening, ui: { trailerFullscreen } }) => ({
    selectedScreening,
    trailerFullscreen,
  })),
  withState('topOffset', 'setTopOffset', {}),
  withState('containerHeight', 'setContainerHeight', {}),
  // withState('containerHeight', 'set', {}),
  withPropsOnChange(
    ['list', 'getItemHeight', 'containerHeight', 'selectedScreening'],
    ({ list, getItemHeight, containerHeight, selectedScreening }) => ({
      heightList: pipe(
        map(item =>
          getItemHeight(
            item,
            selectedScreening && containerHeight.screening === selectedScreening
              ? containerHeight
              : {},
          ),
        ),
      )(list),
    }),
  ),
  withPropsOnChange(['heightList'], ({ heightList }) => ({
    height: pipe(sum)(heightList),
  })),
  withPropsOnChange(['heightList'], ({ heightList }) => ({
    topOffsetsList: pipe(
      reduce(
        ([lastItemBottomOffset, offsetsList], itemHeight) => {
          offsetsList.push(lastItemBottomOffset)
          return [lastItemBottomOffset + itemHeight, offsetsList]
        },
        [0, []],
      ),
      ([_, offsetsList]) => offsetsList,
    )(heightList),
  })),
  withHandlers(() => {
    let ref = undefined
    return {
      setRef: () => _ref => {
        ref = _ref
      },
      onScroll: ({ setTopOffset }) => () => {
        if (ref) {
          setTopOffset(ref.getBoundingClientRect().top)
        }
      },
    }
  }),
  withHandlers({
    shouldRender: ({
      topOffsetsList,
      heightList,
      topOffset,
      containerHeight: { height = 0 },
      trailerFullscreen,
    }) => (i, [date]) => {
      if (topOffset !== undefined) {
        const itemTopOffset = topOffsetsList[i]
        const itemHeight = heightList[i]

        const trueItemTopOffset = itemTopOffset + topOffset
        const trueItemBottomOffset = trueItemTopOffset + itemHeight

        const windowHeight = window.innerHeight
        const limit = 300 + height

        return (
          (trueItemTopOffset < 0 && 0 < trueItemBottomOffset) ||
          (trueItemTopOffset > -limit &&
            trueItemTopOffset < windowHeight + limit) ||
          (trueItemBottomOffset > -limit &&
            trueItemBottomOffset < windowHeight + limit) ||
          trailerFullscreen === date
        )
      } else {
        return false
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { onScroll } = this.props
      onScroll()
      window.addEventListener('customScroll', onScroll)
    },
    componentWillUnmount() {
      const { onScroll } = this.props
      window.removeEventListener('customScroll', onScroll)
    },
  }),
)(DayScreeningsVirtualList)
