export const MEDIA_DESKTOP = '@media (min-width: 835px)'

export const FULL_DESKTOP_WIDTH = 1000

export const EXTENDED_DESKTOP_WIDTH = 1300

export const MEDIA_FULL_DESKTOP = `@media (min-width: ${FULL_DESKTOP_WIDTH}px)`

export const MEDIA_EXTENDED_DESKTOP = `@media (min-width: ${EXTENDED_DESKTOP_WIDTH}px)`

export const MEDIA_IOS = '@supports (-webkit-overflow-scrolling: touch)'
