import React from 'react'
import styled from '@emotion/styled/macro'

export const StyledHoverDetailImage = styled.div`
  display: block;
  width: 100%;
  max-width: 280px;
  padding-bottom: min(${(200 / 280) * 100}%, 200px);
  background: ${({ image }) => `url('${image}')`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 15px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  border-radius: 3px;
`

const ScreeningHoverDetailRightImage = ({ image }) => {
  return <StyledHoverDetailImage {...{ image }} />
}

export default ScreeningHoverDetailRightImage
