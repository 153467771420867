import React from 'react'
import { CLASSICS, TOP_RATED } from '../constants/storiesGenres'

const None = () => null

export const storiesGenresIcons = {
  [TOP_RATED]: () => {
    return (
      <svg width="25.027" height="24.267" viewBox="0 0 25.027 24.267">
        <path
          fill="#fff"
          d="M24.961,9.665a1.332,1.332,0,0,0-1.144-.924l-7.225-.664L13.738,1.314a1.325,1.325,0,0,0-2.448,0L8.435,8.077l-7.226.664a1.336,1.336,0,0,0-1.144.924A1.358,1.358,0,0,0,.453,11.1l5.461,4.845L4.3,23.117a1.354,1.354,0,0,0,.517,1.39,1.314,1.314,0,0,0,1.462.065l6.23-3.769,6.229,3.769a1.317,1.317,0,0,0,1.463-.065,1.354,1.354,0,0,0,.517-1.39l-1.61-7.176L24.573,11.1A1.358,1.358,0,0,0,24.961,9.665ZM12.633,20.73"
          transform="translate(0 -0.496)"
        />
      </svg>
    )
  },
  [CLASSICS]: None,
  ['Komedie']: () => {
    return (
      <svg
        width={20.927}
        height={23.657}
        viewBox="0 0 20.927 23.657"
        style={{ position: 'relative', top: 2 }}
      >
        <path
          d="M0 2.133v11.06a10.464 10.464 0 1020.927 0V2.133A2.134 2.134 0 0018.36.046a40.371 40.371 0 01-7.9.7 40.371 40.371 0 01-7.9-.7A2.149 2.149 0 002.124 0 2.132 2.132 0 00-.004 2.133zm12.324 5.58a4.072 4.072 0 015.759 0 1.282 1.282 0 11-1.812 1.812 1.509 1.509 0 00-2.134 0 1.282 1.282 0 01-1.813-1.812zm.542 7.229a1.282 1.282 0 111.812 1.812 5.962 5.962 0 01-8.431 0 1.282 1.282 0 011.812-1.812 3.4 3.4 0 004.807 0zM2.699 7.713a4.077 4.077 0 015.759 0 1.281 1.281 0 11-1.812 1.812 1.511 1.511 0 00-2.135 0 1.282 1.282 0 01-1.812-1.812z"
          fill="#fff"
        />
      </svg>
    )
  },
  ['Akční']: () => {
    return (
      <svg
        width={27.436}
        height={16.293}
        viewBox="0 0 27.436 16.293"
        style={{ position: 'relative', top: 1, left: -1 }}
      >
        <path
          fill="#fff"
          d="M.808 1.622a.812.812 0 01.177.02v3.273A.807.807 0 010 4.127v-1.7a.808.808 0 01.808-.805zM2.863 1.127h.287V.808a.808.808 0 011.617 0v.319h19.284a.8.8 0 01.748.511l.206.526.944-.545.254-.147a.823.823 0 011.1.251.808.808 0 01-.276 1.138l-.486.28-.944.545.162.412H7.263v-.559A.539.539 0 006.724 3H2.063V1.932a.8.8 0 01.8-.805zM6.189 4.078v.559a.539.539 0 00.539.539h19.29v.652a.808.808 0 01.412.5.82.82 0 01-.034.536 4.939 4.939 0 00-.153 3.3l.993 3.243a2.231 2.231 0 01-2.134 2.884h-3.84a.808.808 0 01-.8-.686l-.62-4.031h-1.771a3.532 3.532 0 01-3.532-3.532V6.868H2.867a.8.8 0 01-.8-.8V4.079zm9.967 3.967a1.917 1.917 0 001.915 1.915h1.525l-.06-.39a.816.816 0 01-1.114-.64l-.128-.981-.141-1.078h-2z"
        />
      </svg>
    )
  },
  ['Romantický']: None,
  ['Drama']: () => {
    return (
      <svg
        width={21.046}
        height={23.791}
        viewBox="0 0 21.046 23.791"
        style={{ position: 'relative', top: 2 }}
      >
        <path
          d="M18.898.009a1.775 1.775 0 00-.435.045 40.911 40.911 0 01-7.936.7 41 41 0 01-7.945-.7A1.805 1.805 0 002.138 0 2.156 2.156 0 000 2.147v11.125a10.491 10.491 0 00.6 3.515 10.54 10.54 0 009.929 7 10.537 10.537 0 009.92-7 10.491 10.491 0 00.6-3.515V2.147A2.132 2.132 0 0018.898.009zm-6.5 7.755a4.093 4.093 0 015.782-.009v.009a1.28 1.28 0 010 1.821 1.3 1.3 0 01-1.821 0 1.509 1.509 0 00-2.147 0 1.285 1.285 0 01-.888.353 1.3 1.3 0 01-.933-.39 1.286 1.286 0 01.003-1.784zm-9.676 0a4.085 4.085 0 015.789 0 1.28 1.28 0 010 1.821 1.3 1.3 0 01-1.821 0 1.509 1.509 0 00-2.147 0 1.3 1.3 0 01-1.821 0 1.28 1.28 0 01-.004-1.821zm12.04 10.772a1.28 1.28 0 01-1.821 0 3.426 3.426 0 00-2.41-1 3.415 3.415 0 00-2.419 1 1.28 1.28 0 01-1.821 0 1.28 1.28 0 010-1.821 5.977 5.977 0 018.471 0 1.277 1.277 0 01.381.906 1.3 1.3 0 01-.385.915z"
          fill="#fff"
        />
      </svg>
    )
  },
  ['Fantasy']: None,
  ['Sci-Fi']: None,
  ['Dobrodružný']: None,
  ['Rodinný']: None,
  ['Thriller']: None,
  ['Dokumentární']: None,
  ['Životopisný']: None,
  ['Historický']: None,
  ['Horor']: None,
  ['Muzikál']: None,
  ['Western']: None,
  ['Hudební']: None,
  ['Pohádka']: None,
  ['Animovaný']: None,
}
