import typeToReducer from 'type-to-reducer'
import * as ActionTypes from '../constants/ActionTypes'

const initialState = {
  returnToTopButtonVisible: false,
  screeningsToShowCount: 150,
  filterMenuVisible: false,
  trailerFullscreen: undefined,
  priceFeature:
    (window.localStorage && window.localStorage.getItem('priceFeature')) ||
    false,
}

export default typeToReducer(
  {
    [ActionTypes.SET_RETURN_TO_TOP_VISIBILITY]: (
      state,
      { payload: { visible } },
    ) => ({
      ...state,
      returnToTopButtonVisible: visible,
    }),
    [ActionTypes.SET_FILTER_MENU_VISIBILITY]: (
      state,
      { payload: { visible } },
    ) => ({
      ...state,
      filterMenuVisible: visible,
    }),
    [ActionTypes.SET_SCREENINGS_TO_SHOW_COUNT]: (
      state,
      { payload: { count } },
    ) => ({
      ...state,
      screeningsToShowCount: count,
    }),
    [ActionTypes.SET_TRAILER_FULLSCREEN]: (
      state,
      { payload: { trailerFullscreen } },
    ) => ({
      ...state,
      trailerFullscreen: trailerFullscreen,
    }),
  },
  initialState,
)
