import typeToReducer from 'type-to-reducer'
import * as ActionTypes from '../constants/ActionTypes'

export const initialState = {
  where: {},
  daysOfWeek: {},
  date: undefined,
  movies: {},
  price: false,
  favourites: false,
  query: '',
}

export default typeToReducer(
  {
    [ActionTypes.SET_FILTER_WHERE]: (state, { payload: { where } }) => ({
      ...state,
      where,
    }),
    [ActionTypes.SET_FILTER_WHERE_ITEM]: (
      state,
      { payload: { cinemaName, isSelected } },
    ) => ({
      ...state,
      where: {
        ...state.where,
        [cinemaName]: isSelected,
      },
    }),
    [ActionTypes.SET_FILTER_DAYS_OF_WEEK]: (
      state,
      { payload: { daysOfWeek } },
    ) => ({
      ...state,
      daysOfWeek,
    }),
    [ActionTypes.SET_FILTER_DATE]: (state, { payload: { date } }) => ({
      ...state,
      date,
    }),
    [ActionTypes.SET_FILTER_MOVIES]: (state, { payload: { movies } }) => ({
      ...state,
      movies,
    }),
    [ActionTypes.SET_FILTER_MOVIES_ITEM]: (
      state,
      { payload: { canonicalTitle, isSelected } },
    ) => ({
      ...state,
      movies: {
        ...state.movies,
        [canonicalTitle]: isSelected,
      },
    }),
    [ActionTypes.SET_FILTER_PRICE]: (state, { payload: { price } }) => ({
      ...state,
      price,
    }),
    [ActionTypes.SET_FILTER_FAVOURITES]: (
      state,
      { payload: { favourites } },
    ) => ({
      ...state,
      favourites,
    }),
    [ActionTypes.SET_FILTER_QUERY]: (state, { payload: { query } }) => ({
      ...state,
      query,
    }),
    [ActionTypes.RESET_FILTER]: () => initialState,
  },
  initialState,
)
