import {
  path,
  prop,
  pipe,
  isEmpty,
  not,
  reject,
  equals,
  map,
  filter,
  pluck,
  defaultTo,
  head,
  take,
} from 'ramda'
import lookup from '../utils/lookup'
import composeSelector from '../utils/composeSelectors'
import getHallName from '../utils/getHallName'

export const isDataLoaded = pipe(
  path(['data', 'screenings']),
  isEmpty,
  not,
)

export const getMovies = path(['data', 'movies'])

export const getScreeningsObj = path(['data', 'screeningsObj'])

export const getScreening = (state, { id }) => {
  return path([id], getScreeningsObj(state))
}

export const getScreeningMovieId = composeSelector(
  [getScreening],
  path(['movie']),
)

export const getScreeningMovieById = (state, { id }) => {
  return path([id], getMovies(state))
}

export const getScreeningMovie = composeSelector(
  [getScreeningMovieId, getMovies],
  (movieId, movies) => path([movieId], movies),
)

export const getScreeningMovieCanonicalTitle = composeSelector(
  [getScreeningMovie],
  path(['canonicalTitle']),
)

export const getCsfdMovies = path(['data', 'movieCsfdData'])

export const getCsfdLinks = path(['data', 'movieCsfdLinks'])

export const getScreeningCsfdMovie = composeSelector(
  [getCsfdMovies, getScreeningMovieCanonicalTitle],
  (csfdMovies, canonicalTitle) => path([canonicalTitle], csfdMovies),
)

export const getScreeningCsfdLink = composeSelector(
  [getCsfdLinks, getScreeningMovieCanonicalTitle],
  (csfdLinks, canonicalTitle) => path([canonicalTitle], csfdLinks),
)

export const getScreeningImage = composeSelector(
  [getScreeningMovie, getScreeningCsfdMovie],
  (movie, csfdMovie) => {
    return (
      prop('image', movie) ||
      (csfdMovie && csfdMovie.images && csfdMovie.images[0]) ||
      prop('imageAlt', movie)
    )
  },
)

export const getScreeningHall = composeSelector(
  [getScreening],
  ({ tags, hall } = {}) => hall || getHallName(tags),
)

export const deriveIsBigHall = ({ hall }) => hall.includes('Velký')

export const deriveOtherScreenings = ({ movie, screeningsObj, id }) => {
  return pipe(
    prop('screenings'),
    defaultTo([]),
    reject(equals(id)),
    map(lookup(screeningsObj || {})),
    filter(Boolean),
  )(movie)
}

export const deriveAllScreenings = ({ movie, screeningsObj }) => {
  return pipe(
    prop('screenings'),
    defaultTo([]),
    map(lookup(screeningsObj || {})),
    filter(Boolean),
  )(movie)
}

export const deriveOtherMovies = ({ otherScreenings, movies }) => {
  return pipe(
    pluck('movie'),
    map(lookup(movies || {})),
    filter(Boolean),
  )(otherScreenings || [])
}

export const deriveMovieFallbackedData = ({
  movie,
  otherMovies,
  csfdMovie,
}) => {
  const getFallbackProp = propName =>
    head(otherMovies.filter(m => !!m[propName]).map(m => m[propName]))
  const withFallBack = (prop, propName) => prop || getFallbackProp(propName)
  const withFallBackReversed = (prop, propName) =>
    getFallbackProp(propName) || prop

  const {
    title,
    youtube,
    director,
    language,
    origin,
    originalTitle,
    duration,
    year,
    csfd,
    imdb,
    website,
    description,
    genre,
  } = movie

  return {
    title,
    language,
    website,
    youtube: withFallBack(youtube, 'youtube'),
    director: withFallBack(director, 'director'),
    origin: withFallBack(origin, 'origin'),
    originalTitle: withFallBack(originalTitle, 'originalTitle') || '',
    duration:
      duration && duration > 600
        ? withFallBackReversed(duration, 'duration')
        : withFallBack(duration, 'duration'),
    year:
      year && year < 1800
        ? withFallBackReversed(year, 'year')
        : withFallBack(year, 'year'),
    csfd: withFallBack(csfd, 'csfd'),
    imdb: withFallBack(imdb, 'imdb'),
    description: withFallBack(description, 'description'),
    genre: withFallBack(genre, 'genre'),
    score: prop('score', csfdMovie),
    actors: prop('actors', csfdMovie) || [],
    images: prop('images', csfdMovie),
  }
}
