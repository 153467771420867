export default (x, y, msDuration) => {
  if (!msDuration) {
    window.scrollTo(x, y)
  } else {
    const scrollStep = -window.scrollY / (msDuration / 15)
    const scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep)
      } else {
        clearInterval(scrollInterval)
      }
    }, 15)
  }
}
