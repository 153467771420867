import { useState, useEffect } from 'react'

export const useGeolocation = (shouldGet = true) => {
  const [location, setLocation] = useState(
    shouldGet && navigator.geolocation ? null : false,
  )

  useEffect(
    () => {
      if (shouldGet && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            setLocation(position)
          },
          () => {
            setLocation(false)
          },
        )
      }
    },
    [shouldGet],
  )

  return location
}
