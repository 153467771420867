import React from 'react'
import styled from '@emotion/styled/macro'
import { pure, compose, withHandlers, withState, lifecycle } from 'recompose'

import {
  SECONDARY_FONT,
  MOUSE_DEVICE,
  SHOW_HIDE_FAST_DURATION,
  CHROME_ANDROID_NO_HIGHLIGHT,
} from '../../constants/CSS'
import { PRIMARY_BACKGROUND_COLOR, THEME_COLOR } from '../../constants/colors'
import {
  CINEMA_NAMES,
  CINEMA_MAP_LINKS,
  CINEMA_LOCATIONS,
} from '../../constants/cinemas'
import CinemaLogo from '../../components/CinemaLogo'
import { DAYS_OF_WEEK_FULL } from '../../constants/date'
import Ticket from '../../components/Ticket'
import VerticalDivider from '../../components/VerticalDivider'
import Share from '../../components/Share'
import Ripple from '../../components/Ripple'
import { MEDIA_FULL_DESKTOP } from '../../constants/media'
import ScreeningDetailInfoHeart from './ScreeningDetailInfoHeart'
import { map } from 'ramda'
import MoreScreeningsItem, {
  StyledScreeningDate,
  StyledScreeningTime,
  StyledScreeningDateTime,
} from '../MoreScreenings/MoreScreeningsItem'
import { StyledPrice } from '../../components/Price'
// import { StyledHall } from '../../components/Hall'
import { StyledCinemaLogoContainer } from '../../components/Cinema'
import withContentRenderDefer from '../../hoc/withContentRenderDefer'
import { zindex } from '../../constants/zIndexes'
import BigHall from '../../components/BigHall'
import SmallHall from '../../components/SmallHall'
import Tram from '../../components/Tram'
import { deriveIsBigHall } from '../../selectors/data'
import getTimeText from '../../utils/getTimeText'

const StyledRightPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;

  width: 300px;
  flex-shrink: 0;
  margin-left: 15px;
`

const StyledTicketInfo = styled.div`
  position: relative;
  background: ${THEME_COLOR};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 40px 0px 40px;

  border-radius: 3px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);

  &:before {
    content: 'Odkaz zkopírován do schránky';
    position: 'absolute';
    font-family: ${SECONDARY_FONT};
    color: ${THEME_COLOR};
    background: ${PRIMARY_BACKGROUND_COLOR};
    font-weight: 900;
    font-size: 14px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    padding: 8px 10px;
    border-radius: 50px;
    position: absolute;
    top: 39px;
    left: -5px;
    z-index: ${zindex.tooltip};
    transition: opacity 0.2s ease-out;

    opacity: ${({ tooltipVisible }) => (tooltipVisible ? 0.9 : 0)};
  }
`

const StyledCinemaName = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 20px;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
  color: ${PRIMARY_BACKGROUND_COLOR};
  font-weight: 900;
  margin-bottom: 14px;

  display: flex;
  align-items: center;
`

const StyledDateContainer = styled.div`
  display: flex;
  align-items: center;

  margin-bottom: 15px;
`

const StyledDot = styled.div`
  height: 3px;
  width: 3px;
  margin: 0px 7px;
  border-radius: 50%;
  background: ${PRIMARY_BACKGROUND_COLOR};
`

const StyledDate = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${PRIMARY_BACKGROUND_COLOR};
  font-weight: 500;
`

const StyledHour = styled(StyledDate)`
  font-weight: 700;
`

const StyledCinemaLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;

  & svg {
    position: relative;
  }

  position: relative;
  margin-bottom: 20px;
`

const StyledCinemaLogoContainerBackground = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  border-radius: 50%;
  background: ${PRIMARY_BACKGROUND_COLOR};
  opacity: 1;
`

const StyledBuyButton = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 11px 18px;
  border-radius: 50px;
  background: ${PRIMARY_BACKGROUND_COLOR};
  text-transform: uppercase;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  font-family: ${SECONDARY_FONT};
  color: ${THEME_COLOR};
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  font-size: 13px;
  font-weight: 900;
  margin-top: 16px;

  & svg {
    margin-left: 6px;
  }

  transition: background ${SHOW_HIDE_FAST_DURATION} ease-out;

  ${MOUSE_DEVICE} {
    &:hover {
      /* opacity: 0.9; */
      background: #b1c8e1;
    }
  }

  ${CHROME_ANDROID_NO_HIGHLIGHT}
`

const StyledCinemaDetailButton = styled(StyledBuyButton)``.withComponent('a')

const StyledShareButton = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  cursor: pointer;
  user-select: none;
  position: absolute;
  right: 20px;
  top: 30px;

  & svg {
    margin-right: 2px;
  }

  transition: background ${SHOW_HIDE_FAST_DURATION} ease-out;

  ${MOUSE_DEVICE} {
    &:hover {
      /* opacity: 0.9; */
      background: #b1c8e1;
    }
  }

  ${CHROME_ANDROID_NO_HIGHLIGHT}
`

const StyledFirstGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledSecondGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledMoreScreenings = styled.div`
  margin-top: 15px;

  display: flex;
  flex-direction: column;
  width: 100%;

  ${StyledScreeningDate} {
    font-size: 14px;
  }

  ${StyledScreeningTime} {
    font-size: 14px;
  }

  ${StyledPrice} {
    font-size: 14px;
  }

  ${StyledCinemaLogoContainer} {
    height: 35px;
  }

  ${StyledScreeningDateTime} {
    padding: 13px 0px;
  }
`

const StyledHall = styled.div`
  display: flex;
  align-items: center;

  & svg {
    margin-left: 15px;
    margin-bottom: 5px;
  }

  & svg path {
    fill: ${PRIMARY_BACKGROUND_COLOR};
    stroke: ${PRIMARY_BACKGROUND_COLOR};
  }
`
const StyledCinemaHall = styled.div`
  display: flex;
  align-items: center;
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  font-weight: 700;
  padding: 0px 0px 15px;
  line-height: 1.5;
  color: ${PRIMARY_BACKGROUND_COLOR};

  & svg {
    margin-left: 15px;
    margin-bottom: 5px;
  }

  & svg path {
    fill: ${PRIMARY_BACKGROUND_COLOR};
    stroke: ${PRIMARY_BACKGROUND_COLOR};
  }
`

const StyledLocation = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${PRIMARY_BACKGROUND_COLOR};
  font-weight: 700;
`

const MoreScreenings = withContentRenderDefer(
  ({ numOfOtherScreenings, otherScreenings }) => {
    return numOfOtherScreenings > 0 ? (
      <StyledMoreScreenings>
        {map(
          screening => (
            <MoreScreeningsItem key={screening.id} screening={screening} />
          ),
          otherScreenings,
        )}
      </StyledMoreScreenings>
    ) : null
  },
)

const ScreeningDetailRightPanel = ({
  cinema,
  time,
  date,
  price,
  buyLink,
  numOfOtherScreenings,
  onShareClick,
  screeningId,
  otherScreenings,
  tooltipVisible,
  hall,
}) => {
  const dateText = `${
    DAYS_OF_WEEK_FULL[date.getDay()]
  } ${date.getDate()}/${date.getMonth() + 1}`

  return (
    <StyledRightPanel>
      <StyledTicketInfo {...{ tooltipVisible }}>
        <StyledShareButton
          {...{
            onClick: onShareClick,
          }}
        >
          <Share />
          <Ripple {...{ color: PRIMARY_BACKGROUND_COLOR, opacity: 0.3 }} />
        </StyledShareButton>
        {/*<ScreeningDetailInfoHeart {...{ screeningId }} />*/}

        <StyledFirstGroup>
          <StyledCinemaLogo>
            <StyledCinemaLogoContainerBackground />
            <CinemaLogo
              {...{
                cinema,
                width: 40,
                height: 40,
                SECONDARY_COLOR: THEME_COLOR,
              }}
            />
          </StyledCinemaLogo>
          <StyledCinemaName>
            {CINEMA_NAMES[cinema]}{' '}
            {hall && (
              <StyledHall>
                {/* {hall} */}
                {deriveIsBigHall({ hall }) ? <BigHall /> : <SmallHall />}
              </StyledHall>
            )}
          </StyledCinemaName>
          <StyledDateContainer>
            <StyledDate>{dateText}</StyledDate>
            <StyledDot />
            <StyledHour>{getTimeText(time)}</StyledHour>
          </StyledDateContainer>
          <StyledLocation>{CINEMA_LOCATIONS[cinema]}</StyledLocation>
        </StyledFirstGroup>

        <VerticalDivider {...{ space: 10 }} />

        <StyledSecondGroup>
          {CINEMA_MAP_LINKS[cinema] && (
            <StyledCinemaDetailButton
              {...{ target: '_blank', href: CINEMA_MAP_LINKS[cinema] }}
            >
              Jak se tam dostanu <Tram />
              <Ripple {...{ color: THEME_COLOR, opacity: 0.3 }} />
            </StyledCinemaDetailButton>
          )}
          {price !== undefined && buyLink && (
            <StyledBuyButton {...{ target: '_blank', href: buyLink }}>
              Koupit lístek za {price}Kč <Ticket />
              <Ripple {...{ color: THEME_COLOR, opacity: 0.3 }} />
            </StyledBuyButton>
          )}
        </StyledSecondGroup>
      </StyledTicketInfo>
      <MoreScreenings {...{ numOfOtherScreenings, otherScreenings }} />
    </StyledRightPanel>
  )
}

export default compose(
  withState('tooltipVisible', 'setTooltipVisible', false),
  withHandlers({
    onShareClick: ({
      title: movieTitle,
      cinema,
      date,
      time,
      setTooltipVisible,
    }) => () => {
      const cinemaName = CINEMA_NAMES[cinema]
      const dateText = `${
        DAYS_OF_WEEK_FULL[date.getDay()]
      } ${date.getDate()}/${date.getMonth() + 1}`
      const title = `${movieTitle} - ${cinemaName} - ${dateText} ${getTimeText(
        time,
      )}`
      const url = window.location.href

      if (navigator.share) {
        navigator.share({
          title,
          url,
        })
      } else {
        const hiddenInput = document.createElement('input')

        document.body.appendChild(hiddenInput)
        hiddenInput.value = url
        hiddenInput.select()
        document.execCommand('copy')
        document.body.removeChild(hiddenInput)

        setTooltipVisible(true)
        setTimeout(() => {
          setTooltipVisible(false)
        }, 1500)
      }
    },
  }),
  pure,
)(ScreeningDetailRightPanel)
