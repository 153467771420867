import prop from 'ramda/src/prop'
import pipe from 'ramda/src/pipe'
import not from 'ramda/src/not'
import { compose, withState, branch, renderNothing } from 'recompose'

import withLifecycle from './withLifecycle'

/**
 * Use when you want to show loader while content is rendered
 */
const withContentRenderDefer = compose(
  withState('didMount', 'setDidMount', false),
  withLifecycle({
    componentDidMount: ({ setDidMount }) => () => {
      setTimeout(() => setDidMount(true), 0)
    },
  }),
  branch(
    pipe(
      prop('didMount'),
      not,
    ),
    renderNothing,
  ),
)

export default withContentRenderDefer
