import React from 'react'
import styled from '@emotion/styled/macro'
import CinemaLogo from './CinemaLogo'
import {
  SHOW_HIDE_COMMON_SNIPPET,
  SHOW_HIDE_MOBILE_SNIPPET,
  SHOW_HIDE_MOUSE_SNIPPET,
  CHROME_ANDROID_NO_HIGHLIGHT,
  TOUCH_DEVICE,
  CHROME_MOBILE_HIGHLIGHT,
  SECONDARY_FONT,
} from '../constants/CSS'
import { CINEMA_NAMES } from '../constants/cinemas'
import { zindex } from '../constants/zIndexes'

export const StyledCinemaLogoContainer = styled.div`
  width: ${({ containerSize }) => containerSize}px;
  height: ${({ containerSize }) => containerSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  z-index: ${zindex.cinemaLogo};

  ${CHROME_ANDROID_NO_HIGHLIGHT}
`

export const StyledCinemaLogo = styled.div`
  display: flex;
  position: relative;
  ${CHROME_ANDROID_NO_HIGHLIGHT}
`

const CINEMA_LOGO_DARK_COLOR = '#1c1b19'

const Cinema = ({ cinema, onCinemaClick, show, containerSize, logoSize }) => (
  <StyledCinemaLogoContainer
    onClick={onCinemaClick}
    containerSize={containerSize}
  >
    <StyledCinemaLogo
      show={show}
      // show={hoveredElement === `cinema_${id}`}
      description={CINEMA_NAMES[cinema]}
    >
      <CinemaLogo
        cinema={cinema}
        width={logoSize}
        height={logoSize}
        SECONDARY_COLOR={CINEMA_LOGO_DARK_COLOR}
      />
    </StyledCinemaLogo>
  </StyledCinemaLogoContainer>
)

export default Cinema
