import React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled/macro'
import {
  compose,
  withState,
  withProps,
  withHandlers,
  lifecycle,
  pure,
} from 'recompose'
import { map, prop, pipe, propEq, find, filter, toPairs } from 'ramda'
import {
  SHOW_HIDE_FAST_DURATION,
  MOUSE_DEVICE,
  CHROME_ANDROID_NO_HIGHLIGHT,
  TOUCH_DEVICE,
  SECONDARY_FONT,
} from '../constants/CSS'
import { setFilterWhere } from '../actions/filter'
import CinemaLogo from './CinemaLogo'
import scrollToTop from '../utils/scrollToTop'
import { CINEMAS_ORDER } from '../constants/cinemas'
import Ripple from './Ripple'
import eventStopPropagation from '../utils/eventStopPropagation'

const StyledContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0px 0px;
`

const StyledTitle = styled.div`
  font-family: ${SECONDARY_FONT};
  display: flex;
  flex-direction: column;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  user-select: none;
`

const StyledCinemasContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 15px;

  ${CHROME_ANDROID_NO_HIGHLIGHT}
`

const CINEMA_LOGO_DARK_COLOR = '#1c1b19'

const StyledCinemaContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  opacity: 1;
  border: 3px solid ${({ selected }) => (selected ? 'white' : 'white')};
  background: ${({ selected }) =>
    selected ? 'white' : CINEMA_LOGO_DARK_COLOR};
  /* background: ${({ selected }) => (selected ? '#fff' : '#000')}; */
  /* background: ${({ selected }) => (selected ? '#fff' : '#000')}; */
  cursor: pointer;
  margin: 10px;

	/* filter: invert(${({ selected }) => (selected ? '0%' : '100%')}); */
  transition: background-color ${SHOW_HIDE_FAST_DURATION}, filter ${SHOW_HIDE_FAST_DURATION}, box-shadow ${SHOW_HIDE_FAST_DURATION};

  & svg .PRIMARY_COLOR, & svg .SECONDARY_COLOR {
    transition: fill ${SHOW_HIDE_FAST_DURATION};
  }

  ${MOUSE_DEVICE} {
    &:hover {
      background: white;
      & svg .PRIMARY_COLOR {
        fill: white;
      }
      & svg .SECONDARY_COLOR {
        fill: black;
      }
      border: 3px solid ${({ selected }) => (selected ? '' : 'white')};
      box-shadow: 0 0px 15px 0 rgba(255,255,255,0.3), 0 0px 18px 0 rgba(255,255,255,0.3);
    }
  }
  border-radius: 50%;
  overflow: hidden;
  opacity: 0.99; /* needed because of safari ripple overflow bug */
`

const Where = ({ where, onClick }) => (
  <StyledContainer>
    <StyledTitle>Kam?</StyledTitle>
    <StyledCinemasContainer>
      {pipe(
        map(cinemaName => {
          const selected = where[cinemaName]

          return (
            <StyledCinemaContainer
              key={cinemaName}
              onClick={onClick(cinemaName)}
              selected={selected}
            >
              <Ripple {...{ color: '#777', opacity: 0.6 }} />
              <CinemaLogo
                cinema={cinemaName}
                width={35}
                height={35}
                PRIMARY_COLOR={selected ? 'white' : CINEMA_LOGO_DARK_COLOR}
                SECONDARY_COLOR={selected ? CINEMA_LOGO_DARK_COLOR : 'white'}
              />
            </StyledCinemaContainer>
          )
        }),
      )(CINEMAS_ORDER)}
    </StyledCinemasContainer>
  </StyledContainer>
)

export default compose(
  pure,
  connect(
    ({ filter: { where } }) => ({
      where,
    }),
    { setFilterWhere },
  ),
  withState('internalValue', 'setInternalValue', ({ where }) => where),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.where !== prevProps.where) {
        this.props.setInternalValue(this.props.where)
      }
    },
  }),
  withProps(({ internalValue }) => ({ where: internalValue })),
  withHandlers({
    onCinemaClick: ({
      where,
      setFilterWhere,
      setInternalValue,
    }) => cinemaName => {
      const newWhere = {
        ...where,
        [cinemaName]: !where[cinemaName],
      }
      setInternalValue(newWhere)
      window.requestAnimationFrame(() => {
        setTimeout(() => {
          window.requestAnimationFrame(() => {
            setFilterWhere(newWhere)
          })
        }, 180)
      })
    },
  }),
  withHandlers({
    onClick: ({ onCinemaClick }) => cinemaName => e => {
      e.stopPropagation()
      onCinemaClick(cinemaName)
    },
  }),
  pure,
)(Where)
