import { parse } from 'query-string'
import curry from 'ramda/src/curry'
import pipe from 'ramda/src/pipe'
import defaultTo from 'ramda/src/defaultTo'
import prop from 'ramda/src/prop'
import path from 'ramda/src/path'

export const getRouterLocation = path(['router', 'location'])

export const getRouterLocationPathname = pipe(
  getRouterLocation,
  prop('pathname'),
)

export const getRouterLocationSearch = pipe(
  getRouterLocation,
  prop('search'),
)

export const getRouterLocationQuery = pipe(
  getRouterLocationSearch,
  defaultTo(''),
  parse,
)

export const getRouterLocationQueryKey = curry(({ key }, state) => {
  return (getRouterLocationQuery(state) || {})[key]
})
