const WHITE = `#FFFFFF`
const GREY = `#484848`
const LIGHT_GREY = `#999`

export const THEME_COLOR = '#1f5797'
export const PRIMARY_BACKGROUND_COLOR = WHITE
export const PRIMARY_TEXT_COLOR = GREY
export const SECONDARY_TEXT_COLOR = LIGHT_GREY

export const DESKTOP_FILTER_ITEM_BG_COLOR = WHITE
