import React from 'react'
import { pure } from 'recompose'
import { THEME_COLOR } from '../constants/colors'

const Clock = pure(() => (
  <svg width="25.19" height="25.183" viewBox="0 0 25.19 25.183">
    <g transform="translate(-65.745 -206.749)">
      <g transform="translate(65.999 206.999)">
        <path
          fill="#484848"
          stroke="#484848"
          strokeWidth="0.5px"
          d="M78.346,208.94a10.4,10.4,0,1,1-7.836,3.555,10.391,10.391,0,0,1,7.836-3.555h0m0-1.941a12.341,12.341,0,1,0,8.117,3.05A12.315,12.315,0,0,0,78.345,207Z"
          transform="translate(-65.999 -206.999)"
        />
      </g>
      <g transform="translate(76.421 211.934)">
        <g transform="translate(0.44 0)">
          <rect
            fill="#484848"
            stroke="#484848"
            strokeWidth="0.5px"
            width="2.219"
            height="8.857"
            rx="1.109"
            transform="translate(0 0)"
          />
          <path
            fill="#484848"
            stroke="#484848"
            strokeWidth="0.5px"
            d="M106.276,224.794a1.109,1.109,0,0,0-1.109,1.109v6.639a1.109,1.109,0,1,0,2.219,0V225.9a1.109,1.109,0,0,0-1.109-1.109Z"
            transform="translate(-105.167 -224.794)"
          />
        </g>
        <g transform="translate(0 6.201)">
          <rect
            fill="#484848"
            stroke="#484848"
            strokeWidth="0.5px"
            width="2.219"
            height="8.857"
            rx="1.109"
            transform="matrix(-0.589, 0.808, -0.808, -0.589, 8.466, 5.213)"
          />
          <path
            fill="#484848"
            stroke="#484848"
            strokeWidth="0.5px"
            d="M106.277,248.741a1.11,1.11,0,0,0-.654,2.006l5.367,3.907a1.109,1.109,0,0,0,1.306-1.794l-5.367-3.907a1.1,1.1,0,0,0-.652-.213Z"
            transform="translate(-104.726 -248.301)"
          />
        </g>
      </g>
    </g>
  </svg>
))

export default Clock
