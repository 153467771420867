import {
  compose,
  withHandlers,
  withState,
  pure,
  withPropsOnChange,
  mapProps,
} from 'recompose'
import mergeLeft from 'ramda/src/mergeLeft'
import pipe from 'ramda/src/pipe'
import gt from 'ramda/src/gt'
import __ from 'ramda/src/__'
import when from 'ramda/src/when'
import equals from 'ramda/src/equals'
import findIndex from 'ramda/src/findIndex'
import subtract from 'ramda/src/subtract'
import max from 'ramda/src/max'
import add from 'ramda/src/add'
import min from 'ramda/src/min'

import {
  requestAnimationTimeout,
  cancelAnimationTimeout,
} from '../utils/requestAnimationTimeout'
import withLifecycle from '../utils/withLifecycle'

const VirtualList = compose(
  withPropsOnChange(['itemHeights'], ({ itemHeights }) => ({
    itemHeightsSums: itemHeights.reduce((sums, val, i) => {
      const sum = val + (i === 0 ? 0 : sums[i - 1])
      // eslint-disable-next-line immutable/no-mutation
      sums[i] = sum

      return sums
    }, []),
    itemsCount: itemHeights ? itemHeights.length : 0,
  })),
  withState('state', 'setState', {
    oldFirstRenderIndex: 0,
    oldLastRenderIndex: 0,
    firstRenderIndex: 0,
    lastRenderIndex: 0,
    isScrolling: false,
    containerTopPadding: 0,
    containerBottomPadding: 0,
  }),
  withHandlers(({ setState }) => {
    // eslint-disable-next-line immutable/no-let
    let containerRef

    const getContainerTopOffset = () =>
      -containerRef.getBoundingClientRect().top
    const getContainerBottomOffset = () =>
      -containerRef.getBoundingClientRect().bottom
    const getContainerBottomScreenOffset = () =>
      getContainerTopOffset() + window.innerHeight

    // eslint-disable-next-line immutable/no-let
    let scrollEndTimeoutId = undefined

    const scrollEnd = () => {
      const callback = () => setState(mergeLeft({ isScrolling: false }))

      if (scrollEndTimeoutId) {
        cancelAnimationTimeout(scrollEndTimeoutId)
      }

      scrollEndTimeoutId = requestAnimationTimeout(callback, 50)
    }

    // eslint-disable-next-line immutable/no-let
    let lastContainerTopOffset = 0
    // eslint-disable-next-line immutable/no-let
    let lastContainerBottomOffset = 0

    return {
      update: ({
        state: {
          isScrolling: previousIsScrolling,
          firstRenderIndex: previousFirstRenderIndex,
          lastRenderIndex: previousLastRenderIndex,
          oldFirstRenderIndex,
          oldLastRenderIndex,
        },
        setState,
        itemsCount,
        itemHeightsSums,
        listBufferSize = 3,
        visible = true,
      }) => (isScrolling = false) => {
        if (!itemsCount || !containerRef || (isScrolling && !visible)) {
          return
        }

        const containerTopOffset = getContainerTopOffset()
        const containerBottomOffset = getContainerBottomOffset()
        if (
          Math.floor(containerTopOffset) === lastContainerTopOffset ||
          Math.floor(containerBottomOffset) === lastContainerBottomOffset
        ) {
          // eslint-disable-next-line no-param-reassign
          isScrolling = false
        }
        lastContainerTopOffset = Math.floor(containerTopOffset)
        lastContainerBottomOffset = Math.floor(containerBottomOffset)

        const firstRenderIndex = pipe(
          findIndex(gt(__, containerTopOffset)),
          when(equals(-1), () => itemsCount - 1),
          subtract(__, listBufferSize),
          max(0),
        )(itemHeightsSums)

        const lastRenderIndex = pipe(
          findIndex(gt(__, getContainerBottomScreenOffset())),
          when(equals(-1), () => itemsCount - 1),
          add(listBufferSize),
          min(itemsCount - 1),
        )(itemHeightsSums)

        const containerTopPadding =
          firstRenderIndex === 0 ? 0 : itemHeightsSums[firstRenderIndex - 1]
        const containerBottomPadding =
          itemHeightsSums[itemsCount - 1] - itemHeightsSums[lastRenderIndex]

        const newOldFirstRenderIndex =
          previousIsScrolling === false && isScrolling
            ? previousFirstRenderIndex
            : oldFirstRenderIndex

        const newOldLastRenderIndex =
          previousIsScrolling === false && isScrolling
            ? previousLastRenderIndex
            : oldLastRenderIndex

        setState(
          mergeLeft({
            firstRenderIndex,
            lastRenderIndex,
            oldFirstRenderIndex: newOldFirstRenderIndex,
            oldLastRenderIndex: newOldLastRenderIndex,
            containerTopPadding,
            containerBottomPadding,
            isScrolling,
          }),
        )

        if (isScrolling) {
          scrollEnd()
        }
      },
      setContainerRef: () => r => {
        containerRef = r
      },
      shouldRenderItem: ({
        state: { firstRenderIndex, lastRenderIndex },
      }) => index => {
        return index >= firstRenderIndex && index <= lastRenderIndex
      },
      scrollToIndex: ({ itemHeightsSums, scrollOffset = 0 }) => index => {
        const bodyTopOffset = document.body.getBoundingClientRect().top
        const containerTopOffset = containerRef.getBoundingClientRect().top

        const scrollPosition =
          containerTopOffset -
          bodyTopOffset +
          (index === 0 ? 0 : itemHeightsSums[index - 1]) -
          scrollOffset
        window.scrollTo(0, scrollPosition)
      },
    }
  }),
  withPropsOnChange(['itemHeights'], ({ update }) => {
    update()
  }),
  withHandlers({
    handleScroll: ({ update }) => () => {
      update(true)
    },
    shouldRenderPlaceholder: ({
      state: { oldFirstRenderIndex, oldLastRenderIndex, isScrolling },
      shouldRenderItem,
    }) => index => {
      return (
        isScrolling &&
        shouldRenderItem(index) &&
        (index < oldFirstRenderIndex || index > oldLastRenderIndex)
      )
    },
  }),
  withLifecycle({
    componentDidMount: ({
      setScrollToIndexHandler,
      handleScroll,
      update,
      scrollToIndex,
    }) => () => {
      window.addEventListener('scroll', handleScroll)
      window.addEventListener('resize', handleScroll)
      update()
      setScrollToIndexHandler && setScrollToIndexHandler(scrollToIndex)
    },
    componentWillUnmount: ({ setScrollToIndexHandler, handleScroll }) => () => {
      setScrollToIndexHandler && setScrollToIndexHandler(undefined)
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
    },
  }),
  mapProps(
    ({
      children,
      state: {
        isScrolling,
        containerTopPadding,
        containerBottomPadding,
        firstRenderIndex,
        lastRenderIndex,
        oldFirstRenderIndex,
        oldLastRenderIndex,
      },
      shouldRenderItem,
      setContainerRef,
      shouldRenderPlaceholder,
      doNotPassIsSrolling = false,
    }) => ({
      isScrolling: doNotPassIsSrolling ? undefined : isScrolling,
      containerTopPadding,
      containerBottomPadding,
      shouldRenderItem,
      setContainerRef,
      shouldRenderPlaceholder,
      children,
      firstRenderIndex,
      lastRenderIndex,
      oldFirstRenderIndex,
      oldLastRenderIndex,
    }),
  ),
  pure,
)(({ children, ...props }) => {
  return children(props)
})

export default VirtualList
