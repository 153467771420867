import React from 'react'
import { connect } from 'react-redux'
import {
  compose,
  lifecycle,
  withHandlers,
  withState,
  withPropsOnChange,
} from 'recompose'
import { sum, pipe, map, reduce } from 'ramda'
import styled from '@emotion/styled/macro'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
`

const MoviesFilterVirtualList = ({
  renderItem: Item,
  shouldRender,
  height,
  list,
  topOffsetsList,
  setRef,
  getItemKey,
  onClick,
  titleCharMap,
  movies,
  setCharRef,
}) => {
  return (
    <StyledContainer ref={setRef} style={{ height }}>
      {list.map((item, i) => {
        const [canonicalTitle, title] = item
        const char = titleCharMap[canonicalTitle]
        const selected = movies[canonicalTitle]
        if (shouldRender(i, !!char)) {
          return (
            <div
              key={getItemKey(item)}
              style={{
                position: 'absolute',
                top: topOffsetsList[i],
                width: '100%',
              }}
            >
              <Item
                {...{
                  onClick,
                  canonicalTitle,
                  title,
                  char,
                  selected,
                  setCharRef,
                }}
              />
            </div>
          )
        } else {
          return null
        }
      })}
    </StyledContainer>
  )
}

export default compose(
  withState('topOffset', 'setTopOffset', {}),
  withPropsOnChange(['heightList'], ({ heightList }) => ({
    height: pipe(sum)(heightList),
  })),
  withPropsOnChange(['heightList'], ({ heightList }) => ({
    topOffsetsList: pipe(
      reduce(
        ([lastItemBottomOffset, offsetsList], itemHeight) => {
          offsetsList.push(lastItemBottomOffset)
          return [lastItemBottomOffset + itemHeight, offsetsList]
        },
        [0, []],
      ),
      ([_, offsetsList]) => offsetsList,
    )(heightList),
  })),
  withHandlers(() => {
    let ref = undefined
    return {
      setRef: () => _ref => {
        ref = _ref
      },
      onScroll: ({ setTopOffset, visible }) => initial => {
        if (ref && (visible || initial)) {
          setTopOffset(ref.getBoundingClientRect().top)
        }
      },
    }
  }),
  withHandlers({
    shouldRender: ({ topOffsetsList, heightList, topOffset }) => (
      i,
      hasChar,
    ) => {
      if (topOffset !== undefined) {
        const itemTopOffset = topOffsetsList[i]
        const itemHeight = heightList[i]

        const trueItemTopOffset = itemTopOffset + topOffset
        const trueItemBottomOffset = trueItemTopOffset + itemHeight

        const windowHeight = window.innerHeight
        const limit = 400

        return (
          hasChar ||
          (trueItemTopOffset < 0 && 0 < trueItemBottomOffset) ||
          (trueItemTopOffset > -limit &&
            trueItemTopOffset < windowHeight + limit) ||
          (trueItemBottomOffset > -limit &&
            trueItemBottomOffset < windowHeight + limit)
        )
      } else {
        return false
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { onScroll } = this.props
      onScroll(true)
      window.addEventListener('customScroll', onScroll)
    },
    componentWillUnmount() {
      const { onScroll } = this.props
      window.removeEventListener('customScroll', onScroll)
    },
  }),
)(MoviesFilterVirtualList)
