export const SCREENING_ROUTE = 'projekce'
export const ALL_SCREENINGS = 'vsechny-projekce'
export const CINEMA_ROUTE = 'bijak'

export const createMainRoute = () => `/`

export const createScreeningDetailRoute = ({ screeningId }) =>
  `/${SCREENING_ROUTE}/${screeningId}`

export const createAllScreeningsRoute = ({ movieId }) =>
  `/${ALL_SCREENINGS}/${movieId}`

export const createCinemaDetailRoute = ({ cinemaId }) =>
  `/${CINEMA_ROUTE}/${cinemaId}`

export const ROUTE_STACK = {
  MAIN: 'MAIN_SCREEN',
  STORIES: 'STORIES',
  FILTERS_CINEMA: 'FILTERS_CINEMA',
  FILTERS_MOVIE: 'FILTERS_MOVIE',
  SCREENING_DETAIL: 'SCREENING_DETAIL',
  MORE_SCREENINGS: 'MORE_SCREENINGS',
  CINEMA_DETAIL: 'CINEMA_DETAIL',
}
