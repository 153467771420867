import createAction from '../utils/createAction'
import * as ActionTypes from '../constants/ActionTypes'

export const setHoveredScreeningElement = ({ screeningId, element }) =>
  createAction(ActionTypes.SET_HOVERED_SCREENING_ELEMENT, {
    screeningId,
    element,
  })

export const unsetHoveredScreeningElement = () =>
  createAction(ActionTypes.UNSET_HOVERED_SCREENING_ELEMENT)
