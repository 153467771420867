import { map } from 'ramda'
import canonizeTitle from '../canonizeTitle'

export const AERO = 'aero'
export const BIOOKO = 'biooko'
export const SVETOZOR = 'svetozor'
export const LUCERNA = 'lucerna'
export const DLABACOV = 'dlabacov'
export const EVALD = 'evald'
export const ATLAS = 'atlas'
export const MAT = 'mat'
export const PILOTU = 'pilotu'
export const KINO35 = 'kino35'
export const RADOTIN = 'radotin'
export const MODRANSKY_BIOGRAF = 'modransky'
export const EDISON = 'edison'
export const KINOLOD = 'kinolod'
export const KARLIN = 'karlin'
export const PONREPO = 'ponrepo'
export const PRITOMNOST = 'pritomnost'
export const MOJEKINOLIVE = 'mojekinolive'
export const STRAHOV = 'strahov'
export const LEDARNY = 'ledarny'

export const CINEMA_NAMES = {
  [AERO]: 'Kino Aero',
  [BIOOKO]: 'Bio Oko',
  [SVETOZOR]: 'Kino Světozor',
  [LUCERNA]: 'Kino Lucerna',
  [DLABACOV]: 'Kino Dlabačov',
  [EVALD]: 'Komorní kino Evald',
  [ATLAS]: 'Kino Atlas',
  [MAT]: 'Kino MAT',
  [PILOTU]: 'Kino Pilotů',
  [KINO35]: 'Kino 35 Francouzského institutu',
  [RADOTIN]: 'Kino Radotín',
  [MODRANSKY_BIOGRAF]: 'Modřanský biograf',
  [EDISON]: 'Filmhub Edison',
  [KINOLOD]: 'Kinoloď',
  [KARLIN]: 'Kasárna Karlín',
  [PONREPO]: 'Kino Ponrepo',
  [PRITOMNOST]: 'Kino Přítomnost',
  [MOJEKINOLIVE]: 'Moje kino LIVE',
  [STRAHOV]: 'Autokino Strahov',
  [LEDARNY]: 'AutoKino Ledárny',
}

export const SHORT_CINEMA_NAMES = {
  [AERO]: 'Aero',
  [BIOOKO]: 'Bio Oko',
  [SVETOZOR]: 'Světozor',
  [LUCERNA]: 'Lucerna',
  [DLABACOV]: 'Dlabačov',
  [EVALD]: 'Evald',
  [ATLAS]: 'Atlas',
  [MAT]: 'MAT',
  [PILOTU]: 'Kino Pilotů',
  [KINO35]: 'Kino 35',
  [RADOTIN]: 'Radotín',
  [MODRANSKY_BIOGRAF]: 'Modřanský biograf',
  [EDISON]: 'Edison',
  [KINOLOD]: 'Kinoloď',
  [KARLIN]: 'Kasárna Karlín',
  [PONREPO]: 'Ponrepo',
  [PRITOMNOST]: 'Přítomnost',
  [MOJEKINOLIVE]: 'Moje kino LIVE',
  [STRAHOV]: 'Autokino Strahov',
  [LEDARNY]: 'AutoKino Ledárny',
}

export const SHORT_CINEMA_NAMES_WHERE = {
  [AERO]: 'v Aeru',
  [BIOOKO]: 'v Bio Oku',
  [SVETOZOR]: 've Světozoru',
  [LUCERNA]: 'v Lucerně',
  [DLABACOV]: 'v Dlabačově',
  [EVALD]: 'v Evaldu',
  [ATLAS]: 'v Atlasu',
  [MAT]: 'v MATu',
  [PILOTU]: 'v Kině Pilotů',
  [KINO35]: 'v Kině 35',
  [RADOTIN]: 'v Radotíně',
  [MODRANSKY_BIOGRAF]: 'v Modřanech',
  [EDISON]: 'v Edisonu',
  [KINOLOD]: 'v Kinolodi',
  [KARLIN]: 'v Kasárnách Karlín',
  [PONREPO]: 'v Ponrepu',
  [PRITOMNOST]: 'v Přítomnosti',
  [MOJEKINOLIVE]: 'Moje kino LIVE',
  [STRAHOV]: 'v Autokině Strahov',
  [LEDARNY]: 'v AutoKině Ledárny',
}

export const CINEMA_LOCATIONS = {
  [AERO]: 'Na Žižkově',
  [BIOOKO]: 'V Holešovicích',
  [SVETOZOR]: 'U Václaváku',
  [LUCERNA]: 'U Václaváku',
  [DLABACOV]: 'V Břevnově',
  [EVALD]: 'Na Národní',
  [ATLAS]: 'Na Florenci',
  [MAT]: 'Na Karláku',
  [PILOTU]: 'Ve Vršovicích',
  [KINO35]: 'U Václaváku',
  [RADOTIN]: 'V Radotíně',
  [MODRANSKY_BIOGRAF]: 'V Modřanech',
  [EDISON]: 'V Jindřišské',
  [KINOLOD]: 'V Holešovicích',
  [KARLIN]: 'V Karlíně',
  [PONREPO]: 'Na Národní',
  [PRITOMNOST]: 'Na Žižkově',
  [MOJEKINOLIVE]: 'Stream',
  [STRAHOV]: 'na Strahově',
  [LEDARNY]: 'v Braníku',
}

export const CINEMA_LOCATIONS_FORMAL = {
  [AERO]: 'Žižkov',
  [BIOOKO]: 'Letná',
  [SVETOZOR]: 'Václavské náměstí',
  [LUCERNA]: 'Václavské náměstí',
  [DLABACOV]: 'Břevnov',
  [EVALD]: 'Národní',
  [ATLAS]: 'Florenc',
  [MAT]: 'Karlovo náměstí',
  [PILOTU]: 'Vršovice',
  [KINO35]: 'Václavské náměstí',
  [RADOTIN]: 'Radotín',
  [MODRANSKY_BIOGRAF]: 'Modřany',
  [EDISON]: 'Jindřišská ulice',
  [KINOLOD]: 'Holešovice',
  [KARLIN]: 'Karlín',
  [PONREPO]: 'Národní',
  [PRITOMNOST]: 'Žižkov',
  [MOJEKINOLIVE]: 'Stream',
  [STRAHOV]: 'Strahov',
  [LEDARNY]: 'Braník',
}

export const CINEMA_MAP_LINKS = {
  [AERO]:
    'https://www.google.com/maps/place/Kino+Aero/@50.0901754,14.4697259,17z/data=!3m1!4b1!4m5!3m4!1s0x470b935c7f0a0a35:0xcec0a53841f09d05!8m2!3d50.090172!4d14.4719146',
  [BIOOKO]:
    'https://www.google.com/maps/place/Bio+Oko/@50.0995687,14.4277517,17z/data=!3m1!4b1!4m5!3m4!1s0x470b94c60f369991:0xced4ccb8f888fee!8m2!3d50.0995653!4d14.4299404',
  [SVETOZOR]:
    'https://www.google.com/maps/place/Kino+Sv%C4%9Btozor/@50.081836,14.4230702,17z/data=!3m1!4b1!4m5!3m4!1s0x470b94ed1491d57b:0x8437d3ca844207bd!8m2!3d50.0818326!4d14.4252589',
  [LUCERNA]:
    'https://www.google.com/maps/place/Kino+Lucerna/@50.0813773,14.4230571,17z/data=!3m1!4b1!4m5!3m4!1s0x470b94ed6a1b741b:0xbc350a80170e7447!8m2!3d50.0813739!4d14.4252459',
  [DLABACOV]:
    'https://www.google.com/maps/place/Dlaba%C4%8Dov/@50.0856018,14.3770236,17z/data=!3m1!4b1!4m5!3m4!1s0x470b950e15e8204f:0xea752ce693991bce!8m2!3d50.0855984!4d14.3792123',
  [EVALD]:
    'https://www.google.com/maps/place/Komorn%C3%AD+kino+Evald/@50.0824427,14.4179309,17z/data=!3m1!4b1!4m5!3m4!1s0x470b94ee74d089bd:0x5860c24f7be7d6c4!8m2!3d50.0824393!4d14.4201196',
  [ATLAS]:
    'https://www.google.com/maps/place/Kino+Atlas/@50.0915907,14.4361925,17z/data=!3m1!4b1!4m5!3m4!1s0x470b94be647d75c9:0xbd2d156d6ddcde0e!8m2!3d50.0915873!4d14.4383812',
  [MAT]:
    'https://www.google.com/maps/place/Klubov%C3%A9+Kino+Mat/@50.0778414,14.4171036,17z/data=!3m1!4b1!4m5!3m4!1s0x470b94f3c5ef0f37:0xc782fe997a028bd3!8m2!3d50.077838!4d14.4192923',
  [PILOTU]:
    'https://www.google.com/maps/place/Kino+Pilot%C5%AF/@50.0714092,14.4446297,17z/data=!3m1!4b1!4m5!3m4!1s0x470b94817217bb6b:0xf779b55b908352ad!8m2!3d50.0714058!4d14.4468184',
  [KINO35]:
    'https://www.google.com/maps/place/Kino+35/@50.0788507,14.422936,17z/data=!3m1!4b1!4m5!3m4!1s0x470b94f2cec22773:0x57d6d04a502bd0ed!8m2!3d50.0788473!4d14.4251247',
  [RADOTIN]:
    'https://www.google.com/maps/place/Kino+Radot%C3%ADn/@49.984247,14.3541321,17z/data=!3m1!4b1!4m5!3m4!1s0x470b970f5e2179eb:0x413bb54f250dc5c9!8m2!3d49.9842436!4d14.3563207',
  [MODRANSKY_BIOGRAF]:
    'https://www.google.com/maps/place/Mod%C5%99ansk%C3%BD+Biograf/@50.0034833,14.4024552,17z/data=!3m1!4b1!4m5!3m4!1s0x470b96cf211124ff:0xfc65f5317fdeb91!8m2!3d50.0034799!4d14.4046439',
  [EDISON]:
    'https://www.google.com/maps/place/Edison+Filmhub/@50.0848663,14.4292099,17z/data=!3m1!4b1!4m5!3m4!1s0x470b95f7730ba369:0x4c2284df47814b15!8m2!3d50.0848629!4d14.4313986',
  [KINOLOD]:
    'https://www.google.com/maps/place/50.109268,14.454516/@50.109268,14.454516,16z',
  [KARLIN]:
    'https://www.google.com/maps/place/Kas%C3%A1rna+Karl%C3%ADn/@50.0899105,14.4411504,17z/data=!3m1!4b1!4m5!3m4!1s0x470b94bd05a920d1:0xb4ab62b11de22eb4!8m2!3d50.0899071!4d14.4433391',
  [PONREPO]:
    'https://www.google.com/maps/place/Kino+NFA+Ponrepo/@50.0831298,14.4154784,17z/data=!3m1!4b1!4m5!3m4!1s0x470b94ee4e82dc6f:0xa6d97f03353c46c!8m2!3d50.0831264!4d14.4176671',
  [PRITOMNOST]:
    'https://www.google.com/maps/place/P%C5%99%C3%ADtomnost/@50.0845067,14.440981,17z/data=!3m1!4b1!4m5!3m4!1s0x470b95cf25e6713f:0x596475a449ccb658!8m2!3d50.0845067!4d14.4431697',
  [STRAHOV]:
    'https://www.google.com/maps/place/Autokino+Strahov/@50.0823569,14.3835269,17z/data=!3m1!4b1!4m5!3m4!1s0x470b95440d91f0b9:0xb02f5c7bb4ef8daf!8m2!3d50.0823569!4d14.3857156',
  [LEDARNY]:
    'https://www.google.com/maps/place/Led%C3%A1rny+Bran%C3%ADk/@50.031627,14.4099707,14.96z/data=!4m9!1m2!2m1!1sautokino+ledarny!3m5!1s0x470b941e448ca3cb:0x935919152601056a!8m2!3d50.0329141!4d14.4067496!15sChBhdXRva2lubyBsZWRhcm55WhwKCGF1dG9raW5vIhBhdXRva2lubyBsZWRhcm55kgETaGlzdG9yaWNhbF9sYW5kbWFya5oBJENoZERTVWhOTUc5blMwVkpRMEZuU1VSTGEwMWxRMnhSUlJBQg',
  [MOJEKINOLIVE]: '',
}

export const CINEMA_SEARCH_NAMES = map(
  s => canonizeTitle(s).split(' '),
  CINEMA_NAMES,
)

export const CINEMAS_ORDER = [
  LEDARNY,
  STRAHOV,
  BIOOKO,
  EDISON,
  KARLIN,
  KINO35,
  AERO,
  ATLAS,
  DLABACOV,
  LUCERNA,
  MAT,
  PILOTU,
  SVETOZOR,
  // KINOLOD,
  PONREPO,
  PRITOMNOST,
  RADOTIN,
  EVALD,
  MODRANSKY_BIOGRAF,
  MOJEKINOLIVE,
]

export const CINEMAS_POSITIONS = {
  [BIOOKO]: {
    latitude: 50.1000008,
    longitude: 14.4279053,
  },
  [EDISON]: {
    latitude: 50.0848663,
    longitude: 14.4292099,
  },
  [KARLIN]: {
    latitude: 50.0898051,
    longitude: 14.4411975,
  },
  [KINO35]: {
    latitude: 50.0788507,
    longitude: 14.422936,
  },
  [AERO]: {
    latitude: 50.0901624,
    longitude: 14.4697083,
  },
  [ATLAS]: {
    latitude: 50.0915713,
    longitude: 14.4362246,
  },
  [DLABACOV]: {
    latitude: 50.0856319,
    longitude: 14.3769521,
  },
  [LUCERNA]: {
    latitude: 50.0813729,
    longitude: 14.4232139,
  },
  [MAT]: {
    latitude: 50.0778414,
    longitude: 14.4171036,
  },
  [PILOTU]: {
    latitude: 50.0714092,
    longitude: 14.4446297,
  },
  [SVETOZOR]: {
    latitude: 50.081715,
    longitude: 14.4230752,
  },
  [PONREPO]: {
    latitude: 50.0831298,
    longitude: 14.4154784,
  },
  [PRITOMNOST]: {
    latitude: 50.0845101,
    longitude: 14.440981,
  },
  [RADOTIN]: {
    latitude: 49.9841701,
    longitude: 14.3542002,
  },
  [EVALD]: {
    latitude: 50.0824427,
    longitude: 14.4179309,
  },
  [MODRANSKY_BIOGRAF]: {
    latitude: 50.0034333,
    longitude: 14.40247,
  },
  [MOJEKINOLIVE]: null,
  [STRAHOV]: {
    latitude: 50.0820023,
    longitude: 14.383999,
  },
  [LEDARNY]: {
    latitude: 50.031627,
    longitude: 14.4099707,
  },
}
