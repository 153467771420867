import React, { memo } from 'react'
import styled from '@emotion/styled/macro'
import { map, range } from 'ramda'

import { StoriesComponentProgress } from './StoriesComponentProgress'

const StyledStoriesProgressBar = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 7px 8px 8px;
  align-self: center;
  filter: drop-shadow(0 1px 8px #222);
  z-index: 2;

  transition: opacity 200ms ease-in-out;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
`

export const StoriesComponentProgressBar = memo(
  ({ currentIndex, length, progressRef, paused }) => {
    return (
      <StyledStoriesProgressBar {...{ hidden: paused }}>
        {map(index => {
          return (
            <StoriesComponentProgress
              {...{
                key: index,
                status:
                  currentIndex < index
                    ? 'FUTURE'
                    : currentIndex > index
                    ? 'PAST'
                    : 'CURRENT',
                progressRef: currentIndex === index ? progressRef : undefined,
              }}
            />
          )
        }, range(0, length))}
      </StyledStoriesProgressBar>
    )
  },
)
