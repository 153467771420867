import path from 'ramda/src/path'
import { matchPath } from 'react-router'

import { find, dropLast, assocPath } from 'ramda'
import { ROUTE_STACK, SCREENING_ROUTE, CINEMA_ROUTE } from '../constants/routes'
import locationToStack from '../utils/locationToStack'
import Route from 'route-parser'

const stackHistory = ({ getState }) => next => (action = {}) => {
  const { type } = action

  if (type === '@@router/LOCATION_CHANGE') {
    const state = getState()
    const stateLocation = path(['router', 'location'], state)
    const actionLocation = path(['payload', 'location'], action)

    const routerAction = path(['payload', 'action'], action)
    const isFirstRendering = path(['payload', 'isFirstRendering'], action)

    // console.log({
    //   routerAction,
    //   action,
    //   actionLocation,
    //   stateLocation,
    // })

    if (routerAction === 'POP') {
      const stack = locationToStack(stateLocation)

      if (isFirstRendering) {
        if (stack) {
          const newAction = assocPath(
            ['payload', 'location', 'state'],
            JSON.stringify(stack),
            action,
          )

          return next(newAction)
        }

        return next(action)
      } else {
        // const newStack = [...dropLast(1, stack || [])]
        // const newAction = assocPath(
        //   ['payload', 'location', 'state'],
        //   JSON.stringify(newStack),
        //   action,
        // )
        // return next(newAction)
      }
    }

    // if (routerAction === 'REPLACE') {
    //   const stackItem = JSON.parse(path(['state'], actionLocation))
    //   const stack = locationToStack(stateLocation)

    //   const newStack = [...dropLast(1, stack || []), stackItem]

    //   const newAction = assocPath(
    //     ['payload', 'location', 'state'],
    //     JSON.stringify(newStack),
    //     action,
    //   )

    //   return next(newAction)
    // }

    // if (routerAction === 'PUSH') {
    //   const stackItem = JSON.parse(path(['state'], actionLocation))
    //   const stack = locationToStack(stateLocation)

    //   const newStack = [...(stack || []), stackItem]

    //   const newAction = assocPath(
    //     ['payload', 'location', 'state'],
    //     JSON.stringify(newStack),
    //     action,
    //   )

    //   return next(newAction)
    // }

    return next(action)
  }

  return next(action)
}

export default stackHistory
