import { all } from 'ramda'

const levenshteinDistance = (a, b, max) => {
  if (a.length == 0) return b.length
  if (b.length == 0) return a.length

  var matrix = []

  // increment along the first column of each row
  var i
  for (i = 0; i <= b.length; i++) {
    matrix[i] = [i]
  }

  // increment each column in the first row
  var j
  for (j = 0; j <= a.length; j++) {
    matrix[0][j] = j
  }

  // Fill in the rest of the matrix
  for (i = 1; i <= b.length; i++) {
    let end = true
    for (j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) == a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1]
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          Math.min(
            matrix[i][j - 1] + 1, // insertion
            matrix[i - 1][j] + 1,
          ),
        ) // deletion
      }

      if (matrix[i][j] <= max) {
        end = false
      }
    }

    if (end) {
      return 100000
    }
  }

  return matrix[b.length][a.length]
}

const matchesQuery = ({ item, query, fast = false, maxDistance = 1 }) => {
  return all(q => {
    for (const it of item) {
      if (it.includes(q)) {
        return true
      }
    }

    if (!fast) {
      for (const it of item) {
        if (levenshteinDistance(q, it, maxDistance) <= maxDistance) {
          return true
        }
      }
    }

    return false
  })(query)
}

export default matchesQuery
