import React from 'react'
import { pure } from 'recompose'
import { THEME_COLOR } from '../constants/colors'

const Tram = pure(() => (
  <svg width="12.71" height="19.531" viewBox="0 0 12.71 19.531">
    <g transform="translate(-102.638)">
      <g transform="translate(102.638)">
        <path
          fill={THEME_COLOR}
          d="M111.45,2.966h-1.822V1.271h4.025a.456.456,0,0,1,.424.424.636.636,0,0,0,1.271,0A1.7,1.7,0,0,0,113.653,0h-9.321a1.7,1.7,0,0,0-1.695,1.695.635.635,0,1,0,1.271,0,.4.4,0,0,1,.424-.424h4.025V2.923h-1.822a3.734,3.734,0,0,0-3.728,3.728v7.626a1.691,1.691,0,0,0,1.44,1.695l-1.229,2.33a.872.872,0,0,0,.339,1.144.629.629,0,0,0,.381.085.893.893,0,0,0,.763-.466l.254-.508h8.389l.254.508a.893.893,0,0,0,.763.466,1.237,1.237,0,0,0,.381-.085.8.8,0,0,0,.339-1.144l-1.229-2.33a1.729,1.729,0,0,0,1.44-1.695V6.694A3.61,3.61,0,0,0,111.45,2.966Zm1.017,11.524a1.229,1.229,0,1,1,1.229-1.229A1.23,1.23,0,0,1,112.467,14.489ZM104.333,7.16a2.333,2.333,0,0,1,2.373-2.33h4.66a2.36,2.36,0,0,1,2.373,2.33V9.236a.815.815,0,0,1-.847.8h-7.753a.815.815,0,0,1-.847-.8C104.333,9.236,104.333,7.16,104.333,7.16Zm1.186,4.83a1.229,1.229,0,1,1-1.229,1.229A1.23,1.23,0,0,1,105.519,11.99Zm-.085,5.381.678-1.313h5.719l.678,1.313Z"
          transform="translate(-102.638 0)"
        />
      </g>
    </g>
  </svg>
))

export default Tram
