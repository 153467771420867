import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled/macro'
import { compose, equals, find, indexOf, prop } from 'ramda'
import { lifecycle, pure, withHandlers, withPropsOnChange } from 'recompose'
import connect from '../hoc/connect'
import { getRouterLocation } from '../selectors/router'
import withDerivedProps from '@surglogs/with-derived-props'
import {
  ALL_SCREENINGS,
  CINEMA_ROUTE,
  ROUTE_STACK,
  SCREENING_ROUTE,
} from '../constants/routes'
import { MEDIA_DESKTOP, MEDIA_IOS } from '../constants/media'
import triggerOnScroll from '../utils/triggerOnScroll'
import Route from 'route-parser'

const TRANSITION_DURATION = '0.2s'

const getTransitionDuration = ({ noAnimation }) =>
  noAnimation ? '0s' : TRANSITION_DURATION

const StyledStackScreen = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* z-index: 1000; */
  min-height: 100vh;

  transform: translateX(${({ visible }) => (visible ? 0 : 110)}vw);
  z-index: ${({ stackPosition }) =>
    stackPosition !== 0 ? stackPosition * 1000 : 20000};
  ${({ isMain }) => (isMain ? `z-index: 0;` : '')};
  ${({ isMain }) => (isMain ? `transform: none;` : '')};

  opacity: ${({ noAnimation, visible }) =>
    noAnimation ? (visible ? 1 : 0) : 1};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  ${({ noAnimation, visible }) =>
    noAnimation
      ? `
        transition: ${visible ? '' : `opacity ${TRANSITION_DURATION},`} ${
          visible ? '' : `visibility 0s linear ${TRANSITION_DURATION},`
        } transform ${visible ? TRANSITION_DURATION : '0s'} ease-out ${
          visible ? '0s' : TRANSITION_DURATION
        };
        `
      : `
        transition: transform ${TRANSITION_DURATION} ease-out, visibility 0s linear ${
          visible ? '0s' : TRANSITION_DURATION
        };
  `};
  box-shadow: -4px 0px 20px 0px rgba(0, 0, 0, 0.3);
  //will-change: transform, opacity;

  ${MEDIA_DESKTOP} {
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
    box-shadow: none;
    transform: none;
    will-change: auto;

    ${({ visible }) =>
      visible
        ? `
        transition: opacity ${getTransitionDuration};
        `
        : `
        transition: opacity ${getTransitionDuration}, visibility 0s ${getTransitionDuration};
  `}
  }

  ${MEDIA_IOS} {
    ${({ visible, noAnimation }) =>
      noAnimation
        ? `
        transition: ${visible ? '' : `opacity ${TRANSITION_DURATION},`} ${
            visible ? '' : `visibility 0s linear ${TRANSITION_DURATION},`
          } transform ${visible ? TRANSITION_DURATION : '0s'} ease-out ${
            visible ? '0s' : TRANSITION_DURATION
          };
        `
        : visible
        ? `
        transition: transform ${getTransitionDuration} ease-out;
        `
        : `
        transition: none;)
  `}
  }
`

// const StackScreenContent

const EMPTY_OBJECT = {}

const StackScreen = ({
  location,
  visible,
  stackPosition,
  item = {},
  setRef,
  children,
  isActive,
  isMain,
  noAnimation,
}) => {
  const [lastItem, setLastItem] = useState(EMPTY_OBJECT)

  useEffect(
    () => {
      if (item.routeKey) {
        setLastItem(item)
      } else {
        setTimeout(() => {
          setLastItem(currentItem => {
            return equals(lastItem, currentItem) ? EMPTY_OBJECT : currentItem
          })
        }, 300)
      }
    },
    [item],
  )

  return (
    <StyledStackScreen
      {...{
        visible,
        isMain,
        stackPosition,
        ref: setRef,
        noAnimation,
      }}
    >
      {children({ item: item.routeKey ? item : lastItem, isActive })}
    </StyledStackScreen>
  )
}

const routes = [
  {
    path: new Route('/'),
    exact: true,
    routeKey: ROUTE_STACK.MAIN,
  },
  {
    path: new Route('/'),
    exact: true,
    routeKey: ROUTE_STACK.STORIES,
  },
  {
    path: new Route('/'),
    exact: true,
    routeKey: ROUTE_STACK.FILTERS_CINEMA,
  },
  {
    path: new Route('/'),
    exact: true,
    routeKey: ROUTE_STACK.FILTERS_MOVIE,
  },
  {
    path: new Route(`/${SCREENING_ROUTE}/:screeningId`),
    exact: true,
    routeKey: ROUTE_STACK.SCREENING_DETAIL,
  },
  {
    path: new Route(`/${CINEMA_ROUTE}/:cinemaId`),
    exact: true,
    routeKey: ROUTE_STACK.CINEMA_DETAIL,
  },
  {
    path: new Route(`/${ALL_SCREENINGS}/:movieId`),
    exact: true,
    routeKey: ROUTE_STACK.MORE_SCREENINGS,
  },
]

export const getScreenByLocation = location => {
  const { pathname, search, hash } = location

  const match = find(({ path }) => path.match(pathname), routes)

  return match
}

export default compose(
  connect({ location: getRouterLocation }),
  withDerivedProps(['location', 'routeKey'], {
    stack: ({ location }) => {
      if (prop('state', location)) {
        return JSON.parse(prop('state', location))
      }

      const screen = getScreenByLocation(location)
      const params = screen.path.match((location || {}).pathname)

      const stack = screen ? [{ routeKey: screen.routeKey, ...params }] : []
      return stack
    },
    item: ({ stack, routeKey, location }) => {
      const item = find(item => item.routeKey === routeKey, stack)

      if (!item && routeKey === ROUTE_STACK.MAIN) {
        return { routeKey }
      }

      return item
    },
    visible: ({ item }) => !!item,
    stackPosition: ({ stack, item }) => {
      return indexOf(item, stack) + 1
    },
    isActive: ({ visible, stack, stackPosition }) => {
      return visible && (stack.length === 0 || stackPosition === stack.length)
    },
  }),
  withHandlers(() => {
    let ref = undefined
    let top = 0
    let fixed = false

    return {
      setRef: () => _ref => {
        ref = _ref

        if (fixed && ref) {
          ref.style.position = 'fixed'
          ref.style.top = `${top}px`
        }
      },
      setTop: () => val => {
        top = val
      },
      setFixed: () => shouldSet => {
        if (!ref && shouldSet) {
          fixed = true
          return
        }

        if (ref && top !== undefined) {
          if (shouldSet) {
            ref.style.position = 'fixed'
            ref.style.top = `${top}px`
          } else {
            ref.style.position = 'absolute'
            ref.style.top = '0px'

            window.scrollTo(0, -top)
          }
        }
      },
      resetFixed: () => () => {
        if (ref) {
          top = 0
          ref.style.position = 'fixed'
          ref.style.top = `${top}px`
          triggerOnScroll()
        }
      },
      onScroll: () => () => {
        if (ref) {
          top = ref.getBoundingClientRect().top
        }
      },
    }
  }),
  withPropsOnChange(
    ['isActive', 'visible'],
    ({ isActive, visible, setFixed, setTop, item }) => {
      if (!visible) {
        setTop(0)
        setFixed(true)
      } else {
        if (isActive) {
          setFixed(false)
        } else {
          setFixed(true)
        }
      }
    },
  ),
  lifecycle({
    componentDidMount() {
      const { onScroll } = this.props
      window.addEventListener('customScroll', onScroll)
    },
    componentWillUnmount() {
      const { onScroll } = this.props
      window.removeEventListener('customScroll', onScroll)
    },
  }),
  pure,
)(StackScreen)
