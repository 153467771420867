import React from 'react'
import connect from '../../hoc/connect'
import ScreeningsList from '../../components/ScreeningsList'
import Layout from '../../components/Layout'
import {
  compose,
  lifecycle,
  pure,
  withHandlers,
  withProps,
  withState,
} from 'recompose'
import triggerOnScroll from '../../utils/triggerOnScroll'
import reloadPage from '../../utils/reloadPage'
import { path } from 'ramda'
import { getRouterLocationPathname } from '../../selectors/router'
import StackScreen from '../../components/StackScreen'
import { ROUTE_STACK } from '../../constants/routes'
import pickProps from '../../hoc/pickProps'
import ScreeningHoverDetail from '../../components/ScreeningHoverDetail'
import withLifecycle from '../../hoc/withLifecycle'
import { CustomEvents } from '../../constants/events'
import { FiltersDesktop } from '../../components/FiltersDesktop'

const Screenings = ({ setRef }) => (
  <StackScreen {...{ routeKey: ROUTE_STACK.MAIN, isMain: true }}>
    {() => {
      return (
        <Layout setRef={setRef}>
          <ScreeningsList />
          <ScreeningHoverDetail />
          {/*<FiltersDesktop />*/}
        </Layout>
      )
    }}
  </StackScreen>
)

export default compose(
  connect({
    filterMenuVisible: path(['ui', 'filterMenuVisible']),
    filter: path(['ui', 'priceFeature']),
    priceFeature: path(['filter']),
    pathname: getRouterLocationPathname,
  }),
  withProps(({ pathname, priceFeature }) => {
    if (pathname === '/dostovky' && !priceFeature) {
      window.localStorage && window.localStorage.setItem('priceFeature', 'true')
      reloadPage()
    }

    return {}
  }),
  withHandlers(() => {
    let ref = undefined
    let top = 0

    return {
      setRef: () => _ref => {
        ref = _ref
      },
      setFixed: () => shouldSet => {
        if (ref && top !== undefined) {
          if (shouldSet) {
            ref.style.position = 'fixed'
            ref.style.top = `${top}px`
          } else {
            ref.style.position = 'relative'
            ref.style.top = '0px'

            window.scrollTo(0, -top)
          }
        }
      },
      resetFixed: () => () => {
        if (ref) {
          top = 0
          ref.style.position = 'fixed'
          ref.style.top = `${top}px`
          triggerOnScroll()
        }
      },
      onScroll: () => () => {
        if (ref) {
          top = ref.getBoundingClientRect().top
        }
      },
    }
  }),
  lifecycle({
    componentDidMount() {
      const { onScroll } = this.props
      window.addEventListener('customScroll', onScroll)
    },
    componentDidUpdate(prevProps) {
      const { setFixed, resetFixed } = this.props

      if (this.props.filterMenuVisible !== prevProps.filterMenuVisible) {
        if (this.props.filterMenuVisible) {
          setFixed(true)
        } else {
          setFixed(false)
        }
      }

      if (this.props.filter !== prevProps.filter) {
        if (this.props.filter) {
          resetFixed()
        } else {
          resetFixed()
        }
      }
    },
    componentWillUnmount() {
      const { onScroll } = this.props
      window.removeEventListener('customScroll', onScroll)
    },
  }),
  pickProps(['setRef']),
  pure,
)(Screenings)
