import React from 'react'
import { pure } from 'recompose'
import styled from '@emotion/styled/macro'

import Logo from './Logo'
import Menu from './Menu'
import { zindex } from '../constants/zIndexes'
import { MEDIA_DESKTOP } from '../constants/media'
import { HEADER_LOGO_CONTAINER_HEIGHT } from '../constants/layout'

const StyledContainer = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  z-index: ${zindex.logo};

  display: flex;
  align-items: center;
  justify-content: center;
  height: ${HEADER_LOGO_CONTAINER_HEIGHT}px;
  padding: 0 15px 0;
  /* background: white; */
  overflow: hidden;
  margin: 0 0px 15px;
  width: 100%;
  background: white;
  cursor: pointer;

  /* box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12); */

  ${MEDIA_DESKTOP} {
    position: fixed;
  }
`

const Header = () => (
  <StyledContainer href={'/'}>
    <Logo />
    {/* <Menu /> */}
  </StyledContainer>
)

export default pure(Header)
