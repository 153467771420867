import React from 'react'
import { pure } from 'recompose'

const BackArrow = pure(() => (
  <svg width="29.195" height="24.249" viewBox="0 0 29.195 24.249">
    <defs>
      <style>{'.a{fill:#484848;}'}</style>
    </defs>
    <g transform="translate(0 -41.674)">
      <g transform="translate(0 41.674)">
        <path
          className="a"
          d="M27.554,51.509l.046.01H8.063L14.2,45.364a1.613,1.613,0,0,0,0-2.267l-.956-.956a1.6,1.6,0,0,0-2.256,0L.465,52.666a1.609,1.609,0,0,0,0,2.264L10.993,65.458a1.6,1.6,0,0,0,2.256,0L14.2,64.5a1.581,1.581,0,0,0,.466-1.128,1.534,1.534,0,0,0-.466-1.107L7.994,56.077H27.575a1.655,1.655,0,0,0,1.619-1.638V53.087A1.623,1.623,0,0,0,27.554,51.509Z"
          transform="translate(0 -41.674)"
        />
      </g>
    </g>
  </svg>
))

export default BackArrow
