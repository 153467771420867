import React, { memo, useCallback, useMemo } from 'react'
import { filter, map, pipe, sortBy, sum, toPairs, values } from 'ramda'
import styled from '@emotion/styled/macro'
import { DesktopFiltersHeader } from './DesktopFiltersHeader'
import { useSelector } from 'react-redux'
import { useAction } from '../hooks/useAction'
import { setFilterWhere, setFilterWhereItem } from '../actions/filter'
import { useInterimValue } from '../hooks/useInterimValue'
import Ripple from './Ripple'
import CinemaLogo from './CinemaLogo'
import {
  CINEMA_LOCATIONS_FORMAL,
  CINEMA_NAMES,
  CINEMAS_ORDER,
  CINEMAS_POSITIONS,
} from '../constants/cinemas'
import { PRIMARY_TEXT_COLOR, THEME_COLOR } from '../constants/colors'
import { DesktopFiltersButtons } from './DesktopFiltersButtons'
import { CHROME_ANDROID_NO_HIGHLIGHT } from '../constants/CSS'
import { useGeolocation } from '../hooks/useGeolocation'

const StyledCinemaFilter = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const StyledCinemaFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 80px;
  overflow-y: scroll;
`

const StyledCinemaPosition = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 10px 20px 10px;
  font-size: 14px;
  font-weight: 900;
  color: ${THEME_COLOR};
  user-select: none;
  background: white;
`

const StyledFilterItem = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background: ${({ isSelected }) => (isSelected ? THEME_COLOR : 'transparent')};
  border: 2px solid
    ${({ isSelected }) => (isSelected ? THEME_COLOR : PRIMARY_TEXT_COLOR)};
  padding: 15px 23px;
  border-radius: 100px;
  margin: 0 25px 15px;
  cursor: pointer;

  ${CHROME_ANDROID_NO_HIGHLIGHT}
`

const StyledFilterItemText = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 900;
  color: ${({ isSelected }) => (isSelected ? 'white' : PRIMARY_TEXT_COLOR)};
  user-select: none;
  text-align: center;
`

const StyledFilterItemLocation = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 900;
  color: ${({ isSelected }) => (isSelected ? 'white' : '#A7A7A7')};
  user-select: none;
  text-align: center;
  padding-top: 5px;
`

const StyledFilterItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 17px;
`

const FilterCinemaItem = memo(({ cinemaName, dist }) => {
  const [isSelected, setSelected] = useInterimValue({
    value: useSelector(state => state.filter.where[cinemaName]),
    onChange: useAction(setFilterWhereItem),
  })

  const onClick = useCallback(
    () => {
      setSelected(!isSelected, cinemaName)
    },
    [isSelected, setSelected],
  )

  return (
    <StyledFilterItem onClick={onClick} isSelected={isSelected}>
      {/*<Ripple />*/}
      <CinemaLogo
        cinema={cinemaName}
        width={30}
        height={30}
        PRIMARY_COLOR={isSelected ? THEME_COLOR : '#fff'}
        SECONDARY_COLOR={isSelected ? 'white' : '#484848'}
      />
      <StyledFilterItemTextContainer>
        <StyledFilterItemText isSelected={isSelected}>
          {CINEMA_NAMES[cinemaName]}
        </StyledFilterItemText>
        <StyledFilterItemLocation isSelected={isSelected}>
          {CINEMA_LOCATIONS_FORMAL[cinemaName]}{' '}
          {typeof dist === 'number'
            ? `(${dist === 0 ? 'online' : `${Math.round(dist * 10) / 10} km`})`
            : ''}
        </StyledFilterItemLocation>
      </StyledFilterItemTextContainer>
    </StyledFilterItem>
  )
})

const useFiltersCount = () => {
  const where = useSelector(state => state.filter.where)

  return useMemo(
    () => {
      return sum(map(() => 1, filter(Boolean, values(where))))
    },
    [where],
  )
}

const toRad = x => {
  return (x * Math.PI) / 180
}

function distance(lon1, lat1, lon2, lat2) {
  const R = 6371 // Radius of the earth in km
  const dLat = toRad(lat2 - lat1)
  const dLon = toRad(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c // Distance in km

  return d
}

export const DesktopFiltersCinema = memo(({ closeFilter }) => {
  const setWhere = useAction(setFilterWhere)
  const filtersCount = useFiltersCount()

  const clearFilters = useCallback(
    () => {
      setWhere({})
    },
    [setWhere],
  )
  const position = useGeolocation()
  const cinemasOrder = useMemo(
    () => {
      if (position && position.coords) {
        const coords = position.coords

        return pipe(
          map(cinemaCoords => {
            if (!cinemaCoords) {
              return 0
            }

            const dist = distance(
              coords.longitude,
              coords.latitude,
              cinemaCoords.longitude,
              cinemaCoords.latitude,
            )

            return dist
          }),
          toPairs,
          // sortBy(([_, dist]) => dist),
          sortBy(([cinemaName]) => CINEMAS_ORDER.indexOf(cinemaName)),
        )(CINEMAS_POSITIONS)
      }

      return CINEMAS_ORDER.map(cinemaName => [cinemaName])
    },
    [position],
  )

  return (
    <StyledCinemaFilter>
      <DesktopFiltersHeader
        closeFilter={closeFilter}
        title="Vyber kino"
        filtersCount={filtersCount}
      />
      {/*{position !== false && (*/}
      {/*  <StyledCinemaPosition>*/}
      {/*    {position ? 'Řazeno podle vzdálenosti' : 'Hledání polohy...'}*/}
      {/*  </StyledCinemaPosition>*/}
      {/*)}*/}
      <StyledCinemaFiltersContainer>
        {cinemasOrder.map(([cinemaName, dist]) => {
          return (
            <FilterCinemaItem
              key={cinemaName}
              cinemaName={cinemaName}
              dist={dist}
            />
          )
        })}
      </StyledCinemaFiltersContainer>
      <DesktopFiltersButtons
        disabled={!filtersCount}
        clearFilters={clearFilters}
        closeFilter={closeFilter}
      />
    </StyledCinemaFilter>
  )
})
