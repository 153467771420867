/* eslint-disable max-lines-per-function */
import { compose, withState, withHandlers, withProps } from 'recompose'
import timeout from 'timeout'

import omitProps from './omitProps'
import withLifecycle from './withLifecycle'

let counter = 0

const getCounter = () => {
  // eslint-disable-next-line no-plusplus
  return counter++
}

const withEarlyPropChange = ({
  propName = 'value',
  handlerName = 'onChange',
  delay = 400,
  convertor,
}) => {
  const TIMEOUT_ID = `INTERNAL-${getCounter()}`

  return compose(
    withState('$internal', 'set$Internal', props => {
      return props[propName]
    }),
    withHandlers({
      [handlerName]({ set$Internal, ...rest }) {
        return value => {
          if (typeof convertor === 'function') {
            // TODO: rewrite without mutation
            // eslint-disable-next-line no-param-reassign
            value = convertor(value)
          }
          set$Internal(value)

          timeout.timeout(TIMEOUT_ID, delay, () => {
            rest[handlerName](value)
          })

          return value
        }
      },
    }),
    withLifecycle({
      componentWillUnmount: () => () => {
        timeout.timeout(TIMEOUT_ID, null)
      },
      componentDidUpdate: props => prevProps => {
        if (prevProps[propName] !== props[propName]) {
          props.set$Internal(props[propName])
        }
      },
    }),
    withProps(({ $internal }) => {
      return {
        [propName]: $internal,
      }
    }),
    omitProps(['$internal', 'set$Internal']),
  )
}

export default withEarlyPropChange
