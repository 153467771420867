import React from 'react'
import styled from '@emotion/styled/macro'
import { pure, compose } from 'recompose'
import ScreeningDetailActors from './ScreeningDetailActors'
import ScreeningDetailImages from './ScreeningDetailImages'

const StyledLeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 300px;

  flex-shrink: 0;
  margin-right: 15px;
`

const ScreeningDetailLeftPanel = ({ images, actors }) => {
  return (
    <StyledLeftPanel>
      {actors && <ScreeningDetailActors {...{ actors }} />}
      {images && <ScreeningDetailImages {...{ images }} />}
    </StyledLeftPanel>
  )
}

export default compose(pure)(ScreeningDetailLeftPanel)
