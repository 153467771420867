import React from 'react'
import { connect } from 'react-redux'
import {
  compose,
  withHandlers,
  lifecycle,
  withProps,
  withState,
} from 'recompose'
import { filter } from 'ramda'
import HeartIcon from './HeartIcon'
import { setFavouriteScreenings } from '../actions/favouriteScreenings'
import styled from '@emotion/styled/macro'
import {
  CHROME_ANDROID_NO_HIGHLIGHT,
  SHOW_HIDE_FAST_DURATION,
  MOUSE_DEVICE,
} from '../constants/CSS'
import { setSelectedScreening } from '../actions/selectedScreening'

// const HEART_COLOR = '#e04d45'
const HEART_COLOR = '#cc3d35'

export const StyledHeartContainer = styled.div`
  box-sizing: initial;
  ${CHROME_ANDROID_NO_HIGHLIGHT}
  cursor: pointer;
  display: flex;

  & > svg {
    transition: all ${SHOW_HIDE_FAST_DURATION};
    filter: ${({ favourite }) =>
      favourite
        ? `drop-shadow( 0px 0px 0px ${HEART_COLOR} )`
        : 'filter: drop-shadow( 0px 0px 0px #222 )'};
  }

  & > svg path {
    transition: all ${SHOW_HIDE_FAST_DURATION};
    fill: ${({ favourite }) => (favourite ? HEART_COLOR : '#514b43')};
    stroke: ${({ favourite }) => (favourite ? HEART_COLOR : '#514b43')};
    stroke-width: 23px;
  }

  & > svg path#heart_full {
    opacity: ${({ favourite }) => (favourite ? 1 : 0)};
  }

  ${MOUSE_DEVICE} {
    &:hover > svg {
      filter: drop-shadow(0px 0px 3px ${HEART_COLOR});
    }
    &:hover > svg path {
      fill: ${HEART_COLOR};
      stroke: ${HEART_COLOR};
    }
    &:hover > svg path#heart_full {
      opacity: 1;
    }
  }
`

const ScreeningHeart = ({ onHeartClick, favouriteScreenings, screeningId }) => (
  <StyledHeartContainer
    onClick={onHeartClick}
    favourite={favouriteScreenings[screeningId]}
  >
    <HeartIcon size={21} />
  </StyledHeartContainer>
)

export default compose(
  connect(
    (
      { favouriteScreenings, selectedScreening, filter: { favourites } },
      { screeningId },
    ) => {
      return {
        favouriteScreenings,
        favourites,
        isSelectedScreening: screeningId === selectedScreening,
      }
    },
    { setFavouriteScreenings, setSelectedScreening },
  ),
  withState(
    'internalValue',
    'setInternalValue',
    ({ favouriteScreenings }) => favouriteScreenings,
  ),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.favouriteScreenings !== prevProps.favouriteScreenings) {
        this.props.setInternalValue(this.props.favouriteScreenings)
      }
    },
  }),
  withProps(({ internalValue }) => ({ favouriteScreenings: internalValue })),
  withHandlers({
    onHeartClick: ({
      favouriteScreenings,
      setFavouriteScreenings,
      screeningId,
      setInternalValue,
      favourites,
      setSelectedScreening,
      isSelectedScreening,
    }) => () => {
      const newFavouriteScreenings = filter(a => !!a, {
        ...favouriteScreenings,
        [screeningId]: !favouriteScreenings[screeningId],
      })

      setInternalValue(newFavouriteScreenings)
      window.requestAnimationFrame(() => {
        setTimeout(() => {
          window.requestAnimationFrame(() => {
            if (
              isSelectedScreening &&
              favourites &&
              favouriteScreenings[screeningId]
            ) {
              setSelectedScreening(null)
            }
            setFavouriteScreenings(newFavouriteScreenings)
            window.localStorage &&
              window.localStorage.setItem(
                'favouriteScreenings',
                JSON.stringify(newFavouriteScreenings),
              )
          })
        }, 110)
      })
    },
  }),
)(ScreeningHeart)
