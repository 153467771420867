import React from 'react'
import styled from '@emotion/styled/macro'
import { compose, pure, withHandlers } from 'recompose'

let HAS_TOUCH = false

if (typeof window !== 'undefined') {
  // HAS_TOUCH =
  //   'ontouchstart' in window ||
  //   (window.DocumentTouch && document instanceof window.DocumentTouch)
  HAS_TOUCH =
    window &&
    window.matchMedia &&
    window.matchMedia('(pointer: coarse)').matches &&
    ('ontouchstart' in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch))
}

const RADIUS_IN_DURATION = 150
const OPACITY_IN_DURATION = 75
const RIPPLE_IN_DURATION = Math.max(RADIUS_IN_DURATION, OPACITY_IN_DURATION)

const OPACITY_OUT_DURATION = 75
const RIPPLE_OUT_DURATION = OPACITY_OUT_DURATION

// export const RIPPLE_DURATION = RIPPLE_IN_DURATION + RIPPLE_OUT_DURATION

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  overflow: var(--overflow);

  @keyframes ripple-in {
    0% {
      transform: scale(var(--ripple-startScale));
      opacity: 0;
    }
    33% {
      opacity: var(--ripple-opacity);
    }
    100% {
      transform: scale(var(--ripple-endScale));
      opacity: var(--ripple-opacity);
    }
  }

  /* @keyframes ripple-opacity-in {
    from {
      opacity: 0;
    }
    to {
      opacity: var(--ripple-opacity);
    }
  }

  @keyframes ripple-opacity-out {
    from {
      opacity: var(--ripple-opacity);
    }
    to {
      opacity: 0;
    }
  } */

  &.ripple-effect-in::before {
    animation: ${RADIUS_IN_DURATION}ms ripple-in linear;
  }

  /* &.ripple-radius-running::before {
    opacity: var(--ripple-opacity);
    transform: scale(var(--ripple-endScale));
  } */

  &.ripple-is-touched::before {
    opacity: var(--ripple-opacity);
    transform: scale(var(--ripple-endScale));
  }

  /* &.ripple-effect-out::before {
    animation: ripple-opacity-out ${OPACITY_OUT_DURATION}ms linear;
  } */

  &::before {
    content: '';
    background: var(--ripple-color);
    opacity: 0;
    transform: scale(var(--ripple-endScale));
    border-radius: 50%;
    width: var(--ripple-size);
    height: var(--ripple-size);
    position: absolute;
    left: var(--ripple-x);
    top: var(--ripple-y);
    transform-origin: center center;
    transition: opacity ${OPACITY_IN_DURATION}ms;
  }
`

const Ripple = ({
  onAnimationEnd,
  onTouchStart,
  onTouchEnd,
  setRef,
  startScale = 0.1,
  endScale = 2.5,
  opacity = 0.4,
  color = 'white',
  unbounded = false,
  forScroll = false,
}) => {
  const events = HAS_TOUCH
    ? {
        onTouchStart: forScroll ? undefined : onTouchStart,
        onTouchEnd: forScroll ? undefined : onTouchEnd,
        onTouchCancel: forScroll ? undefined : onTouchEnd,
        onTouchMove: forScroll ? undefined : onTouchEnd,
        onClick: forScroll ? onTouchEnd : undefined,
      }
    : {
        onMouseDown: forScroll ? undefined : onTouchStart,
        onMouseUp: forScroll ? undefined : onTouchEnd,
        onMouseLeave: forScroll ? undefined : onTouchEnd,
      }

  return (
    <StyledContainer
      {...{
        onAnimationEnd,
        ...events,
        ref: setRef,
        unbounded,
        style: {
          '--ripple-color': `${color}`,
          '--ripple-startScale': `${startScale}`,
          '--ripple-endScale': `${endScale}`,
          '--ripple-opacity': `${opacity}`,
          '--ripple-x': '0px',
          '--ripple-y': '0px',
          '--ripple-size': '0px',
          '--overflow': unbounded ? 'initial' : 'hidden',
        },
      }}
    />
  )
}

const restartAnimByClass = (el, className) => {
  el.classList.remove(className)
  // eslint-disable-next-line no-unused-vars
  const _ = el.offsetHeight
  el.classList.add(className)
}

export default compose(
  withHandlers(() => {
    let r

    const onStart = e => {
      const rect = r.getBoundingClientRect()
      const { changedTouches } = e
      const coordX =
        changedTouches && changedTouches[0]
          ? changedTouches[0].clientX - rect.left - rect.width / 2
          : e.clientX - rect.left - rect.width / 2
      const coordY =
        changedTouches && changedTouches[0]
          ? changedTouches[0].clientY - rect.top - rect.width / 2
          : e.clientY - rect.top - rect.width / 2

      r.style.setProperty('--ripple-x', `${coordX}px`)
      r.style.setProperty('--ripple-y', `${coordY}px`)
      r.style.setProperty('--ripple-size', `${rect.width}px`)
      // r.classList.remove('ripple-effect-out')
      // restartAnimByClass(r, 'ripple-effect-in')
      // r.classList.add('ripple-on')
      // r.classList.add('ripple-radius-running')
      restartAnimByClass(r, 'ripple-effect-in')
      r.classList.add('ripple-is-touched')
    }

    return {
      onTouchStart: ({ forScroll = false }) => e => {
        if (!forScroll) {
          onStart(e)
        }
      },
      onTouchEnd: ({ forScroll = false }) => e => {
        if (forScroll) {
          onStart(e)

          setTimeout(() => {
            r.classList.remove('ripple-is-touched')
          }, 0)
        } else {
          r.classList.remove('ripple-is-touched')
        }
        // if (r.classList.contains('ripple-is-touched')) {
        //   r.classList.remove('ripple-is-touched')
        //   if (!r.classList.contains('ripple-radius-running')) {
        //     r.classList.remove('ripple-effect-in')
        //     restartAnimByClass(r, 'ripple-effect-out')
        //     r.classList.remove('ripple-on')
        //   }
        // }
      },
      // onAnimationEnd: () => e => {
      //   const { animationName } = e
      //   if (animationName === 'ripple-radius-in') {
      //     r.classList.remove('ripple-radius-running')

      //     if (!r.classList.contains('ripple-is-touched')) {
      //       r.classList.remove('ripple-effect-out')
      //       const a = r.offsetHeight
      //       r.classList.remove('ripple-on')
      //       r.classList.add('ripple-effect-out')
      //     }
      //   }
      // },
      setRef: () => _r => {
        r = _r
      },
    }
  }),
  pure,
)(Ripple)
