import { routerMiddleware } from 'react-router-redux'
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import stackHistory from './stackHistory'

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

const getMiddleware = () => [
  stackHistory,
  routerMiddleware(history),
  sagaMiddleware,
  ...(process.env.NODE_ENV === 'development'
    ? [
        createLogger({
          // diff: true,
          collapsed: true,
        }),
      ]
    : []),
]

const runSagas = sagas => sagaMiddleware.run(sagas)

export { getMiddleware, runSagas, history }
