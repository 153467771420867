import React from 'react'
import { pure } from 'recompose'
import { THEME_COLOR } from '../constants/colors'

const Ticket = pure(() => (
  <svg width="17.747" height="15.708" viewBox="0 0 17.747 15.708">
    <path
      fill={THEME_COLOR}
      d="M17.354,139.434a1.407,1.407,0,0,1-.808-1.818.612.612,0,0,0,.008-.416.98.98,0,0,0-.585.9,1.041,1.041,0,0,1-1.04,1.04H11.623a.613.613,0,0,1-.6.507.612.612,0,0,1-.6-.507h-7.6a1.041,1.041,0,0,1-1.04-1.04.98.98,0,0,0-.979-.979A1.032,1.032,0,0,1,.33,137l-.29.753a.612.612,0,0,0,.351.791,1.409,1.409,0,0,1,.808,1.818.612.612,0,0,0,.351.791L12.856,145.5a.611.611,0,0,0,.791-.351,1.416,1.416,0,0,1,1.314-.9,1.4,1.4,0,0,1,.5.094.612.612,0,0,0,.791-.351l1.449-3.768A.612.612,0,0,0,17.354,139.434Zm-6.948,2.9a.612.612,0,0,1-.571.393.62.62,0,0,1-.22-.041.612.612,0,1,1,.791-.352ZM11,140.791a.611.611,0,1,1-.351-.79A.612.612,0,0,1,11,140.791Zm3.156.231a.611.611,0,0,1-.791.351l-.942-.362a.612.612,0,1,1,.439-1.142l.942.362A.612.612,0,0,1,14.154,141.022Z"
      transform="translate(0.001 -129.837)"
    />
    <path
      fill={THEME_COLOR}
      d="M10.474,23.48a1.837,1.837,0,0,1,1.835,1.835.184.184,0,0,0,.184.184H24.606a.184.184,0,0,0,.184-.184,1.837,1.837,0,0,1,1.835-1.835.184.184,0,0,0,.184-.184V19.259a.184.184,0,0,0-.184-.184,1.837,1.837,0,0,1-1.835-1.835.184.184,0,0,0-.184-.184H12.493a.184.184,0,0,0-.184.184,1.837,1.837,0,0,1-1.835,1.835.184.184,0,0,0-.184.184V23.3A.184.184,0,0,0,10.474,23.48Zm12.112-2.814H23.6a.612.612,0,1,1,0,1.224H22.587a.612.612,0,1,1,0-1.224ZM20.568,17.41a.612.612,0,1,1-.612.612A.612.612,0,0,1,20.568,17.41Zm0,1.652a.612.612,0,1,1-.612.612A.612.612,0,0,1,20.568,19.062Zm0,1.652a.612.612,0,1,1-.612.612A.612.612,0,0,1,20.568,20.714Zm0,1.652a.612.612,0,1,1-.612.612A.612.612,0,0,1,20.568,22.366Zm0,1.652a.612.612,0,1,1-.612.612A.612.612,0,0,1,20.568,24.017Z"
      transform="translate(-9.676 -17.057)"
    />
  </svg>
))

export default Ticket
