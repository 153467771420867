import { createStore, applyMiddleware, combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { getMiddleware, runSagas, history } from './middleware'
import * as reducers from '../reducers'
import sagas from '../sagas'

const initializeStore = () => {
  const middleware = getMiddleware()

  const store = createStore(
    combineReducers({ ...reducers, router: connectRouter(history || {}) }),
    applyMiddleware(...middleware),
  )

  runSagas(sagas)

  return store
}

export default initializeStore
