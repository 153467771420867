import React from 'react'
import { pure, compose } from 'recompose'
import { ROUTE_STACK } from '../../constants/routes'
import MoreScreeningsContent from './MoreScreeningsContent'
import StackScreen from '../../components/StackScreen'

const MoreScreenings = () => {
  return (
    <StackScreen {...{ routeKey: ROUTE_STACK.MORE_SCREENINGS }}>
      {({ item }) => {
        const { movieId } = item
        return <MoreScreeningsContent {...{ movieId }} />
      }}
    </StackScreen>
  )
}

export default compose(pure)(MoreScreenings)
