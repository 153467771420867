import createAction from '../utils/createAction'
import * as ActionTypes from '../constants/ActionTypes'

export const setSavedFilter = savedFilter =>
  createAction(ActionTypes.SET_SAVED_FILTER, {
    savedFilter,
  })

export const resetSavedFilter = () =>
  createAction(ActionTypes.RESET_SAVED_FILTER)
