import { put, takeLatest, call } from 'redux-saga/effects'

import * as ActionTypes from '../../constants/ActionTypes'
import { fetchShowtimes } from '../../services/fetchShowtimes'
import { dataSuccess } from '../../actions/data'

function* dataFetch() {
  const data = yield call(fetchShowtimes)
  yield put(dataSuccess(data))
}

export default function* watchDataFetch() {
  yield takeLatest(ActionTypes.DATA_FETCH, dataFetch)
}
