import React from 'react'
import { pure } from 'recompose'

const Award = pure(() => (
  <svg width="19.471" height="30.99" viewBox="0 0 19.471 30.99">
    <g transform="translate(-95.22)">
      <path
        fill="#484848"
        d="M205.294,288.886l-3.5-1.836-3.5,1.836.537,4.271h5.929Z"
        transform="translate(-96.837 -269.688)"
      />
      <path
        fill="#484848"
        d="M110.963,18.463l-1.131-6.642,4.859-4.748-6.722-.985L104.956,0l-3.014,6.089-6.722.985,4.86,4.748-1.131,6.642,6.008-3.15Z"
      />
      <path
        fill="#484848"
        d="M127.8,423.72h15.529v-5.706H127.8Zm10.386-3.881v1.815h-5.242v-1.815Z"
        transform="translate(-30.614 -392.73)"
      />
    </g>
  </svg>
))

export default Award
