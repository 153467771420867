import React from 'react'
import { pure } from 'recompose'

const ResetIcon = pure(({ size }) => (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    width={size}
    height={size}
    viewBox="0 0 512 512"
  >
    <g transform="matrix(-1.66108 0 0 1.66108 912.857 -398.087)">
      <path d="M497.989,298.667c-5.888,0-10.667,4.779-10.667,10.667v26.027c-16.085-28.309-48.213-58.027-96-58.027    c-64.704,0-117.333,52.629-117.333,117.333S326.619,512,391.323,512c5.888,0,10.667-4.779,10.667-10.667    c0-5.888-4.779-10.667-10.667-10.667c-52.928,0-96-43.072-96-96s43.072-96,96-96c50.304,0,77.973,41.557,84.779,64h-42.112    c-5.888,0-10.667,4.779-10.667,10.667c0,5.888,4.779,10.667,10.667,10.667h64c5.888,0,10.667-4.779,10.667-10.667v-64    C508.656,303.445,503.899,298.667,497.989,298.667z" />
    </g>
  </svg>
))

export default ResetIcon
