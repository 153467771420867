import { branch, renderNothing } from 'recompose'
import pipe from 'ramda/src/pipe'
import prop from 'ramda/src/prop'
import not from 'ramda/src/not'

const withRenderOnProp = propName => {
  return branch(
    pipe(
      prop(propName),
      not,
    ),
    renderNothing,
  )
}

export default withRenderOnProp
