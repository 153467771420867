import React from 'react'
import { pure } from 'recompose'
import { THEME_COLOR } from '../constants/colors'

const SmallHall = pure(() => (
  <svg width="20.982" height="25.472" viewBox="0 0 20.982 25.472">
    <g transform="translate(-6.312 -2.749)">
      <path
        fill="#484848"
        stroke="#484848"
        d="M110.365,0H94.645a.924.924,0,0,0-.923.923v9.84a.924.924,0,0,0,.923.923h15.72a.924.924,0,0,0,.923-.923V.923A.924.924,0,0,0,110.365,0Zm.231,10.762a.231.231,0,0,1-.231.231H94.645a.231.231,0,0,1-.231-.231V.923a.231.231,0,0,1,.231-.231h15.72a.231.231,0,0,1,.231.231Zm0,0"
        transform="translate(-85.713 3.249)"
      />
      <g transform="translate(8.007 17.832)">
        <path
          fill="#484848"
          stroke="#484848"
          strokeWidth="0.5px"
          d="M214.594,262.559A2.561,2.561,0,0,0,212.036,260h-2.578a2.561,2.561,0,0,0-2.558,2.559v6.99a.591.591,0,0,0,.59.59H214a.591.591,0,0,0,.59-.59Zm-5.138-1.378h2.579a1.38,1.38,0,0,1,1.378,1.378v3.651H208.08v-3.651a1.38,1.38,0,0,1,1.378-1.378Zm-1.378,7.777V267.39h5.335v1.568Zm0,0"
          transform="translate(-206.899 -260)"
        />
        <path
          fill="#484848"
          stroke="#484848"
          strokeWidth="0.5px"
          d="M312.9,260a2.561,2.561,0,0,0-2.558,2.559v6.99a.591.591,0,0,0,.59.59h6.515a.59.59,0,0,0,.591-.59v-6.99A2.561,2.561,0,0,0,315.481,260Zm-1.378,8.958V267.39h5.334v1.568Zm5.334-6.4v3.651h-5.334v-3.651a1.379,1.379,0,0,1,1.378-1.378h2.579A1.379,1.379,0,0,1,316.859,262.559Zm0,0"
          transform="translate(-300.165 -260)"
        />
      </g>
    </g>
  </svg>
))

export default SmallHall
