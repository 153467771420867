import React from 'react'
import styled from '@emotion/styled/macro'
import { prop } from 'ramda'

import { storiesGenreOptions } from '../constants/storiesGenres'

const SvgCircle = ({ color }) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      style={{ position: 'relative' }}
    >
      {/*<circle cx={30} cy={30} r={25} fill={color} />*/}
      <circle
        cx={30}
        cy={30}
        r={26}
        stroke="#fff"
        strokeWidth={4}
        fill="none"
      />
      <circle cx={30} cy={30} r={24.5} stroke="#d3d3d3" fill="none" />
      <g fill="none">
        <circle cx={30} cy={30} r={30} />
        <circle cx={30} cy={30} r={28.5} stroke="#1f5797" strokeWidth={3} />
      </g>
    </svg>
  )
}

const StyledIconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 50px;
`

export const StyledGenreCircleContainer = styled.div`
  width: calc(${prop('scale')} * 60px);
  height: calc(${prop('scale')} * 60px);
  position: relative;
  border: ${({ noBorder }) => (noBorder ? '1px solid #eee' : 'none')};
  border-radius: 50px;

  ${StyledIconContainer} {
    transform: scale(
      ${({ scale, biggerIcon }) => (biggerIcon ? 1.2 * scale : 1 * scale)}
    );
  }
`

const StyledIconBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: ${prop('color')};
`

export const StoriesCategoryGenreBubble = ({
  genre,
  scale = 1,
  noBorder,
  biggerIcon,
}) => {
  const { icon: Icon, bubbleBackgroundColor, top, left } =
    storiesGenreOptions[genre] || {}

  return (
    <StyledGenreCircleContainer {...{ scale, biggerIcon, noBorder }}>
      <StyledIconBackground {...{ color: bubbleBackgroundColor }} />
      <StyledIconContainer>
        <img
          {...{
            alt: '',
            src: Icon,
            style: { overflow: 'hidden', position: 'relative', top, left },
          }}
        />
      </StyledIconContainer>
      {!noBorder && <SvgCircle {...{ color: bubbleBackgroundColor }} />}
    </StyledGenreCircleContainer>
  )
}
