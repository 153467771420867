import React from 'react'
import styled from '@emotion/styled/macro'

const scale = 0.4

const WIDTH = 303
const HEIGHT = 121

const StyledIcon = styled.div`
  width: ${WIDTH * scale}px;
  height: ${HEIGHT * scale}px;
  position: relative;

  @keyframes loading-screenings-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  & #left-wheel {
    transform-origin: ${scale * 71.337}px ${scale * 61.119}px;
    animation: loading-screenings-spin 1s linear infinite;
  }

  & #right-wheel {
    transform-origin: ${scale * 232.671}px ${scale * 61.119}px;
    animation: loading-screenings-spin 1s linear infinite;
  }
`

const Icon = () => (
  <StyledIcon>
    <svg
      id="left-wheel"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      width={WIDTH * scale}
      height={HEIGHT * scale}
      style={{ position: 'absolute', top: 0, left: 0 }}
    >
      <image className="all" width="100%" height="100%" />
      <g>
        <path
          d="M112.8,36.6c-0.3-0.4-0.5-0.9-0.8-1.2c-0.3-0.4-0.5-0.8-0.8-1.2c0,0-0.1-0.1-0.1-0.2c-0.5-0.8-1-1.4-1.5-2.2
		c-0.3-0.4-0.6-0.8-0.9-1.1c0,0,0,0,0-0.1c-0.3-0.4-0.6-0.7-0.9-1c-0.3-0.4-0.6-0.8-1-1c-0.3-0.4-0.7-0.7-1-1s-0.7-0.7-1-1
		c-0.3-0.4-0.7-0.7-1-1c-0.4-0.3-0.7-0.7-1-1c-0.4-0.3-0.8-0.6-1.1-1c-0.4-0.3-0.8-0.6-1.1-1c-0.4-0.3-0.8-0.6-1.1-0.9
		c-0.6-0.4-1-0.8-1.6-1.1c-0.6-0.4-1.1-0.8-1.6-1c-1.1-0.7-2.2-1.3-3.3-1.9c-1.1-0.6-2.3-1.1-3.5-1.6c-0.5-0.2-1-0.4-1.6-0.7
		c-0.2,0-0.3-0.1-0.4-0.2c-0.5-0.2-1-0.4-1.5-0.5c-0.6-0.2-1.2-0.4-1.8-0.6c-0.6-0.2-1.1-0.3-1.6-0.5c-0.8-0.2-1.4-0.4-2.2-0.5
		c-0.6-0.1-1-0.2-1.6-0.3c-0.4-0.1-0.9-0.1-1.2-0.2c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4-0.1-0.6-0.1c-0.6-0.1-1-0.1-1.6-0.2
		c-0.5,0-1-0.1-1.5-0.1c-0.2,0-0.4,0-0.6,0c-0.5,0-1,0-1.4,0c-0.7,0-1.3,0-2,0c-0.3,0-0.6,0-1,0c-0.5,0-1,0.1-1.4,0.1
		s-1,0.1-1.4,0.1c-4.8,0.6-9.3,1.8-13.5,3.6c-0.9,0.4-1.7,0.8-2.6,1.2c-0.4,0.2-0.9,0.5-1.2,0.7c-0.4,0.2-0.8,0.5-1.1,0.7
		c-0.4,0.2-0.7,0.4-1,0.6c-0.3,0.2-0.6,0.3-0.9,0.5c-0.1,0-0.2,0.1-0.3,0.2c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.2-0.7,0.5-1,0.7
		c-0.7,0.5-1.2,1-1.8,1.4c-0.6,0.5-1.2,1-1.8,1.4c-0.3,0.3-0.6,0.5-0.9,0.8c-0.5,0.5-1,1-1.5,1.5c-1.2,1.2-2.4,2.5-3.5,3.9
		c-0.2,0.3-0.5,0.6-0.7,0.9c-0.3,0.4-0.6,0.8-1,1.2c-0.4,0.6-0.8,1-1.1,1.6c-0.4,0.6-0.8,1.1-1,1.6c-0.7,1-1.3,2.2-1.9,3.3
		s-1.1,2.3-1.5,3.5c-0.2,0.5-0.4,1-0.7,1.6c0,0.1-0.1,0.3-0.2,0.4c-0.2,0.5-0.4,1-0.5,1.5c-0.2,0.6-0.4,1.2-0.6,1.8
		c-0.2,0.6-0.3,1.1-0.5,1.6c-0.2,0.8-0.3,1.4-0.5,2.2c-0.1,0.6-0.2,1-0.3,1.6c-0.1,0.4-0.1,0.9-0.2,1.2c0,0.1,0,0.2,0,0.3
		c0,0.2,0,0.4-0.1,0.6c-0.1,0.6-0.1,1-0.2,1.6c0,0.5-0.1,1-0.1,1.5c0,0.2,0,0.4,0,0.6c0,0.5,0,1,0,1.4c0,0.7,0,1.3,0,2
		c0,0.3,0,0.6,0,1c0,0.5,0.1,1,0.1,1.4c0,0.5,0.1,1,0.2,1.4c0.5,3.8,1.3,7.4,2.6,10.9c0.2,0.5,0.3,0.9,0.5,1.3
		c0.2,0.5,0.4,1,0.6,1.4c0.2,0.4,0.4,0.9,0.6,1.2c0.2,0.5,0.4,0.9,0.6,1.2c0.4,0.9,0.9,1.7,1.3,2.5c0.4,0.7,0.8,1.2,1.1,1.9
		c0.9,1.3,1.7,2.6,2.7,3.8c1.2,1.5,2.5,3,3.8,4.3c0.3,0.3,0.6,0.6,0.9,0.8c0.3,0.3,0.6,0.6,0.9,0.8c0.3,0.3,0.6,0.5,0.9,0.8
		c0.6,0.5,1.1,1,1.7,1.4c0.6,0.4,1,0.9,1.6,1.2c0,0,0.1,0,0.1,0.1c0.4,0.3,0.8,0.5,1.1,0.8c0,0,0.1,0,0.1,0.1
		c0.2,0.2,0.4,0.3,0.7,0.4c0.3,0.2,0.6,0.4,0.9,0.6c0.3,0.2,0.6,0.4,0.9,0.5c0.4,0.3,0.9,0.5,1.2,0.8c0.5,0.3,1,0.5,1.3,0.8l0,0
		c0.2,0.1,0.4,0.2,0.7,0.3c0.5,0.2,1,0.5,1.4,0.7c0.5,0.3,1,0.5,1.5,0.7c0.6,0.3,1.1,0.5,1.8,0.7c0.4,0.1,0.8,0.3,1.1,0.4
		c0.4,0.1,0.8,0.3,1.1,0.4c0.5,0.2,1,0.3,1.4,0.4c0.3,0.1,0.7,0.2,1,0.3c0.8,0.2,1.5,0.4,2.3,0.5c0.4,0.1,0.8,0.2,1.1,0.2
		c0.8,0.2,1.6,0.3,2.4,0.4c0.4,0.1,0.8,0.1,1.2,0.1l0,0c0.8,0.1,1.6,0.2,2.4,0.2c0.4,0,0.9,0,1.2,0.1c0.4,0,0.9,0,1.2,0h0.7
		c9.2-0.1,18.3-2.9,26.1-8h-0.1c13-8.6,20.9-22.9,21.6-38.3c0-0.7,0-1.3,0-2C119.4,52.2,117,43.8,112.8,36.6z M94.2,40.9
		c6.4-2.1,13.1,1.4,15.2,7.7c0.7,2.1,0.8,4.3,0.3,6.3c-0.9,4.1-3.8,7.5-8.1,9c-5.5,1.8-11.3-0.6-14.2-5.4c-0.5-0.8-0.8-1.5-1-2.4
		C84.4,49.8,87.9,42.9,94.2,40.9z M71.1,20.8c2.2,0,4.3,0.6,6.1,1.6c3.6,2.1,6,6,6,10.5c0,5.8-4.1,10.7-9.5,11.8
		C72.9,44.9,72,45,71.1,45c-6.7,0-12.1-5.4-12.1-12.1S64.5,20.8,71.1,20.8z M32.9,48.6c0.7-2.1,1.9-3.9,3.4-5.2
		c3.1-2.8,7.5-3.9,11.8-2.5c5.5,1.8,8.9,7.1,8.3,12.7c-0.1,0.9-0.3,1.7-0.5,2.6c-2.1,6.4-8.9,9.8-15.2,7.7S30.8,55,32.9,48.6z
		 M64.4,91c-3.9,5.4-11.5,6.6-16.9,2.7c-1.8-1.3-3.1-3-3.9-5c-1.7-3.8-1.3-8.4,1.2-12c3.4-4.7,9.5-6.2,14.7-3.9
		c0.8,0.3,1.5,0.8,2.3,1.2C67.1,78.1,68.3,85.6,64.4,91z M67.5,62.9c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3c0,0,0-0.1,0-0.2
		s-0.1-0.2-0.1-0.3c0-0.1,0-0.3-0.1-0.4c0-0.1,0-0.3,0-0.4c0-0.7,0.2-1.2,0.5-1.8c0-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3
		c0,0,0,0,0-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.1,0.2-0.2c0.3-0.3,0.6-0.6,1-0.8c0.1,0,0.2-0.1,0.3-0.1s0.2-0.1,0.3-0.1
		s0.2-0.1,0.4-0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.4-0.1c0.2,0,0.3,0,0.5,0c0.3,0,0.6,0,0.8,0.1c0.1,0,0.2,0,0.3,0.1
		c0.5,0.1,0.9,0.3,1.2,0.6c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.4
		c0.1,0.1,0.2,0.3,0.3,0.4s0.1,0.3,0.2,0.4c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.2,0,0.3,0,0.5
		l0,0c0,0.1,0,0.3,0,0.4v0.1c0,0.1,0,0.3-0.1,0.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.4-0.3,0.9-0.6,1.2c-0.1,0.1-0.1,0.2-0.2,0.3
		c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.4,0.3c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.1-0.4,0.2
		c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0-0.4,0.1c-0.2,0-0.3,0-0.5,0c-0.3,0-0.6,0-0.9-0.1c-0.1,0-0.2,0-0.3-0.1
		c-0.4-0.1-0.9-0.3-1.1-0.6c-0.1-0.1-0.2-0.1-0.3-0.2c0,0-0.1,0-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.2c-0.3-0.2-0.5-0.5-0.6-0.8
		C67.6,63.1,67.6,63,67.5,62.9z M94.8,93.7c-1.8,1.3-3.8,2-5.9,2.2c-4.1,0.5-8.4-1.3-11-4.9c-3.4-4.7-3-11,0.8-15.1
		c0.6-0.7,1.2-1.2,1.9-1.7c5.4-3.9,13-2.8,16.9,2.7C101.4,82.2,100.2,89.8,94.8,93.7z"
        />
      </g>
    </svg>
    <svg
      id="middle-strap"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      width={WIDTH * scale}
      height={HEIGHT * scale}
      style={{ position: 'absolute', top: 0, left: 0 }}
    >
      <image className="all" width="100%" height="100%" />
      <g>
        <polygon points="231.9,108.7 71.1,108.7 81.6,103 94.6,100.7 208.4,100.7 221.4,103 	" />
      </g>
    </svg>
    <svg
      id="right-wheel"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      width={WIDTH * scale}
      height={HEIGHT * scale}
      style={{ position: 'absolute', top: 0, left: 0 }}
    >
      <image className="all" width="100%" height="100%" />
      <g>
        <path
          d="M274.1,36.6c-0.3-0.4-0.5-0.9-0.8-1.2s-0.5-0.8-0.8-1.2c0,0-0.1-0.1-0.1-0.2c-0.5-0.8-1-1.4-1.5-2.2
		c-0.3-0.4-0.6-0.8-0.9-1.1c0,0,0,0,0-0.1c-0.3-0.4-0.6-0.7-0.9-1c-0.3-0.4-0.6-0.8-1-1c-0.3-0.4-0.7-0.7-1-1s-0.7-0.7-1-1
		c-0.3-0.4-0.7-0.7-1-1s-0.7-0.7-1-1c-0.4-0.3-0.8-0.6-1.1-1c-0.4-0.3-0.8-0.6-1.1-1c-0.4-0.3-0.8-0.6-1.1-0.9
		c-0.6-0.4-1-0.8-1.6-1.1c-0.6-0.4-1.1-0.8-1.6-1c-1.1-0.7-2.2-1.3-3.3-1.9c-1.1-0.6-2.3-1.1-3.5-1.6c-0.5-0.2-1-0.4-1.6-0.7
		c-0.2,0-0.3-0.1-0.4-0.2c-0.5-0.2-1-0.4-1.5-0.5c-0.6-0.2-1.2-0.4-1.8-0.6c-0.6-0.2-1.1-0.3-1.6-0.5c-0.8-0.2-1.4-0.4-2.2-0.5
		c-0.6-0.1-1-0.2-1.6-0.3c-0.4-0.1-0.9-0.1-1.2-0.2c-0.1,0-0.2,0-0.3,0c-0.2,0-0.4-0.1-0.6-0.1c-0.6-0.1-1-0.1-1.6-0.2
		c-0.5,0-1-0.1-1.5-0.1c-0.2,0-0.4,0-0.6,0c-0.5,0-1,0-1.4,0c-0.7,0-1.3,0-2,0c-0.3,0-0.6,0-1,0c-0.5,0-1,0.1-1.4,0.1
		s-1,0.1-1.4,0.1c-4.8,0.6-9.3,1.8-13.5,3.6c-0.9,0.4-1.7,0.8-2.6,1.2c-0.4,0.2-0.9,0.5-1.2,0.7c-0.4,0.2-0.8,0.5-1.1,0.7
		c-0.4,0.2-0.7,0.4-1,0.6c-0.3,0.2-0.6,0.3-0.9,0.5c-0.1,0-0.2,0.1-0.3,0.2c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3,0.2-0.7,0.5-1,0.7
		c-0.7,0.5-1.2,1-1.8,1.4c-0.6,0.5-1.2,1-1.8,1.4c-0.3,0.3-0.6,0.5-0.9,0.8c-0.5,0.5-1,1-1.5,1.5c-1.2,1.2-2.4,2.5-3.5,3.9
		c-0.2,0.3-0.5,0.6-0.7,0.9c-0.3,0.4-0.6,0.8-1,1.2c-0.4,0.6-0.8,1-1.1,1.6c-0.4,0.6-0.8,1.1-1,1.6c-0.7,1-1.3,2.2-1.9,3.3
		s-1.1,2.3-1.5,3.5c-0.2,0.5-0.4,1-0.7,1.6c0,0.1-0.1,0.3-0.2,0.4c-0.2,0.5-0.4,1-0.5,1.5c-0.2,0.6-0.4,1.2-0.6,1.8
		c-0.2,0.6-0.3,1.1-0.5,1.6c-0.2,0.8-0.3,1.4-0.5,2.2c-0.1,0.6-0.2,1-0.3,1.6c-0.1,0.4-0.1,0.9-0.2,1.2c0,0.1,0,0.2,0,0.3
		c0,0.2,0,0.4-0.1,0.6c-0.1,0.6-0.1,1-0.2,1.6c0,0.5-0.1,1-0.1,1.5c0,0.2,0,0.4,0,0.6c0,0.5,0,1,0,1.4c0,0.7,0,1.3,0,2
		c0,0.3,0,0.6,0,1c0,0.5,0.1,1,0.1,1.4c0,0.5,0.1,1,0.2,1.4c0.5,3.8,1.3,7.4,2.6,10.9c0.2,0.5,0.3,0.9,0.5,1.3
		c0.2,0.5,0.4,1,0.6,1.4c0.2,0.4,0.4,0.9,0.6,1.2c0.2,0.5,0.4,0.9,0.6,1.2c0.4,0.9,0.9,1.7,1.3,2.5c0.4,0.7,0.8,1.2,1.1,1.9
		c0.9,1.3,1.7,2.6,2.7,3.8c1.2,1.5,2.5,3,3.8,4.3c0.3,0.3,0.6,0.6,0.9,0.8c0.3,0.3,0.6,0.6,0.9,0.8c0.3,0.3,0.6,0.5,0.9,0.8
		c0.6,0.5,1.1,1,1.7,1.4c0.6,0.4,1,0.9,1.6,1.2c0,0,0.1,0,0.1,0.1c0.4,0.3,0.8,0.5,1.1,0.8c0,0,0.1,0,0.1,0.1
		c0.2,0.2,0.4,0.3,0.7,0.4c0.3,0.2,0.6,0.4,0.9,0.6c0.3,0.2,0.6,0.4,0.9,0.5c0.4,0.3,0.9,0.5,1.2,0.8c0.5,0.3,1,0.5,1.3,0.8l0,0
		c0.2,0.1,0.4,0.2,0.7,0.3c0.5,0.2,1,0.5,1.4,0.7c0.5,0.3,1,0.5,1.5,0.7c0.6,0.3,1.1,0.5,1.8,0.7c0.4,0.1,0.8,0.3,1.1,0.4
		c0.4,0.1,0.8,0.3,1.1,0.4c0.5,0.2,1,0.3,1.4,0.4c0.3,0.1,0.7,0.2,1,0.3c0.8,0.2,1.5,0.4,2.3,0.5c0.4,0.1,0.8,0.2,1.1,0.2
		c0.8,0.2,1.6,0.3,2.4,0.4c0.4,0.1,0.8,0.1,1.2,0.1l0,0c0.8,0.1,1.6,0.2,2.4,0.2c0.4,0,0.9,0,1.2,0.1c0.4,0,0.9,0,1.2,0h0.7
		c9.2-0.1,18.3-2.9,26.1-8h-0.1c13-8.6,20.9-22.9,21.6-38.3c0-0.7,0-1.3,0-2C280.8,52.2,278.4,43.8,274.1,36.6z M255.5,40.9
		c6.4-2.1,13.1,1.4,15.2,7.7c0.7,2.1,0.8,4.3,0.3,6.3c-0.9,4.1-3.8,7.5-8.1,9c-5.5,1.8-11.3-0.6-14.2-5.4c-0.5-0.8-0.8-1.5-1-2.4
		C245.7,49.8,249.2,42.9,255.5,40.9z M232.5,20.8c2.2,0,4.3,0.6,6.1,1.6c3.6,2.1,6,6,6,10.5c0,5.8-4.1,10.7-9.5,11.8
		c-0.9,0.2-1.7,0.3-2.6,0.3c-6.7,0-12.1-5.4-12.1-12.1S225.8,20.8,232.5,20.8z M194.2,48.6c0.7-2.1,1.9-3.9,3.4-5.2
		c3.1-2.8,7.5-3.9,11.8-2.5c5.5,1.8,8.9,7.1,8.3,12.7c-0.1,0.9-0.3,1.7-0.5,2.6c-2.1,6.4-8.9,9.8-15.2,7.7S192.1,55,194.2,48.6z
		 M225.7,91c-3.9,5.4-11.5,6.6-16.9,2.7c-1.8-1.3-3.1-3-3.9-5c-1.7-3.8-1.3-8.4,1.2-12c3.4-4.7,9.5-6.2,14.7-3.9
		c0.8,0.3,1.5,0.8,2.3,1.2C228.4,78.1,229.6,85.6,225.7,91z M228.9,62.9c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.3
		c0,0,0-0.1,0-0.2s-0.1-0.2-0.1-0.3c0-0.1,0-0.3-0.1-0.4c0-0.1,0-0.3,0-0.4c0-0.7,0.2-1.2,0.5-1.8c0-0.1,0.1-0.2,0.2-0.3
		c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0-0.1,0.1-0.1,0.2-0.2c0.3-0.3,0.6-0.6,1-0.8
		c0.1,0,0.2-0.1,0.3-0.1s0.2-0.1,0.3-0.1s0.2-0.1,0.4-0.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0,0.4-0.1c0.2,0,0.3,0,0.5,0
		c0.3,0,0.6,0,0.8,0.1c0.1,0,0.2,0,0.3,0.1c0.5,0.1,0.9,0.3,1.2,0.6c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.1,0.2,0.2
		s0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.4c0.1,0.1,0.2,0.3,0.3,0.4s0.1,0.3,0.2,0.4c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0.1,0.2,0.1,0.3
		c0,0.1,0.1,0.3,0.1,0.4c0,0.2,0,0.3,0,0.5l0,0c0,0.1,0,0.3,0,0.4v0.1c0,0.1,0,0.3-0.1,0.4c0,0.1,0,0.3-0.1,0.4
		c-0.1,0.4-0.3,0.9-0.6,1.2c-0.1,0.1-0.1,0.2-0.2,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.4,0.3
		c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0-0.2,0.1-0.4,0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0-0.4,0.1
		c-0.2,0-0.3,0-0.5,0c-0.3,0-0.6,0-0.9-0.1c-0.1,0-0.2,0-0.3-0.1c-0.4-0.1-0.9-0.3-1.1-0.6c-0.1-0.1-0.2-0.1-0.3-0.2
		c0,0-0.1,0-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.2c-0.3-0.2-0.5-0.5-0.6-0.8C229,63.1,229,63,228.9,62.9z M256.1,93.7
		c-1.8,1.3-3.8,2-5.9,2.2c-4.1,0.5-8.4-1.3-11-4.9c-3.4-4.7-3-11,0.8-15.1c0.6-0.7,1.2-1.2,1.9-1.7c5.4-3.9,13-2.8,16.9,2.7
		C262.8,82.2,261.5,89.8,256.1,93.7z"
        />
      </g>
    </svg>
  </StyledIcon>
)

const LoadingScreeningsIcon = Icon

export default LoadingScreeningsIcon
