export const TOUCH_DEVICE = '@media (pointer: coarse)'
export const MOUSE_DEVICE = '@media (pointer: fine)'
export const MIN_1200 = '@media (min-width: 1200px)'
export const MIN_1000 = '@media (min-width: 1000px)'
export const MIN_820 = '@media (min-width: 820px)'
export const MIN_600 = '@media (min-width: 600px)'
export const MAX_400 = '@media (max-width: 400px)'

export const CHROME_ANDROID_NO_HIGHLIGHT = `outline: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;`

export const CHROME_MOBILE_HIGHLIGHT = color => `outline: none;
  user-select: none;
  -webkit-tap-highlight-color: ${color};`

export const PRIMARY_FONT = `'Open Sans'`
export const SECONDARY_FONT = `'Nunito Sans'`

export const SHOW_HIDE_FAST_DURATION = '0.1s'

export const SHOW_HIDE_COMMON_SNIPPET = `
  visibility: hidden;
  opacity: 0;
  transition: opacity ${SHOW_HIDE_FAST_DURATION},
    visibility 0s ${SHOW_HIDE_FAST_DURATION};
`

export const SHOW_HIDE_MOBILE_SNIPPET = ({ show }) =>
  show
    ? `
    transition: opacity ${SHOW_HIDE_FAST_DURATION};
    visibility: visible;
    opacity: 1;
  `
    : ''

export const SHOW_HIDE_MOUSE_SNIPPET = `
  ${MOUSE_DEVICE} {
    &:hover {
      &:before {
        transition: opacity ${SHOW_HIDE_FAST_DURATION};
        visibility: visible;
        opacity: 1;
      }
    }
  }
`

export const LAYOUT_MAX_WIDTH = 680
