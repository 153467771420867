import React from 'react'
import { connect } from 'react-redux-hoc'
import styled from '@emotion/styled/macro'
import { compose, path } from 'ramda'
import { pure, withHandlers } from 'recompose'
import { PRIMARY_BACKGROUND_COLOR, THEME_COLOR } from '../constants/colors'
import Magnifier from './Magnifier'
import {
  CHROME_ANDROID_NO_HIGHLIGHT,
  SECONDARY_FONT,
  MOUSE_DEVICE,
} from '../constants/CSS'
import { zindex } from '../constants/zIndexes'
import withEarlyPropChange from '../hoc/withEarlyPropChange'
import { setFilterQuery } from '../actions/filter'
import { MEDIA_DESKTOP } from '../constants/media'
import { SCREENINGS_TOP_OFFSET, SEARCH_BAR_HEIGHT } from '../constants/layout'
import scrollToTop from '../utils/scrollToTop'
import withScreenWidth from '../hoc/withScreenWidth'
import Ripple from './Ripple'

const SEARCH_BOX_COLOR = '#eaeaea'

const StyledSearchContainer = styled.div`
  background: ${PRIMARY_BACKGROUND_COLOR};
  width: 100%;
  padding: 0 10px 0;
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${SEARCH_BAR_HEIGHT}px;
  /*box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);*/
  /*margin-bottom: 3px;*/

  z-index: ${zindex.searchBar};

  ${MEDIA_DESKTOP} {
    top: ${SCREENINGS_TOP_OFFSET}px;
  }
`

const SEARCH_BOX_HEIGHT = 42

const StyledSearchBox = styled.div`
  background: ${SEARCH_BOX_COLOR};
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 0px 3px #0000002e;
  box-shadow: 0px 0px 6px #0000000f;
  display: flex;
  align-items: center;
  max-width: 800px;
  width: 100%;
  padding: 0px 7px 0px 12px;
  height: ${SEARCH_BOX_HEIGHT}px;

  & svg {
    flex-shrink: 0;
  }
`

const StyledSearchInput = withEarlyPropChange({
  convertor: path(['target', 'value']),
  delay: 200,
})(styled.input`
  background: ${SEARCH_BOX_COLOR};
  flex: 1;
  outline: none;
  border: none;
  line-height: 36px;
  margin-left: 10px;
  margin-right: 0px;
  font-size: 16px;

  font-family: ${SECONDARY_FONT};

  text-overflow: ellipsis;
  overflow: hidden;
  color: #111;

  &::placeholder {
    color: #666;
  }

  -webkit-tap-highlight-color: transparent;
`)

const StyledClearButton = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 5px 8px;
  border-radius: 5px;
  font-family: ${SECONDARY_FONT};
  font-weight: 700;
  flex-shrink: 0;
  font-size: 16px;
  user-select: none;
  cursor: pointer;

  ${MOUSE_DEVICE} {
    &:hover {
      color: ${THEME_COLOR};
    }
  }

  ${CHROME_ANDROID_NO_HIGHLIGHT}

  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
`

const SearchBox = ({ value, onChange, clearInput, onKeyUp }) => (
  <StyledSearchContainer>
    <StyledSearchBox>
      <Magnifier />
      <StyledSearchInput
        {...{
          placeholder: 'Hledat film nebo kino',
          onChange,
          value,
          onKeyUp,
        }}
      />
      <StyledClearButton {...{ onClick: clearInput, visible: !!value }}>
        <Ripple {...{ color: '#999', opacity: 0.3 }} />
        vymazat
      </StyledClearButton>
    </StyledSearchBox>
  </StyledSearchContainer>
)

export default compose(
  connect(
    { value: path(['filter', 'query']) },
    { setFilterQuery },
  ),
  withScreenWidth,
  withHandlers({
    onChange: ({ isDesktop, setFilterQuery }) => val => {
      scrollToTop(undefined, isDesktop ? 0 : SCREENINGS_TOP_OFFSET)
      setFilterQuery(val)
    },
    clearInput: ({ isDesktop, setFilterQuery }) => () => {
      scrollToTop(undefined, isDesktop ? 0 : SCREENINGS_TOP_OFFSET)
      setFilterQuery('')
    },
    onKeyUp: () => event => {
      const hasTouch =
        window &&
        window.matchMedia &&
        window.matchMedia('(pointer: coarse)').matches &&
        ('ontouchstart' in window ||
          (window.DocumentTouch && document instanceof window.DocumentTouch))

      if (event.key === 'Enter' && hasTouch) {
        event.preventDefault()
        event.target.blur()
      }
    },
  }),
  pure,
)(SearchBox)
