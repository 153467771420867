import React from 'react'
import { pure } from 'recompose'
import { THEME_COLOR } from '../constants/colors'

const Genre = pure(() => (
  <svg width="23.416" height="20.489" viewBox="0 0 23.416 20.489">
    <g transform="translate(0 -32.764)">
      <path
        fill="#484848"
        d="M255.791,98.415a.736.736,0,0,0-.75.031,12.028,12.028,0,0,1-6.189,1.339l.006,5.858a8.722,8.722,0,0,1-.532,2.981,2.9,2.9,0,0,1,3.459,2.873H246.6a8.81,8.81,0,0,1-2.961,2.163,7.226,7.226,0,0,0,5.216,2.227,7.326,7.326,0,0,0,7.318-7.318V99.057A.732.732,0,0,0,255.791,98.415Zm-5.47,7.228a1.464,1.464,0,1,1,2.927,0Z"
        transform="translate(-232.759 -62.634)"
      />
      <path
        fill="#484848"
        d="M14.635,43.01V33.5a.733.733,0,0,0-1.135-.611,12.155,12.155,0,0,1-6.183,1.343,12.176,12.176,0,0,1-6.182-1.342A.732.732,0,0,0,0,33.5V43.01a7.318,7.318,0,1,0,14.635,0ZM4.391,38.619a1.464,1.464,0,0,1,1.464,1.464H2.927A1.464,1.464,0,0,1,4.391,38.619Zm2.927,7.318A2.927,2.927,0,0,1,4.391,43.01h5.854A2.927,2.927,0,0,1,7.318,45.937Zm1.463-5.854a1.464,1.464,0,1,1,2.927,0Z"
        transform="translate(0)"
      />
    </g>
  </svg>
))

export default Genre
