import createAction from '../utils/createAction'
import * as ActionTypes from '../constants/ActionTypes'

export const setFilterWhere = where =>
  createAction(ActionTypes.SET_FILTER_WHERE, {
    where,
  })

export const setFilterWhereItem = (isSelected, cinemaName) =>
  createAction(ActionTypes.SET_FILTER_WHERE_ITEM, {
    cinemaName,
    isSelected,
  })

export const setFilterDaysOfWeek = daysOfWeek =>
  createAction(ActionTypes.SET_FILTER_DAYS_OF_WEEK, {
    daysOfWeek,
  })

export const setFilterDate = date =>
  createAction(ActionTypes.SET_FILTER_DATE, {
    date,
  })

export const setFilterMovies = movies =>
  createAction(ActionTypes.SET_FILTER_MOVIES, {
    movies,
  })

export const setFilterMoviesItem = (isSelected, canonicalTitle) =>
  createAction(ActionTypes.SET_FILTER_MOVIES_ITEM, {
    canonicalTitle,
    isSelected,
  })

export const setFilterPrice = price =>
  createAction(ActionTypes.SET_FILTER_PRICE, {
    price,
  })

export const setFilterQuery = query =>
  createAction(ActionTypes.SET_FILTER_QUERY, {
    query,
  })

export const setFilterFavourites = favourites =>
  createAction(ActionTypes.SET_FILTER_FAVOURITES, {
    favourites,
  })

export const resetFilter = () => createAction(ActionTypes.RESET_FILTER)
