import React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled/macro'
import { unsetHoveredScreeningElement } from '../actions/hoveredScreeningElement'
// import FilterMenu from './FilterMenu'
// import FilterButton from './FilterButton'
// import OkButton from './OkButton'
// import ReturnToTopButton from './ReturnToTopButton'

const StyledWrapper = styled.div`
  margin: 0 auto;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  //min-height: 100vh;
  /* background: #474747; */
  /* background: #ccc; */
  background: #221f1a;
  /* position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px; */
  background: #14120f;
  background: black;
  background: #2f2e2b;
  background: rgb(229, 224, 219);
  background: rgb(231, 220, 209);
  background: #e7d7c9;
  background: #fafafa;
  background: rgb(229, 224, 219);
  background: #e6e6e6;
  /* background: rgb(229, 229, 229); */
`

const Wrapper = ({ children, unsetHoveredScreeningElement }) => (
  <StyledWrapper onClick={unsetHoveredScreeningElement}>
    {/*<FilterMenu />*/}
    {/*<FilterButton />*/}
    {/*<OkButton />*/}
    {/*<ReturnToTopButton />*/}
    {children}
  </StyledWrapper>
)

export default connect(
  undefined,
  {
    unsetHoveredScreeningElement,
  },
)(Wrapper)
