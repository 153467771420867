import {
  prop,
  pipe,
  groupBy,
  toPairs,
  map,
  sortBy,
  values,
  filter,
} from 'ramda'
import axios from 'axios'
import { DAYS_OF_WEEK } from '../constants/date'
import { deriveStoriesCategories } from './deriveStoriesCategories'

// const wait = ms =>
//   new Promise(res => {
//     setTimeout(() => res(), ms)
//   })

const fetchShowtimes = async () => {
  // await wait(180000)

  const data = await axios.get('/api/data').then(prop('data'))
  const now = new Date()
  const screeningsObj = pipe(
    map(s => ({ ...s, date: new Date(s.date) })),
    filter(({ date }) => now < date),
  )(data.screenings)
  const screenings = pipe(
    values,
    sortBy(prop('date')),
  )(screeningsObj)

  const { movies, movieLinks, movieCsfdLinks, movieCsfdData } = data
  const screeningsByDate = pipe(
    groupBy(
      ({ date }) =>
        `${DAYS_OF_WEEK[date.getDay()]} ${date.getDate()}/${date.getMonth() +
          1}`,
    ),
    toPairs,
  )(screenings)

  const categories = deriveStoriesCategories({
    screeningsObj,
    movies,
    movieCsfdData,
    movieLinks,
  })

  return {
    movies,
    movieLinks,
    movieCsfdLinks,
    movieCsfdData,
    screenings,
    screeningsByDate,
    screeningsObj,
    categories,
  }
}

export { fetchShowtimes }
