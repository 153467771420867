import React from 'react'
import styled from '@emotion/styled/macro'
import {
  HEADER_LOGO_CONTAINER_HEIGHT,
  SEARCH_BAR_HEIGHT,
} from '../constants/layout'
import { zindex } from '../constants/zIndexes'
import { MEDIA_DESKTOP } from '../constants/media'

const HEIGHT = 10

const StyledHeaderShadow = styled.div`
  height: ${HEIGHT}px;
  width: 100%;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  background: white;
  margin-bottom: 3px;
  position: sticky;

  z-index: ${zindex.headerShadow};
  top: ${SEARCH_BAR_HEIGHT - HEIGHT}px;

  ${MEDIA_DESKTOP} {
    top: ${HEADER_LOGO_CONTAINER_HEIGHT + SEARCH_BAR_HEIGHT - HEIGHT}px;
  }
`

export const HeaderShadow = () => {
  return <StyledHeaderShadow />
}
