import React from 'react'
import styled from '@emotion/styled/macro'
import { compose, withState, withHandlers, pure } from 'recompose'
// import PlayButton from './PlayButton'
import {
  MOUSE_DEVICE,
  SECONDARY_FONT,
  CHROME_ANDROID_NO_HIGHLIGHT,
} from '../../constants/CSS'
import { THEME_COLOR, PRIMARY_BACKGROUND_COLOR } from '../../constants/colors'
import { zindex } from '../../constants/zIndexes'
import PlayArrow from '../../components/PlayArrow'
import PlayArrow2 from '../../components/PlayArrow2'
import HorizontalDivider from '../../components/HorizontalDivider'
import {
  MEDIA_FULL_DESKTOP,
  FULL_DESKTOP_WIDTH,
  MEDIA_IOS,
} from '../../constants/media'
import withScreenWidth from '../../hoc/withScreenWidth'
import YouTube from 'react-youtube'

const StyledTrailer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 10px;
  width: 100%;
  position: sticky;
  top: 65px;

  ${MEDIA_IOS} {
    position: relative;
    top: 0px;
  }

  ${MEDIA_FULL_DESKTOP} {
    position: relative;
    top: 0px;
  }
`

const StyledVideoImage = styled.img`
  width: 100%;
  filter: ${({ hasVideo }) => (hasVideo ? 'opacity(100%)' : 'none')};
`

const StyledVideoContainer = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  cursor: ${({ hasVideo }) => (hasVideo ? 'pointer' : 'default')};
  background: #111;

  ${StyledVideoImage} {
    transition: 0.15s all;
  }

  ${({ hasVideo }) =>
    hasVideo
      ? `${MOUSE_DEVICE} {
    /*&:hover {
      ${StyledVideoImage} {
        filter: opacity(85%);
      }

    }*/
  }`
      : ''}

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    ${({ hideVideo }) => (hideVideo ? 'visibility: hidden;' : '')}
  }
`

const StyledVideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
`

const PLAY_BUTTON_WIDTH = 110
const PLAY_BUTTON_HEIGHT = 34

const StyledPlayButton = styled.div`
  position: absolute;
  /* transform: translate(0%, 0%); */
  top: 48%;
  left: 50%;
  margin-left: -${PLAY_BUTTON_WIDTH / 2}px;
  margin-top: -${PLAY_BUTTON_HEIGHT / 2}px;
  z-index: ${zindex.playButton};
  cursor: pointer;

  & svg {
    transform: scale(0.8);
  }

  /* ${MOUSE_DEVICE} {
    &:hover {
      opacity: 0.9;
    }
  } */

  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${PRIMARY_BACKGROUND_COLOR};
  border-radius: 100px;
  width: ${PLAY_BUTTON_WIDTH}px;
  height: ${PLAY_BUTTON_HEIGHT}px;
  background: ${THEME_COLOR};

  ${CHROME_ANDROID_NO_HIGHLIGHT}
`

const StyledPlayButtonText = styled.div`
  color: ${PRIMARY_BACKGROUND_COLOR};
  font-family: ${SECONDARY_FONT};
  font-size: 11px;
  font-weight: 900;
  text-transform: uppercase;
`

const ScreeningDetailTrailer = ({
  youtube,
  image,
  playVideo,
  onClick,
  isDesktop,
  onReady,
}) => {
  return (
    <StyledTrailer>
      <StyledVideoContainer
        hasVideo={!!youtube}
        hideVideo={!playVideo && !isDesktop}
      >
        {youtube && (
          <YouTube
            videoId={youtube}
            opts={{
              playerVars: {
                rel: 0,
                autoplay: isDesktop ? 1 : 0,
                mute: isDesktop ? 1 : 0,
                frameBorder: 0,
                allow:
                  'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
                allowFullScreen: 1,
              },
            }}
            onReady={onReady}
          />
        )}
        {!playVideo && (!isDesktop || !youtube) && (
          <StyledVideoWrapper>
            {youtube && (
              <StyledPlayButton {...{ onClick }}>
                <StyledPlayButtonText>Přehrát</StyledPlayButtonText>
                <HorizontalDivider {...{ space: 6 }} />
                <PlayArrow2 />
              </StyledPlayButton>
            )}
            {image && (
              <StyledVideoImage
                alt="trailer_image"
                src={image}
                hasVideo={!!youtube}
                onClick={youtube ? onClick : undefined}
              />
            )}
          </StyledVideoWrapper>
        )}
      </StyledVideoContainer>
    </StyledTrailer>
  )
}

export default compose(
  withState('playVideo', 'setPlayVideo', false),
  withHandlers(() => {
    let player

    return {
      onClick: ({ setPlayVideo }) => () => {
        setPlayVideo(true)

        if (player) {
          player.playVideo()
        }
      },
      onReady: () => e => {
        player = e && e.target
      },
    }
  }),
  withScreenWidth,
  pure,
)(ScreeningDetailTrailer)
