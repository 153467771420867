const canonizeTitle = (title = '') =>
  title
    .replace(/\[[a-zA-ZÀ-ž\. ]+\]$/g, '')
    .replace(/\([a-zA-ZÀ-ž\. ]+\)$/g, '')
    .trimRight()
    .trimLeft()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/&/g, 'a')
    .replace(/[|!\-–:.'"’´„“?,\/]/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .toLowerCase()
    .replace(/kino za 60/gi, '')
    .replace(/kino dlabacov live/gi, '')
    .replace(/^35mm film/gi, '')
    .replace(/^kino senioru/gi, '')
    .replace(/\s{2,}/g, ' ')
    .trimRight()
    .trimLeft()
    .toLowerCase()

export default canonizeTitle
