import React from 'react'
import styled from '@emotion/styled/macro'
import Link from './Link'
import {
  MOUSE_DEVICE,
  SHOW_HIDE_FAST_DURATION,
  SECONDARY_FONT,
} from '../constants/CSS'
import { zindex } from '../constants/zIndexes'

const PRICE_HOVER_BACKGROUND_COLOR = 'rgba(34, 34, 34, 0.8196078431372549)'

const StyledPriceContainer = styled(Link)`
  user-select: none;
  position: relative;
  width: 68px;
  flex-shrink: 0;
`

export const StyledPrice = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  font-size: 15px;
  font-weight: 700;
  text-align: right;
  text-decoration: underline;
  white-space: nowrap;
  color: #333;

  position: absolute;
  top: -17px;
  right: 4px;
  z-index: ${zindex.price};
  padding: 8px 12px 8px 0px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;

  transition: ${SHOW_HIDE_FAST_DURATION} all;

  &:before,
  &:after {
    z-index: -1;
    background: ${PRICE_HOVER_BACKGROUND_COLOR};
    position: absolute;
    opacity: 0;
    transition: opacity ${SHOW_HIDE_FAST_DURATION},
      visibility 0s ${SHOW_HIDE_FAST_DURATION};
    visibility: hidden;
  }

  &:before {
    content: 'Koupit za ';
    padding: 8px 3px 8px 12px;
    left: -80px;
    top: 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:after {
    content: '_';
    padding: 8px 0px 8px 0px;
    right: 0px;
    left: 0px;
    top: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: transparent;
  }

  ${MOUSE_DEVICE} {
    &:hover {
      color: white;
      text-decoration: none;

      &:before,
      &:after {
        transition: opacity ${SHOW_HIDE_FAST_DURATION};
        visibility: visible;
        opacity: 1;
      }
    }
  }
`

const Price = ({ price, buyLink }) => (
  <StyledPriceContainer
    href={buyLink}
    target="_blank"
    style={
      price !== undefined && buyLink !== undefined
        ? undefined
        : { display: 'none' }
    }
  >
    <StyledPrice>{price} Kč</StyledPrice>
  </StyledPriceContainer>
)

export default Price
