import React, { memo } from 'react'

export const SwipeUpIcon = memo(() => (
  <svg width={21.062} height={13.276} viewBox="0 0 21.062 13.276">
    <path
      d="M20.548 9.301l-8.787-8.8a1.73 1.73 0 00-1.23-.5 1.656 1.656 0 00-1.217.5l-8.8 8.8A1.678 1.678 0 000 10.531a1.607 1.607 0 00.514 1.217l1.014 1.014a1.607 1.607 0 001.217.514 1.607 1.607 0 001.217-.514l6.57-6.557 6.57 6.557a1.607 1.607 0 001.217.514 1.679 1.679 0 001.23-.514l1.014-1.014a1.656 1.656 0 00.5-1.217 1.677 1.677 0 00-.515-1.23z"
      fill="#fff"
    />
  </svg>
))
