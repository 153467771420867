import React from 'react'
import styled from '@emotion/styled/macro'
import { fetchShowtimes } from '../services/fetchShowtimes'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 40px;
  height: 25px;
  margin: 0 10px 0 10px;
`

// const StyledLine = styled.div`
//   height: 3px;
//   width: 100%;
//   border-radius: 1.5px;
//   background: black;
//   /* padding-bottom: ${p => (p.order === 1 ? '8px' : '0')}; */
//   /* padding-top: ${p => (p.order === 3 ? '8px' : '0')}; */
// `

const Menu = () => (
  <StyledContainer onClick={fetchShowtimes}>
    {/* <StyledLine order={1} />
    <StyledLine order={2} />
    <StyledLine order={3} /> */}
  </StyledContainer>
)

export default Menu
