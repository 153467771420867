import React from 'react'
import styled from '@emotion/styled/macro'
import { SECONDARY_FONT } from '../constants/CSS'
import { CINEMA_LOCATIONS_FORMAL, CINEMA_NAMES } from '../constants/cinemas'
import { SECONDARY_TEXT_COLOR } from '../constants/colors'

const StyledCinemaName = styled.div`
  font-family: ${SECONDARY_FONT};
  color: ${SECONDARY_TEXT_COLOR};
  display: flex;
  font-size: 12px;
  font-weight: 800;
  user-select: none;
  white-space: nowrap;
  line-height: 13px;
  //text-transform: uppercase;
`

const ScreeningSecondSectionContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 5px;
`

const StyledCinemaLocation = styled.div`
  font-family: ${SECONDARY_FONT};
  color: ${SECONDARY_TEXT_COLOR};
  display: flex;
  font-size: 12px;
  font-weight: 500;
  user-select: none;
  //text-transform: uppercase;
  line-height: 13px;
  white-space: nowrap;
  padding-left: 5px;
`

export const ScreeningSecondSection = ({ cinema }) => {
  return (
    <ScreeningSecondSectionContent>
      <StyledCinemaName>{CINEMA_NAMES[cinema]}</StyledCinemaName>
      <StyledCinemaLocation>
        ({CINEMA_LOCATIONS_FORMAL[cinema]})
      </StyledCinemaLocation>
    </ScreeningSecondSectionContent>
  )
}
