import React from 'react'
import styled from '@emotion/styled/macro'
import { compose, withProps, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import {
  SECONDARY_FONT,
  CHROME_ANDROID_NO_HIGHLIGHT,
} from '../../constants/CSS'
import getHallName from '../../utils/getHallName'
import getTimeText from '../../utils/getTimeText'
import getFormattedDate from '../../utils/getFormattedDate'
import { setHoveredScreeningElement } from '../../actions/hoveredScreeningElement'
import Hall, { StyledHall } from '../../components/Hall'
import Cinema, { StyledCinemaLogo } from '../../components/Cinema'
import Price from '../../components/Price'
import ScreeningHeart, {
  StyledHeartContainer,
} from '../../components/ScreeningHeart'
import { PRIMARY_BACKGROUND_COLOR } from '../../constants/colors'
import { goTo } from '../../actions/router'
import { createScreeningDetailRoute, ROUTE_STACK } from '../../constants/routes'
import encodeId from '../../utils/encodeId'
import dateToTime from '../../utils/dateToTime'

const StyledScreening = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  background: ${PRIMARY_BACKGROUND_COLOR};
  box-shadow: 0px 1px 3px #0000001c;
  border-radius: 7px;
  margin-bottom: 8px;
  padding: 3px 0px;
  width: 100%;

  ${StyledHeartContainer} {
    position: relative;
    top: 0px;
    right: 0px;
    padding: 5px 0px 5px 10px;
  }

  ${StyledCinemaLogo} {
    &:before {
      padding: 5px 14px 5px 14px;
      font-size: 13px;
      left: 36px;
      top: 0px;
    }
  }

  ${StyledHall} {
    &:before {
      padding: 5px 14px 5px 14px;
      font-size: 13px;
      right: 33px;
    }
  }
`

const SMALL_CINEMA_LOGO_SIZE = 28

export const StyledScreeningDateTime = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
  flex-shrink: 0;
  flex: 1;
  padding: 15px 0px;

  text-decoration: none;
  color: black;
  cursor: pointer;

  ${CHROME_ANDROID_NO_HIGHLIGHT}

  @media (max-width: 359px) {
    display: flex;
    flex-direction: column;
  }
`

export const StyledScreeningDate = styled.span`
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  font-weight: 500;
`

export const StyledScreeningTime = styled.span`
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  font-weight: 700;
`

const ScreeningDateTime = compose(
  withProps(({ date }) => ({
    time: dateToTime(date),
    day: date.getDate(),
    dayOfWeek: date.getDay(),
    month: date.getMonth() + 1,
  })),
)(({ time, day, dayOfWeek, month, id, onClick }) => {
  const Container = StyledScreeningDateTime

  return (
    <Container {...{ onClick }}>
      <StyledScreeningDate>
        {getFormattedDate({ dayOfWeek, day, month })}
      </StyledScreeningDate>{' '}
      <StyledScreeningTime>{getTimeText(time)}</StyledScreeningTime>
    </Container>
  )
})

const MoreScreeningsItem = ({
  screening: { id, cinema, date, price, buyLink },
  hall,
  onCinemaClick,
  onHallClick,
  hoveredElement,
  onClick,
}) => (
  <StyledScreening>
    <ScreeningHeart screeningId={id} />
    <Cinema
      cinema={cinema}
      containerSize={50}
      logoSize={SMALL_CINEMA_LOGO_SIZE}
      onCinemaClick={onCinemaClick}
      show={hoveredElement === `more_cinema_${id}`}
    />
    <ScreeningDateTime date={date} id={id} {...{ onClick }} />
    {hall && (
      <Hall
        onHallClick={onHallClick}
        name={hall}
        show={hoveredElement === `more_hall_${id}`}
      />
    )}
    <Price price={price} buyLink={buyLink} />
  </StyledScreening>
)

export default compose(
  connect(
    ({ hoveredScreeningElement }, { screening: { id } }) => ({
      hoveredElement:
        hoveredScreeningElement &&
        hoveredScreeningElement.screeningId === id &&
        hoveredScreeningElement.element,
    }),
    { setHoveredScreeningElement, goTo },
  ),
  withProps(({ screening: { tags, hall } }) => {
    return {
      hall: hall || getHallName(tags),
    }
  }),
  withHandlers({
    onClick: ({ screening: { id }, goTo }) => () => {
      const encodedId = encodeId(id)
      goTo(
        {
          pathname: createScreeningDetailRoute({ screeningId: encodedId }),
          state: JSON.stringify({
            routeKey: ROUTE_STACK.SCREENING_DETAIL,
            screeningId: encodedId,
          }),
        },
        true,
      )
    },
    onCinemaClick: ({
      hoveredElement,
      setHoveredScreeningElement,
      screening: { id },
    }) => e => {
      if (matchMedia('(pointer: coarse)').matches) {
        if (hoveredElement !== `more_cinema_${id}`) {
          e.stopPropagation()
          setHoveredScreeningElement({
            screeningId: id,
            element: `more_cinema_${id}`,
          })
        }
      }
    },
    onHallClick: ({
      hoveredElement,
      setHoveredScreeningElement,
      screening: { id },
    }) => e => {
      if (matchMedia('(pointer: coarse)').matches) {
        if (hoveredElement !== `more_hall_${id}`) {
          e.stopPropagation()
          setHoveredScreeningElement({
            screeningId: id,
            element: `more_hall_${id}`,
          })
        }
      }
    },
  }),
)(MoreScreeningsItem)
