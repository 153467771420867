import React, { memo } from 'react'

export const CloseCross = memo(() => (
  <svg width={18.557} height={19.022} viewBox="0 0 18.557 19.022">
    <path
      d="M15.995 18.578l-6.949-6.95-6.486 6.486a1.5 1.5 0 01-2.121 0 1.5 1.5 0 010-2.122l6.485-6.485L.439 3.021A1.5 1.5 0 01.439.9a1.5 1.5 0 012.122 0l6.485 6.485L15.995.436a1.5 1.5 0 012.122 0 1.5 1.5 0 010 2.121l-6.95 6.95 6.95 6.95a1.5 1.5 0 010 2.122 1.5 1.5 0 01-1.061.44 1.5 1.5 0 01-1.061-.441z"
      fill="rgba(255,255,255,.85)"
    />
  </svg>
))
