import React from 'react'
import { pure } from 'recompose'
import { THEME_COLOR } from '../constants/colors'

const Share = pure(() => (
  <svg width="25.625" height="25.625" viewBox="0 0 25.625 25.625">
    <path
      fill="#fff"
      d="M19.016,17.033a3.109,3.109,0,0,0-2.093.822L9.31,13.425a3.5,3.5,0,0,0,.1-.747,3.5,3.5,0,0,0-.1-.747l7.527-4.388A3.2,3.2,0,1,0,15.813,5.2a3.5,3.5,0,0,0,.1.747L8.381,10.339a3.2,3.2,0,1,0,0,4.677l7.6,4.442a3.012,3.012,0,0,0-.085.694,3.118,3.118,0,1,0,3.118-3.118Z"
      transform="translate(0.203 0.135)"
    />
  </svg>
))

export default Share
