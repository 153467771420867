import { useRef, useCallback } from 'react'

const useHandler = handler => {
  const handlerRef = useRef(handler)

  handlerRef.current = handler

  return useCallback((...args) => handlerRef.current(...args), [])
}

export default useHandler
