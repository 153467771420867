import React from 'react'
import styled from '@emotion/styled/macro'
import {
  compose,
  withHandlers,
  withState,
  lifecycle,
  withProps,
} from 'recompose'
import { connect } from 'react-redux-hoc'

import {
  MOUSE_DEVICE,
  SHOW_HIDE_FAST_DURATION,
  CHROME_ANDROID_NO_HIGHLIGHT,
} from '../../constants/CSS'
import { PRIMARY_BACKGROUND_COLOR } from '../../constants/colors'
import Ripple from '../../components/Ripple'
import Heart from '../../components/Heart'
import { setFavouriteScreenings } from '../../actions/favouriteScreenings'
import { filter } from 'ramda'
import { MEDIA_FULL_DESKTOP } from '../../constants/media'

const HEART_COLOR = '#cc3d35'

const StyledHeartButton = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  cursor: pointer;
  user-select: none;
  position: absolute;
  right: 20px;
  top: 100px;

  ${MEDIA_FULL_DESKTOP} {
    top: 80px;
  }

  transition: background ${SHOW_HIDE_FAST_DURATION} ease-out;

  & svg path {
    transition: fill ${SHOW_HIDE_FAST_DURATION} ease-out;
    ${({ favourite }) => (favourite ? 'fill: ' + HEART_COLOR : '')};
  }

  ${MOUSE_DEVICE} {
    &:hover {
      /* opacity: 0.9; */
      background: #b1c8e1;

      & svg path {
        fill: ${HEART_COLOR};
      }
    }
  }

  ${CHROME_ANDROID_NO_HIGHLIGHT}
`

const ScreeningDetailInfoHeart = ({
  onHeartClick,
  favouriteScreenings,
  screeningId,
}) => {
  return (
    <StyledHeartButton
      {...{
        onClick: onHeartClick,
        favourite: favouriteScreenings[screeningId],
      }}
    >
      <Heart />
      <Ripple {...{ color: PRIMARY_BACKGROUND_COLOR, opacity: 0.3 }} />
    </StyledHeartButton>
  )
}

export default compose(
  connect(
    ({ favouriteScreenings, filter: { favourites } }) => {
      return {
        favouriteScreenings,
        favourites,
      }
    },
    { setFavouriteScreenings },
  ),
  withState(
    'internalValue',
    'setInternalValue',
    ({ favouriteScreenings }) => favouriteScreenings,
  ),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.favouriteScreenings !== prevProps.favouriteScreenings) {
        this.props.setInternalValue(this.props.favouriteScreenings)
      }
    },
  }),
  withProps(({ internalValue }) => ({ favouriteScreenings: internalValue })),
  withHandlers({
    onHeartClick: ({
      favouriteScreenings,
      setFavouriteScreenings,
      screeningId,
      setInternalValue,
    }) => () => {
      const newFavouriteScreenings = filter(a => !!a, {
        ...favouriteScreenings,
        [screeningId]: !favouriteScreenings[screeningId],
      })

      setInternalValue(newFavouriteScreenings)
      window.requestAnimationFrame(() => {
        setTimeout(() => {
          window.requestAnimationFrame(() => {
            setFavouriteScreenings(newFavouriteScreenings)
            window.localStorage &&
              window.localStorage.setItem(
                'favouriteScreenings',
                JSON.stringify(newFavouriteScreenings),
              )
          })
        }, 110)
      })
    },
  }),
)(ScreeningDetailInfoHeart)
