import React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled/macro'
import {
  compose,
  withState,
  withProps,
  withHandlers,
  lifecycle,
  pure,
} from 'recompose'
import { map, prop, pipe, propEq, find, filter, toPairs } from 'ramda'
import {
  SHOW_HIDE_FAST_DURATION,
  MOUSE_DEVICE,
  CHROME_ANDROID_NO_HIGHLIGHT,
  TOUCH_DEVICE,
  SECONDARY_FONT,
} from '../constants/CSS'
import { setFilterDaysOfWeek } from '../actions/filter'
import CinemaLogo from './CinemaLogo'
import scrollToTop from '../utils/scrollToTop'
import { CINEMAS_ORDER } from '../constants/cinemas'
import { DAYS_OF_WEEK_ORDERED } from '../constants/date'

const StyledContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0px;
`

const StyledTitle = styled.div`
  font-family: ${SECONDARY_FONT};
  display: flex;
  flex-direction: column;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
  user-select: none;
`

const StyledDaysOfWeekContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px;

  ${CHROME_ANDROID_NO_HIGHLIGHT}
`

const StyledDayOfWeek = styled.div`
  position: relative;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* width: 90px; */
  height: 38px;
  width: 75px;
  opacity: 1;
  font-weight: 600;
  /* border: 3px solid ${({ selected }) => (selected ? 'white' : 'black')}; */
  /* background: ${({ selected }) => (selected ? '#fff' : '#000')}; */
  /* background: ${({ selected }) => (selected ? '#fff' : '#000')}; */
  cursor: pointer;
  margin: 0px 10px 0px 50px;

	/* filter: invert(${({ selected }) => (selected ? '0%' : '100%')}); */
  /* background: #fff; */
  /* border-radius: 100px; */
  transition: background-color ${SHOW_HIDE_FAST_DURATION}, box-shadow ${SHOW_HIDE_FAST_DURATION};

  &:before {
    transition: background-color ${SHOW_HIDE_FAST_DURATION}, box-shadow ${SHOW_HIDE_FAST_DURATION};
    content: '_';
    color: transparent;
    position: absolute;
    left: -30px;
    height: 20px;
    width: 20px;
    border-radius: 2px;
    border: 2px solid white;
    background: ${({ selected }) =>
      selected ? 'rgba(255, 255, 255, 255)' : 'rgba(255, 255, 255, 0)'};
  }

  ${CHROME_ANDROID_NO_HIGHLIGHT}

  ${MOUSE_DEVICE} {
    &:hover {
      /* filter: invert(${({ selected }) => (selected ? '' : '0%')}); */
      &:before {

        background: rgba(255, 255, 255, 255);
        box-shadow: 0 0px 15px 0 rgba(255,255,255,0.2), 0 0px 18px 0 rgba(255,255,255,0.2);
      }
    }
  }
`

const StyledDayOfWeekWrapper = styled.div`
  position: relative;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  cursor: pointer;
  ${CHROME_ANDROID_NO_HIGHLIGHT}

  ${MOUSE_DEVICE} {
    &:hover {
      ${StyledDayOfWeek}:before {
        background: rgba(255, 255, 255, 255);
        box-shadow: 0 0px 15px 0 rgba(255, 255, 255, 0.2),
          0 0px 18px 0 rgba(255, 255, 255, 0.2);
      }
    }
  }
`

const When = ({ daysOfWeek, onClick }) => (
  <StyledContainer>
    <StyledTitle>Kdy?</StyledTitle>
    <StyledDaysOfWeekContainer>
      {pipe(
        map(day => {
          const selected = daysOfWeek[day]

          return (
            <StyledDayOfWeekWrapper key={day} onClick={onClick(day)}>
              <StyledDayOfWeek selected={selected}>{day}</StyledDayOfWeek>
            </StyledDayOfWeekWrapper>
          )
        }),
      )(DAYS_OF_WEEK_ORDERED)}
    </StyledDaysOfWeekContainer>
  </StyledContainer>
)

export default compose(
  pure,
  connect(
    ({ filter: { daysOfWeek } }) => ({
      daysOfWeek,
    }),
    { setFilterDaysOfWeek },
  ),
  withState(
    'internalValue',
    'setInternalValue',
    ({ daysOfWeek }) => daysOfWeek,
  ),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.daysOfWeek !== prevProps.daysOfWeek) {
        this.props.setInternalValue(this.props.daysOfWeek)
      }
    },
  }),
  withProps(({ internalValue }) => ({ daysOfWeek: internalValue })),
  withHandlers({
    onDayClick: ({
      daysOfWeek,
      setFilterDaysOfWeek,
      setInternalValue,
    }) => day => {
      const newDaysOfWeek = {
        ...daysOfWeek,
        [day]: !daysOfWeek[day],
      }
      setInternalValue(newDaysOfWeek)
      window.requestAnimationFrame(() => {
        setTimeout(() => {
          // scrollToTop()
          window.requestAnimationFrame(() => {
            setFilterDaysOfWeek(newDaysOfWeek)
          })
        }, 110)
      })
    },
  }),
  withHandlers({
    onClick: ({ onDayClick }) => day => e => {
      e.stopPropagation()
      onDayClick(day)
    },
  }),
  pure,
)(When)
