import React, { memo } from 'react'

export const CameraIcon = memo(() => (
  <svg width={24.286} height={21.253} viewBox="0 0 24.286 21.253">
    <path
      fill="#fff"
      d="M12.902 13.664a.759.759 0 00-.759.759V16.7H4.554v-4.554h5.312a.759.759 0 000-1.518H3.795a.759.759 0 00-.759.759v6.067a.759.759 0 00.759.759h9.107a.759.759 0 00.759-.759v-3.036a.759.759 0 00-.759-.754zm0 0"
    />
    <path
      fill="#fff"
      d="M21.667 9.236a.76.76 0 00-.717-.064l-4.25 1.824V9.87a2.279 2.279 0 00-1.6-2.174 3.791 3.791 0 00-5.657-5.043 4.549 4.549 0 10-7.491 4.966A2.277 2.277 0 000 9.87v9.107a2.279 2.279 0 002.277 2.277H14.42a2.279 2.279 0 002.28-2.277v-1.126l4.254 1.823a.759.759 0 001.058-.7V9.87a.759.759 0 00-.345-.634zm-9.525-6.2a2.277 2.277 0 11-2.276 2.28 2.277 2.277 0 012.277-2.277zM9.114 7.593H8.7q.119-.133.228-.275.086.142.186.275zm-3.8-6.071a3.036 3.036 0 11-3.037 3.035 3.036 3.036 0 013.035-3.035zm9.866 17.455a.759.759 0 01-.759.759H2.277a.759.759 0 01-.759-.759V9.87a.759.759 0 01.759-.759H14.42a.759.759 0 01.759.759zm5.312-1.151L16.7 16.201v-3.554l3.795-1.626zm0 0"
    />
    <path
      fill="#fff"
      d="M5.313 5.316a.759.759 0 00.759-.759.746.746 0 00-.224-.535.782.782 0 00-1.074 0 .759.759 0 00.539 1.294zm0 0M12.366 10.849a.759.759 0 101.07 0 .777.777 0 00-1.07 0zm0 0M23.527 9.111a.759.759 0 00-.759.759v9.107a.759.759 0 101.518 0V9.87a.759.759 0 00-.759-.759zm0 0"
    />
  </svg>
))
