export const DATA_FETCH = 'DATA_FETCH'
export const DATA_SUCCESS = 'DATA_SUCCESS'

export const SET_SELECTED_SCREENING = 'SET_SELECTED_SCREENING'
export const SET_HOVERED_SCREENING_ELEMENT = 'SET_HOVERED_SCREENING_ELEMENT'
export const UNSET_HOVERED_SCREENING_ELEMENT = 'UNSET_HOVERED_SCREENING_ELEMENT'
export const SET_DAYS_TO_SHOW = 'SET_DAYS_TO_SHOW'

export const SET_RETURN_TO_TOP_VISIBILITY = 'SET_RETURN_TO_TOP_VISIBILITY'
export const SET_FILTER_MENU_VISIBILITY = 'SET_FILTER_MENU_VISIBILITY'
export const SET_SCREENINGS_TO_SHOW_COUNT = 'SET_SCREENINGS_TO_SHOW_COUNT'
export const SET_TRAILER_FULLSCREEN = 'SET_TRAILER_FULLSCREEN'

export const SET_FILTER_WHERE = 'SET_FILTER_WHERE'
export const SET_FILTER_WHERE_ITEM = 'SET_FILTER_WHERE_ITEM'
export const SET_FILTER_DAYS_OF_WEEK = 'SET_FILTER_DAYS_OF_WEEK'
export const SET_FILTER_DATE = 'SET_FILTER_DATE'
export const SET_FILTER_MOVIES = 'SET_FILTER_MOVIES'
export const SET_FILTER_MOVIES_ITEM = 'SET_FILTER_MOVIES_ITEM'
export const SET_FILTER_PRICE = 'SET_FILTER_PRICE'
export const SET_FILTER_FAVOURITES = 'SET_FILTER_FAVOURITES'
export const SET_FILTER_QUERY = 'SET_FILTER_QUERY'
export const RESET_FILTER = 'RESET_FILTER'

export const SET_SAVED_FILTER = 'SET_SAVED_FILTER'
export const RESET_SAVED_FILTER = 'RESET_SAVED_FILTER'

export const SET_FAVOURITE_SCREENINGS = 'SET_FAVOURITE_SCREENINGS'

export const OPEN_CINEMA_INFO_MODAL = 'OPEN_CINEMA_INFO_MODAL'
export const CLOSE_CINEMA_INFO_MODAL = 'CLOSE_CINEMA_INFO_MODAL'
