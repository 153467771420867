import React from 'react'
import { pure } from 'recompose'
import { THEME_COLOR } from '../constants/colors'

const Clapboard = pure(() => (
  <svg width="24.528" height="29.805" viewBox="0 0 24.528 29.805">
    <path
      fill="484848"
      d="M46.911,11.934H30.456L45.626,5.57a1.9,1.9,0,0,0,1.018-2.489l-.8-1.917A1.9,1.9,0,0,0,43.351.148l-17.9,7.51a1.9,1.9,0,0,0-1.018,2.489l.8,1.916a1.894,1.894,0,0,0,.632.8,1.886,1.886,0,0,0-.271.974V27.9a1.9,1.9,0,0,0,1.9,1.9H46.911a1.9,1.9,0,0,0,1.9-1.9V13.836A1.9,1.9,0,0,0,46.911,11.934Zm.272,1.9v2.35H44.628l1.147-2.621h1.136A.275.275,0,0,1,47.182,13.836ZM27.5,13.564h2.09l-1.147,2.621H27.226v-2.35A.275.275,0,0,1,27.5,13.564Zm3.276,0h3.815l-1.147,2.621H29.627Zm5,0h3.815l-1.147,2.621H34.627Zm5,0h3.815l-1.147,2.621H39.628ZM37.181,7.346,33.663,8.822l.043-2.861,3.518-1.476Zm1.137-3.32,3.518-1.476-.043,2.861L38.274,6.887ZM32.569,9.281l-3.518,1.476L29.1,7.9,32.613,6.42ZM43.982,1.65a.261.261,0,0,1,.1-.021.277.277,0,0,1,.253.166l.8,1.916A.275.275,0,0,1,45,4.068l-2.11.885.043-2.861ZM25.934,9.308a.271.271,0,0,1,.145-.148L28,8.354l-.043,2.861-.865.363a.261.261,0,0,1-.1.021.277.277,0,0,1-.253-.166l-.8-1.916A.271.271,0,0,1,25.934,9.308ZM46.911,28.175H27.5a.275.275,0,0,1-.272-.272V17.815H47.182V27.9A.275.275,0,0,1,46.911,28.175Z"
      transform="translate(-24.284)"
    />
    <path
      fill="484848"
      d="M74.779,194.105H66.6a.815.815,0,1,0,0,1.63h8.177a.815.815,0,1,0,0-1.63Z"
      transform="translate(-61.278 -173.017)"
    />
    <path
      fill="484848"
      d="M74.779,224.493H66.6a.815.815,0,0,0,0,1.63h8.177a.815.815,0,0,0,0-1.63Z"
      transform="translate(-61.278 -200.104)"
    />
    <rect
      fill="484848"
      width="22"
      height="12"
      transform="translate(1.66 15.91)"
    />
  </svg>
))

export default Clapboard
