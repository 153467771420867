import React from 'react'
import styled from '@emotion/styled/macro'
import {
  CHROME_ANDROID_NO_HIGHLIGHT,
  MOUSE_DEVICE,
  SHOW_HIDE_FAST_DURATION,
  MIN_600,
  LAYOUT_MAX_WIDTH,
} from '../constants/CSS'
import Arrow from './Arrow'
import {
  compose,
  withHandlers,
  lifecycle,
  withPropsOnChange,
  withState,
  withProps,
  pure,
} from 'recompose'
import { connect } from 'react-redux'
import {
  setReturnToTopButtonVisibility,
  setResetMenuVisibility,
} from '../actions/ui'
import scrollToTop from '../utils/scrollToTop'
import ResetIcon from './ResetIcon'
import { resetFilter } from '../actions/filter'
import { values } from 'ramda'
import { resetSavedFilter } from '../actions/savedFilter'
import { zindex } from '../constants/zIndexes'

const StyledIconWrapper = styled.div`
  padding-top: 2px;
  display: flex;
`

const StyledButton = styled.div`
  position: fixed;
  z-index: ${zindex.resetButton};
  right: 20px;
  right: calc(20px + 70px);

  & svg path {
    fill: white;
  }

  @media (min-width: 835px) {
    right: calc(50% - ${LAYOUT_MAX_WIDTH / 2}px + 20px);
    right: calc(50% - ${LAYOUT_MAX_WIDTH / 2}px + 20px + 70px);
  }
  @media (min-width: 1035px) {
    right: calc(50% - ${LAYOUT_MAX_WIDTH / 2}px - 80px);
    right: calc(50% - ${LAYOUT_MAX_WIDTH / 2}px - 80px + 70px);
  }

  visibility: ${({ showButton }) => (showButton ? 'visible' : 'hidden')};
  bottom: 93px;
  bottom: 21px;
  width: 60px;
  height: 60px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ showButton }) => (showButton ? '1' : '0')};
  transition: ${({ showButton }) =>
    showButton
      ? `
    opacity ${SHOW_HIDE_FAST_DURATION}
  `
      : `
    opacity ${SHOW_HIDE_FAST_DURATION}, visibility 0s ${SHOW_HIDE_FAST_DURATION}
  `};
  cursor: pointer;
  ${CHROME_ANDROID_NO_HIGHLIGHT}

  ${MOUSE_DEVICE} {
    &:hover {
      opacity: ${({ showButton }) => (showButton ? '0.7' : '0')};
    }
  }
`

const ResetButton = ({ onClick, showButton }) => (
  <StyledButton onClick={onClick} showButton={showButton}>
    <StyledIconWrapper>
      <ResetIcon size={32} />
    </StyledIconWrapper>
  </StyledButton>
)

export default compose(
  pure,
  connect(
    ({ filter, ui: { filterMenuVisible } }) => ({
      filterMenuVisible,
      filter,
    }),
    { resetFilter, resetSavedFilter },
  ),
  withPropsOnChange(['filter'], ({ filter }) => {
    const nothingChanged =
      !filter.data &&
      values(filter.where).filter(a => !!a).length === 0 &&
      values(filter.daysOfWeek).filter(a => !!a).length === 0 &&
      values(filter.movies).filter(a => !!a).length === 0
    return {
      nothingChanged,
    }
  }),
  withPropsOnChange(
    ['nothingChanged', 'filterMenuVisible'],
    ({ nothingChanged, filterMenuVisible }) => {
      return {
        showButton: filterMenuVisible && !nothingChanged,
      }
    },
  ),
  withState(
    'internalValue',
    'setInternalValue',
    ({ showButton }) => showButton,
  ),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.showButton !== prevProps.showButton) {
        this.props.setInternalValue(this.props.showButton)
      }
    },
  }),
  withProps(({ internalValue }) => ({ showButton: internalValue })),
  withHandlers(() => ({
    onClick: ({ resetFilter, resetSavedFilter, setInternalValue }) => e => {
      e.stopPropagation()
      // scrollToTop()

      setInternalValue(false)
      window.requestAnimationFrame(() => {
        setTimeout(() => {
          // scrollToTop()
          window.requestAnimationFrame(() => {
            resetFilter()
            resetSavedFilter()
          })
        }, 110)
      })
    },
  })),
)(ResetButton)
