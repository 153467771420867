import React from 'react'
import styled from '@emotion/styled/macro'
import {
  SHOW_HIDE_COMMON_SNIPPET,
  SHOW_HIDE_MOBILE_SNIPPET,
  SHOW_HIDE_MOUSE_SNIPPET,
  SECONDARY_FONT,
} from '../constants/CSS'
import { HALLS, HALLS_DESCRIPTION } from '../constants/halls'
import { zindex } from '../constants/zIndexes'
import BigHall from './BigHall'
import SmallHall from './SmallHall'

export const StyledHall = styled.div`
  position: relative;
  font-family: ${SECONDARY_FONT};
  margin: 0 8px 0 5px;
  flex-shrink: 0;
  color: white;
  width: 28px;
  height: 28px;
  /* border-radius: 15px; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  user-select: none;

  font-size: 13px;
  font-weight: 800;
  /* background: #393939; */

  & svg {
    transform: scale(0.8);
  }

  & svg path {
    fill: #000;
    stroke: #000;
  }

  &:before {
    content: '${({ description }) => (description ? description : '')}';
    display: flex;
    visibility: hidden;
    opacity: 0;
    flex-direction: column;
    padding: 6px 16px 6px 16px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    background: rgba(0, 0, 0, 0.8196078431372549);
    color: white;
    position: absolute;
    border-radius: 26px;
    z-index: ${zindex.screeningHall};
    right: 34px;
    white-space: nowrap;

    ${SHOW_HIDE_COMMON_SNIPPET}
    ${SHOW_HIDE_MOBILE_SNIPPET}
  }

  ${SHOW_HIDE_MOUSE_SNIPPET}
`

const Hall = ({ name, onHallClick, show }) => (
  <StyledHall
    onClick={onHallClick}
    show={show}
    // show={hoveredElement === `hall_${id}`}
    description={HALLS_DESCRIPTION[name]}
  >
    {HALLS[name] === 'V' ? <BigHall /> : <SmallHall />}
  </StyledHall>
)

export default Hall
