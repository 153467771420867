import { useEffect, useCallback } from 'react'

export const useClickOutside = ({ refs, onOutsideClick }) => {
  const onDocumentClick = useCallback(
    e => {
      if (
        refs.every(
          ref =>
            ref.current &&
            e.target instanceof Node &&
            !ref.current.contains(e.target),
        )
      ) {
        onOutsideClick()
      }
    },
    [refs, onOutsideClick],
  )

  useEffect(
    () => {
      document.addEventListener('click', onDocumentClick)

      return () => {
        document.removeEventListener('click', onDocumentClick)
      }
    },
    [onDocumentClick],
  )
}
