import React from 'react'
import styled from '@emotion/styled/macro'
import { pure, withHandlers } from 'recompose'

import {
  MIN_600,
  SECONDARY_FONT,
  MOUSE_DEVICE,
  CHROME_ANDROID_NO_HIGHLIGHT,
} from '../../constants/CSS'
import BackArrow from '../../components/BackArrow'
import {
  PRIMARY_BACKGROUND_COLOR,
  PRIMARY_TEXT_COLOR,
  THEME_COLOR,
} from '../../constants/colors'
import Ripple from '../../components/Ripple'
import { zindex } from '../../constants/zIndexes'
import { compose } from 'ramda'
import connect from '../../hoc/connect'
import { goTo } from '../../actions/router'
import goBackOrPush from '../../utils/goBackOrPush'
import { createMainRoute, ROUTE_STACK } from '../../constants/routes'
import { MEDIA_FULL_DESKTOP } from '../../constants/media'
import Logo from '../../components/Logo'

const StyledBackText = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 18px;
  color: ${PRIMARY_TEXT_COLOR};
  font-weight: 900;
  display: none;
  white-space: nowrap;
  height: 100%;

  ${MEDIA_FULL_DESKTOP} {
    display: flex;
    align-items: center;
    position: absolute;
    left: 80px;
  }
`

const StyledButton = styled.div`
  flex-shrink: 0;
  height: 65px;
  width: 70px;
  /* overflow: hidden; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  position: relative;

  & svg {
    transform: scale(0.8);
  }

  ${MOUSE_DEVICE} {
    &:hover {
      & svg path {
        fill: ${THEME_COLOR};
      }

      ${StyledBackText} {
        color: ${THEME_COLOR};
      }
    }
  }

  ${MEDIA_FULL_DESKTOP} {
    & svg {
      transform: scale(0.8);
    }
  }

  ${CHROME_ANDROID_NO_HIGHLIGHT}
`

const StyledHeaderContainer = styled.div`
  background: ${PRIMARY_BACKGROUND_COLOR};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: 100vw;
  position: sticky;
  top: 0px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  z-index: ${zindex.header};
`

const StyledHeader = styled.div`
  background: ${PRIMARY_BACKGROUND_COLOR};
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: 1000px;
  padding: 0px 0px 0px 0px;
`

const StyledMovieTitle = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${PRIMARY_TEXT_COLOR};
  font-weight: 900;
  text-align: center;
  padding-right: 60px;
  width: 100%;

  ${MIN_600} {
    font-size: 18px;
  }

  ${MEDIA_FULL_DESKTOP} {
    display: none;
  }
`

const StyledSpace = styled(StyledButton)`
  display: none;

  ${MEDIA_FULL_DESKTOP} {
    display: flex;
  }
`

const StyledLogoContainer = styled.div`
  display: none;
  cursor: pointer;
  user-select: none;

  ${CHROME_ANDROID_NO_HIGHLIGHT}

  ${MEDIA_FULL_DESKTOP} {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0px 33px;
  }
`

const ScreeningDetailHeader = ({ title, onBackClick, onLogoClick }) => {
  return (
    <StyledHeaderContainer>
      <StyledHeader>
        <StyledButton {...{ onClick: onBackClick }}>
          <Ripple {...{ color: THEME_COLOR, opacity: 0.4 }} />
          <BackArrow />
          <StyledBackText>Zpět k projekcím</StyledBackText>
        </StyledButton>

        <StyledMovieTitle>{title}</StyledMovieTitle>

        <StyledLogoContainer {...{ onClick: onLogoClick }}>
          <Logo />
        </StyledLogoContainer>
        <StyledSpace />
      </StyledHeader>
    </StyledHeaderContainer>
  )
}

export default compose(
  connect(
    null,
    { goTo },
  ),
  withHandlers({
    onLogoClick: ({ goTo }) => () => {
      goTo({
        pathname: createMainRoute(),
        state: JSON.stringify({
          routeKey: ROUTE_STACK.MAIN,
        }),
      })
    },
    onBackClick: ({ goTo }) => () => {
      goBackOrPush(() =>
        goTo({
          pathname: createMainRoute(),
          state: JSON.stringify({
            routeKey: ROUTE_STACK.MAIN,
          }),
        }),
      )
    },
  }),
  pure,
)(ScreeningDetailHeader)
