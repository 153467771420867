import React from 'react'
import styled from '@emotion/styled/macro'
import { compose, lifecycle, withPropsOnChange } from 'recompose'
import { connect } from 'react-redux'
import { dataFetch } from '../actions/data'
import { MIN_600, MIN_820 } from '../constants/CSS'
import { setScreeningsToShowCount } from '../actions/ui'
import { SECONDARY_FONT } from '../constants/CSS'
import {
  pipe,
  groupBy,
  toPairs,
  filter,
  and,
  values,
  reduce,
  map,
  not,
} from 'ramda'

import DayScreenings from './DayScreenings'
import DayScreeningsVirtualList from './DayScreeningsVirtualList'
import { DAYS_OF_WEEK_FULL } from '../constants/date'
import { Global } from '@emotion/core'
import { StyledContainer as StyledLayoutContainer } from './Layout'
import LoadingScreeningsIcon from './LoadingScreeningsIcon'
import canonizeTitle from '../canonizeTitle'
import { CINEMA_SEARCH_NAMES } from '../constants/cinemas'

import { path, take } from 'ramda'
import matchesQuery from '../utils/matchesQuery'

const StyledLoadingData = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-family: ${SECONDARY_FONT};
  font-size: 20px;
  font-weight: 600;
  /* width: 100%; */
  /* background: #393939; */
  /* color: white; */
  color: #292522;
  text-align: center;

  /* ${MIN_600} {
    max-width: 820px;
    padding: 0 10px;
  } */

  /* ${MIN_820} {
    border-left: 2px solid #ccc;
    border-right: 2px solid #ccc;
  } */

  padding-left: 50px;
  padding-right: 50px;
  line-height: 1.5;


  height: calc(100vh - 250px);
`

const StyledContainer = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  max-width: 800px;
  padding-bottom: 150px;

  ${MIN_600} {
    max-width: 820px;
    padding: 0 10px 150px;
  }
`

// const ITEM_HEIGHT = 67
const ITEM_HEIGHT = 77

const ScreeningsList = ({
  screenings = {},
  screeningsByDate = {},
  hasSelectedScreening,
}) => {
  const isLoading = Object.keys(screeningsByDate).length === 0
  return isLoading ? (
    <StyledLoadingData>
      {/* Už se to točí... */}
      {Object.keys(screenings).length === 0 ? (
        <LoadingScreeningsIcon />
      ) : (
        'Nenalezeny žádné projekce se zadanými filtry'
      )}
      {/* {Object.keys(screenings).length === 0
        ? 'Už se to točí...'
        : 'Nenalezeny žádné projekce se zadanými filtry'} */}
      <Global
        styles={{
          [StyledLayoutContainer]: {
            position: 'fixed',
            top: '0px',
            bottom: '0px',
            // left: '0px',
            // right: '0px',
            display: 'flex',
            flexDirection: 'column',
          },
        }}
      />
    </StyledLoadingData>
  ) : (
    <StyledContainer>
      <DayScreeningsVirtualList
        hasSelectedScreening={hasSelectedScreening}
        list={screeningsByDate}
        getItemHeight={([date, dayScreenings], { height, date: _date }) => {
          return (
            54 +
            ITEM_HEIGHT * dayScreenings.length +
            (date === _date ? height + 5 : 0)
          )
        }}
        getItemKey={([date, _]) => date}
        renderItem={([date, dayScreenings], setContainerHeight) => {
          return (
            <DayScreenings
              {...{
                date,
                dayScreenings,
                setContainerHeight,
              }}
            />
          )
        }}
      />
    </StyledContainer>
  )
}

export default compose(
  connect(
    ({
      data,
      selectedScreening,
      ui: { screeningsToShowCount },
      filter,
      favouriteScreenings,
    }) => ({
      screenings: data && data.screenings,
      moviesData: data && data.movies,
      movieCsfdData: data && data.movieCsfdData,
      screeningsToShowCount,
      hasSelectedScreening: !!selectedScreening,
      filter,
      query: filter.query,
      favouriteScreenings,
    }),
    { dataFetch, setScreeningsToShowCount },
  ),
  withPropsOnChange(
    ['moviesData', 'movieCsfdData', 'query'],
    ({ moviesData, movieCsfdData, query }) => {
      if (!moviesData || !query) {
        return moviesData
      }

      const canonicalQuery = canonizeTitle(query.trim()).split(' ')

      const filteredMovies = filter(
        ({
          canonicalTitle,
          titleForSearch,
          originalTitleForSearch,
          directorForSearch,
        }) => {
          if (
            titleForSearch &&
            matchesQuery({ item: titleForSearch, query: canonicalQuery })
          ) {
            return true
          }

          if (
            originalTitleForSearch &&
            matchesQuery({
              item: originalTitleForSearch,
              query: canonicalQuery,
              fast: true,
            })
          ) {
            return true
          }

          if (
            directorForSearch &&
            matchesQuery({
              item: directorForSearch,
              query: canonicalQuery,
            })
          ) {
            return true
          }

          const canonicalActors = take(
            10,
            path([canonicalTitle, 'actorsForSearch'], movieCsfdData) || [],
          )

          for (const actor of canonicalActors) {
            if (
              matchesQuery({
                item: actor,
                query: canonicalQuery,
                fast: true,
              })
            ) {
              return true
            }
          }
        },
        moviesData,
      )

      return {
        moviesData: filteredMovies,
      }
    },
  ),
  withPropsOnChange(
    ['screenings', 'filter', 'moviesData', 'favouriteScreenings', 'query'],
    ({
      screenings,
      moviesData = {},
      filter: { where, daysOfWeek, movies, price: upTo100, favourites },
      favouriteScreenings,
      query,
    }) => {
      const allCinemasSelected = reduce(and, true, map(not, values(where)))
      const allDaysOfWeekSelected = reduce(
        and,
        true,
        map(not, values(daysOfWeek)),
      )
      const allMoviesSelected = reduce(and, true, map(not, values(movies)))

      const canonicalQuery = canonizeTitle(query || '').split(' ')

      const screeningsByDate = pipe(
        filter(({ movie, cinema }) => {
          return (
            !query ||
            moviesData[movie] ||
            matchesQuery({
              item: CINEMA_SEARCH_NAMES[cinema],
              query: canonicalQuery,
            })
          )
        }),
        filter(({ id }) => (favourites ? favouriteScreenings[id] : true)),
        filter(({ cinema }) => allCinemasSelected || where[cinema]),
        filter(
          ({ date }) =>
            allDaysOfWeekSelected ||
            daysOfWeek[DAYS_OF_WEEK_FULL[date.getDay()]],
        ),
        filter(
          ({ movie }) =>
            allMoviesSelected ||
            (moviesData[movie] &&
              moviesData[movie].title &&
              movies[moviesData[movie].canonicalTitle]),
        ),
        filter(({ price }) => (upTo100 ? price <= 100 : true)),
        groupBy(
          ({ date }) =>
            `${
              DAYS_OF_WEEK_FULL[date.getDay()]
            } ${date.getDate()}/${date.getMonth() + 1}`,
        ),
        toPairs,
      )(screenings)

      // console.log({ allSelected, screeningsByDate })

      return { screeningsByDate }
    },
  ),
  lifecycle({
    componentDidMount() {
      const { dataFetch } = this.props
      dataFetch()
    },
  }),
)(ScreeningsList)
