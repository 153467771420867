import React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled/macro'
import {
  compose,
  withState,
  withProps,
  withHandlers,
  lifecycle,
  pure,
  withPropsOnChange,
} from 'recompose'
import {
  map,
  pipe,
  toPairs,
  sort,
  mapObjIndexed,
  values,
  uniq,
  uniqBy,
  fromPairs,
  groupBy,
  unnest,
} from 'ramda'
import {
  SHOW_HIDE_FAST_DURATION,
  MOUSE_DEVICE,
  CHROME_ANDROID_NO_HIGHLIGHT,
  MAX_400,
  SECONDARY_FONT,
} from '../constants/CSS'
import { setFilterMovies } from '../actions/filter'
import normalizeTitle from '../utils/normalizeTitle'
import { setReturnToTopButtonVisibility } from '../actions/ui'
import Ripple from './Ripple'
import VirtualList from './VirtualList'
import eventStopPropagation from '../utils/eventStopPropagation'
import MoviesFilterVirtualList from './MoviesFilterVirtualList'

const StyledContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0px;
`

const StyledTitle = styled.div`
  font-family: ${SECONDARY_FONT};
  display: flex;
  flex-direction: column;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 25px;
  user-select: none;
`

const StyledAlphabet = styled.div`
  font-size: 22px;
  font-weight: 600;

  user-select: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  max-width: 430px;
  margin: 0 15px 25px;

  ${CHROME_ANDROID_NO_HIGHLIGHT}
`

const StyledCharacter = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 16px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  opacity: 0.9;
  color: white;
  background: rgb(39, 38, 35);
  border: 2px solid white;
  border-radius: 100px;
  text-align: center;
  position: relative;
  overflow: hidden;
`

const StyledCharacterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 6px;

  ${MOUSE_DEVICE} {
    &:hover {
      ${StyledCharacter} {
        opacity: 1;
        box-shadow: 0 0px 15px 0 rgba(255, 255, 255, 0.35),
          0 0px 18px 0 rgba(255, 255, 255, 0.35);
      }
    }
  }
`

const StyledMoviesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 15px;

  ${CHROME_ANDROID_NO_HIGHLIGHT}
`

// TODO: mayber return
// ${({ visible }) =>
//   visible
//     ? `
//   transition: height ${SHOW_HIDE_FAST_DURATION};
// `
//     : `
//   height: 0px;
//   overflow: hidden;
//   transition: height ${SHOW_HIDE_FAST_DURATION},
//   overflow 0s ${SHOW_HIDE_FAST_DURATION};
// `}

// const SELECETED_MOVIE_BACKGROUND_COLOR = 'rgb(38, 38, 38)'
const SELECETED_MOVIE_BACKGROUND_COLOR = '#1c1b19'

const StyledMovieWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledMovieCharacter = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 22px;
  font-weight: 900;
  color: white;
  user-select: none;
  margin: 15px 0px 15px;
`

const StyledMovie = styled.div`
  position: relative;
  display: block;
  align-items: center;
  height: 35px;
  line-height: 35px;
  max-width: 250px;

  ${MAX_400} {
    max-width: calc(100vw - 50px);
  }

  font-weight: 600;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: var(--color);
  background: var(--background);
  margin: 0px 15px 10px;
  cursor: pointer;
  padding: 0px 20px 3px 20px;
  font-size: 13px;

  border-radius: 100px;
  border: 1px solid rgba(255, 255, 255, 0.47058823529411764);
  transition: all ${SHOW_HIDE_FAST_DURATION};

  ${MOUSE_DEVICE} {
    &:hover {
      background: rgba(255, 255, 255, 210);
      box-shadow: 0 0px 15px 0 rgba(255, 255, 255, 0.2),
        0 0px 18px 0 rgba(255, 255, 255, 0.2);
      color: black;
    }
  }
  flex-shrink: 0;

  opacity: 0.99; /* needed because of safari ripple overflow bug */

  ${CHROME_ANDROID_NO_HIGHLIGHT}
`

const Alphabet = pure(({ onCharacterClick, alphabet }) => (
  <StyledAlphabet>
    {pipe(
      map(character => {
        return (
          <StyledCharacterWrapper
            {...{
              key: character,
              onClick: onCharacterClick(character),
            }}
          >
            <StyledCharacter>
              {character}
              <Ripple {...{ opacity: 0.5 }} />
            </StyledCharacter>
          </StyledCharacterWrapper>
        )
      }),
    )(alphabet)}
  </StyledAlphabet>
))

const MovieButton = pure(
  ({ onClick, canonicalTitle, title, char, selected, setCharRef }) => {
    return (
      <StyledMovieWrapper
        {...{
          onClick: onClick(canonicalTitle),
        }}
      >
        {char && (
          <StyledMovieCharacter onClick={eventStopPropagation}>
            {char}
          </StyledMovieCharacter>
        )}
        <StyledMovie
          style={{
            '--color': selected ? 'black' : 'white',
            '--background': selected
              ? 'rgba(255, 255, 255, 210)'
              : SELECETED_MOVIE_BACKGROUND_COLOR,
          }}
          ref={char ? setCharRef(char) : undefined}
        >
          {title}
          <Ripple {...{ color: '#999', opacity: 0.7 }} />
        </StyledMovie>
      </StyledMovieWrapper>
    )
  },
)

const getItemKey = ([canonicalTitle]) => canonicalTitle

const Movies = compose(
  withPropsOnChange(
    ['movieTitledLinks', 'titleCharMap'],
    ({ movieTitledLinks, titleCharMap }) => {
      return {
        heightList: map(
          ([canonicalTitle]) => (titleCharMap[canonicalTitle] ? 100 : 45),
          movieTitledLinks,
        ),
      }
    },
  ),
  pure,
)(
  ({
    movieTitledLinks,
    titleCharMap,
    setCharRef,
    onClick,
    movies,
    visible,
    heightList,
  }) => {
    return (
      <MoviesFilterVirtualList
        visible={visible}
        list={movieTitledLinks}
        heightList={heightList}
        getItemKey={getItemKey}
        renderItem={MovieButton}
        onClick={onClick}
        titleCharMap={titleCharMap}
        movies={movies}
        setCharRef={setCharRef}
      />
    )
  },
)

const MoviesFilter = ({
  movies,
  onClick,
  onCharacterClick,
  movieTitledLinks,
  alphabet,
  titleCharMap,
  setCharRef,
  visible,
}) => (
  <StyledContainer>
    <StyledTitle>Na co?</StyledTitle>
    <Alphabet {...{ onCharacterClick, alphabet }} />
    <Movies
      {...{
        movieTitledLinks,
        titleCharMap,
        setCharRef,
        onClick,
        movies,
        visible,
      }}
    />
  </StyledContainer>
)

const normTitle = t =>
  t
    .replace('„', '')
    .replace('#', '')
    .toLocaleUpperCase()
const stringComparatorProp1 = ([_, a], [__, b]) =>
  normTitle(a).localeCompare(normTitle(b))

export default compose(
  connect(
    ({
      filter: { movies },
      data: { movies: moviesData, movieLinks, movieCsfdData },
    }) => ({
      movies,
      movieLinks,
      movieCsfdData,
      moviesData,
    }),
    { setFilterMovies, setReturnToTopButtonVisibility },
  ),
  withState('internalValue', 'setInternalValue', ({ movies }) => movies),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.movies !== prevProps.movies) {
        this.props.setInternalValue(this.props.movies)
      }
    },
  }),
  withProps(({ internalValue }) => ({ movies: internalValue })),
  withHandlers(() => {
    const titleCharRefMap = {}
    return {
      setCharRef: () => char => _ref => {
        titleCharRefMap[char] = _ref
      },
      onCharacterClick: ({ setReturnToTopButtonVisibility }) => char => e => {
        e.stopPropagation()

        const ref = titleCharRefMap[char]

        if (ref) {
          const rect = ref.getBoundingClientRect()
          const topOffset = rect.top - window.innerHeight / 2
          const bodyOffsetTop = window.document.body.getBoundingClientRect().top
          const distance = Math.abs(topOffset - bodyOffsetTop)
          window.scrollTo(0, distance)
          window.requestAnimationFrame(() => {
            setReturnToTopButtonVisibility(true)
          })
        }
      },
      onMovieClick: ({
        movies,
        setFilterMovies,
        setInternalValue,
      }) => movieName => {
        const newMovies = {
          ...movies,
          [movieName]: !movies[movieName],
        }
        setInternalValue(newMovies)
        window.requestAnimationFrame(() => {
          setTimeout(() => {
            window.requestAnimationFrame(() => {
              setFilterMovies(newMovies)
            })
          }, 180)
        })
      },
    }
  }),
  withHandlers({
    onClick: ({ onMovieClick }) => day => e => {
      e.stopPropagation()
      onMovieClick(day)
    },
  }),
  withPropsOnChange(
    ['movieLinks', 'moviesData', 'movieCsfdData'],
    ({ movieLinks, moviesData, movieCsfdData }) => {
      const movieTitledLinks = pipe(
        mapObjIndexed((links, canonicalTitle) => {
          const csfdTitle =
            movieCsfdData[canonicalTitle] &&
            normalizeTitle(movieCsfdData[canonicalTitle].title)

          if (csfdTitle) {
            return normalizeTitle(csfdTitle)
          }

          const title =
            links.length > 0 &&
            moviesData[links[0]] &&
            moviesData[links[0]].title

          if (title) {
            return title
          }

          return canonicalTitle
        }),
        toPairs,
        sort(stringComparatorProp1),
        groupBy(([_, title]) => normTitle(title).substr(0, 1)),
        map(sort(stringComparatorProp1)),
        values,
        unnest,
      )(movieLinks)

      const alphabet = pipe(
        map(([_, title]) => {
          return normTitle(title).substr(0, 1)
        }),
        uniq,
      )(movieTitledLinks)

      const titleCharMap = pipe(
        map(([canonicalTitle, title]) => {
          return [canonicalTitle, normTitle(title).substr(0, 1)]
        }),
        uniqBy(([_, character]) => character),
        fromPairs,
      )(movieTitledLinks)

      return {
        movieTitledLinks,
        alphabet,
        titleCharMap,
      }
    },
  ),
  pure,
)(MoviesFilter)
