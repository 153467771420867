import React, { memo } from 'react'
import styled from '@emotion/styled/macro'
import { SECONDARY_FONT } from '../constants/CSS'
import { compose, pure } from 'recompose'
import { deriveIsBigHall } from '../selectors/data'
import {
  AERO,
  ATLAS,
  BIOOKO,
  CINEMA_NAMES,
  DLABACOV,
  EDISON,
  EVALD,
  KARLIN,
  KINO35,
  LEDARNY,
  LUCERNA,
  MAT,
  MODRANSKY_BIOGRAF,
  PILOTU,
  PONREPO,
  PRITOMNOST,
  RADOTIN,
  STRAHOV,
  SVETOZOR,
} from '../constants/cinemas'
import BigHall from './BigHall'
import SmallHall from './SmallHall'
import { PRIMARY_TEXT_COLOR } from '../constants/colors'
import aero from '../images/maps/aero.png'
import biooko from '../images/maps/biooko.png'
import svetozor from '../images/maps/svetozor.png'
import lucerna from '../images/maps/lucerna.png'
import dlabacov from '../images/maps/dlabacov.png'
import evald from '../images/maps/evald.png'
import atlas from '../images/maps/atlas.png'
import mat from '../images/maps/mat.png'
import pilotu from '../images/maps/pilotu.png'
import kino35 from '../images/maps/kino35.png'
import radotin from '../images/maps/radotin.png'
import modransky from '../images/maps/modransky.png'
import edison from '../images/maps/edison.png'
import pritomnost from '../images/maps/pritomnost.png'
// import kinolod from '../images/maps/kinolod.png'
import karlin from '../images/maps/karlin.png'
import ponrepo from '../images/maps/ponrepo.png'
import strahov from '../images/maps/strahov.png'
import ledarny from '../images/maps/ledarny.png'

const mapImages = {
  [AERO]: aero,
  [BIOOKO]: biooko,
  [SVETOZOR]: svetozor,
  [LUCERNA]: lucerna,
  [DLABACOV]: dlabacov,
  [EVALD]: evald,
  [ATLAS]: atlas,
  [MAT]: mat,
  [PILOTU]: pilotu,
  [KINO35]: kino35,
  [RADOTIN]: radotin,
  [MODRANSKY_BIOGRAF]: modransky,
  [EDISON]: edison,
  // [KINOLOD]: kinolod,
  [KARLIN]: karlin,
  [PONREPO]: ponrepo,
  [PRITOMNOST]: pritomnost,
  [STRAHOV]: strahov,
  [LEDARNY]: ledarny,
}

const StyledLeftHoverDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 20px;
  margin-left: 120px;
`

const StyledCinemaTitle = styled.div`
  font-family: ${SECONDARY_FONT};
  font-size: 22px;
  color: ${PRIMARY_TEXT_COLOR};
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 900;
  margin-bottom: 14px;
`

const StyledHall = styled.div`
  display: flex;
  align-items: center;
  font-family: ${SECONDARY_FONT};
  font-size: 18px;
  color: ${PRIMARY_TEXT_COLOR};
  text-align: right;
  font-weight: 500;

  & svg {
    margin-left: 15px;
    margin-bottom: 5px;
  }

  & svg path {
    fill: ${PRIMARY_TEXT_COLOR};
    stroke: ${PRIMARY_TEXT_COLOR};
  }
`

const StyledMapImage = styled.div`
  margin-top: 10px;
  width: 100%;
  max-width: 200px;
  padding-bottom: min(100%, 200px);
  border-radius: 4px;
  background: url("${({ cinema }) => mapImages[cinema]}");
  background-size: cover;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
`

const ScreeningHoverDetailLeft = memo(({ cinema, hall }) => {
  return (
    !!mapImages[cinema] && (
      <StyledLeftHoverDetail>
        <StyledCinemaTitle>{CINEMA_NAMES[cinema]}</StyledCinemaTitle>
        {hall && (
          <StyledHall>
            {hall}
            {deriveIsBigHall({ hall }) ? <BigHall /> : <SmallHall />}
          </StyledHall>
        )}
        <StyledMapImage {...{ cinema }} />
      </StyledLeftHoverDetail>
    )
  )
})

export default compose(pure)(ScreeningHoverDetailLeft)
