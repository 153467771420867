import typeToReducer from 'type-to-reducer'
import * as ActionTypes from '../constants/ActionTypes'
import { initialState } from './filter'

export default typeToReducer(
  {
    [ActionTypes.SET_SAVED_FILTER]: (_, { payload: { savedFilter } }) =>
      savedFilter,
    [ActionTypes.RESET_SAVED_FILTER]: () => initialState,
  },
  initialState,
)
