import React from 'react'
import { pure } from 'recompose'
import { THEME_COLOR } from '../constants/colors'

const Heart = pure(props => (
  <svg width="27.809" height="24.55" viewBox="0 0 27.809 24.55" {...props}>
    <g transform="translate(0 -39)">
      <g transform="translate(0 39)">
        <path
          fill="#fff"
          d="M20.422,30a6.584,6.584,0,0,0-4.112,1.421A9.367,9.367,0,0,0,13.9,34.314,9.366,9.366,0,0,0,11.5,31.421,6.584,6.584,0,0,0,7.387,30C3.176,30,0,33.444,0,38.012c0,4.935,3.962,8.311,9.959,13.422,1.018.868,2.173,1.852,3.373,2.9a.869.869,0,0,0,1.144,0c1.2-1.049,2.354-2.033,3.373-2.9,6-5.111,9.959-8.487,9.959-13.421C27.809,33.444,24.633,30,20.422,30Z"
          transform="translate(0 -30)"
        />
      </g>
    </g>
  </svg>
))

export default Heart
