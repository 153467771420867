import { isEmpty, path, take } from 'ramda'
import { push, replace as routerReplace } from 'connected-react-router'

import { history } from '../store/middleware'
import locationToStack from '../utils/locationToStack'
import { ROUTE_STACK } from '../constants/routes'
import scrollToTop from '../utils/scrollToTop'
import { findIndex } from 'ramda'

const createNewStack = (stack, item) => {
  const index = findIndex(it => it.routeKey === item.routeKey, stack)
  console.log('push', {
    stack,
    item,
    index,
    indexItem: index !== -1 && stack[index],
  })
  return [...stack, item]
}

const replaceInStack = (stack = [], item) => {
  const index = findIndex(it => it.routeKey === item.routeKey, stack)
  console.log('replace', {
    stack,
    item,
    index,
    indexItem: index !== -1 && stack[index],
  })
  return isEmpty(stack) ? [item] : [...take(stack.length - 1, stack), item]
}

export const goTo = (location, clear = false) => {
  const stack = locationToStack({ state: history.location.state })
  const item = JSON.parse(path(['state'], location))

  const newStack =
    item.routeKey === ROUTE_STACK.MAIN
      ? []
      : clear
      ? [item]
      : createNewStack(stack || [], item)

  // console.log({
  //   location,
  //   state: history.location.state,
  //   stack,
  //   item,
  //   newStack,
  //   clear,
  //   toPush: { ...location, state: JSON.stringify(newStack) },
  // })

  setTimeout(() => {
    scrollToTop()
  }, 0)

  return push({ ...location, state: JSON.stringify(newStack) })
}

export const replace = (location = false) => {
  const stack = locationToStack({ state: history.location.state })
  const item = JSON.parse(path(['state'], location))

  const newStack =
    item.routeKey === ROUTE_STACK.MAIN ? [] : replaceInStack(stack || [], item)

  // console.log({
  //   location,
  //   state: history.location.state,
  //   stack,
  //   item,
  //   newStack,
  //   clear,
  //   toPush: { ...location, state: JSON.stringify(newStack) },
  // })

  setTimeout(() => {
    scrollToTop()
  }, 0)

  return routerReplace({ ...location, state: JSON.stringify(newStack) })
}
