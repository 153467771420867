const locationToStack = ({ state } = {}) => {
  if (!state) {
    return undefined
  }

  const stack = JSON.parse(state)

  return Array.isArray(stack) ? stack : undefined
}

export default locationToStack
